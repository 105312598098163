import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

export default (reducers) => {
  const persistedReducer = persistReducer(
    {
      key: 'kuppido',
      storage,
      whitelist: ['auth', 'user', 'lead', 'utils', 'dashboard', 'financial', 'clients', 'mgm'],
    },
    reducers,
  );

  return persistedReducer;
};
