import produce from 'immer';

const INITIAL_STATE = {
  indications: [],
  advertisers: [],
  loading: false,
  not_fount: false,
};

export default function mgm(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@mgm/INDEX_INDICATIONS':
        draft.loading = true;
        break;
      case '@mgm/INDEX_ADVERTISERS':
        draft.loading = true;
        break;
      case '@mgm/SET_INDICATIONS':
        draft.loading = false;
        draft.indications = action.payload.indications;
        break;
      case '@mgm/SET_ADVERTISERS':
        draft.loading = false;
        draft.advertisers = action.payload.advertisers;
        break;
      case '@mgm/NOT_FOUND':
        draft.loading = false;
        draft.not_fount = true;
        break;
      case '@mgm/END_ACTION':
        return INITIAL_STATE;
      default:
    }
  });
}
