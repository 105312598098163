import { Box } from '@chakra-ui/react';
import { Form } from '@rocketseat/unform';
import React, { useEffect, useState } from 'react';
import { MdClear } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';

import { updateNote } from '../../../stores/modules/lead/actions';
import { TextareaCustom } from '../../../styles/common';
import {
  CloseButton,
  ColumnContent,
  Container,
  InputCustom,
  Label,
  Modal,
  ModalButton,
  ModelTitle,
  RowContent,
} from '../styles';

export default function ProfileModalTwo({ visible, onChange, item }) {
  const [modal, setModal] = useState(visible);
  const lead_id = useSelector((state) => state.lead.lead.id);
  const dispatch = useDispatch();

  useEffect(() => {
    setModal(visible);
  }, [visible]);

  const handleVisible = () => onChange();

  const handleSubmit = ({ name, description }) => {
    const activity_id = item.id;
    dispatch(updateNote(activity_id, lead_id, name, description));
  };

  return (
    <Container visible={modal}>
      <Modal modelEmail>
        <CloseButton onClick={handleVisible}>
          <Box as={MdClear} w="35px" h="35px" />
        </CloseButton>
        <ModelTitle>Alterar dados</ModelTitle> <br />
        <Form
          initialData={{
            name: item && item.name,
            description: item && item.description,
          }}
          onSubmit={handleSubmit}
        >
          <ColumnContent>
            <Label>Titulo da atividade</Label>
            <InputCustom name="name" type="text" placeholder="Titulo da atividade" />
          </ColumnContent>

          <RowContent
          // margin="0 0 100px 0"
          >
            <ColumnContent>
              <Label>Informações adicionais</Label>
              <TextareaCustom
                multiline="true"
                name="description"
                placeholder="Informações adicionais"
              />
            </ColumnContent>
          </RowContent>

          <RowContent lastRow>
            <ColumnContent left button>
              <ModalButton outlineTheme onClick={handleVisible} type="button">
                Cancelar
              </ModalButton>
            </ColumnContent>

            <ColumnContent right button>
              <ModalButton type="submit">Confirmar</ModalButton>
            </ColumnContent>
          </RowContent>
        </Form>
      </Modal>
    </Container>
  );
}
