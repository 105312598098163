import { Box, Spinner } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { BiPencil } from 'react-icons/bi';
import { MdChevronLeft, MdSentimentDissatisfied } from 'react-icons/md';
import { RiCalendarTodoLine, RiDeleteBin2Line } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import ConfirmModal from '../../components/ConfirmModal';
import ContentActivity from '../../components/ContentActivity';
import ContentNote from '../../components/ContentNote';
import StatusLeadModal from '../../components/FunnelModal/StatusLead';
import LoseLeadModal from '../../components/LeadDetailModal/LoseLeads';
import { leadManagement } from '../../routes/routeMap';
import { destroyLead, showLead } from '../../stores/modules/lead/actions';
import { cleanAddress } from '../../stores/modules/utils/actions';
import { cinzaEscuro2, verdeKuppi, vermelhoClaro } from '../../styles/colors';
import { ContentDefault, SubmitButton, SwitchTableButton, Text } from '../../styles/common';
import { Col, Row } from '../../styles/grid';
import ActivityComponent from './activity';
import Address from './address';
import Alerts from './alerts';
import Info from './info';
import NoteComponent from './note';
import { Container, CustonLink, Title } from './styles';

export default function LeadsDetail() {
  const [content, setContent] = useState('info');
  const [content2, setContent2] = useState('note');
  const lead = useSelector((state) => state.lead.lead);
  const loading = useSelector((state) => state.lead.lead_loading);
  const notFoundLead = useSelector((state) => state.lead.lead_not_found);
  const status = useSelector((state) => state.lead.lead.funnel_status);
  const situation = useSelector((state) => state.lead.lead.situation);
  const dispatch = useDispatch();
  const { slug } = useParams();

  const [visibleConfirm, setVisibleConfirm] = useState(false);
  const handleVisibleConfirm = () => {
    setVisibleConfirm(!visibleConfirm);
  };

  const [activitiesAndNotes, setActivitiesAndNotes] = useState(undefined);
  const [concludedActivities, setConcludedActivities] = useState(undefined);

  useEffect(() => {
    if (lead !== undefined) {
      try {
        const activities = lead.kuppidoLeadActivity;
        const notes = lead.kuppidoLeadNote;
        const contated = activities.concat(notes);
        contated.sort((a, b) => {
          if (a.created_at < b.created_at) return 1;
          if (a.created_at > b.created_at) return -1;
          return 0;
        });

        const filteredConcluded = contated.filter((item) => item.is_concluded === true);

        const filteredUnconcluded = contated.filter((item) => item.is_concluded !== true);

        setActivitiesAndNotes(filteredUnconcluded);
        setConcludedActivities(filteredConcluded);
      } catch (err) {}
    }
  }, [lead]);

  useEffect(() => {
    dispatch(showLead(slug));
    dispatch(cleanAddress());
  }, [dispatch, slug]);

  useEffect(() => {
    dispatch(showLead(slug));
    dispatch(cleanAddress());
  }, [dispatch, slug]);

  const toggleContent = (newConten) => {
    if (content === 'info') {
      setContent(newConten);
    }
    if (content === 'address') {
      setContent(newConten);
    }
  };

  const toggleContent2 = (newConten) => {
    if (content2 === 'note') {
      setContent2(newConten);
    }
    if (content2 === 'activity') {
      setContent2(newConten);
    }
  };

  const [visibleOne, setVisibleOne] = useState(false);
  const toggleOne = () => setVisibleOne(!visibleOne);

  const [visibleTwo, setVisibleTwo] = useState(false);
  const toggleTwo = () => setVisibleTwo(!visibleTwo);

  function handleDelete() {
    dispatch(destroyLead(slug, true));
  }

  const pageContent = (
    <Container>
      <Row alignCenter justifyStart>
        <CustonLink color={cinzaEscuro2} to={leadManagement}>
          <Box as={MdChevronLeft} w="22px" h="22px" /> voltar
        </CustonLink>
      </Row>
      <Row alignCenter justifyStart>
        <Title>Gestão de Leads</Title>
        <Row width="auto" alignCenter justifyCenter className="ml-auto" margin="0 10px 0 0 ">
          {status !== null && (
            <SubmitButton onClick={toggleOne} padding="8px 20px" bgColor={vermelhoClaro}>
              Lead Perdido
            </SubmitButton>
          )}
        </Row>
        <Row onClick={handleVisibleConfirm} pointer width="auto" alignCenter justifyCenter>
          <Box as={RiDeleteBin2Line} w="22px" h="22px" />
          <Text margin="0 0 0 5px">Excluir Negócio</Text>
        </Row>
      </Row>
      <Row padding="20px" />
      <Row>
        <Col margin="0 5px 0 0" width="30%">
          <ContentDefault groupTop>
            <Row alignStart justifyStart>
              <Col width="auto" margin="0 20px 0 0">
                <SwitchTableButton
                  onClick={() => toggleContent('info')}
                  selected={content === 'info'}
                >
                  <Text>Informações</Text>
                  <div className="selected" />
                </SwitchTableButton>
              </Col>
              <Col width="auto">
                <SwitchTableButton
                  onClick={() => toggleContent('address')}
                  selected={content === 'address'}
                >
                  <Text>Endereço</Text>
                  <div className="selected" />
                </SwitchTableButton>
              </Col>
            </Row>
          </ContentDefault>
          <ContentDefault groupBottom>
            {content === 'address' && <Address />}
            {content === 'info' && <Info />}
          </ContentDefault>
        </Col>
        <Col transition margin="0 0 0 5px" width="70%">
          {status === 'enviar_contrato' && <Alerts slug={lead.slug} sendContract />}
          {situation === 'perdido' && <Alerts lose slug={lead.slug} />}
          {situation === 'rejeitou_outro_kuppido' && lead.is_show_reason_for_loss && (
            <Alerts lose slug={lead.slug} another />
          )}
          <ContentDefault groupTop>
            <Row alignStart justifyStart>
              <Col alignStart justifyCenter width="auto" margin="0 30px 0 0">
                <SwitchTableButton
                  onClick={() => toggleContent2('note')}
                  selected={content2 === 'note'}
                >
                  <Row alignStart justifyCenter>
                    <Box
                      as={BiPencil}
                      w="22px"
                      h="22px"
                      borderBottom={`2px solid ${cinzaEscuro2}`}
                    />
                    <Text margin="0 0 0 5px">Adicionar Observação</Text>
                  </Row>
                  <div className="selected" />
                </SwitchTableButton>
              </Col>
              <Col alignStart justifyCenter width="auto" margin="0 30px 0 0">
                <SwitchTableButton
                  onClick={() => toggleContent2('activity')}
                  selected={content2 === 'activity'}
                >
                  <Row alignStart justifyCenter>
                    <Box as={RiCalendarTodoLine} w="22px" h="22px" />
                    <Text margin="0 0 0 5px">Adicionar Atividade</Text>
                  </Row>
                  <div className="selected" />
                </SwitchTableButton>
              </Col>
            </Row>
          </ContentDefault>
          {content2 === 'note' && <NoteComponent disabled={status === null} />}
          {content2 === 'activity' && <ActivityComponent disabled={status === null} />}
          <Row padding="20px" />

          {/* ATIVAS */}

          <Col>
            <Text>Observações e Atividades</Text>
          </Col>
          <Col margin="10px 0">
            {activitiesAndNotes && activitiesAndNotes.length > 0
              ? activitiesAndNotes.map((item, index) =>
                  item.type ? (
                    <ContentActivity key={index} item={item} disabled={status === null} />
                  ) : (
                    <ContentNote key={index} item={item} disabled={status === null} />
                  ),
                )
              : 'Esse lead não possui nenhuma observação ou atividade pendente no momento.'}
          </Col>

          {/* CONCLUÍDAS */}

          <Col>
            <Text>Atividades Concluídas</Text>
          </Col>
          <Col margin="10px 0">
            {concludedActivities && concludedActivities.length > 0
              ? concludedActivities.map((item, index) => (
                  <ContentActivity key={index} item={item} disabled={status === null} />
                ))
              : 'Esse lead não possui nenhuma atividade concluída no momento.'}
          </Col>
        </Col>
      </Row>
      <LoseLeadModal
        visible={visibleOne}
        onChange={() => {
          setVisibleOne(false);
          toggleTwo();
        }}
        slug={slug}
      />
      <ConfirmModal
        onHandleSubmit={handleDelete}
        onHandleVisible={handleVisibleConfirm}
        visible={visibleConfirm}
      />
      <StatusLeadModal situationProps="perdido" visible={visibleTwo} onChange={toggleTwo} />
    </Container>
  );

  const loader = (
    <Col height="85vh" alignCenter justifyCenter>
      <Spinner w="50px" h="50px" color={verdeKuppi} />
    </Col>
  );

  const notFoundLeadContent = (
    <Col height="85vh" alignCenter justifyCenter>
      <Row alignCenter justifyCenter>
        <Box as={MdSentimentDissatisfied} w="22px" h="22px" />
        <Text margin="0 0 0 5px">O Lead não pôde ser encontrado.</Text>
      </Row>
    </Col>
  );

  if (loading) {
    return loader;
  }
  if (notFoundLead) {
    return notFoundLeadContent;
  }
  return pageContent;
}
