import styled from '@emotion/styled';
import { Form, Input, Select } from '@rocketseat/unform';
import React from 'react';
import { Link } from 'react-router-dom';

import py2vw from '../utils/px2vw';
import {
  branco,
  cinzaClaro2,
  cinzaClaro3,
  cinzaEscuro,
  cinzaEscuro2,
  cinzaMedio,
  cinzaMedio2,
  verdeKuppi,
} from './colors';

export const Button = styled('button')`
  border: none !important;
  box-shadow: none !important;
  background: transparent !important;

  :focus {
    background: transparent !important;
  }
`;

export const SubmitButton = styled('button')<any>`
  font-family: AvenirNextLTPro;
  font-weight: 700;
  background: ${verdeKuppi} !important;
  color: ${branco} !important;
  border-radius: 10px;
  border: none;
  padding: 12.5px 40px;
  transition: all 0.2s ease-in-out;

  ${(props) => props.bgColor && `background: ${props.bgColor} !important;`};
  ${(props) => props.padding && `padding: ${props.padding}`};
  ${(props) => props.height && `height: ${props.height}`};
  ${(props) => props.width && `width: ${props.width}`};

  :active {
    filter: brightness(95%);
  }

  :disabled {
    background: ${verdeKuppi}98 !important;
    cursor: not-allowed;
  }
`;

export const Label = styled('div')<any>`
  font-weight: bold;
  margin-bottom: 5px;
  text-align: left;
  font-size: 15px;
  font-family: AvenirNextLTPro;
  font-weight: 700;
`;

export const CustomForm = styled(Form)`
  width: 100%;
`;

export const Error = styled('div')<any>`
  margin-top: 5px;
  width: 100%;
  height: 21px;
  font-size: 14px;
  color: red;
  text-align: left;
`;

export const InputCustom = styled(Input)`
  border: 1px solid ${(props) => (props.onError ? 'red' : cinzaEscuro)} !important;
  padding: 7px 10px !important;
  height: auto !important;
  width: 100%;
  margin: 0 !important;
  border-radius: 5px !important;

  box-sizing: inherit !important;
  font-size: 14px !important;

  ::placeholder {
    color: ${cinzaMedio2} !important;
    font-size: 12px;
  }

  :focus {
    border: 1px solid ${(props) => (props.onError ? 'red' : cinzaEscuro)} !important;
    box-shadow: none !important;
  }

  :disabled {
    background: ${cinzaClaro3} !important;
  }
`;

export const InputCustomInvisible = styled(Input)<any>`
  height: auto !important;
  border: none !important;
  padding: 7px 10px 8px 10px !important;
  margin: 0 !important;
  width: 100%;
  box-sizing: inherit !important;
  font-size: 14px !important;

  ${(props) => props.padding && `padding: ${props.padding} !important;`}

  ::placeholder {
    color: ${cinzaMedio2} !important;
    font-size: 14px;
  }

  :focus {
    border: none !important;
    box-shadow: none !important;
  }
`;

export const SelectCustomInvisible = styled(Select)`
  border: none !important;
  margin: 0 !important;
  width: 100% !important;
  display: inherit;
  background: ${branco} !important;
  color: ${cinzaMedio2} !important;
  height: 56px !important;
  max-height: 56px !important;
  box-sizing: inherit !important;
  font-size: 14px !important;

  ::placeholder {
    color: ${cinzaMedio2} !important;
    font-size: 14px !important;
  }

  :focus {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
  }

  option {
    color: ${cinzaMedio2} !important;
  }
`;

export const TextareaCustomInvisible = styled(Input)`
  border: none !important;
  padding: 7px 10px !important;
  min-height: 65px;
  margin: 0 !important;
  width: 100%;

  box-sizing: inherit !important;
  font-size: 14px !important;

  ::placeholder {
    color: ${cinzaMedio2} !important;
    font-size: 14px;
  }

  :focus {
    border: none !important;
    box-shadow: none !important;
  }
`;

export const SelectCustom = styled(Select)`
  border: 1px solid ${(props) => (props.onError ? 'red' : cinzaEscuro)} !important;
  padding: 5px 10px !important;
  height: 37px !important;
  margin: 0 !important;
  border-radius: 5px !important;
  width: 100% !important;
  display: inherit;
  background: ${branco} !important;

  box-sizing: inherit !important;
  font-size: 14px !important;

  ::placeholder {
    color: ${cinzaMedio2} !important;
    font-size: 12px;
  }

  :focus {
    border: 1px solid ${(props) => (props.onError ? 'red' : cinzaEscuro)} !important;
    box-shadow: none !important;
    border-radius: 5px !important;
    outline: none !important;
  }

  :disabled {
    background: ${cinzaClaro3} !important;
  }
`;

export const TextareaCustom = styled(Input)`
  border: 1px solid ${(props) => (props.onError ? 'red' : cinzaEscuro)} !important;
  padding: 5px 10px !important;
  min-height: 100px;
  margin: 0 !important;
  width: 100%;
  box-sizing: inherit !important;
  font-size: 14px !important;
  border-radius: 5px !important;

  ::placeholder {
    color: ${cinzaMedio2} !important;
    font-size: 14px;
  }

  :focus {
    border: 1px solid ${(props) => (props.onError ? 'red' : cinzaEscuro)} !important;
  }

  :disabled {
    background: ${cinzaClaro3} !important;
  }
`;

export const ContentDefault = styled('div')<any>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  background: ${branco};
  width: 100%;
  height: 100%;
  padding: 10px;
  border-radius: 15px;
  margin-bottom: 30px;

  /* ****** RIGHT ****** */

  ${(props) => props.alignStart && `align-items: flex-start;`}
  ${(props) => props.justifyStart && `justify-content: flex-start;`}

  /* ****** CENTER ****** */

  ${(props) => props.alignCenter && `align-items: center;`}
  ${(props) => props.justifyCenter && `justify-content: center;`}

  /* ****** LEFT ****** */

  ${(props) => props.alignEnd && `align-items: flex-end;`}
  ${(props) => props.justifyEnd && `justify-content: flex-end;`}

  ${(props) =>
    props.groupTop &&
    `
    border-radius: 0px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    margin-bottom: 1px;
  `}

  ${(props) =>
    props.groupMiddle &&
    `
    border-radius: 0px;
    margin-top: 1px;
    margin-bottom: 1px;
  `}

  ${(props) =>
    props.groupBottom &&
    `
    margin-top: 1px;
    border-radius: 0px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    margin-bottom: 30px;
  `}

  ${(props) => props.margin && `margin: ${props.margin};`}
  ${(props) => props.bgColor && `background: ${props.bgColor};`}
  ${(props) => props.padding && `padding: ${props.padding};`}

  .MuiPaper-elevation1 {
    box-shadow: none;
  }
  .MuiTableCell-root {
  }
  button.MuiButtonBase-root {
    :focus {
      background: transparent;
    }
  }

  .MuiTableRow-root.Mui-selected,
  .MuiTableRow-root.Mui-selected:hover {
    background-color: transparent;
  }

  .MuiTableRow-head {
    th {
      font-size: ${py2vw(11)};
      font-family: AvenirNextLTPro;
      font-weight: 700;
    }
  }

  .ml-auto {
    margin-left: auto;
  }
  .mr-auto {
    margin-right: auto;
  }
  .mt-auto {
    margin-top: auto;
  }
  .mb-auto {
    margin-bottom: auto;
  }
`;

export const SwitchTableButton = styled('div')<any>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: ${py2vw(16)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  cursor: pointer;

  .selected {
    margin-top: 5px;
    width: 100%;
    height: 4px;
    background: transparent;
    border-radius: 10px;
  }

  ${(props) =>
    props.selected &&
    `
    .selected {
      background: ${verdeKuppi};
    }
  `}

  ${(props) => props.disabled && `color: ${cinzaMedio} !important;`}
  ${(props) => props.size && `font-size: ${props.size};`}
`;

export const Text = styled('div')<any>`
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: AvenirNextLTPro;
  font-weight: 700;

  ${(props) => props.padding && `padding: ${props.padding};`}
  ${(props) => props.display && `display: ${props.display};`}
  ${(props) => props.textAlign && `text-align: ${props.textAlign};`}
  ${(props) => props.size && `font-size: ${props.size};`}
  ${(props) => props.color && `color: ${props.color};`}
  ${(props) => props.margin && `margin: ${props.margin};`}
  ${(props) => props.bold && `font-family: AvenirNextLTPro; font-weight: 700;`}
  ${(props) => props.regular && `font-weight: 400;`}
`;

export const Text2 = styled('div')<any>`
  font-size: 16px;
  font-family: AvenirNextLTPro;

  ${(props) => props.textAlign && `text-align: ${props.textAlign};`}
  ${(props) => props.width && `width: ${props.width};`}
  ${(props) => props.size && `font-size: ${props.size};`}
  ${(props) => props.color && `color: ${props.color};`}
  ${(props) => props.margin && `margin: ${props.margin};`}
  ${(props) => props.bold && `font-family: AvenirNextLTPro; font-weight: 700;`}
  ${(props) => props.regular && `font-weight: 400;`}

  .bold {
    font-family: AvenirNextLTPro;
    font-weight: 700;
  }

  .fs-15 {
    font-size: 15px !important;
  }

  .fs-16 {
    font-size: 16px !important;
  }
`;

export const Vr = styled('div')<any>`
  height: 100px;
  width: 1px;
  background: ${cinzaClaro2};
`;

/** ****************** */

const StyledLink = styled(Link)<any>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  width: 100%;
  height: 100%;
  text-decoration: none !important;
  ${(props) => props.size && `font-size: ${props.size} !important;`}
  ${(props) => props.width && `width: ${props.width} !important;`}
  ${(props) => props.height && `height: ${props.height} !important;`}
  ${(props) => props.color && `color: ${props.color} !important;`}
  ${(props) => props.padding && `padding: ${props.padding};`}

  ${(props) => props.noStyle && `font-size: 14px; color: ${cinzaEscuro2}; `}
`;

export const CustonLink = ({ ...props }) => <StyledLink {...props} />;

export const LabelField = styled('div')<any>`
  font-size: ${py2vw(13)};

  margin-bottom: 12px;
  ${(props) => props.margin && `margin: ${props.margin};`}
  ${(props) => props.bold && `font-family: AvenirNextLTPro; font-weight: 700;`}
`;

export const BigText2 = styled('div')<any>`
  font-size: ${py2vw(28)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
`;

export const TextField = styled('div')<any>`
  font-size: 15px;
`;

export const LoadingBox = styled('div')<any>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const CloseButton = styled('button')`
  border: none;
  font-size: 20px;
  background: transparent;
  position: absolute;
  top: 20px;
  right: 30px;

  :focus {
    background: transparent;
  }

  i {
    font-size: 34px;
  }
`;

export const CloseButton2 = styled('button')`
  border: none;
  font-size: 20px;
  background: transparent;

  :focus {
    background: transparent;
  }

  i {
    font-size: 25px;
  }
`;

export const Hr = styled('div')<any>`
  border-top: 2px solid ${cinzaClaro3};
  height: 1px;
  width: 100%;
`;

export const ImageBox = styled('div')<any>`
  position: relative;
  ${(props) => props.width && `width: ${props.width};`}
  ${(props) => props.height && `height: ${props.height};`}
`;

export const Image = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
`;
