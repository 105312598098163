export default function DragAndDrop(handleBodyHeight, handleChangeCardStatus) {
  /** VARS */
  const cards = document.querySelectorAll('#funnelMain .card');
  const dropzones = document.querySelectorAll('#funnelMain .dropzone');

  let draggedCard = 0;
  let cardStatus = 0;

  /** CARDS */

  function dragstart() {
    dropzones.forEach((dropzone) => dropzone.classList.add('highlight'));
    this.classList.add('is-dragging');
    cardStatus = this.id;
  }

  function drag() {}

  function dragend() {
    dropzones.forEach((dropzone) => dropzone.classList.remove('highlight'));
    this.classList.remove('is-dragging');
    handleChangeCardStatus(cardStatus, draggedCard);
    handleBodyHeight();
  }

  /** DROPZONES */

  function dragenter() {}

  function dragover(event) {
    event.preventDefault();
    this.classList.add('over');
    const cardDragged = document.querySelector('.is-dragging');
    try {
      this.appendChild(cardDragged);
    } catch (err) {}
    draggedCard = this.id;
  }

  function dragleave() {
    this.classList.remove('over');
  }

  function drop() {
    this.classList.remove('over');
  }

  cards.forEach((card) => {
    card.addEventListener('dragstart', dragstart);
    card.addEventListener('drag', drag);
    card.addEventListener('dragend', dragend);
  });

  dropzones.forEach((dropzone) => {
    dropzone.addEventListener('dragenter', dragenter);
    dropzone.addEventListener('dragover', dragover);
    dropzone.addEventListener('dragleave', dragleave);
    dropzone.addEventListener('drop', drop);
  });
}
