import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { updateFunnelStatus } from '../../stores/modules/lead/actions';
import { Row } from '../../styles/grid';
import { Bolt, Bolt2, SvgBox } from './icons';
import { statusConfig } from './status';
import { Container, Content, Select, Text } from './styles';

export default function TableStatus({ lead }) {
  const [selected, setSelected] = useState('');
  const [
    status,
    // setStatus
  ] = useState(lead.funnel_status);
  const [selectedAll, setSelectedAll] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    statusConfig.map((item) => item.status === status && setSelected(item));
  }, [status]);

  const toggle = () => setSelectedAll(!selectedAll);

  const handleChangeOption = (selectedOption) => {
    setSelected(selectedOption);
    toggle();
    dispatch(updateFunnelStatus(lead.slug, selectedOption.status));
  };

  const withStatus = (
    <Container selectedAll={selectedAll} selected={selected}>
      <Select selectedAll={selectedAll}>
        <Content onClick={toggle}>
          <SvgBox selectedAll={selectedAll}>
            <Bolt d="M4,9H17l-1.6,1.2a1,1,0,0,0,1.2,1.6l4-3a1,1,0,0,0,0-1.59l-3.86-3a1,1,0,1,0-1.23,1.58L17.08,7H4A1,1,0,0,0,4,9Z" />
            <Bolt2 d="M20,16H7l1.6-1.2a1,1,0,0,0-1.2-1.6l-4,3a1,1,0,0,0,0,1.59l3.86,3a1,1,0,0,0,1.23-1.58L6.92,18H20a1,1,0,0,0,0-2Z" />
          </SvgBox>
          <Text>{selected.text || 'Clique para escolher'}</Text>
        </Content>
        {statusConfig.map((option) => (
          <Content key={option.id} onClick={() => handleChangeOption(option)} className="options">
            <SvgBox />
            <Text>{option.text}</Text>
          </Content>
        ))}
      </Select>
    </Container>
  );

  const noStatus = (
    <Row alignStart justifyStart padding="0 0 0 5px">
      Sem Status
    </Row>
  );

  return status && status !== 'ganho' ? withStatus : noStatus;
}
