import styled from '@emotion/styled';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';

import { cinzaClaro3, cinzaMedio2, verdeKuppi } from '../../styles/colors';
import { Row } from '../../styles/grid';
import px2vw from '../../utils/px2vw';

export const Container = styled.div``;

export const Header = styled(Row)`
  justify-content: flex-start;
  align-items: flex-start;
  padding: 25px 0;
  padding: ${(props) => props.padding};
  color: ${verdeKuppi};
  font-family: AvenirNextLTPro;
  font-weight: 700;

  ${(props) =>
    props.center &&
    `justify-content: center;
     align-items: center;
  `}
`;

export const HeaderButton = styled.button`
  /* width: 100%; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: ${verdeKuppi};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;

  ${(props) =>
    props.center &&
    `justify-content: center;
     align-items: center;
  `}
`;

export const Content = styled(Row)`
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px 0;

  ${(props) =>
    props.center &&
    `justify-content: center;
     align-items: center;
  `}
`;

export const TableRow = styled(Row)`
  :hover {
    background: ${cinzaClaro3};
  }
`;

export const Hr = styled.div`
  border-top: 2px solid ${cinzaClaro3};
  height: 1px;
  width: 100%;
`;

export const PagButton = styled.button`
  width: ${px2vw(40)};
  height: ${px2vw(40)};
  border-radius: ${px2vw(40)};
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;

  :hover {
    ${(props) =>
      props.disabled
        ? `background: transparent !important; cursor: not-allowed !important`
        : `background: ${cinzaClaro3} !important`};
  }
`;

export const PrevIcon = styled(AiOutlineLeft)`
  color: ${(props) => props.disabled && cinzaMedio2};
`;
export const NextIcon = styled(AiOutlineRight)`
  color: ${(props) => props.disabled && cinzaMedio2};
`;
