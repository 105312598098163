import styled from '@emotion/styled';

import { branco, cinzaClaro2 } from '../../styles/colors';
import { Button } from '../../styles/common';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 180px;
  height: 28px;
  position: relative;
  transition: all 0.2s ease-in-out;

  ${(props) => props.selectedAll && `z-index: 4;`}
`;

export const Select = styled.div`
  background: transparent;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border-radius: 2px;
  padding: 0;
  margin: 0;

  ${(props) =>
    props.selectedAll && `box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.15) ; background: ${branco};`}

  .options {
    transition: all 0.2s ease-in-out;

    ${(props) =>
      !props.selectedAll
        ? `
          opacity: 0;
          height: 0;
          padding: 0;
      `
        : `
          z-index: 4;
          height: 100%;
          opacity: 1;
          padding-right: 10px;
          background: ${branco} !important;
      `}
    :hover {
      background: ${cinzaClaro2} !important;
    }
  }
`;

export const Text = styled.div``;

export const Content = styled(Button)`
  padding: 2px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;
