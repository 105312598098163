import * as yup from 'yup';

export default yup.object().shape({
  zipcode: yup
    .string()
    .required('Por favor informe o CEP do endereço do anunciante.')
    .min(10, 'CEP é inválido.'),
  street: yup.string().required('Por favor informe o endereço do anunciante.').max(255),
  number: yup.string().required('Por favor informe o número do endereço do anunciante.').max(10),
  neighborhood: yup
    .string()
    .required('Por favor informe o bairro do endereço do anunciante.')
    .max(100),
  complement: yup.string().max(255),
  city: yup.string().required('Por favor informe a cidade do endereço do anunciante.').max(100),
  state: yup.string().required('Por favor informe a cidade do endereço do anunciante.').max(2),
});
