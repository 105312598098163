import styled from '@emotion/styled';

import { amarelo, branco, cinzaMedio2, verde, verdeKuppi, vermelho } from '../../styles/colors';
import py2vw from '../../utils/px2vw';

export const Title = styled.div`
  font-family: AvenirNextLTPro;
  font-weight: 700;
  font-size: ${py2vw(13)};
`;

export const TagText = styled.div`
  font-size: ${py2vw(11)};

  ${(props) => props.tag === 'noActiviTy' && `margin-left: 5px`}
`;

export const FunnelItem = styled.div`
  position: relative;
  margin: 10px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background: ${branco};
  border-radius: 15px;
  padding: 10px;
  min-height: ${py2vw(90)};
  width: 100%;
`;

export const InfoTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  font-weight: 600;
  font-size: ${py2vw(11)};
  padding: 1px 4px;
  height: ${py2vw(20)};
  width: auto;

  ${(props) =>
    props.tag === 'forTheDay' &&
    `
    border: 2px solid ${verde};
    border-radius: 5px;
  `}
  ${(props) =>
    props.tag === 'noActiviTy' &&
    `
    border: 2px solid ${amarelo};
    border-radius: 5px;
  `}
  ${(props) =>
    props.tag === 'forTheFuture' &&
    `
    border: 2px solid ${cinzaMedio2};
    border-radius: 5px;
  `}
  ${(props) =>
    props.tag === 'late' &&
    `
    border: 2px solid ${vermelho};
    border-radius: 5px;
  `}
`;

export const SubmitButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  font-weight: 600;
  font-size: ${py2vw(12)};
  padding: 1px 4px;
  height: ${py2vw(20)};
  width: auto;
  border-radius: 5px;
  border: 2px solid ${verdeKuppi};
  background: ${verdeKuppi};
  cursor: pointer;
  color: ${branco};
`;
