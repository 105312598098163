import styled from '@emotion/styled';

import { cinzaEscuro2 } from '../../styles/colors';

export const SvgBox = styled.svg`
  height: 24px;
  width: 24px;
  margin-right: 2px;

  path {
    fill: ${cinzaEscuro2};
    /* transform: scale(0.8); */
  }
`;

export const Bolt = styled.path``;

export const Bolt2 = styled.path``;
