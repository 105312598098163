import { all, call, put, takeLatest } from 'redux-saga/effects';

import { apiGlobal } from '../../../services/api';
import { endAction, setAddress } from './actions';
import { logout } from '../auth/actions';

export function* getAddress({ payload }) {
  try {
    const { zipcode } = payload;
    const response = yield call(apiGlobal.post, `/query-zipcode`, {
      zipcode,
    });
    const address = response.data;
    yield put(setAddress(address));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(endAction());
    }
  }
}

export default all([takeLatest('@utils/GET_ADDRESS', getAddress)]);
