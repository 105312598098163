import { Box, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { toast } from 'react-toastify';

import { apiKuppido } from '~/services/api';

import Button from '../Button';
import Modal from '../Modal';
import { SvgBox, Verified } from './styles';

interface ResendContractModalProps {
  isOpen: boolean;
  onClose: () => void;
  itemId: number;
}

const ResendContractModal: React.FC<ResendContractModalProps> = ({ isOpen, onClose, itemId }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const resendContract = async () => {
    try {
      setLoading(true);
      const response = await apiKuppido.get(`/contract/${itemId}/resend`);
      setSuccess(response.status === 200);
    } catch (err) {
      toast.error('Não foi possível reenviar o contrato.');
    } finally {
      setLoading(false);
    }
  };

  const onCloseModal = () => {
    onClose();
    setLoading(false);
    setSuccess(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onCloseModal}
      modalContentProps={{ minW: '16rem', maxW: '24rem' }}
      headerText={!success ? 'Reenviar contrato' : null}
      isCentered
    >
      {success ? (
        <Box d="flex" flexDir="column" p={4} justifyContent="center" alignItems="center">
          <SvgBox>
            <Verified
              transform="translate(-2 -2)"
              d="M42,2A40,40,0,1,0,82,42,40,40,0,0,0,42,2ZM59.2,32.44l-18.28,24a4.012,4.012,0,0,1-6.32.04L24.84,44.04a4,4,0,0,1,6.32-4.92l6.52,8.32,15.12-20a4.024,4.024,0,0,1,6.4,4.88Z"
            />
          </SvgBox>
          <Text mt={4} fontSize="lg" fontWeight="700" textAlign="center">
            Contrato reenviado com sucesso!
          </Text>
        </Box>
      ) : (
        <Box d="flex" flexDir="column" py={4}>
          <Text mb={4} fontSize="md">
            Você tem certeza que deseja reenviar o contrato?
          </Text>
          <Box d="flex" justifyContent="space-between">
            <Button
              variant="outline"
              colorScheme="black"
              onClick={onClose}
              mr={4}
              isDisabled={loading}
            >
              Cancelar
            </Button>
            <Button onClick={resendContract} isLoading={loading}>
              Confirmar
            </Button>
          </Box>
        </Box>
      )}
    </Modal>
  );
};

export default ResendContractModal;
