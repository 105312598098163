import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { leadsByStatus, updateFunnelStatus } from '../../stores/modules/lead/actions';
import { Col, Row } from '../../styles/grid';
import DragAndDrop from '../../utils/DragAndDrop';
import FunelItem from '../FunelItem';
import { Container, Count, FunnelBody, FunnelHeader, Title } from './styles';

export default function Funnel() {
  const [funnelBodyHeight, setFunnelBodyHeight] = useState('');
  const allLeads = useSelector((state) => state.lead.all_leads);
  const [leads, setLeads] = useState(allLeads);
  const dispatch = useDispatch();
  const [potentialCustomers, setPotentialCustomers] = useState(0);
  const [contacted, setContacted] = useState(0);
  const [meetingScheduled, setMeetingScheduled] = useState(0);
  const [proposalSent, setProposalSent] = useState(0);
  const [inNegociation, setInNegociation] = useState(0);
  const [submitContract, setSubmitContract] = useState(0);

  const [cartSlug, setCartSlug] = useState(undefined);
  const [cartFunnelStatus, setCartFunnelStatus] = useState(undefined);

  useEffect(() => {
    setLeads(allLeads);
  }, [allLeads]);

  useEffect(() => {
    try {
      setPotentialCustomers(
        document.querySelector('#funnelMain #cliente_potencial').childElementCount,
      );
      setContacted(document.querySelector('#funnelMain #contactado').childElementCount);
      setMeetingScheduled(document.querySelector('#funnelMain #reuniao_marcada').childElementCount);
      setProposalSent(document.querySelector('#funnelMain #proposta_enviada').childElementCount);
      setInNegociation(document.querySelector('#funnelMain #em_negociacao').childElementCount);
      setSubmitContract(
        document.querySelector('#funnelMain #enviar_contrato').childElementCount -
          document.querySelectorAll('#funnelMain #enviar_contrato #SendContractModal').length,
      );
    } catch (err) {}
  }, [cartSlug, cartFunnelStatus]);

  useEffect(() => {
    if (cartFunnelStatus && cartSlug) {
      dispatch(updateFunnelStatus(cartSlug, cartFunnelStatus));

      // setLeads((prevState) =>
      //   prevState.map((lead) =>
      //     lead.slug === cartSlug ? { ...lead, funnel_status: cartFunnelStatus } : lead,
      //   ),
      // );
    }
  }, [cartSlug, cartFunnelStatus, dispatch]);

  const handleBodyHeight = useCallback(() => {
    setFunnelBodyHeight(
      document.getElementById('funnelMain').clientHeight -
        document.getElementById('funnelHeader').clientHeight,
    );
  }, []);

  function handleChangeCardStatus(slug, funel_status) {
    setCartSlug(slug);
    setCartFunnelStatus(funel_status);
  }
  const currentDragAndDrop = useCallback(() => {
    DragAndDrop(handleBodyHeight, handleChangeCardStatus);
  }, [handleBodyHeight]);

  useEffect(() => {
    dispatch(leadsByStatus(leads));
    currentDragAndDrop();
    setFunnelBodyHeight(
      document.getElementById('funnelMain').clientHeight -
        document.getElementById('funnelHeader').clientHeight,
    );
  }, [dispatch, leads, currentDragAndDrop]);

  return (
    <Container>
      <Row id="funnelMain">
        <Col right alignStart justifyCenter id="potentialCustomers">
          <FunnelHeader id="funnelHeader">
            <Title>Clientes potenciais</Title>
            <Count>{potentialCustomers} Negócios</Count>
          </FunnelHeader>
          <FunnelBody id="cliente_potencial" className="dropzone" height={funnelBodyHeight}>
            {leads &&
              leads.map(
                (item) => item.funnel_status === 'cliente_potencial' && <FunelItem lead={item} />,
              )}
          </FunnelBody>
        </Col>

        <Col right alignStart justifyCenter id="contacted">
          <FunnelHeader>
            <Title>Contactados</Title>
            <Count>{contacted} Negócios</Count>
          </FunnelHeader>
          <FunnelBody id="contactado" className="dropzone" height={funnelBodyHeight}>
            {leads &&
              leads.map((item) => item.funnel_status === 'contactado' && <FunelItem lead={item} />)}
          </FunnelBody>
        </Col>

        <Col right alignStart justifyCenter id="meetingScheduled">
          <FunnelHeader>
            <Title>Reunião marcada</Title>
            <Count>{meetingScheduled} Negócios</Count>
          </FunnelHeader>
          <FunnelBody id="reuniao_marcada" className="dropzone" height={funnelBodyHeight}>
            {leads &&
              leads.map(
                (item) => item.funnel_status === 'reuniao_marcada' && <FunelItem lead={item} />,
              )}
          </FunnelBody>
        </Col>

        <Col right alignStart justifyCenter id="proposalSent">
          <FunnelHeader>
            <Title>Proposta enviada</Title>
            <Count>{proposalSent} Negócios</Count>
          </FunnelHeader>
          <FunnelBody id="proposta_enviada" className="dropzone" height={funnelBodyHeight}>
            {leads &&
              leads.map(
                (item) => item.funnel_status === 'proposta_enviada' && <FunelItem lead={item} />,
              )}
          </FunnelBody>
        </Col>

        <Col right alignStart justifyCenter id="inNegociation">
          <FunnelHeader>
            <Title>Em negociação</Title>
            <Count>{inNegociation} Negócios</Count>
          </FunnelHeader>
          <FunnelBody id="em_negociacao" className="dropzone" height={funnelBodyHeight}>
            {leads &&
              leads.map(
                (item) => item.funnel_status === 'em_negociacao' && <FunelItem lead={item} />,
              )}
          </FunnelBody>
        </Col>

        <Col center alignStart justifyCenter id="submitContract">
          <FunnelHeader>
            <Title>Enviar contrato</Title>
            <Count>{submitContract} Negócios</Count>
          </FunnelHeader>
          <FunnelBody id="enviar_contrato" className="dropzone" height={funnelBodyHeight}>
            {leads &&
              leads.map(
                (item) => item.funnel_status === 'enviar_contrato' && <FunelItem lead={item} />,
              )}
          </FunnelBody>
        </Col>
      </Row>
    </Container>
  );
}
