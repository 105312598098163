import { Box, Text } from '@chakra-ui/react';
import React from 'react';

interface StepsProps {
  step: number;
}

const Steps: React.FC<StepsProps> = ({ step }) => {
  return (
    <Box d="flex" alignItems="center" justifyContent="center" mt="1rem" mb="3rem">
      <Box
        mx="20px"
        w="51px"
        h="51px"
        border="4px solid"
        borderColor="green.500"
        borderRadius="50%"
        d="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Text color="green.500" fontWeight="bold" fontSize="1.5rem">
          1
        </Text>
      </Box>
      <Box
        borderRadius="10px"
        border="2px solid"
        borderColor={step > 1 ? 'green.500' : 'gray.200'}
        w="122px"
      />
      <Box
        mx="20px"
        w="51px"
        h="51px"
        border="4px solid"
        borderColor={step > 1 ? 'green.500' : 'gray.200'}
        borderRadius="50%"
        d="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Text color={step > 1 ? 'green.500' : 'gray.200'} fontWeight="bold" fontSize="1.5rem">
          2
        </Text>
      </Box>
      <Box
        borderRadius="10px"
        border="2px solid"
        borderColor={step > 2 ? 'green.500' : 'gray.200'}
        w="122px"
      />
      <Box
        mx="20px"
        w="51px"
        h="51px"
        border="4px solid"
        borderColor={step > 2 ? 'green.500' : 'gray.200'}
        borderRadius="50%"
        d="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Text color={step > 2 ? 'green.500' : 'gray.200'} fontWeight="bold" fontSize="1.5rem">
          3
        </Text>
      </Box>
      <Box
        borderRadius="10px"
        border="2px solid"
        borderColor={step > 3 ? 'green.500' : 'gray.200'}
        w="122px"
      />
      <Box
        mx="20px"
        w="51px"
        h="51px"
        border="4px solid"
        borderColor={step > 3 ? 'green.500' : 'gray.200'}
        borderRadius="50%"
        d="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Text color={step > 3 ? 'green.500' : 'gray.200'} fontWeight="bold" fontSize="1.5rem">
          4
        </Text>
      </Box>
    </Box>
  );
};

export default Steps;
