import styled from '@emotion/styled';

import { situationConfig } from './situations';

export const Ball = styled.div`
  height: 13px;
  width: 13px;
  border-radius: 10px;
  background: ${(props) => props.situation && situationConfig[props.situation].color};

  ${(props) =>
    props.big &&
    `
    height: 25px;
    width: 25px;
    border-radius: 20px;
  `};
`;

export const Text = styled.div`
  margin-left: 5px;

  font-size: 14px;
  color: ${(props) => props.situation && situationConfig[props.situation].color};

  ${(props) => props.big && `font-size: 22px; font-family: AvenirNextLTPro; font-weight: 700;`};
`;
