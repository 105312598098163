export const situationConfig = {
  ganho: { text: 'Ganho', color: '#2FFE60' },
  disponivel: { text: 'Disponível', color: '#26AB81' },
  foi_cliente: { text: 'Foi cliente', color: '#2D6E3D' },
  rejeitou_outro_kuppido: { text: 'Rejeitou outro Kuppido', color: '#F9AE00' },
  outro_kuppido_em_contato: {
    text: 'Outro Kuppido em contato',
    color: '#bfbfbf',
  },
  ja_e_cliente: { text: 'Ja é cliente', color: '#707070' },
  perdido: { text: 'Perdido', color: '#FF4343' },
};
