import { css, Global } from '@emotion/react';
import React from 'react';

import { cinzaEscuro2 } from './colors';

const globalCss = css`
  /* ********** AvenirNextLTPro ********** */

  @font-face {
    font-family: 'AvenirNextLTPro';
    src: url('/assets/fonts/AvenirNext/AvenirNextLTPro-Italic.otf');
    font-weight: normal;
    font-style: italic;
  }
  @font-face {
    font-family: 'AvenirNextLTPro';
    src: url('/assets/fonts/AvenirNext/AvenirNextLTPro-Regular.otf');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'AvenirNextLTPro';
    src: url('/assets/fonts/AvenirNext/AvenirNextLTPro-Bold.otf');
    font-weight: 700;
    font-style: normal;
  }
  @font-face {
    font-family: 'AvenirNextLTPro';
    src: url('/assets/fonts/AvenirNext/AvenirNextLTPro-Medium.otf');
    font-weight: 600;
    font-style: normal;
  }
  @font-face {
    font-family: 'AvenirNextLTPro';
    src: url('/assets/fonts/AvenirNext/AvenirNextLTPro-MediumItalic.otf');
    font-weight: 600;
    font-style: italic;
  }

  /* ********** CircularStd ********** */

  @font-face {
    font-family: 'CircularStd';
    src: url('/assets/fonts/Circular/CircularStd-BookItalic.otf');
    font-weight: normal;
    font-style: italic;
  }
  @font-face {
    font-family: 'CircularStd';
    src: url('/assets/fonts/Circular/CircularStd-Book.otf');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'CircularStd';
    src: url('/assets/fonts/Circular/CircularStd-Bold.otf');
    font-weight: 700;
    font-style: normal;
  }
  @font-face {
    font-family: 'CircularStd';
    src: url('/assets/fonts/Circular/CircularStd-BookItalic.otf');
    font-weight: 700;
    font-style: italic;
  }
  @font-face {
    font-family: 'CircularStd';
    src: url('/assets/fonts/Circular/CircularStd-Medium.otf');
    font-weight: 600;
    font-style: normal;
  }
  @font-face {
    font-family: 'CircularStd';
    src: url('/assets/fonts/Circular/CircularStd-MediumItalic.otf');
    font-weight: 600;
    font-style: italic;
  }

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  html {
    font-size: 100%;
  }

  html,
  body,
  #root {
    height: 100%;
    min-height: 100%;
  }

  body {
    -webkit-font-smoothing: antialiased !important;
    height: 100%;
    min-height: 100%;
  }

  body,
  input,
  button {
    color: ${cinzaEscuro2};
    font-size: 14px;
  }
  button {
    cursor: pointer;
  }
  /* TEST */
  @media (max-width: 800px) {
    html,
    body,
    #root {
      overflow-x: hidden;
    }
  }
  /* TEST */
`;

const GlobalStyles = () => <Global styles={globalCss} />;

export default GlobalStyles;
