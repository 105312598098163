import { Box, BoxProps } from '@chakra-ui/react';
import React from 'react';
import { MdError } from 'react-icons/md';

import { amareloClaro2, vermelhoClaro } from '../../styles/colors';
import { ContentDefault, Text2 } from '../../styles/common';
import { Row } from '../../styles/grid';

interface AlertsProps extends BoxProps {
  danger?: boolean;
  text?: React.ReactNode;
}

const Alerts: React.FC<AlertsProps> = ({ danger, text, ...rest }) => {
  const contentAlert = (
    <Box
      as={ContentDefault}
      padding="15px"
      margin="10px 0 5px 0"
      bgColor={danger ? vermelhoClaro : amareloClaro2}
      {...rest}
    >
      <Row justifyStart alignCenter>
        <Row width="auto" height="auto" margin="0 5px 0 0 " justifyCenter alignCenter>
          <Box as={MdError} w="35px" h="35px" />
        </Row>
        <Text2 size="14px" regular>
          <span className="bold"> Atenção: </span> {text}
        </Text2>
      </Row>
    </Box>
  );

  return contentAlert;
};

export default Alerts;
