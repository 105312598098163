import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { cinzaEscuro2 } from '~/styles/colors';

import { leadDetailForLink } from '../../routes/routeMap';
import { CustonLink } from '../../styles/common';
import { Col, Row } from '../../styles/grid';
import { tableSegments } from '../../utils/commonObjects';
import { phoneMask } from '../../utils/masks';
import px2vw from '../../utils/px2vw';
import LeadTableEditButton from '../LeadTableEditButton';
import TablePerPage from '../TablePerPage';
import TableSituation from '../TableSituation';
import TableStatus from '../TableStatus';
import { Arrow, Arrow2, SvgBox } from './icons';
import {
  Content,
  Header,
  HeaderButton,
  Hr,
  NextIcon,
  PagButton,
  PrevIcon,
  TableRow,
} from './styles';
import { makePages, sort } from './tableOptions';

export default function LeadTable({ leads }) {
  const [rows, setRows] = useState([]);
  const [rowsCurrentPage, setRowsCurrentPage] = useState();
  const [structure, setStructure] = useState(null);
  const [currentPage, setCurrentPage] = useState('1');
  const [lastPage, setLastPage] = useState();
  const [firstPage] = useState('1');
  const [sortedCol, setSortedCol] = useState('');

  useEffect(() => {
    if (structure) {
      setLastPage(Object.keys(structure)[Object.keys(structure).length - 1].toString());
    }
  }, [structure]);

  useMemo(() => {
    setStructure(makePages(rows.length, rowsCurrentPage));
  }, [rows, rowsCurrentPage]);

  useEffect(() => {
    setRows(leads);
  }, [leads]);

  useEffect(() => {
    if (rows.length < 5) {
      setRowsCurrentPage(rows.length);
    }
    if (rowsCurrentPage > 5) {
    } else {
      setRowsCurrentPage(5);
    }
  }, [rows, rowsCurrentPage]);

  function handleDeleteItem(id) {
    setRows(rows.filter((item) => item.id !== id));
  }

  const handleSort = useCallback(
    (col) => {
      if (sortedCol !== col) {
        setSortedCol(col);
        setRows(sort(rows, col, 'asc'));
      } else {
        setSortedCol('');
        setRows(sort(rows, col, 'desc'));
      }
    },
    [rows, sortedCol],
  );

  function handlePerPage(size) {
    setRowsCurrentPage(size);
  }

  function handleChangePage(direction) {
    if (direction === 'next') {
      const curr = +currentPage + 1;
      setCurrentPage(curr.toString());
    } else if (direction === 'prev') {
      const curr = +currentPage - 1;
      setCurrentPage(curr.toString());
    }
  }

  return (
    <Col padding="0 15px" alignCenter JustifyCenter>
      <Row>
        <Header onClick={() => handleSort('name')}>
          <HeaderButton>
            Negócio
            <SvgBox sorted={sortedCol === 'name'}>
              <Arrow d="M6.63,11.61,12,7.29l5.37,4.48a1,1,0,0,0,1.27-1.54l-6-5a1,1,0,0,0-1.27,0l-6,4.83a1,1,0,1,0,1.26,1.55Z" />
              <Arrow2 d="M12.64,12.23a1,1,0,0,0-1.27,0l-6,4.83a1,1,0,1,0,1.26,1.56L12,14.29l5.37,4.48a1,1,0,0,0,1.27-1.54Z" />
            </SvgBox>
          </HeaderButton>
        </Header>

        <Header onClick={() => handleSort('advertiser_category_id')}>
          <HeaderButton>
            Segmento
            <SvgBox sorted={sortedCol === 'advertiser_category_id'}>
              <Arrow d="M6.63,11.61,12,7.29l5.37,4.48a1,1,0,0,0,1.27-1.54l-6-5a1,1,0,0,0-1.27,0l-6,4.83a1,1,0,1,0,1.26,1.55Z" />
              <Arrow2 d="M12.64,12.23a1,1,0,0,0-1.27,0l-6,4.83a1,1,0,1,0,1.26,1.56L12,14.29l5.37,4.48a1,1,0,0,0,1.27-1.54Z" />
            </SvgBox>
          </HeaderButton>
        </Header>

        <Header onClick={() => handleSort('phone_1')}>
          <HeaderButton>
            Telefone
            <SvgBox sorted={sortedCol === 'phone_1'}>
              <Arrow d="M6.63,11.61,12,7.29l5.37,4.48a1,1,0,0,0,1.27-1.54l-6-5a1,1,0,0,0-1.27,0l-6,4.83a1,1,0,1,0,1.26,1.55Z" />
              <Arrow2 d="M12.64,12.23a1,1,0,0,0-1.27,0l-6,4.83a1,1,0,1,0,1.26,1.56L12,14.29l5.37,4.48a1,1,0,0,0,1.27-1.54Z" />
            </SvgBox>
          </HeaderButton>
        </Header>

        <Header onClick={() => handleSort('situation')}>
          <HeaderButton>
            Situação
            <SvgBox sorted={sortedCol === 'situation'}>
              <Arrow d="M6.63,11.61,12,7.29l5.37,4.48a1,1,0,0,0,1.27-1.54l-6-5a1,1,0,0,0-1.27,0l-6,4.83a1,1,0,1,0,1.26,1.55Z" />
              <Arrow2 d="M12.64,12.23a1,1,0,0,0-1.27,0l-6,4.83a1,1,0,1,0,1.26,1.56L12,14.29l5.37,4.48a1,1,0,0,0,1.27-1.54Z" />
            </SvgBox>
          </HeaderButton>
        </Header>

        <Header onClick={() => handleSort('funnel_status')}>
          <HeaderButton>
            Status no Funil
            <SvgBox sorted={sortedCol === 'funnel_status'}>
              <Arrow d="M6.63,11.61,12,7.29l5.37,4.48a1,1,0,0,0,1.27-1.54l-6-5a1,1,0,0,0-1.27,0l-6,4.83a1,1,0,1,0,1.26,1.55Z" />
              <Arrow2 d="M12.64,12.23a1,1,0,0,0-1.27,0l-6,4.83a1,1,0,1,0,1.26,1.56L12,14.29l5.37,4.48a1,1,0,0,0,1.27-1.54Z" />
            </SvgBox>
          </HeaderButton>
        </Header>

        <Header width="10%" padding="25px 5px" />
      </Row>
      <Row>
        <Hr />
      </Row>
      {structure ? (
        rows.map((item, index) =>
          structure[currentPage].start <= index + 1 &&
          structure[currentPage].end >= index + 1 &&
          item.funnel_status !== 'ganho' ? (
            <React.Fragment key={item.id}>
              <TableRow alignCenter justifyCenter>
                <Content>
                  <CustonLink
                    width="auto"
                    noStyle
                    color={cinzaEscuro2}
                    to={`${leadDetailForLink}/${item.slug}`}
                  >
                    {item.name ? item.name : '-'}
                  </CustonLink>
                </Content>
                <Content>
                  {item.advertiser_category_id
                    ? tableSegments[item.advertiser_category_id - 1]
                    : '-'}
                </Content>
                <Content>{item.phone_1 ? phoneMask(item.phone_1) : '-'}</Content>
                <Content>
                  <TableSituation noColorText situation={item.situation} />
                </Content>
                <Content>
                  <TableStatus lead={item} />
                </Content>
                <Content width="10%">
                  <LeadTableEditButton
                    slug={item.slug}
                    handleDeleteItem={() => handleDeleteItem(item.id)}
                  />
                </Content>
              </TableRow>
              <Row>
                <Hr />
              </Row>
            </React.Fragment>
          ) : null,
        )
      ) : (
        <Row padding="15px 0 10px 0">Nenhum lead no momento.</Row>
      )}
      {structure && (
        <Row alignCenter justifyEnd>
          <Row alignCenter justifyEnd height={px2vw(40)} margin="10px">
            <TablePerPage response={handlePerPage} length={rows.length} />
            <Row alignCenter justifyCenter width="10%">
              {structure[currentPage].start} - {structure[currentPage].end} de {rows.length}
            </Row>
            <PagButton
              onClick={() => handleChangePage('prev')}
              disabled={currentPage === firstPage}
              title="Página anterior"
            >
              <PrevIcon size={px2vw(12)} disabled={currentPage === firstPage} />
            </PagButton>
            <PagButton
              onClick={() => handleChangePage('next')}
              title="Próxima página"
              disabled={currentPage === lastPage}
            >
              <NextIcon size={px2vw(12)} disabled={currentPage === lastPage} />
            </PagButton>
          </Row>
        </Row>
      )}
    </Col>
  );
}
