import { Box } from '@chakra-ui/react';
import { Form } from '@rocketseat/unform';
import React, { useEffect, useState } from 'react';
import { MdClear } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';

import { storeLead } from '../../../stores/modules/lead/actions';
import { SelectCustom } from '../../../styles/common';
import { funnelStatus } from '../../../utils/commonObjects';
import { cpfCnpjMask, phoneMask } from '../../../utils/masks';
import { onlyNumbers } from '../../../utils/onlyNumbers';
import {
  CloseButton,
  ColumnContent,
  Container,
  InputCustom,
  Label,
  Modal,
  ModalButton,
  ModelTitle,
  RowContent,
} from './styles';

export default function NewLead({ visible, onChange }) {
  const [modal, setModal] = useState(visible);
  const [documentField, setDocumentField] = useState('');
  const [phone1Field, setPhone1Field] = useState('');
  const [phone2Field, setPhone2Field] = useState('');
  const segments = useSelector((state) => state.user.advertiserCategories);

  const dispatch = useDispatch();

  useEffect(() => {
    setModal(visible);
  }, [visible]);

  const handleVisible = () => onChange();

  const handleSubmit = ({
    advertiser_category_id,
    name,
    cpf_cnpj,
    email,
    phone_1,
    phone_2,
    funnel_status,
  }) => {
    if (name === '' || funnel_status === '') return;

    dispatch(
      storeLead(
        advertiser_category_id,
        name,
        onlyNumbers(cpf_cnpj),
        email,
        onlyNumbers(phone_1),
        onlyNumbers(phone_2),
        funnel_status,
      ),
    );
    handleVisible();
  };

  return (
    <Container visible={modal}>
      <Modal modelEmail>
        <CloseButton onClick={handleVisible}>
          <Box as={MdClear} w="35px" h="35px" />
        </CloseButton>
        <ModelTitle>Adicionar negócio</ModelTitle> <br />
        <Form onSubmit={handleSubmit}>
          <RowContent>
            <ColumnContent left>
              <Label>Nome do Estabelecimento</Label>
              <InputCustom name="name" type="text" placeholder="Nome completo" required />
              {/* <Error /> */}
            </ColumnContent>
            <ColumnContent right>
              <Label>CPF/CNPJ</Label>
              <InputCustom
                name="cpf_cnpj"
                type="text"
                maxLength="18"
                placeholder="CPF/CNPJ"
                value={documentField}
                onChange={(event) => setDocumentField(cpfCnpjMask(event.target.value))}
              />
              {/* <Error /> */}
            </ColumnContent>
          </RowContent>

          <RowContent>
            <ColumnContent left>
              <Label>Defina a etapa do funil</Label>
              <SelectCustom
                name="funnel_status"
                options={funnelStatus}
                placeholder="Selecione uma etapa do funil"
                required
              />
              {/* <Error /> */}
            </ColumnContent>

            <ColumnContent right>
              <Label>E-mail</Label>
              <InputCustom name="email" type="email" placeholder="E-mail" />
              {/* <Error /> */}
            </ColumnContent>
          </RowContent>

          <RowContent>
            <ColumnContent left>
              <Label>Telefone 1</Label>
              <InputCustom
                name="phone_1"
                type="text"
                value={phone1Field}
                onChange={(event) => setPhone1Field(phoneMask(event.target.value))}
                placeholder="Telefone"
              />
              {/* <Error /> */}
            </ColumnContent>

            <ColumnContent right>
              <Label>Telefone 2</Label>
              <InputCustom
                name="phone_2"
                value={phone2Field}
                onChange={(event) => setPhone2Field(phoneMask(event.target.value))}
                type="text"
                placeholder="Telefone"
              />
              {/* <Error /> */}
            </ColumnContent>
          </RowContent>

          <RowContent>
            <ColumnContent left>
              <Label>Segmento</Label>
              <SelectCustom
                name="advertiser_category_id"
                placeholder="Selecione um Segmento"
                options={segments}
              />
              {/* <Error /> */}
            </ColumnContent>
            <ColumnContent rigth />
          </RowContent>

          <RowContent lastRow>
            <ColumnContent left button>
              <ModalButton outlineTheme onClick={handleVisible} type="button">
                Cancelar
              </ModalButton>
            </ColumnContent>

            <ColumnContent right button>
              <ModalButton type="submit">Adicionar</ModalButton>
            </ColumnContent>
          </RowContent>
        </Form>
      </Modal>
    </Container>
  );
}
