import { Box } from '@chakra-ui/react';
import React, { useState } from 'react';
import { GrFormClose } from 'react-icons/gr';
import { MdMoreVert } from 'react-icons/md';
import { useDispatch } from 'react-redux';

import { cinzaEscuro2 } from '~/styles/colors';

import { leadDetailForLink } from '../../routes/routeMap';
import { destroyLead } from '../../stores/modules/lead/actions';
import { CustonLink } from '../../styles/common';
import ConfirmModal from '../ConfirmModal';
import { ButtonEdit, ChangeButton, Container, ModalEdit } from './styles';

export default function LeadTableEditButton({ slug, handleDeleteItem }) {
  const [visible, setVisible] = useState(false);
  const handleVisible = () => setVisible(!visible);
  const dispatch = useDispatch();

  const handleEdit = () => {
    handleVisible();
  };

  const [visibleOne, setVisibleOne] = useState(false);
  const handleVisibleOne = () => {
    setVisibleOne(!visibleOne);
    setVisible(false);
  };

  const handleDelete = () => {
    dispatch(destroyLead(slug));
    handleDeleteItem();
    handleVisible();
  };

  return (
    <Container>
      <ModalEdit visible={visible}>
        <ButtonEdit padding="0" onClick={handleEdit}>
          <CustonLink
            padding="8px 0"
            color={cinzaEscuro2}
            noStyle
            to={`${leadDetailForLink}/${slug}`}
          >
            Editar
          </CustonLink>
        </ButtonEdit>
        <ButtonEdit onClick={handleVisibleOne}>Excluir</ButtonEdit>
      </ModalEdit>
      <ChangeButton onClick={handleVisible} visible={visible}>
        {!visible ? (
          <Box as={MdMoreVert} w="22px" h="22px" />
        ) : (
          <Box
            alignItems="center"
            justifyContent="center"
            w="22px"
            h="22px"
            border="2px solid #000"
            borderRadius="50%"
          >
            <Box as={GrFormClose} w="100%" h="100%" />
          </Box>
        )}
      </ChangeButton>

      <ConfirmModal
        onHandleSubmit={handleDelete}
        onHandleVisible={handleVisibleOne}
        visible={visibleOne}
      />
    </Container>
  );
}
