import { format } from 'date-fns';

export const formatDateForDatefield = (date: string) => {
  const current = new Date(date);
  const userTimezoneOffset = current.getTimezoneOffset() * 60000;
  return format(new Date(current.getTime() + userTimezoneOffset), 'dd/MM/yyyy');
};

export const formatTimeForDatefield = (date: string) => {
  const current = new Date(date);
  const userTimezoneOffset = current.getTimezoneOffset() * 60000;
  return format(new Date(current.getTime() + userTimezoneOffset), 'HH:mm');
};

export const formatDatetimeForDatefield = (date: string) => {
  const current = new Date(date);
  const userTimezoneOffset = current.getTimezoneOffset() * 60000;
  return format(new Date(current.getTime() + userTimezoneOffset), 'dd/MM/yyyy HH:mm');
};
