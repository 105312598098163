import { toast } from 'react-toastify';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { apiGlobal, apiKuppido } from '../../../services/api';
import { onlyNumbers } from '../../../utils/onlyNumbers';
import {
  endAction,
  endActionOnboarding,
  getAdvertiserCategoriesFailure,
  getAdvertiserCategoriesSuccess,
  getAllprofile as getAllprofileAction,
  getTermsAndPolicyFailure,
  getTermsAndPolicySuccess,
  setAccountBank,
  setAddress,
  setIpAddress,
  setIsTermsTermsAndPolicyAccepted,
  setUser,
  setUserInfo,
  setUserInfoMaster,
} from './actions';
import { logout } from '../auth/actions';

const getState = (state) => state;

export function* editProfile({ payload }) {
  try {
    const state = yield select(getState);
    const userId = state.user.user.id;
    const { userInfo } = state.user;
    const { master } = state.auth;
    const { name, cpf_cnpj, is_corporation } = payload;
    const data = {
      name,
    };

    const document_changed_at = new Date();

    if (master) {
      if (onlyNumbers(cpf_cnpj) !== onlyNumbers(userInfo.cpf_cnpj)) {
        data.cpf_cnpj = cpf_cnpj;
        data.document_changed_at = document_changed_at;
      }
    } else {
      if (onlyNumbers(cpf_cnpj) !== onlyNumbers(userInfo.cpf_cnpj)) {
        data.cpf_cnpj = cpf_cnpj;
        data.is_corporation = is_corporation.toLowerCase() === 'true';
        data.document_changed_at = document_changed_at;
      }
    }

    const response = yield call(
      apiKuppido.put,
      `/${master ? 'master/' : ''}user/${userId}/user-info`,
      data,
    );

    yield put(setUserInfoMaster(response.data));

    yield put(endAction());
    toast.success('Informações atualizadas com sucesso!');
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(endAction());
      toast.error('Não foi possível alterar as informações.');
    }
  }
}

export function* editProfileOnboarding({ payload }) {
  try {
    const state = yield select(getState);
    const userId = state.user.user.id;
    const { master } = state.auth;
    const { name, cpf_cnpj, is_corporation } = payload;

    const data = master
      ? { name, cpf_cnpj }
      : {
          name,
          cpf_cnpj,
          is_corporation: is_corporation.toLowerCase() === 'true',
        };

    const response = yield call(
      apiKuppido.put,
      `/${master ? 'master/' : ''}user/${userId}/user-info`,
      data,
    );
    const newUserInfo = response.data;

    yield put(setUserInfoMaster(newUserInfo));

    yield put(endActionOnboarding(true));
    toast.success('Informações atualizadas com sucesso!');
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(endActionOnboarding(false));
      toast.error('Não foi possível alterar as informações.');
    }
  }
}

export function* editPassword({ payload }) {
  try {
    const state = yield select(getState);
    const userId = state.user.user.id;
    const { password } = payload;

    yield call(apiGlobal.put, `/user/${userId}`, { password });
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(endAction());
      toast.error('Não foi possível atualizar sua senha.');
    }
  }
}

export function* editAddress({ payload }) {
  try {
    const reduxState = yield select(getState);
    const userId = reduxState.user.user.id;
    const {
      street,
      number,
      complement,
      zipcode,
      neighborhood,
      city,
      state,
      phone_1,
      phone_2,
      phone_whatsapp,
    } = payload;

    const data = {
      street,
      number,
      complement,
      zipcode,
      neighborhood,
      city,
      state,
      phone_1,
      phone_2,
      phone_whatsapp,
    };

    const response = yield call(apiGlobal.put, `/user/${userId}/address`, data);
    const address = response.data;
    yield put(setAddress(address));

    if (payload.atOnboarding) {
      yield put(endActionOnboarding(true));
    }
    toast.success('Informações atualizadas com sucesso!');
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(endAction());

      if (payload.atOnboarding) {
        yield put(endActionOnboarding(false));
      }
      toast.error('Não foi possível alterar as informações.');
    }
  }
}

export function* createBankAccount({ payload }) {
  try {
    const reduxState = yield select(getState);
    const { user, userInfo } = reduxState.user;
    const { master } = reduxState.auth;
    const userId = user.id;
    const { email } = user;
    const { name, cpf_cnpj, is_corporation } = userInfo;

    const {
      bank_id,
      agency,
      agency_digit,
      account,
      account_digit,
      account_type,
      account_holder_name,
    } = payload;

    const data = {
      email,
      name,
      cpf_cnpj,
      is_corporation,
      bank_id,
      agency,
      agency_digit,
      account,
      account_digit,
      account_type,
      account_cpf_cnpj: cpf_cnpj,
      account_holder_name,
      master,
    };

    const response = yield call(apiKuppido.post, `/user/${userId}/kuppido-bank-account`, data);
    const bankAccount = response.data;

    yield put(setAccountBank(bankAccount));
    yield put(endActionOnboarding(true));
    toast.success('Conta bancária cadastrada com sucesso!');
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(endActionOnboarding(false));
      toast.error(
        'Não foi possível cadastrar a sua conta bancária, verifique se todas as informações estão corretas.',
        {
          autoClose: 6000,
        },
      );
    }
  }
}

export function* editBankAccount({ payload }) {
  try {
    const reduxState = yield select(getState);
    const { user, userInfo } = reduxState.user;
    const userId = user.id;
    const { cpf_cnpj } = userInfo;

    const {
      bank_id,
      agency,
      agency_digit,
      account,
      account_digit,
      account_type,
      account_holder_name,
    } = payload;

    const data = {
      cpf_cnpj: cpf_cnpj,
      bank_id,
      agency,
      agency_digit,
      account,
      account_digit,
      account_type,
      account_holder_name,
    };

    const response = yield call(apiKuppido.put, `/user/${userId}/kuppido-bank-account`, data);
    const bankAccount = response.data;

    yield put(setAccountBank(bankAccount));
    yield put(endActionOnboarding(true));
    toast.success('Informações atualizadas com sucesso!');
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(endAction());
      yield put(endActionOnboarding(false));
      toast.error(
        'Não foi possível alterar os dados de sua conta bancária, verifique se todas as informações estão corretas.',
        {
          autoClose: 6000,
        },
      );
    }
  }
}

export function* getAllProfile() {
  try {
    const state = yield select(getState);
    const userId = state.user.user.id;
    const { master } = state.auth;

    const response = yield call(apiGlobal.get, `/user/${userId}`);

    yield put(setUser(response.data));

    const { address } = response.data;
    yield put(setAddress(address[0]));

    const { kuppidoBankAccount } = response.data;
    yield put(setAccountBank(kuppidoBankAccount));

    if (master) {
      const userInfo = response.data.kuppidoMasterInfo;
      yield put(setUserInfoMaster(userInfo));
    } else {
      const userInfo = response.data.kuppidoInfo;
      yield put(setUserInfo(userInfo));
    }
    yield put(endAction());
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(endAction());
      toast.error(
        'Não foi possível verificar os dados de seu perfil, recarregue sua página e tente novamente. Caso o erro persista, contacte o suporte.',
        {
          autoClose: 6000,
        },
      );
    }
  }
}

export function* setIsDoneOnboarding() {
  try {
    const state = yield select(getState);
    const userId = state.user.user.id;
    const { master } = state.auth;

    yield call(apiKuppido.put, `/${master ? 'master/' : ''}user/${userId}/user-info`, {
      is_done_onboarding: true,
    });

    yield put(getAllprofileAction());
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(endAction());
      toast.error('Não foi possível atualizar as informações.');
    }
  }
}

export function* getAdvertiserCategoriesRequest() {
  try {
    //Get advertiser categories
    const categories = yield call(apiGlobal.get, `/anunciante/categories`);

    // Store advertiser categories inside redux
    yield put(getAdvertiserCategoriesSuccess(categories.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(getAdvertiserCategoriesFailure());
    }
  }
}

// TODO: Remove this function when all kuppido types are using the new
// way of term acceptance
export function* setTermsAccepted() {
  try {
    const state = yield select(getState);
    const userId = state.user.user.id;
    const { master } = state.auth;

    const data = {
      is_terms_accepted: true,
    };

    const response = yield call(
      apiKuppido.put,
      `/${master ? 'master/' : ''}user/${userId}/user-info`,
      data,
    );

    yield put(setUserInfoMaster(response.data));

    yield put(endAction());
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(endAction());
      toast.error('Não foi possível alterar as informações.');
    }
  }
}

export function* fetchIpAddress() {
  try {
    const ipAddress = yield select((state) => state.user.ipAddress);

    if (!ipAddress) {
      const GEOLOCATION_DB_KEY = process.env.REACT_APP_GEOLOCATION_DB;
      const response = yield call(fetch, `https://geolocation-db.com/json/${GEOLOCATION_DB_KEY}`, {
        method: 'GET',
        headers: {
          accept: 'application/json',
        },
      });

      const data = yield response.json();
      yield put(setIpAddress(data.IPv4));
    }
  } catch (err) {
    yield put(setIpAddress(null));
  }
}

export function* getTermsAndPolicyRequest({ payload }) {
  try {
    // Get terms and policy data
    const response = yield call(apiGlobal.get, `/term-and-policy/${payload.termPolicyCode}`);

    // Save in redux the terms data
    yield put(getTermsAndPolicySuccess(response.data));
  } catch (err) {
    yield put(getTermsAndPolicyFailure());
  }
}

export function* acceptTermsAndPolicyRequest({ payload }) {
  try {
    const state = yield select(getState);
    const userId = state.user.user.id;
    const ipAddress = state.user.ipAddress;
    const { master } = state.auth;

    // Get terms and policy data
    yield call(apiKuppido.put, `/${master ? 'master/' : ''}user/${userId}/accept-terms`, {
      term_policy_id: payload.termsId,
      ip_address: ipAddress,
    });

    yield put(setIsTermsTermsAndPolicyAccepted(true));
    toast.success('Você aceitou os termos de uso.');
  } catch (err) {
    yield put(setIsTermsTermsAndPolicyAccepted(false));
    toast.error('Ocorreu um erro ao aceitar os termos de uso. Contate o suporte.', {
      autoClose: 6000,
    });
  }
}

export default all([
  takeLatest('@user/SET_IS_DONE_ONBOARDING', setIsDoneOnboarding),
  takeLatest('@user/GET_ALL_PROFILE', getAllProfile),
  takeLatest('@user/CREATE_BANK_ACCOUNT', createBankAccount),
  takeLatest('@user/EDIT_BANK_ACCOUNT', editBankAccount),
  takeLatest('@user/EDIT_ADDRESS', editAddress),
  takeLatest('@user/EDIT_PROFILE', editProfile),
  takeLatest('@user/EDIT_PROFILE_ONBOARDING', editProfileOnboarding),
  takeLatest('@user/EDIT_PASSWORD', editPassword),
  takeLatest('@user/GET_ADVERTISER_CATEGORIES_REQUEST', getAdvertiserCategoriesRequest),
  takeLatest('@user/FETCH_IP_ADDRESS', fetchIpAddress),
  takeLatest('@user/SET_TERMS_ACCEPTED', setTermsAccepted),
  takeLatest('@user/GET_TERMS_AND_POLICY_REQUEST', getTermsAndPolicyRequest),
  takeLatest('@user/ACCEPT_TERMS_AND_POLICY_REQUEST', acceptTermsAndPolicyRequest),
]);
