import {
  Badge,
  Box,
  Button,
  FormControl,
  FormLabel,
  ListItem,
  Stat,
  StatLabel,
  StatNumber,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import format from 'date-fns/format';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { VscGoToFile } from 'react-icons/vsc';
import { useDispatch, useSelector } from 'react-redux';

import Modal from '~/components/Modal';
import { useAdvertiserSubscription } from '~/contexts/AdvertiserSubscriptionContext';
import useYupValidationResolver from '~/hooks/useYupValidationResolver';
import Payable from '~/models/Payable';
import Recipient from '~/models/Recipient';
import updateContractValidation from '~/models/schemas/updateContractSchema';
import Subscription from '~/models/Subscription';
import validateUnique from '~/services/validateUnique';
import { formatCurrencyBRL } from '~/utils/currency';
import { formatDateForDatefield } from '~/utils/formatDate';
import { onlyNumbers } from '~/utils/onlyNumbers';

import {
  fetchSubscriptions,
  getRecipientData,
  saveContract,
} from '../../stores/modules/financial/actions';
import { amarelo, verdeKuppi } from '../../styles/colors';
import { Hr } from '../../styles/common';
import { accountTypesObject, banksObject } from '../../utils/commonObjects';
import { amountMask, cellPhoneMask, cpfCnpjMask } from '../../utils/masks';
import InputWithValidation from '../Form/InputWithValidation';
import SelectWithValidation from '../Form/SelectWithValidation';
import ScrollableList from '../ScrollableList';
import CashIcon from './CashIcon';

interface FinancialModalProps {
  isOpen: boolean;
  onClose: () => void;
  // eslint-disable-next-line no-unused-vars
  onClickResendContract: (advertiserInfoId: number) => void;
}

type FormInputs = {
  name: string;
  email: string;
  phone?: string;
  whatsapp?: string;
  amount: number;
  subscription_id: number;
};

const FinancialModal: React.FC<FinancialModalProps> = ({
  isOpen,
  onClose,
  onClickResendContract,
}) => {
  const dispatch = useDispatch();
  const [state] = useAdvertiserSubscription();
  const loading = useSelector((state: any) => state.financial.loading);
  const { isKuppiCommercialTeam } = useSelector((state: any) => state.auth);
  const contract = state?.advertiserSubscription;
  const [currMinPlanAmount, setCurrMinPlanAmount] = useState<string>('50');
  const [currMaxPlanAmount, setCurrMaxPlanAmount] = useState<string>('300');
  const { handleSubmit, register, setValue, watch, errors, setError } = useForm<FormInputs>({
    resolver: useYupValidationResolver(
      updateContractValidation(currMinPlanAmount, currMaxPlanAmount),
    ),
  });

  const selectedSubscriptionId = watch('subscription_id');
  const amount = watch('amount');
  const email = watch('email');

  const isEmailChanged = useMemo(() => {
    if (!(!!email && !!contract?.advertiserInfo?.user?.email)) return false; // False if both are falsy

    const isEmailChanged =
      email?.toLowerCase() !== contract?.advertiserInfo?.user?.email?.toLowerCase();
    return isEmailChanged;
  }, [email, contract?.advertiserInfo?.user?.email]);
  const emailInputRef = useRef<HTMLInputElement>(null);

  const [isFormDisabled, setIsFormDisabled] = useState(true);

  const payables: Record<number, Payable[]> = useSelector(
    (state: any) => state.financial.active_contracts_payables,
  );
  const subscriptions: Subscription[] = useSelector((state: any) => state.financial.subscriptions);
  const selectedSubscription = useMemo(() => {
    return subscriptions?.find((s) => s.id === Number(selectedSubscriptionId));
  }, [selectedSubscriptionId, subscriptions]);

  const totalAmount = useMemo(() => {
    const parsedAmount = parseFloat(onlyNumbers(amount));
    return selectedSubscription?.period && parsedAmount
      ? formatCurrencyBRL(parsedAmount * selectedSubscription?.period, { minimumFractionDigits: 0 })
      : 'Não definido';
  }, [selectedSubscription, amount]);

  const { master } = useSelector((state: any) => state.auth);

  const accountRecipient: Recipient = useSelector((state: any) => state.financial.recipient_data);

  const [totalCommission, setTotalCommission] = useState<string>();
  const [paidCommission, setPaidCommission] = useState<string>();
  const [notPaidCommission, setNotPaidCommission] = useState<string>();

  useEffect(() => {
    dispatch(fetchSubscriptions());
  }, [dispatch]);

  useEffect(() => {
    setTimeout(() => {
      setValue('name', contract?.advertiserInfo?.name, { shouldValidate: true });
      setValue('email', contract?.advertiserInfo?.user?.email, { shouldValidate: true });
      setValue(
        'phone',
        cellPhoneMask(contract?.advertiserInfo?.user?.address[0]?.phone_1),
        // { shouldValidate: true, }
      );
      setValue(
        'whatsapp',
        cellPhoneMask(contract?.advertiserInfo?.user?.address[0]?.phone_whatsapp),
        // { shouldValidate: true, }
      );
      setValue('amount', amountMask(contract?.amount.toString(), false));
      setValue('subscription_id', contract?.subscription_id);
      setIsFormDisabled(true);
    }, 50);
  }, [contract, isOpen, setValue]);

  useEffect(() => {
    const payablesArray = payables[contract?.id];
    let currentPaidCommission = 0;
    let currentNotPaidCommission = 0;
    let currentTotalCommission = 0;

    if (Array.isArray(payablesArray) && payablesArray.length > 0) {
      payablesArray.forEach((i: any) => {
        currentTotalCommission += i.amount / 100;
        if (i.status === 'paid') {
          currentPaidCommission += i.amount / 100;
        }
        if (i.status === 'waiting_funds') {
          currentNotPaidCommission += i.amount / 100;
        }
      });
      setPaidCommission(formatCurrencyBRL(currentPaidCommission));
      setNotPaidCommission(formatCurrencyBRL(currentNotPaidCommission));
      setTotalCommission(formatCurrencyBRL(currentTotalCommission));
    } else {
      setPaidCommission('');
      setNotPaidCommission('');
      setTotalCommission('');
    }
  }, [payables, contract]);

  useEffect(() => {
    const recipient = master
      ? contract?.advertiserInfo.advertiserSplit.kuppido_master_recipient_id
      : contract?.advertiserInfo.advertiserSplit.kuppido_recipient_id;

    if (recipient) {
      dispatch(getRecipientData(recipient));
    }
  }, [dispatch, master, contract]);

  useEffect(() => {
    if (!loading) {
      setIsFormDisabled(true);
    }
  }, [loading]);

  const resendContractButton = useMemo(
    () =>
      contract?.status !== 'waiting_payment' && !contract?.is_active ? (
        <Button
          ml={4}
          leftIcon={<VscGoToFile size={22} />}
          variant="ghost"
          onClick={() => onClickResendContract(contract?.id)}
        >
          <Text size="14px">Reenviar Contrato</Text>
        </Button>
      ) : null,
    [contract, onClickResendContract],
  );

  const handleOnClickEnableForm = () => {
    setIsFormDisabled((prev) => !prev);
  };

  const handleOnClickCancel = useCallback(() => {
    setTimeout(() => {
      setValue('name', contract?.advertiserInfo?.name, { shouldValidate: true });
      setValue('email', contract?.advertiserInfo?.user?.email, { shouldValidate: true });
      setValue('phone', cellPhoneMask(contract?.advertiserInfo?.user?.address[0]?.phone_1), {
        shouldValidate: true,
      });
      setValue(
        'whatsapp',
        cellPhoneMask(contract?.advertiserInfo?.user?.address[0]?.phone_whatsapp),
        {
          shouldValidate: true,
        },
      );
      setValue('amount', amountMask(contract?.amount.toString(), false));
      setValue('subscription_id', contract?.subscription_id);
      setIsFormDisabled(true);
    }, 50);
  }, [contract, setValue]);

  const onSubmit = async (data: FormInputs) => {
    if (isEmailChanged) {
      const isNewEmailAvaialable = await validateUnique({ email: data.email });
      if (isNewEmailAvaialable) {
        dispatch(
          saveContract({
            ...data,
            id: contract.id,
            amount: onlyNumbers(data.amount),
            phone: onlyNumbers(data.phone),
            whatsapp: onlyNumbers(data.whatsapp),
            resendContract: true,
            userId: contract?.advertiserInfo?.user_id,
          }),
        );
      } else {
        setError('email', {
          message: 'Este email já está em uso.',
          type: 'email-not-unique',
        });
        emailInputRef.current?.focus();
      }
      return;
    }
    dispatch(
      saveContract({
        ...data,
        id: contract.id,
        amount: onlyNumbers(data.amount),
        phone: onlyNumbers(data.phone),
        whatsapp: onlyNumbers(data.whatsapp),
      }),
    );
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        headerText="Detalhes do Contrato"
        headerAddon={resendContractButton}
        modalContentProps={{ minH: '610px', minW: '40rem', maxW: '50rem' }}
      >
        <Hr />
        <Tabs isFitted>
          <TabList>
            <Tab
              fontWeight="700"
              borderBottom="5px solid"
              _focus={{ boxShadow: 'none' }}
              p={3}
              _selected={{
                color: 'gray.700',
                borderBottom: '5px solid currentColor',
                borderColor: 'green.500',
              }}
            >
              Resumo de contrato
            </Tab>{' '}
            <>
              <Tab
                fontWeight="700"
                p={3}
                borderBottom="5px solid"
                _focus={{ boxShadow: 'none' }}
                _selected={{
                  color: 'gray.700',
                  borderBottom: '5px solid currentColor',
                  borderColor: 'green.500',
                }}
                isDisabled={
                  contract?.payment_type === 'free' ||
                  (contract?.status !== 'paid' && !contract?.is_active) ||
                  isKuppiCommercialTeam
                }
              >
                Histórico de pagamentos
              </Tab>
              <Tab
                fontWeight="700"
                p={3}
                borderBottom="5px solid"
                _focus={{ boxShadow: 'none' }}
                _selected={{
                  color: 'gray.700',
                  borderBottom: '5px solid currentColor',
                  borderColor: 'green.500',
                }}
                isDisabled={
                  contract?.payment_type === 'free' ||
                  (contract?.status !== 'paid' && !contract?.is_active) ||
                  isKuppiCommercialTeam
                }
              >
                Conta bancária recebedora
              </Tab>
            </>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Box as="form" d="flex" flexDir="column" onSubmit={handleSubmit(onSubmit)}>
                <Box d="flex" justifyContent="space-between" mb={4}>
                  <InputWithValidation
                    name="name"
                    errors={errors}
                    textLabel="Estabelecimento"
                    register={register({})}
                    formProps={{
                      isDisabled: isFormDisabled,
                      mr: 4,
                    }}
                  />
                  <FormControl id="cnpj">
                    <FormLabel fontSize="md" fontWeight="700">
                      CPF/CNPJ
                    </FormLabel>
                    <Text fontSize="lg" px={4} h="2.5rem" d="flex" alignItems="center">
                      {contract?.advertiserInfo?.cpf_cnpj &&
                        cpfCnpjMask(contract?.advertiserInfo.cpf_cnpj)}
                    </Text>
                  </FormControl>
                </Box>
                <Box d="flex" justifyContent="space-between" mb={4}>
                  <InputWithValidation
                    name="email"
                    errors={errors}
                    textLabel="Email"
                    register={(el: HTMLInputElement) => {
                      register(el);
                      emailInputRef.current = el;
                    }}
                    formProps={{
                      isDisabled: isFormDisabled,
                      flex: 2,
                      mr: 4,
                    }}
                  />
                  <InputWithValidation
                    name="phone"
                    errors={errors}
                    textLabel="Telefone 1"
                    register={register({})}
                    onChange={(e) => setValue('phone', cellPhoneMask(e.target.value))}
                    formProps={{
                      isDisabled: isFormDisabled,
                      flex: 1,
                      mr: 4,
                    }}
                  />
                  <InputWithValidation
                    name="whatsapp"
                    errors={errors}
                    textLabel="Telefone 2"
                    register={register({})}
                    onChange={(e) => setValue('whatsapp', cellPhoneMask(e.target.value))}
                    formProps={{
                      isDisabled: isFormDisabled,
                      flex: 1,
                    }}
                  />
                </Box>
                <Hr />
                <Box d="flex" justifyContent="space-between" mt={4} mb={4}>
                  <SelectWithValidation
                    register={register({})}
                    name="subscription_id"
                    errors={errors}
                    textLabel="Plano contratado"
                    placeholder="Selecione o plano"
                    formProps={{
                      id: 'subscription_id',
                      flex: 1,
                      mr: 4,
                      isDisabled: isFormDisabled,
                    }}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      setValue('subscription_id', e.target.value);

                      subscriptions.forEach((currSub: any) => {
                        if (parseInt(currSub.id, 10) === parseInt(e.target.value, 10)) {
                          setCurrMinPlanAmount(currSub.min_amount || '50');
                          setCurrMaxPlanAmount(currSub.max_amount || '300');
                        }
                      });
                    }}
                  >
                    {subscriptions?.map((s) => {
                      if (s.slug === 'plano_inaugural' && !isKuppiCommercialTeam) {
                        return;
                      }

                      return (
                        <option key={s.id} id={String(s.id)} value={s.id}>
                          {s.name}
                        </option>
                      );
                    })}
                  </SelectWithValidation>
                  <InputWithValidation
                    name="amount"
                    errors={errors}
                    textLabel="Valor mensal"
                    register={register({})}
                    onChange={(e) => setValue('amount', amountMask(e.target.value, false))}
                    formProps={{
                      isDisabled: isFormDisabled,
                      flex: 1,
                      mr: 4,
                    }}
                  />
                  <FormControl id="status" flex={1} isDisabled={isFormDisabled}>
                    <FormLabel fontSize="md" fontWeight="700">
                      Status do contrato
                    </FormLabel>
                    <Text
                      fontSize="lg"
                      color={contract?.is_active ? 'green.500' : 'gray.400'}
                      px={4}
                      h="2.5rem"
                      d="flex"
                      alignItems="center"
                    >
                      {contract?.is_active ? 'Ativo' : 'Inativo'}
                    </Text>
                  </FormControl>
                </Box>
                <Box d="flex" justifyContent="space-between" mb={4}>
                  <FormControl id="subscription" flex={1} mr={4}>
                    <FormLabel fontSize="md" fontWeight="700">
                      Valor total do plano
                    </FormLabel>
                    <Text
                      fontSize="lg"
                      color="green.500"
                      px={4}
                      h="2.5rem"
                      d="flex"
                      alignItems="center"
                    >
                      {totalAmount}
                    </Text>
                  </FormControl>
                  <FormControl id="status" flex={1} mr={4}>
                    <FormLabel fontSize="md" fontWeight="700">
                      Valor total de comissão
                    </FormLabel>
                    <Text
                      fontSize="lg"
                      color={!totalCommission ? 'yellow.500' : 'gray.800'}
                      px={4}
                      h="2.5rem"
                      d="flex"
                      alignItems="center"
                    >
                      {totalCommission ? `R$ ${totalCommission}` : 'Não definido'}
                    </Text>
                  </FormControl>
                  <FormControl id="status" flex={1}>
                    <FormLabel fontSize="md" fontWeight="700">
                      Vigência do contrato
                    </FormLabel>
                    <Text fontSize="lg" px={4} h="2.5rem" d="flex" alignItems="center">
                      {contract?.status === 'paid' && contract?.is_active ? (
                        <>
                          <Badge fontSize="md" colorScheme="green">
                            {formatDateForDatefield(contract?.start_validity_at)}
                          </Badge>
                          <Text fontWeight="bold">&nbsp;-&nbsp;</Text>
                          <Badge fontSize="md">
                            {formatDateForDatefield(contract?.finish_validity_at)}
                          </Badge>
                        </>
                      ) : (
                        'Não possui'
                      )}
                    </Text>
                  </FormControl>
                </Box>

                {!isFormDisabled && (
                  <Box d="flex" justifyContent="flex-end">
                    <Button
                      onClick={handleOnClickCancel}
                      minW="9rem"
                      type="button"
                      colorScheme="red"
                      mr={2}
                      isDisabled={loading}
                    >
                      Cancelar
                    </Button>
                    <Button minW="9rem" type="submit" colorScheme="green" isLoading={loading}>
                      {isEmailChanged ? 'Salvar e reenviar contrato' : 'Salvar'}
                    </Button>
                  </Box>
                )}
              </Box>
              {isFormDisabled && (
                <Box d="flex" justifyContent="flex-end">
                  <Button
                    minW="18rem"
                    type="button"
                    onClick={handleOnClickEnableForm}
                    colorScheme={isFormDisabled ? 'gray' : 'green'}
                    isDisabled={contract?.status === 'paid' && contract?.is_active}
                  >
                    Alterar dados do contrato
                  </Button>
                </Box>
              )}
            </TabPanel>
            <TabPanel>
              <Box d="flex" flexDir="column" mb={4}>
                <Box d="flex" w="50%" mb={2}>
                  <Stat>
                    <StatLabel fontSize="md" fontWeight="700">
                      Valor recebido
                    </StatLabel>
                    <StatNumber color={amarelo}>{paidCommission}</StatNumber>
                    {/* <StatHelpText>Feb 12 - Feb 28</StatHelpText> */}
                  </Stat>
                  <Stat>
                    <StatLabel fontSize="md" fontWeight="700">
                      Valor à receber
                    </StatLabel>
                    <StatNumber color={verdeKuppi}>
                      {notPaidCommission && notPaidCommission}
                    </StatNumber>
                    {/* <StatHelpText>Feb 12 - Feb 28</StatHelpText> */}
                  </Stat>
                </Box>
                <Hr />
              </Box>
              <ScrollableList spacing={3} maxH="20rem" overflowY="scroll">
                {payables[contract?.id]?.map((i, index, arr) => (
                  <ListItem key={i.id} d="flex" px={3}>
                    <CashIcon received={i.status === 'paid'} />
                    <Box ml={2} d="flex" flexDir="column" color="gray.800">
                      <Text fontSize="md" mb={2}>
                        Pagamento {index + 1}/{arr.length} -{' '}
                        <Text
                          as="span"
                          fontWeight="700"
                          color={i.status === 'paid' ? 'green.400' : 'yellow.400'}
                        >
                          {i.status === 'paid' ? 'Recebido' : 'Pendente'}
                        </Text>
                      </Text>
                      <Text fontSize="md" fontWeight="600">
                        {i.amount && formatCurrencyBRL(i.amount / 100)}
                      </Text>
                    </Box>
                    <Text color="gray.400" fontSize="sm" ml="auto" pr={2}>
                      {i.payment_date && format(new Date(i.payment_date), 'dd/MM/yyyy')}
                    </Text>
                  </ListItem>
                ))}
              </ScrollableList>
            </TabPanel>
            <TabPanel>
              <Box d="flex" flexDir="column">
                <Box d="flex" justifyContent="space-between" mb={4}>
                  <Stat>
                    <StatLabel fontSize="md" fontWeight="700" mb={2}>
                      Nome/Razão do Titular da conta
                    </StatLabel>
                    <StatNumber fontSize="lg" color="gray.500">
                      {accountRecipient?.register_information?.company_name}
                    </StatNumber>
                  </Stat>
                  <Stat>
                    <StatLabel fontSize="md" fontWeight="700" mb={2}>
                      CPF/CNPJ do Titular da conta
                    </StatLabel>
                    <StatNumber fontSize="lg" color="gray.500">
                      {cpfCnpjMask(accountRecipient?.register_information?.document_number)}
                    </StatNumber>
                  </Stat>
                </Box>
                <Box d="flex" justifyContent="space-between" mb={4}>
                  <Stat>
                    <StatLabel fontSize="md" fontWeight="700" mb={2}>
                      Banco
                    </StatLabel>
                    <StatNumber fontSize="lg" color="gray.500">
                      {(banksObject as any)[accountRecipient?.bank_account?.bank_code]}
                    </StatNumber>
                  </Stat>
                  <Stat>
                    <StatLabel fontSize="md" fontWeight="700" mb={2}>
                      Tipo da conta
                    </StatLabel>
                    <StatNumber fontSize="lg" color="gray.500">
                      {(accountTypesObject as any)[accountRecipient?.bank_account?.type]}
                    </StatNumber>
                  </Stat>
                </Box>
                <Box d="flex" justifyContent="space-between" mb={4}>
                  <Stat>
                    <StatLabel fontSize="md" fontWeight="700" mb={2}>
                      Agência
                    </StatLabel>
                    <StatNumber fontSize="lg" color="gray.500">
                      {accountRecipient?.bank_account?.agencia}{' '}
                      {accountRecipient?.bank_account?.agencia_dv &&
                        `- ${accountRecipient?.bank_account?.agencia_dv}`}
                    </StatNumber>
                  </Stat>
                  <Stat>
                    <StatLabel fontSize="md" fontWeight="700" mb={2}>
                      Conta
                    </StatLabel>
                    <StatNumber fontSize="lg" color="gray.500">
                      {accountRecipient?.bank_account?.conta}{' '}
                      {accountRecipient?.bank_account?.conta_dv &&
                        `- ${accountRecipient?.bank_account?.conta_dv}`}
                    </StatNumber>
                  </Stat>
                </Box>
              </Box>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Modal>
    </>
  );
};

export default FinancialModal;
