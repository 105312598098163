import { Box, Spinner } from '@chakra-ui/react';
import { Form } from '@rocketseat/unform';
import React, { useCallback, useEffect, useState } from 'react';
import { MdClear } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { branco } from '~/styles/colors';

import { recoverEmail, recoverPassword } from '../../stores/modules/auth/actions';
import { LoadingBox } from '../../styles/common';
import { cpfCnpjMask } from '../../utils/masks';
import { onlyNumbers } from '../../utils/onlyNumbers';
import {
  CloseButton,
  Container,
  Error,
  InputCustom,
  Label,
  Modal,
  ModalButton,
  ModelBoxBottom,
  ModelBoxTop,
  ModelText,
  ModelTitle,
} from './styles';

export default function LoginModal({ visible, onChange }) {
  const [modal, setModal] = useState(false);
  const loading = useSelector((state) => state.auth.loading_recovery);
  const recoveredEmail = useSelector((state) => state.auth.recovered_email);
  const recoveredEmailFailure = useSelector((state) => state.auth.recovered_email_failure);

  const [whichModal, setWhichModal] = useState('modalStart');
  const [forgotEmailError, setForgotEmailError] = useState(false);
  const [forgotEmailErrorMessage, setForgotEmailErrorMessage] = useState(null);
  const [cpfCnpjField, setCpfCnpjField] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    setModal(visible);
  }, [visible]);

  const handleClose = useCallback(() => {
    setModal(!modal);
    onChange();
    setWhichModal('modalStart');
    setForgotEmailError(false);
    setForgotEmailErrorMessage(null);
  }, [modal, onChange]);

  const handleChangeModal = useCallback((option) => {
    setWhichModal(option);
  }, []);

  const handleCleanStep = () => {
    setWhichModal('modalStart');
    setForgotEmailError(false);
    setForgotEmailErrorMessage(null);
    setCpfCnpjField('');
    handleClose();
  };

  const handleRecoverPassword = ({ email }) => {
    if (email === '') {
      toast.error('O campo de e-mail é obrigatório.');
    } else {
      dispatch(recoverPassword(email));
      handleChangeModal('modalEmailEnd');
    }
    handleChangeModal('modalPasswordEnd');
  };

  function handleRecoverEmail({ name, cpf_cnpj }) {
    if (name === '' || cpf_cnpj === '') {
      toast.error('Todos os campos são obrigatórios.');
    } else {
      dispatch(recoverEmail(name, onlyNumbers(cpf_cnpj)));
    }
  }

  useEffect(() => {
    if (recoveredEmail && !recoveredEmailFailure) {
      handleChangeModal('modalEmailEnd');
    } else if (recoveredEmailFailure && !recoveredEmail) {
      handleChangeModal('modalEmailEndFailure');
    }
  }, [loading, forgotEmailError, recoveredEmailFailure, recoveredEmail, handleChangeModal]);

  const modalStart = (
    <Container visible={modal}>
      <Modal modalStart>
        <CloseButton onClick={handleCleanStep}>
          <Box as={MdClear} w="35px" h="35px" />
        </CloseButton>
        <ModelBoxTop>
          <ModelTitle>Esqueci meu e-mail ou senha</ModelTitle> <br />
          <ModelText>Clique em um dos botões abaixo para recuperar seus dados de acesso.</ModelText>
        </ModelBoxTop>
        <ModelBoxBottom>
          <ModalButton onClick={() => handleChangeModal('modalEmail')} noBg>
            Recuperar meu e-mail
          </ModalButton>
          <ModalButton onClick={() => handleChangeModal('modalPassword')}>
            Recuperar minha senha
          </ModalButton>
        </ModelBoxBottom>
      </Modal>
    </Container>
  );

  const modalEmail = (
    <Container visible={modal}>
      <Modal modelEmail>
        <CloseButton onClick={handleCleanStep}>
          <Box as={MdClear} w="35px" h="35px" />
        </CloseButton>
        <Form onSubmit={handleRecoverEmail}>
          <ModelBoxTop>
            <ModelTitle>Esqueci meu login</ModelTitle> <br />
            <ModelText>
              Digite o seu nome completo e CPF ou CNPJ cadastrado na Plataforma Kuppido.
            </ModelText>
            <Label>Nome completo</Label>
            <InputCustom
              error={forgotEmailError}
              name="name"
              type="text"
              required
              placeholder="Digite seu nome aqui"
            />
            <Label>CPF ou CNPJ</Label>
            <InputCustom
              error={forgotEmailError}
              name="cpf_cnpj"
              type="text"
              required
              value={cpfCnpjField}
              onChange={(e) => setCpfCnpjField(cpfCnpjMask(e.target.value))}
              placeholder="Digite CPF ou CNPJ"
            />
            <Error>{forgotEmailError && forgotEmailErrorMessage}</Error>
          </ModelBoxTop>
          <ModalButton topbox type="submit">
            {loading ? (
              <LoadingBox>
                <Spinner w="16px" h="16px" color={branco} />
              </LoadingBox>
            ) : (
              'Recuperar meu login'
            )}
          </ModalButton>
        </Form>
      </Modal>
    </Container>
  );

  const modalEmailEnd = (
    <Container visible={modal}>
      <Modal modalStart>
        <CloseButton onClick={handleCleanStep}>
          <Box as={MdClear} w="35px" h="35px" />
        </CloseButton>
        <ModelBoxTop>
          <ModelTitle>Esqueci meu login</ModelTitle> <br />
          <ModelText>
            Seu login de acesso a plataforma é: <br /> <span> {recoveredEmail} </span>
          </ModelText>
        </ModelBoxTop>
        <ModelBoxBottom>
          <ModalButton topbox single login onClick={handleCleanStep}>
            Fazer login
          </ModalButton>
        </ModelBoxBottom>
      </Modal>
    </Container>
  );

  const modalEmailEndFailure = (
    <Container visible={modal}>
      <Modal modalStart>
        <CloseButton onClick={handleCleanStep}>
          <Box as={MdClear} w="35px" h="35px" />
        </CloseButton>
        <ModelBoxTop>
          <ModelTitle>Esqueci meu login</ModelTitle> <br />
          <ModelText>Não foi localizado nenhum usuário para os dados informados.</ModelText>
        </ModelBoxTop>
        <ModelBoxBottom>
          <ModalButton topbox single login onClick={handleCleanStep}>
            Ok
          </ModalButton>
        </ModelBoxBottom>
      </Modal>
    </Container>
  );

  const modalPassword = (
    <Container visible={modal}>
      <Modal modalPassword>
        <CloseButton onClick={handleCleanStep}>
          <Box as={MdClear} w="35px" h="35px" />
        </CloseButton>
        <Form onSubmit={handleRecoverPassword}>
          <ModelBoxTop>
            <ModelTitle>Esqueci minha senha</ModelTitle> <br />
            <ModelText>
              Enviaremos um link por e-mail com instruções para você redefinir sua senha.
            </ModelText>
            <Label>E-mail</Label>
            <InputCustom name="email" type="email" required placeholder="nome@email.com.br" />
            <ModalButton type="submit" topbox>
              Recuperar minha senha
            </ModalButton>
          </ModelBoxTop>
        </Form>
      </Modal>
    </Container>
  );

  const modalPasswordEnd = (
    <Container visible={modal}>
      <Modal modalStart>
        <CloseButton onClick={handleCleanStep}>
          <Box as={MdClear} w="35px" h="35px" />
        </CloseButton>
        <ModelBoxTop>
          <ModelTitle>Esqueci minha senha</ModelTitle> <br />
          <ModelText>Enviamos as instruções para troca de senha para seu e-mail.</ModelText>
        </ModelBoxTop>
        <ModelBoxBottom>
          <ModalButton topbox single login onClick={handleCleanStep}>
            Fazer login
          </ModalButton>
        </ModelBoxBottom>
      </Modal>
    </Container>
  );

  if (whichModal === 'modalStart') {
    return modalStart;
  }
  if (whichModal === 'modalEmail') {
    return modalEmail;
  }
  if (whichModal === 'modalEmailEnd') {
    return modalEmailEnd;
  }
  if (whichModal === 'modalEmailEndFailure') {
    return modalEmailEndFailure;
  }
  if (whichModal === 'modalPassword') {
    return modalPassword;
  }
  if (whichModal === 'modalPasswordEnd') {
    return modalPasswordEnd;
  }

  return modalStart;
}
