import styled from '@emotion/styled';

export const Container = styled.div``;

export const Tag = styled.div`
  background: #fbd271 0% 0% no-repeat padding-box;
  border: 1px solid #efefef;
  border-radius: 5px;
  padding: 2px 5px;

  ${(props) =>
    props.dateTag &&
    `
    background: #EFEFEF 0% 0% no-repeat padding-box;
    border: 1px solid #DBDBDB;
    border-radius: 5px;
  `}
`;
