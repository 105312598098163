import { Box } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { MdClear, MdError } from 'react-icons/md';

import { amarelo } from '~/styles/colors';

import { Text, Text2 } from '../../../styles/common';
import { Col, Row } from '../../../styles/grid';
import { CloseButton, Container, Modal, ModalButton } from './styles';

export default function ChangeConfirm({ visible, onHandleVisible }) {
  const [modal, setModal] = useState(visible);

  useEffect(() => {
    setModal(visible);
  }, [visible]);

  return (
    <Container id="SendContractModal" visible={modal}>
      <Modal width="500px">
        <CloseButton onClick={onHandleVisible}>
          <Box as={MdClear} w="35px" h="35px" />
        </CloseButton>
        <Row padding="20px">
          <Box color={amarelo} as={MdError} w="80px" h="80px" />
        </Row>

        <Col alignCenter justifyCenter>
          <Row padding="20px" alignCenter justifyCenter>
            <Text textAlign="center" size="19px">
              Mudança de CPF/CNPJ
            </Text>
          </Row>
          <Text2 textAlign="center" regular size="15px">
            Só é possível alterar os dados de <span className="bold fs-15"> CPF ou CNPJ</span> da
            conta <span className="bold fs-15"> uma única vez</span> . Você realmente deseja fazer a
            alteração?
          </Text2>
        </Col>
        <Row margin="40px 0 0 0" alignCenter justifyCenter>
          <Row>
            <ModalButton onClick={onHandleVisible} outlineTheme width="100%" type="button">
              Cancelar
            </ModalButton>
          </Row>
          <Row width="auto" margin="0 5px" />
          <Row>
            <ModalButton onClick={onHandleVisible} width="100%" type="submit">
              Confirmar mudança
            </ModalButton>
          </Row>
        </Row>
      </Modal>
    </Container>
  );
}
