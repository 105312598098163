import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

// import storage from 'redux-persist/lib/storage';
import auth from './auth/reducer';
import clients from './clients/reducer';
import dashboard from './dashboard/reducer';
import financial from './financial/reducer';
import lead from './lead/reducer';
import mgm from './mgm/reducer';
import user from './user/reducer';
import utils from './utils/reducer';

export default function rootReducer(history) {
  return combineReducers({
    router: connectRouter(history),
    auth,
    user,
    lead,
    utils,
    dashboard,
    financial,
    clients,
    mgm,
  });
}
