import styled from '@emotion/styled';

import { branco, cinzaEscuro } from '../../styles/colors';

export const SvgBox = styled.svg`
  width: 24px;
  height: 24px;

  ${(props) => props.scale && `transform: scale(${props.scale});`};
  ${(props) => props.width && `width: ${props.width} !important;`};
  ${(props) => props.height && `height: ${props.height} !important;`};
`;

export const Doc1 = styled.path`
  fill: ${branco};
`;

export const Doc2 = styled.path`
  fill: ${branco};
`;

export const Doc3 = styled.path`
  fill: ${branco};
`;

export const Junk = styled.path`
  fill: ${cinzaEscuro};
`;

export const Junk2 = styled.path`
  fill: ${cinzaEscuro};
`;

export const Junk3 = styled.path`
  fill: ${cinzaEscuro};
`;

export const Note = styled.path`
  fill: ${cinzaEscuro};
`;

export const Note2 = styled.path`
  fill: ${cinzaEscuro};
`;

export const Activity = styled.path`
  fill: ${cinzaEscuro};
`;

export const Activity2 = styled.circle`
  fill: ${cinzaEscuro};
  transform: translate(7 15);
`;

export const Activity3 = styled.path`
  fill: ${cinzaEscuro};
`;
