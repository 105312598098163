import produce from 'immer';

export interface LeadReducerType {
  lead?: any;
  all_leads: any[];
  leads_by_status?: any;
  leads_situation?: any;
  leads_not_found: boolean;
  lead_not_found: boolean;
  lead_loading: boolean;
  lead_internal_loading: boolean;
  lead_update_loading: boolean;
  address_loading: boolean;
  lead_lose: boolean;
  note_activity_loading: boolean;
  send_contract_status?: boolean;
  loading_send_contract: boolean;
}

const INITIAL_STATE: LeadReducerType = {
  lead: '',
  all_leads: [],
  leads_by_status: null,
  leads_situation: null,
  leads_not_found: false,
  lead_not_found: false,
  lead_loading: false,
  lead_internal_loading: false,
  lead_update_loading: false,
  address_loading: false,
  lead_lose: false,
  note_activity_loading: false,
  send_contract_status: null,
  loading_send_contract: false,
};

export default function lead(state = INITIAL_STATE, action: { payload: any; type: string }) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@lead/STORE_NOTE':
        draft.note_activity_loading = true;
        break;

      case '@lead/STORE_ACTIVITY':
        draft.note_activity_loading = true;
        break;

      case '@lead/INDEX_LEADS2':
        draft.lead_internal_loading = true;
        break;

      case '@lead/SET_LEADS_BY_STATUS':
        draft.leads_by_status = action.payload.allStatus;
        draft.lead_loading = false;
        break;

      case '@lead/LEADS_BY_STATUS':
        draft.lead_loading = true;
        break;

      case '@lead/STORE_LEAD':
        draft.lead_update_loading = true;
        draft.lead_loading = true;
        break;

      case '@lead/ALL_LEADS':
        draft.all_leads = action.payload.leads;
        draft.lead_loading = false;
        draft.leads_not_found = false;
        break;

      case '@lead/INDEX_LEADS':
        draft.lead_loading = action.payload.loadingScreen ? true : false;
        break;

      case '@lead/SHOW_LEAD':
        draft.lead_loading = true;
        break;

      case '@lead/UPDATE_LEAD':
        draft.lead_update_loading = true;
        break;

      case '@lead/CURRENT_LEAD':
        draft.lead = action.payload.lead;
        draft.lead_loading = false;
        draft.lead_update_loading = false;
        draft.lead_not_found = false;
        draft.address_loading = false;
        draft.note_activity_loading = false;
        break;

      case '@lead/NOT_FOUND_LEAD':
        draft.lead_not_found = true;
        draft.lead_loading = false;
        break;

      case '@lead/NOT_FOUND_LEADS':
        draft.leads_not_found = true;
        draft.lead_loading = false;
        break;

      case '@lead/UPDATE_LEAD_ADDRESS':
        draft.address_loading = true;
        break;

      case '@lead/SEND_CONTRACT_STATUS':
        draft.send_contract_status = action.payload.status;
        draft.loading_send_contract = false;
        break;

      case '@lead/SEND_CONTRACT':
        draft.loading_send_contract = true;
        break;

      case '@lead/END_LOADINGS':
        draft.lead_loading = false;
        draft.lead_internal_loading = false;
        draft.lead_update_loading = false;
        draft.address_loading = false;
        draft.note_activity_loading = false;
        draft.loading_send_contract = false;
        break;

      case '@lead/END_ACTION':
        draft.lead_loading = false;
        draft.address_loading = false;
        draft.lead_not_found = false;
        draft.leads_not_found = false;
        draft.lead_update_loading = false;
        draft.lead_internal_loading = false;
        draft.leads_situation = null;
        draft.lead_lose = false;
        draft.note_activity_loading = false;
        draft.send_contract_status = null;
        break;

      case '@lead/SITUATION_LEADS':
        draft.lead_loading = true;
        draft.leads_situation = action.payload.situation;
        break;

      case '@lead/LOSE_LEAD':
        draft.lead_loading = true;
        break;

      case '@lead/SHOW_LOSE_LEADS':
        draft.lead_lose = true;
        break;

      case '@lead/CLEAN_LEAD_SITUATION':
        draft.leads_situation = null;
        break;

      default:
    }
  });
}
