import * as yup from 'yup';

export default yup.object().shape({
  region_type: yup.string().required('Por favor informe tipo da região.'),
  instagram: yup.string(),
  instagram_followers_qty: yup.string().when('instagram', {
    is: (value: String) => value !== '',
    then: yup.string().required('Por favor informe a quantidade de seguidores no instagram.'),
  }),
});
