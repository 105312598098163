import produce from 'immer';

const INITIAL_STATE = {
  token: null,
  signed: false,
  loading: false,
  master: false,
  isKuppiCommercialTeam: false,
  isKuppido10: false,
  isKuppido20: false,
  isKuppido30: false,
  isKuppidoX: false,
  loading_recovery: false,
  recovered_email: null,
  recovered_email_failure: false,
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@auth/LOGIN_REQUEST':
        draft.loading = true;
        break;

      case '@auth/SET_IS_KUPPI_COMMERCIAL_TEAM':
        draft.isKuppiCommercialTeam = action.payload.status;
        break;

      case '@auth/SET_IS_KUPPIDO_1.0':
        draft.isKuppido10 = action.payload.status;
        break;

      case '@auth/SET_IS_KUPPIDO_2.0':
        draft.isKuppido20 = action.payload.status;
        break;

      case '@auth/SET_IS_KUPPIDO_3.0':
        draft.isKuppido30 = action.payload.status;
        break;

      case '@auth/SET_IS_KUPPIDO_X':
        draft.isKuppidoX = action.payload.status;
        break;

      case '@auth/LOGIN_SUCCESS':
        draft.token = action.payload.token.token;
        draft.signed = true;
        draft.loading = false;
        break;

      case '@auth/LOGIN_MASTER_SUCCESS':
        draft.token = action.payload.token.token;
        draft.master = true;
        draft.signed = true;
        draft.loading = false;
        break;

      case '@auth/LOGIN_FAILURE':
        draft.loading = false;
        break;

      case '@auth/LOG_OUT':
        return INITIAL_STATE;

      case '@auth/RECOVER_PASSWORD':
        draft.loading_recovery = true;
        break;

      case '@auth/RECOVER_EMAIL':
        draft.loading_recovery = true;
        break;

      case '@auth/POST_RECOVER_EMAIL':
        draft.loading_recovery = false;
        draft.recovered_email_failure = false;
        draft.recovered_email = action.payload.email;
        break;

      case '@auth/POST_RECOVER_EMAIL_FAILURE':
        draft.loading_recovery = false;
        draft.recovered_email_failure = true;
        break;

      case '@auth/END_ACTION':
        return INITIAL_STATE;

      default:
    }
  });
}
