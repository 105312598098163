import SendContract from '~/models/SendContract';

export function sendContract(payload: SendContract) {
  return {
    type: '@lead/SEND_CONTRACT',
    payload,
  };
}

export function sendContractStatus(status?: boolean) {
  return {
    type: '@lead/SEND_CONTRACT_STATUS',
    payload: { status },
  };
}

export function endLoadings() {
  return {
    type: '@lead/END_LOADINGS',
  };
}

export function cleanLeadSituation() {
  return {
    type: '@lead/CLEAN_LEAD_SITUATION',
  };
}

export function dontShowReasonForLoss(lead_slug: any) {
  return {
    type: '@lead/DONT_SHOW_REASON_FOR_LOSS',
    payload: { lead_slug },
  };
}

export function destroyNote(lead_id: any, note_id: any) {
  return {
    type: '@lead/DESTROY_NOTE',
    payload: { lead_id, note_id },
  };
}

export function destroyActivity(lead_id: any, activity_id: any) {
  return {
    type: '@lead/DESTROY_ACTIVITY',
    payload: { lead_id, activity_id },
  };
}

export function storeNote(lead_id: any, name: any, description: any) {
  return {
    type: '@lead/STORE_NOTE',
    payload: { lead_id, name, description },
  };
}

export function storeActivity(
  lead_id: any,
  name: any,
  description: any,
  type: any,
  activity_at: any,
) {
  return {
    type: '@lead/STORE_ACTIVITY',
    payload: { lead_id, name, description, type, activity_at },
  };
}

export function updateActivity(
  activity_id: any,
  lead_id: any,
  name: any,
  description: any,
  type: any,
  activity_at: any,
  is_concluded: any,
) {
  return {
    type: '@lead/UPDATE_ACTIVITY',
    payload: {
      activity_id,
      lead_id,
      name,
      description,
      type,
      activity_at,
      is_concluded,
    },
  };
}

export function updateNote(note_id: any, lead_id: any, name: any, description: any) {
  return {
    type: '@lead/UPDATE_NOTE',
    payload: { note_id, lead_id, name, description },
  };
}

export function setLeadsByStatus(allStatus?: any) {
  return {
    type: '@lead/SET_LEADS_BY_STATUS',
    payload: { allStatus },
  };
}

export function leadsByStatus(leads: any) {
  return {
    type: '@lead/LEADS_BY_STATUS',
    payload: { leads },
  };
}

export function situationLeads(situation: any) {
  return {
    type: '@lead/SITUATION_LEADS',
    payload: { situation },
  };
}

export function showLoseLead() {
  return {
    type: '@lead/SHOW_LOSE_LEADS',
  };
}

export function indexLeads(loadingScreen = true) {
  return {
    type: '@lead/INDEX_LEADS',
    payload: { loadingScreen },
  };
}

export function indexLeads2() {
  return {
    type: '@lead/INDEX_LEADS2',
  };
}

export function indexLeadsNoLoading() {
  return {
    type: '@lead/INDEX_LEADS_NO_LOADING',
  };
}

export function loseLead(slug: any, reason_for_loss: any, loss_description: any) {
  return {
    type: '@lead/LOSE_LEAD',
    payload: { slug, reason_for_loss, loss_description },
  };
}

export function showLead(slug: any) {
  return {
    type: '@lead/SHOW_LEAD',
    payload: { slug },
  };
}

export function destroyLead(slug: any, redirect: any) {
  return {
    type: '@lead/DESTROY_LEAD',
    payload: { slug, redirect },
  };
}

export function storeLead(
  advertiser_category_id: any,
  name: any,
  cpf_cnpj: any,
  email: any,
  phone_1: any,
  phone_2: any,
  funnel_status: any,
) {
  return {
    type: '@lead/STORE_LEAD',
    payload: {
      advertiser_category_id,
      name,
      cpf_cnpj,
      email,
      phone_1,
      phone_2,
      funnel_status,
    },
  };
}

export function currentLead(lead: any) {
  return {
    type: '@lead/CURRENT_LEAD',
    payload: { lead },
  };
}

export function allLeads(leads: any) {
  return {
    type: '@lead/ALL_LEADS',
    payload: { leads },
  };
}

export function updateLeadAddress(
  zipcode: any,
  state: any,
  city: any,
  street: any,
  neighborhood: any,
  number: any,
  complement: any,
) {
  return {
    type: '@lead/UPDATE_LEAD_ADDRESS',
    payload: { zipcode, state, city, street, neighborhood, number, complement },
  };
}

export function updateFunnelStatus(slug: any, funnel_status: any) {
  return {
    type: '@lead/UPDATE_FUNNEL_STATUS',
    payload: { slug, funnel_status },
  };
}

export function updateLead(
  name: any,
  funnel_status: any,
  advertiser_category_id: any,
  email: any,
  phone_1: any,
  phone_2: any,
  cpf_cnpj: any,
) {
  return {
    type: '@lead/UPDATE_LEAD',
    payload: {
      name,
      funnel_status,
      advertiser_category_id,
      email,
      phone_1,
      phone_2,
      cpf_cnpj,
    },
  };
}

export function endAction() {
  return {
    type: '@lead/END_ACTION',
  };
}

export function notFoundLead() {
  return {
    type: '@lead/NOT_FOUND_LEAD',
  };
}

export function notFoundLeads() {
  return {
    type: '@lead/NOT_FOUND_LEADS',
  };
}
