export const formatCurrencyBRL = (value: number, options?: Intl.NumberFormatOptions) => {
  const formatter = new Intl.NumberFormat('pt-BR', {
    currency: 'BRL',
    style: 'currency',
    ...options,
  });
  return formatter.format(value);
};

export const parseCurrencyBRL = (value: string): number => {
  if (value !== undefined && value !== null) {
    const normalizedValue = value.replace(/R\$/, '').replace('.', ',').replace(',', '.');
    const number = parseFloat(normalizedValue);
    return number || 0;
  }
};

export const maskCurrencyBRL = (value: string, options?: Intl.NumberFormatOptions): string => {
  const number = parseCurrencyBRL(value);
  return formatCurrencyBRL(number, options);
};
