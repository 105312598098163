import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { dashboard, login as loginRoute } from '../../../routes/routeMap';
import { apiGlobal, apiKuppido } from '../../../services/api';
import {
  endAction,
  loginFailure,
  loginMasterSuccess,
  loginSuccess,
  logoutSuccessFailure,
  postRecoverEmail,
  postRecoverEmailFailure,
  setIsKuppiCommercialTeam,
  setIsKuppido10,
  setIsKuppido20,
  setIsKuppido30,
  setIsKuppidoX,
} from './actions';
import history from '~/services/history';

export function* login({ payload }) {
  try {
    const { email, password } = payload;

    const response = yield call(apiGlobal.post, '/auth', {
      email,
      password,
    });

    const { token, user } = response.data;

    if (!user.is_active) {
      toast.error('O Usuário não está ativo. Entre em contato com suporte.');
      yield put(loginFailure());
      return;
    }

    const userRoles = [];

    user.roles.forEach((role) => {
      userRoles.push(role.slug);
    });

    const userIsKuppido = userRoles.includes('kuppido');
    const userIsKuppidoMaster = userRoles.includes('kuppido_master');
    const userIsKuppiCommercialTeam = userRoles.includes('kuppi_commercial_team');
    const userIsKuppido10 = userRoles.includes('kuppido_1.0');
    const userIsKuppido20 = userRoles.includes('kuppido_2.0');
    const userIsKuppido30 = userRoles.includes('kuppido_3.0');
    const userIsKuppidoX = userRoles.includes('kuppido_x');

    if (!userIsKuppido && !userIsKuppidoMaster) {
      toast.error('O Usuário não possui não possui permissões para acesso.');
      yield put(loginFailure());
      return;
    }

    if (userIsKuppidoMaster) {
      yield put(loginMasterSuccess(token, user));
    }
    if (userIsKuppido) {
      yield put(loginSuccess(token, user));
    }
    yield put(setIsKuppiCommercialTeam(userIsKuppiCommercialTeam));
    yield put(setIsKuppido10(userIsKuppido10));
    yield put(setIsKuppido20(userIsKuppido20));
    yield put(setIsKuppido30(userIsKuppido30));
    yield put(setIsKuppidoX(userIsKuppidoX));

    apiGlobal.defaults.headers.common.Authorization = `Bearer ${token.token}`;
    apiKuppido.defaults.headers.common.Authorization = `Bearer ${token.token}`;

    yield put(push(dashboard));
  } catch (err) {
    if (err.response?.status === 403) {
      toast.error('O Usuário não está ativo. Entre em contato com suporte.', {
        autoClose: 6000,
      });
    } else {
      toast.error(`Falha na autenticação. Verifique seus dados.`, {
        autoClose: 6000,
      });
    }

    yield put(loginFailure());
  }
}

export function setToken({ payload }) {
  if (!payload) return;

  const { token } = payload.auth;

  if (token) {
    apiGlobal.defaults.headers.common.Authorization = `Bearer ${token}`;
    apiKuppido.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
}

export function* logout({ payload }) {
  try {
    if (!payload.invalidSession) {
      const user = yield select((state) => state.user.user);

      yield call(apiGlobal.delete, `/user/${user.id}/logout`);
    }

    yield put(logoutSuccessFailure());

    history.push(`${loginRoute}${payload.invalidSession ? '?erro=sessao-invalida' : ''}`);
  } catch (err) {
    yield put(logoutSuccessFailure());

    history.push(loginRoute);
  }
}

export function* recoverPassword({ payload }) {
  const { email } = payload;
  yield call(apiGlobal.post, '/forgot-password', { email });
  yield put(endAction());
}

export function* recoverEmail({ payload }) {
  try {
    const { name, cpf_cnpj } = payload;
    const response = yield call(apiGlobal.post, '/forgot-email', {
      name,
      cpf_cnpj,
    });
    yield put(postRecoverEmail(response.data.email));
  } catch (err) {
    yield put(postRecoverEmailFailure());
  }
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/LOGIN_REQUEST', login),
  takeLatest('@auth/LOG_OUT_REQUEST', logout),
  takeLatest('@auth/RECOVER_PASSWORD', recoverPassword),
  takeLatest('@auth/RECOVER_EMAIL', recoverEmail),
]);
