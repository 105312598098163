import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';

const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
const ENV_API =
  process.env.REACT_APP_ENV_API === 'development' || process.env.REACT_APP_ENV_API === 'local'
    ? null
    : process.env.REACT_APP_ENV_API;

const NODE_ENV = process.env.NODE_ENV !== 'development';

if (NODE_ENV && ENV_API) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: ENV_API,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
