import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import DefaultLayout from '../pages/_layouts/default';
import NoLayout from '../pages/_layouts/noLayout';
import SignedLayout from '../pages/_layouts/signed';
import { store } from '../stores';
import { leadManagement, login, logout } from './routeMap';

export default function RouteWrapper({ isPrivate, option, component: Component, ...rest }) {
  const { signed, master } = store.getState().auth;
  const { userInfo } = store.getState().user;

  if (!signed && isPrivate) {
    return <Redirect to={login} />;
  }

  if (signed && rest.path === login) {
    return <Redirect to={leadManagement} />;
  }

  if (!master && option === 'mgm') {
    return <Redirect to={leadManagement} />;
  }

  if (
    signed &&
    rest.path !== leadManagement &&
    rest.path !== logout &&
    !userInfo.is_terms_accepted
  ) {
    return <Redirect to={leadManagement} />;
  }

  if (
    signed &&
    rest.path !== leadManagement &&
    rest.path !== logout &&
    !userInfo.is_done_onboarding
  ) {
    return <Redirect to={leadManagement} />;
  }

  const Layout = rest.noLayout ? NoLayout : signed ? SignedLayout : DefaultLayout;

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout option={option}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  admin: PropTypes.bool,
  option: PropTypes.string.isRequired,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
  admin: false,
};
