import { ListIcon, ListItem, ListItemProps, Text } from '@chakra-ui/react';
import React from 'react';
import { RiCheckboxCircleLine, RiCloseCircleLine } from 'react-icons/ri';

interface ListItemTooltipProps extends ListItemProps {
  text: string;
  condition: boolean;
}

const ListItemTooltip: React.FC<ListItemTooltipProps> = ({ condition, text, ...rest }) => {
  return (
    <ListItem d="flex" flexDir="row" alignItems="center" justifyContent="flex-start" {...rest}>
      <ListIcon
        as={condition ? RiCheckboxCircleLine : RiCloseCircleLine}
        color={condition ? 'green.500' : 'red.500'}
        height="20px"
        width="20px"
      />
      <Text fontWeight="semibold" color={condition ? 'green.500' : 'red.500'}>
        {text}
      </Text>
    </ListItem>
  );
};

export default ListItemTooltip;
