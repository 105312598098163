import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '~/components/Button';

import { updateLead } from '../../stores/modules/lead/actions';
import { CustomForm, Error, InputCustom, Label, SelectCustom } from '../../styles/common';
import { Col, Row } from '../../styles/grid';
import { funnelStatus } from '../../utils/commonObjects';
import { cnpjMask, phoneMask } from '../../utils/masks';
import { onlyNumbers } from '../../utils/onlyNumbers';

export default function Info() {
  const lead = useSelector((state) => state.lead.lead);
  const loading = useSelector((state) => state.lead.lead_update_loading);
  const [CnpjField, setCnpjField] = useState(lead.cpf_cnpj);
  const [phone1Field, setPhone1Field] = useState(lead.phone_1);
  const [phone2Field, setPhone2Field] = useState(lead.phone_2);
  const segments = useSelector((state) => state.user.advertiserCategories);
  const status = lead.funnel_status;

  useEffect(() => {
    setCnpjField(cnpjMask(lead.cpf_cnpj));
    setPhone1Field(phoneMask(lead.phone_1));
    setPhone2Field(phoneMask(lead.phone_2));
  }, [lead]);

  const dispatch = useDispatch();

  function handleSubmit({ name, funnel_status, advertiser_category_id, email, phone_1, phone_2 }) {
    dispatch(
      updateLead(
        name,
        funnel_status,
        advertiser_category_id,
        email,
        onlyNumbers(phone_1),
        onlyNumbers(phone_2),
      ),
    );
  }

  return (
    <CustomForm
      onSubmit={handleSubmit}
      initialData={{
        email: lead && lead.email,
        name: lead && lead.name,
        phone_1: lead && lead.phone_1,
        phone_2: lead && lead.phone_2,
        cpf_cnpj: lead && lead.cpf_cnpj,
        funnel_status: lead && lead.funnel_status,
        advertiser_category_id: lead && lead.advertiser_category_id,
      }}
    >
      <Col justifyStart alignStart>
        <Label>Nome do estabelecimento</Label>
        <InputCustom disabled={status === null} required type="text" name="name" />
        <Error />
      </Col>
      <Col justifyStart alignStart>
        <Label>Status</Label>
        <SelectCustom
          disabled={status === null}
          required
          options={funnelStatus}
          name="funnel_status"
        />
        <Error />
      </Col>
      <Col justifyStart alignStart>
        <Label>Segmento</Label>
        <SelectCustom disabled={status === null} options={segments} name="advertiser_category_id" />
        <Error />
      </Col>
      <Col justifyStart alignStart>
        <Label>E-mail</Label>
        <InputCustom disabled={status === null} type="email" name="email" />
        <Error />
      </Col>
      <Col justifyStart alignStart>
        <Label>Telefone</Label>
        <InputCustom
          type="text"
          disabled={status === null}
          value={phone1Field}
          onChange={(event) => setPhone1Field(phoneMask(event.target.value))}
          name="phone_1"
        />
        <Error />
      </Col>
      <Col justifyStart alignStart>
        <Label>Telefone 2</Label>
        <InputCustom
          type="text"
          disabled={status === null}
          value={phone2Field}
          onChange={(event) => setPhone2Field(phoneMask(event.target.value))}
          name="phone_2"
        />
        <Error />
      </Col>
      <Col justifyStart alignStart>
        <Label>CNPJ</Label>
        <InputCustom
          required
          disabled
          value={CnpjField}
          onChange={(event) => setCnpjField(cnpjMask(event.target.value))}
          type="text"
          name="cpf_cnpj"
        />
        <Error />
      </Col>
      <Row>
        <Button
          isLoading={loading || status === null}
          padding="0"
          w="130px"
          height="40px"
          type="submit"
        >
          Salvar
        </Button>
      </Row>
      <Row padding="5px" />
    </CustomForm>
  );
}
