import produce from 'immer';

const INITIAL_STATE = {
  active_contracts: [],
  active_contracts_payables: [],
  subscriptions: [],
  others_contracts: [],
  recipient_data: null,
  loading: false,
};

export default function financial(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@financial/INDEX_ACTIVE_CONTRACTS':
        draft.loading = true;
        break;
      case '@financial/FETCH_SUBSCRIPTIONS':
        draft.loading = true;
        break;
      case '@financial/SET_SUBSCRIPTIONS':
        draft.subscriptions = action.payload.subscriptions;
        draft.loading = false;
        break;
      case '@financial/SAVE_CONTRACT':
        draft.loading = true;
        break;
      case '@financial/SET_ACTIVE_CONTRACTS':
        draft.loading = false;
        draft.active_contracts = action.payload.contracts;
        break;
      case '@financial/INDEX_OTHERS_CONTRACTS':
        draft.loading = true;
        break;
      case '@financial/SET_OTHERS_CONTRACTS':
        draft.loading = false;
        draft.others_contracts = action.payload.contracts;
        break;
      case '@financial/SET_PAYABLE_DATA':
        draft.active_contracts_payables = action.payload.payables;
        break;
      case '@financial/SET_RECIPIENT_DATA':
        draft.recipient_data = action.payload.recipient;
        break;
      case '@financial/END_ACTION':
        draft.loading = false;
        break;
      case '@auth/LOG_OUT':
        return INITIAL_STATE;
      default:
    }
  });
}
