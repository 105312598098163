import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import Button from '~/components/Button';

import { storeActivity } from '../../stores/modules/lead/actions';
import {
  ContentDefault,
  CustomForm,
  InputCustomInvisible,
  SelectCustomInvisible,
  TextareaCustomInvisible,
} from '../../styles/common';
import { Row } from '../../styles/grid';
import { activityTypes } from '../../utils/commonObjects';

export default function Activity({ disabled }) {
  const lead_id = useSelector((state) => state.lead.lead.id);
  const loading = useSelector((state) => state.lead.note_activity_loading);
  const dispatch = useDispatch();

  function handleSubmit({ name, description, type, activity_at }, { resetForm }) {
    if (name === '' || description === '' || type === '' || activity_at === '') {
      toast.error('Preencha todos os campos para adicionar uma nova atividade.');
    } else {
      dispatch(storeActivity(lead_id, name, description, type, activity_at));
      resetForm();
    }
  }

  return (
    <CustomForm onSubmit={handleSubmit}>
      <Row>
        <ContentDefault margin="1px 0" groupMiddle>
          <InputCustomInvisible
            name="name"
            type="text"
            disabled={disabled}
            required
            placeholder="Titulo da observação"
          />
        </ContentDefault>
        <ContentDefault margin="1px 2px" groupMiddle>
          <InputCustomInvisible
            padding="6.5px 10px"
            name="activity_at"
            disabled={disabled}
            required
            type="datetime-local"
            placeholder="Data e hora"
          />
        </ContentDefault>
        <ContentDefault margin="1px 0" groupMiddle padding="0 10px">
          <SelectCustomInvisible
            options={activityTypes}
            disabled={disabled}
            name="type"
            required
            placeholder="Tipo de Atividade"
          />
        </ContentDefault>
      </Row>
      <ContentDefault groupBottom>
        <TextareaCustomInvisible
          multiline="true"
          name="description"
          disabled={disabled}
          required
          placeholder="Escreva aqui sua observação"
        />
      </ContentDefault>
      <Row alignCenter justifyEnd>
        <Button isLoading={loading} padding="0" w="130px" height="40px" type="submit">
          Adicionar
        </Button>
      </Row>
    </CustomForm>
  );
}
