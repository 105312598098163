import styled from '@emotion/styled';

import { amarelo, verdeKuppi } from '../../../styles/colors';

export const SvgBox = styled.svg`
  width: 34.424px;
  height: 33.998px;
  transform: scale(0.8);

  .a {
    fill: ${(props) => (props.received ? amarelo : verdeKuppi)};
  }
  .b {
    fill: none;
  }
  .c {
    fill: ${(props) => (props.received ? amarelo : verdeKuppi)};
    stroke-linecap: square;
    stroke-linejoin: round;
  }
  .d,
  .e,
  .f {
    stroke: none;
  }
  .e {
    fill: ${(props) => (props.received ? amarelo : verdeKuppi)};
  }
  .f {
    fill: #fff;
  }
`;
