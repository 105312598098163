import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { AdvertiserSubscriptionProvider } from '~/contexts/AdvertiserSubscriptionContext';

import FinancialTableActives from '../../components/FinancialTableActives';
import FinancialTableOthers from '../../components/FinancialTableOthers';
import { indexActiveContracts, indexOthersContracts } from '../../stores/modules/financial/actions';
import { Row } from '../../styles/grid';
import {
  Container,
  ContentDefault,
  Field,
  SwitchTableButton,
  Title,
  // SearchInput,
  // SearchBox,
} from './styles';

// import { SvgBox, Search } from './icons';

export default function LeadManagement() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(indexActiveContracts());
    dispatch(indexOthersContracts());
  }, [dispatch]);

  const [table, setTable] = useState('actives');
  const toggleTable = (newTable) => setTable(newTable);

  return (
    <Container>
      <Row alignStart justifyStart>
        <Title>Gestão de Contratos</Title>

        {/* <SearchBox className="ml-auto">
          <SvgBox>
            <Search />
          </SvgBox>
          <SearchInput
            name="search"
            placeholder="Busque por um contrato"
            // onFocus={() => toggleTable('actives')}
          />
        </SearchBox> */}
      </Row>
      <Row padding="20px" />
      <ContentDefault flexStart groupTop>
        <Field>
          <SwitchTableButton onClick={() => toggleTable('actives')} selected={table === 'actives'}>
            Contratos Ativos
            <div className="selected" />
          </SwitchTableButton>
        </Field>
        <Field>
          <SwitchTableButton onClick={() => toggleTable('others')} selected={table === 'others'}>
            Contratos Pendentes
            <div className="selected" />
          </SwitchTableButton>
        </Field>
        {/* <Field mlAuto>
          <NiceSelect />
        </Field> */}
      </ContentDefault>
      <ContentDefault groupBottom>
        <AdvertiserSubscriptionProvider>
          {table === 'actives' && <FinancialTableActives />}
          {table === 'others' && <FinancialTableOthers />}
        </AdvertiserSubscriptionProvider>
      </ContentDefault>
    </Container>
  );
}
