import produce from 'immer';

const INITIAL_STATE = {
  received_address: null,
  loading: false,
};

export default function utils(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@utils/GET_ADDRESS':
        draft.loading = true;
        break;
      case '@utils/END_ACTION':
        draft.loading = false;
        break;
      case '@utils/SET_ADDRESS':
        draft.received_address = action.payload.address;
        draft.loading = false;
        break;
      case '@utils/CLEAN_ACTION':
        draft.received_address = null;
        draft.loading = false;
        break;
      case '@auth/LOG_OUT':
        return INITIAL_STATE;
      default:
    }
  });
}
