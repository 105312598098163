import { Box, Text } from '@chakra-ui/react';
import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';

import Button from '~/components/Button';
import InputWithValidation from '~/components/Form/InputWithValidation';
import SelectWithValidation from '~/components/Form/SelectWithValidation';
import useYupValidationResolver from '~/hooks/useYupValidationResolver';
import step2Schema from '~/models/schemas/sendContract/step2Schema';
import queryZipcode from '~/services/queryZipcode';
import { statesOptions } from '~/utils/commonObjects';
// import { debounce } from '~/utils/debounce';
import { cepMask } from '~/utils/masks';

import StepsModel from '../StepsModel';

interface ContractFields {
  zipcode?: string;
  street?: string;
  number?: string;
  neighborhood?: string;
  complement?: string;
  city?: string;
  state?: string;
}

interface Step2Props extends StepsModel {}

const Step2: React.FC<Step2Props> = ({
  handleChangeContractData,
  contractData,
  onClose,
  isOpen,
}) => {
  const { register, handleSubmit, setValue, errors } = useForm<ContractFields>({
    resolver: useYupValidationResolver(step2Schema),
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    setValue('zipcode', cepMask(contractData?.zipcode));
    setValue('street', contractData?.street);
    setValue('number', contractData?.number);
    setValue('neighborhood', contractData?.neighborhood);
    setValue('complement', contractData?.complement);
    setValue('city', contractData?.city);
    setValue('state', contractData?.state);
  }, [contractData, isOpen, setValue]);

  const onSubmit = (data: ContractFields) => {
    handleChangeContractData(data);
  };

  // const getAddressByZipcode = debounce(async (zipcode: string) => await queryZipcode(zipcode));

  const addressNumberInput = useRef(null);

  const onChangeZipcode = async (zip: string) => {
    setValue('zipcode', cepMask(zip), { shouldValidate: true });

    if (zip.length === 10) {
      const fetchedAddress = await queryZipcode(zip);

      if (fetchedAddress) {
        setValue('street', fetchedAddress?.street);
        setValue('neighborhood', fetchedAddress?.neighborhood);
        setValue('city', fetchedAddress?.city);
        setValue('state', fetchedAddress?.state);
        addressNumberInput.current.focus();
      }
    }
  };

  return (
    <>
      <Text fontSize="18px" fontWeight="700" mb="1.5rem">
        Endereço do estabelecimento
      </Text>

      <Box
        as="form"
        d="flex"
        flexDirection="column"
        h="100%"
        w="100%"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box h="100%" d="flex" flexDir="column">
          <Box d="flex" w="100%" mt="10px">
            <InputWithValidation
              textLabel="Cep"
              name="zipcode"
              errors={errors}
              register={register()}
              placeholder="00.000-000"
              formProps={{ mr: '5px', w: '30%' }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeZipcode(e.target.value)}
            />

            <InputWithValidation
              textLabel="Endereço"
              name="street"
              errors={errors}
              register={register()}
              placeholder="Endereço"
              formProps={{ mx: '5px' }}
            />

            <InputWithValidation
              textLabel="Número"
              name="number"
              errors={errors}
              register={(e: HTMLInputElement) => {
                register(e);
                addressNumberInput.current = e;
              }}
              placeholder="Número"
              formProps={{ mx: '5px', w: '20%' }}
            />
          </Box>

          <Box d="flex" w="100%" mt="10px">
            <InputWithValidation
              textLabel="Bairro"
              name="neighborhood"
              errors={errors}
              register={register()}
              placeholder="Bairro"
              formProps={{ mr: '5px' }}
            />

            <InputWithValidation
              textLabel="Cidade"
              name="city"
              errors={errors}
              register={register()}
              placeholder="Cidade"
              formProps={{ mx: '5px' }}
            />

            <SelectWithValidation
              textLabel="Estado"
              name="state"
              errors={errors}
              register={register()}
              placeholder="Estado"
              formProps={{ mx: '5px' }}
            >
              {statesOptions.map((state: { id: string; title: string }) => (
                <option key={state.id} value={state.id}>
                  {state.title}
                </option>
              ))}
            </SelectWithValidation>

            <InputWithValidation
              textLabel="Complemento"
              name="complement"
              errors={errors}
              register={register()}
              placeholder="Complemento"
              formProps={{ ml: '5px' }}
            />
          </Box>
        </Box>

        <Box mt="93px" pt="2.5rem" d="flex" justifyContent="center">
          <Button w="237px" type="button" variant="outline" mr="1rem" onClick={onClose}>
            Cancelar
          </Button>
          <Button w="237px" type="submit">
            Confirmar envio
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default Step2;
