import { Box } from '@chakra-ui/react';
import { Form } from '@rocketseat/unform';
import React, { useEffect, useState } from 'react';
import { BiArrowFromBottom } from 'react-icons/bi';
import { MdClear, MdDone } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { apiGlobal } from '../../../services/api';
import { setUserAvatar } from '../../../stores/modules/user/actions';
import {
  CloseButton,
  ColumnContent,
  Container,
  Error,
  Label,
  Modal,
  ModalButton,
  ModelTitle,
  RowContent,
} from '../styles';
import { FileInputCustom, FileInputCustomBox, FileInputLabel } from './styles';

export default function ProfileModalAvatar({ visible, onChange }) {
  const userId = useSelector((state) => state.user?.user?.id);
  const [modal, setModal] = useState(visible);
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState();
  const [fileSize, setFileSize] = useState();
  const [fileType, setFileType] = useState();
  const [fileSubtype, setFileSubtype] = useState();
  // const [fileUrl, setFileUrl] = useState();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    setModal(visible);
  }, [visible]);

  const handleVisible = () => onChange();

  const handleCleanAll = () => {
    setFile();
    setFileName();
    setFileSize();
    setFileType();
    setFileSubtype();
  };

  async function handleSubmit() {
    if (!file) {
      toast.error('Você precisa selecionar um arquivo para atualiar sua foto.');
    } else if (error) {
      toast.error('Corrija todos os erros para para atualiar sua foto.');
    } else {
      handleVisible();

      const formData = new FormData();
      formData.append('image', file);

      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };

      const response = await apiGlobal.post(`/user/${userId}/avatar`, formData, config);

      if (response.status === 200) {
        dispatch(setUserAvatar(response.data));
        toast.success('Foto de perfil atualizada com sucesso!');
        handleCleanAll();
      } else {
        toast.error('Não foi possível atualizar sua foto de perfil.');
      }
    }
  }

  async function handleFile(event) {
    const f = event.target.files[0];
    if (f) {
      const { name } = f;
      const size = (f.size / 1000000).toFixed(1);
      const type = f.type.split('/')[0];
      const subtype = f.type.split('/')[1];
      // const url = URL.createObjectURL(f);

      setFile(f);
      setFileName(name);
      setFileSize(size);
      setFileType(type);
      setFileSubtype(subtype);
      //   setFileUrl(url);
    } else {
      handleCleanAll();
    }
  }

  useEffect(() => {
    const supportedFormats = ['png', 'jpg', 'jpeg'];

    if (fileSize && fileSize > 5) {
      setError(true);
      setErrorMessage('O Arquivo enviado precisa ser menor que 5mb.');
    } else if (fileType && fileType !== 'image') {
      setError(true);
      setErrorMessage('O Arquivo enviado precisa está no formato de imagem.');
    } else if (fileSubtype && !supportedFormats.includes(fileSubtype)) {
      setError(true);
      setErrorMessage('A Imagem enviado precisa ser do tipo .png, .jpg ou .jpeg.');
    } else {
      setError(false);
      setErrorMessage('');
    }
  }, [file, fileSize, fileType, fileSubtype]);

  return (
    <Container visible={modal}>
      <Modal width="500px">
        <CloseButton onClick={handleVisible}>
          <Box as={MdClear} w="35px" h="35px" />
        </CloseButton>
        <ModelTitle>Alterar Foto</ModelTitle> <br />
        {/* <Col>
          <img src={preview} width="100" />
        </Col> */}
        <Form onSubmit={handleSubmit}>
          <RowContent>
            <ColumnContent>
              <Label>Insira sua foto</Label>
              {fileName ? (
                <FileInputCustomBox onError={error}>
                  <Box as={error ? MdClear : MdDone} w="22px" h="22px" />
                  {fileName}
                </FileInputCustomBox>
              ) : (
                <FileInputCustomBox onError={error}>
                  <Box as={BiArrowFromBottom} w="22px" h="22px" />
                  Arraste e solte a foto aqui
                </FileInputCustomBox>
              )}
              <FileInputCustom
                name="avatar"
                id="avatar"
                type="file"
                accept="image/*"
                onChange={handleFile}
                onError={error}
              />
              <FileInputLabel for="avatar">
                {fileName ? 'Arquivo selecionado' : '+ Escolher foto'}
              </FileInputLabel>
              {error && <Error>{errorMessage}</Error>}
            </ColumnContent>
          </RowContent>

          <RowContent lastRow>
            <ColumnContent left button>
              <ModalButton outlineTheme onClick={handleVisible} type="button">
                Cancelar
              </ModalButton>
            </ColumnContent>

            <ColumnContent right button>
              <ModalButton type="submit">Confirmar</ModalButton>
            </ColumnContent>
          </RowContent>
        </Form>
      </Modal>
    </Container>
  );
}
