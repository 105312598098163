import { ChakraProvider, ColorModeProvider, CSSReset } from '@chakra-ui/react';
import React from 'react';
import { ToastContainer } from 'react-toastify';

import GlobalStyles from '~/styles/global';
import theme from '~/styles/theme';

const ThemeContainer: React.FC = ({ children }) => (
  <ChakraProvider theme={theme}>
    <ColorModeProvider
      options={{
        initialColorMode: 'light',
        useSystemColorMode: true,
      }}
      value="light"
    >
      <GlobalStyles />
      <CSSReset />
      <ToastContainer autoClose={5000} position="top-right" />
      {children}
    </ColorModeProvider>
  </ChakraProvider>
);

export default ThemeContainer;
