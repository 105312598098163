import { Box, Spinner } from '@chakra-ui/react';
import { Form } from '@rocketseat/unform';
import React, { useEffect, useState } from 'react';
import { MdClear } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';

import { loseLead } from '../../../stores/modules/lead/actions';
import { branco, vermelhoClaro } from '../../../styles/colors';
import { LoadingBox, SelectCustom, TextareaCustom } from '../../../styles/common';
import { lossStatus } from '../../../utils/commonObjects';
import {
  CloseButton,
  ColumnContent,
  Container,
  Label,
  Modal,
  ModalButton,
  ModelTitle,
  RowContent,
} from '../styles';

export default function LoseLeads({ visible, onChange, slug }) {
  const [modal, setModal] = useState(visible);
  const loading = useSelector((state) => state.lead.lead_loading);

  const dispatch = useDispatch();

  const handleSubmit = ({ reason_for_loss, loss_description }) => {
    if (reason_for_loss === '') return;
    dispatch(loseLead(slug, reason_for_loss, loss_description));
    onChange();
  };

  useEffect(() => {
    setModal(visible);
  }, [visible]);

  return (
    <>
      <Container visible={modal}>
        <Modal modelEmail>
          <CloseButton onClick={onChange}>
            <Box as={MdClear} w="35px" h="35px" />
          </CloseButton>
          <ModelTitle>Alterar dados</ModelTitle> <br />
          <Form onSubmit={handleSubmit}>
            <ColumnContent width="50%">
              <Label>Escolha o motivo de perda do Lead</Label>
              <SelectCustom
                name="reason_for_loss"
                required
                placeholder="Escolha o motivo"
                options={lossStatus}
              />
            </ColumnContent>

            <RowContent
            // margin="0 0 100px 0"
            >
              <ColumnContent>
                <Label>Observações</Label>
                <TextareaCustom
                  multiline="true"
                  name="loss_description"
                  placeholder="Observações sobre o motivo da perda"
                />
              </ColumnContent>
            </RowContent>

            <RowContent lastRow>
              <ColumnContent left button>
                <ModalButton outlineTheme onClick={onChange} type="button">
                  Cancelar
                </ModalButton>
              </ColumnContent>

              <ColumnContent right button>
                <ModalButton bgColor={vermelhoClaro} disabled={loading} type="submit">
                  {loading ? (
                    <LoadingBox>
                      <Spinner w="16px" h="16px" color={branco} />
                    </LoadingBox>
                  ) : (
                    'Confirmar'
                  )}
                </ModalButton>
              </ColumnContent>
            </RowContent>
          </Form>
        </Modal>
      </Container>
    </>
  );
}
