import styled from '@emotion/styled';

import { branco } from '../../styles/colors';
import px2vw from '../../utils/px2vw';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
`;

export const TextBox = styled.div`
  margin-left: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const EditProfileImage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
export const Title = styled.div`
  font-size: ${px2vw(24)};
  font-weight: bold;
  font-family: AvenirNextLTPro;
  font-weight: 700;
`;

export const AvatarButton = styled.button`
  font-size: 16px;
  border: none;
  background: transparent;

  :focus {
    background: transparent;
  }
`;

export const ContentPrimary = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 70px;
  width: 100%;
`;

export const ProfileImageBox = styled.div`
  position: relative;
  border-radius: 35px;
  max-width: 65px;
  max-height: 65px;
  width: 65px;
  height: 65px;
`;
export const ProfileImage = styled.img`
  border-radius: 35px;
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

/** *************************************** */

export const ContentDefault = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: ${branco};
  width: 100%;
  padding: 30px;
  border-radius: 20px;
  margin-bottom: 30px;

  ${(props) =>
    props.groupTop &&
    `
    border-radius: 0px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    margin-bottom: 2px;
  `}

  ${(props) =>
    props.groupBottom &&
    `
    border-radius: 0px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    margin-bottom: 30px;
  `}
`;

export const Field = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const LabelField = styled.div`
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 12px;
  font-family: AvenirNextLTPro;
  font-weight: 700;
`;

export const TextField = styled.div`
  font-size: 15px;
`;
