import { Select as ChakraSelect, SelectProps as ChakraSelectProps } from '@chakra-ui/react';
import React from 'react';

interface CustomSelectProps {
  register?: any;
  error?: any;
}

export type SelectProps = ChakraSelectProps & CustomSelectProps;

const Select: React.FC<SelectProps> = ({
  h = '37px',
  w = '100%',

  _focus = {
    borderColor: '#2BC48A',
    boxShadow: '0 0 0 1px #2BC48A',
  },
  error,
  register,
  children,
  ...rest
}) => {
  return (
    <ChakraSelect h={h} w={w} _focus={!error && _focus} ref={register} {...rest}>
      {children}
    </ChakraSelect>
  );
};

export default Select;
