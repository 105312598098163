import { IconButton, Spinner, Text, Tooltip, useDisclosure } from '@chakra-ui/react';
import isEqual from 'date-fns/isEqual';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { FiEdit } from 'react-icons/fi';
import { VscFiles, VscGoToFile } from 'react-icons/vsc';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { useAdvertiserSubscription } from '~/contexts/AdvertiserSubscriptionContext';
import { verdeKuppi } from '~/styles/colors';
import { formatCurrencyBRL } from '~/utils/currency';
import { getURL, URLType } from '~/utils/getUrl';

import { indexOthersContracts } from '../../stores/modules/financial/actions';
import { Col, Row } from '../../styles/grid';
import px2vw from '../../utils/px2vw';
import FinancialModal from '../FinancialModal';
import FinancialTableOthersStatus from '../FinancialTableOthersStatus';
import ResendContractModalConfirm from '../ResendContractModalConfirm';
import TablePerPage from '../TablePerPage';
import { Arrow, Arrow2, SvgBox } from './icons';
import {
  Content,
  Header,
  HeaderButton,
  Hr,
  NextIcon,
  PagButton,
  PrevIcon,
  TableRow,
} from './styles';
import { makePages, sort } from './tableOptions';

export default function FinancialTableOthers() {
  const loading = useSelector((state) => state.financial.loading);
  const othersContracts = useSelector((state) => state.financial.others_contracts);
  const [rows, setRows] = useState([]);
  const [rowsCurrentPage, setRowsCurrentPage] = useState();
  const [structure, setStructure] = useState(null);
  const [currentPage, setCurrentPage] = useState('1');
  const [lastPage, setLastPage] = useState();
  const [firstPage] = useState('1');
  const [sortedCol, setSortedCol] = useState('');
  const [itemId, setItemId] = useState();
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: resendContractIsOpen,
    onOpen: resendContractOnOpen,
    onClose: resendContractOnClose,
  } = useDisclosure();
  const [state, dispatchAdvertiserSubscription] = useAdvertiserSubscription();

  useEffect(() => {
    dispatch(indexOthersContracts());
  }, [dispatch]);

  useEffect(() => {
    if (structure) {
      setLastPage(Object.keys(structure)[Object.keys(structure).length - 1].toString());
    }
  }, [structure]);

  useMemo(() => {
    setStructure(makePages(rows.length, rowsCurrentPage));
  }, [rows, rowsCurrentPage]);

  useEffect(() => {
    setRows(othersContracts);
    const currentContract = state?.advertiserSubscription;
    if (currentContract?.id) {
      const updatedContract = othersContracts.find((c) => c.id === currentContract.id);
      if (!!updatedContract) {
        const updatedContractLastUpdated = new Date(updatedContract.updated_at);
        const currentContractLastUpdated = new Date(currentContract.updated_at);

        if (!isEqual(updatedContractLastUpdated, currentContractLastUpdated)) {
          dispatchAdvertiserSubscription({
            type: 'setAdvertiserSubscription',
            payload: updatedContract,
          });
        }
      }
    }
  }, [othersContracts, state.advertiserSubscription, dispatchAdvertiserSubscription]);

  useEffect(() => {
    if (rows.length < 5) {
      setRowsCurrentPage(rows.length);
    }
    if (rowsCurrentPage > 5) {
    } else {
      setRowsCurrentPage(5);
    }
  }, [rows, rowsCurrentPage]);

  const handleSort = useCallback(
    (col) => {
      if (sortedCol !== col) {
        setSortedCol(col);
        if (col === 'advertiserInfo.name') {
          setRows(sort(rows, col, 'asc', true));
        } else {
          setRows(sort(rows, col, 'asc'));
        }
      } else {
        setSortedCol('');
        if (col === 'advertiserInfo.name') {
          setRows(sort(rows, col, 'desc', true));
        } else {
          setRows(sort(rows, col, 'desc'));
        }
      }
    },
    [rows, sortedCol],
  );

  function handlePerPage(size) {
    setRowsCurrentPage(size);
  }

  function handleChangePage(direction) {
    if (direction === 'next') {
      const curr = +currentPage + 1;
      setCurrentPage(curr.toString());
    } else if (direction === 'prev') {
      const curr = +currentPage - 1;
      setCurrentPage(curr.toString());
    }
  }

  const handleSendContract = (advertiserInfoId) => {
    setItemId(advertiserInfoId);
    resendContractOnOpen();
  };

  const handleOnClickResendContract = (advertiserInfoId) => {
    onClose();
    handleSendContract(advertiserInfoId);
  };

  const handleOnClickItemDetails = (contract) => {
    dispatchAdvertiserSubscription({
      type: 'setAdvertiserSubscription',
      payload: contract,
    });
    onOpen();
  };

  const handleOnCopyLink = (contract) => {
    const copyArea = document.createElement('textarea');
    document.body.appendChild(copyArea);
    copyArea.value = `${getURL(URLType.ANUNCIANTE)}pagamento-assinatura?t=${
      contract.payment_token
    }`;
    copyArea.select();
    document.execCommand('copy');
    document.body.removeChild(copyArea);
    toast.info('Link de pagamento copiado!');
  };

  return (
    <>
      {loading && (
        <Col height="20vh" alignCenter justifyCenter>
          <Spinner w="50px" h="50px" color={verdeKuppi} />
        </Col>
      )}
      {!loading && (
        <Col padding="0 15px" alignCenter JustifyCenter>
          <Row>
            <Header width="30%" onClick={() => handleSort('advertiserInfo.name')}>
              <HeaderButton>
                Estabelecimento
                <SvgBox sorted={sortedCol === 'advertiserInfo.name'}>
                  <Arrow d="M6.63,11.61,12,7.29l5.37,4.48a1,1,0,0,0,1.27-1.54l-6-5a1,1,0,0,0-1.27,0l-6,4.83a1,1,0,1,0,1.26,1.55Z" />
                  <Arrow2 d="M12.64,12.23a1,1,0,0,0-1.27,0l-6,4.83a1,1,0,1,0,1.26,1.56L12,14.29l5.37,4.48a1,1,0,0,0,1.27-1.54Z" />
                </SvgBox>
              </HeaderButton>
            </Header>

            <Header width="25%" onClick={() => handleSort('status')}>
              <HeaderButton>
                Status do Contrato
                <SvgBox sorted={sortedCol === 'status'}>
                  <Arrow d="M6.63,11.61,12,7.29l5.37,4.48a1,1,0,0,0,1.27-1.54l-6-5a1,1,0,0,0-1.27,0l-6,4.83a1,1,0,1,0,1.26,1.55Z" />
                  <Arrow2 d="M12.64,12.23a1,1,0,0,0-1.27,0l-6,4.83a1,1,0,1,0,1.26,1.56L12,14.29l5.37,4.48a1,1,0,0,0,1.27-1.54Z" />
                </SvgBox>
              </HeaderButton>
            </Header>

            <Header width="15%">
              <HeaderButton>Plano</HeaderButton>
            </Header>

            <Header center width="15%" onClick={() => handleSort('amount')}>
              <HeaderButton center>
                Valor mensal{' '}
                <SvgBox sorted={sortedCol === 'amount'}>
                  <Arrow d="M6.63,11.61,12,7.29l5.37,4.48a1,1,0,0,0,1.27-1.54l-6-5a1,1,0,0,0-1.27,0l-6,4.83a1,1,0,1,0,1.26,1.55Z" />
                  <Arrow2 d="M12.64,12.23a1,1,0,0,0-1.27,0l-6,4.83a1,1,0,1,0,1.26,1.56L12,14.29l5.37,4.48a1,1,0,0,0,1.27-1.54Z" />
                </SvgBox>
              </HeaderButton>
            </Header>

            <Header width="15%" padding="25px 5px" />
          </Row>
          <Row>
            <Hr />
          </Row>
          {structure ? (
            rows.map((item, index) =>
              structure[currentPage].start <= index + 1 &&
              structure[currentPage].end >= index + 1 ? (
                <Fragment key={item.id}>
                  <TableRow alignCenter justifyCenter>
                    <Content width="30%" title={item.advertiserInfo.name}>
                      <Text
                        whiteSpace="nowrap"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        maxW="22ch"
                      >
                        {item.advertiserInfo.name}
                      </Text>
                    </Content>
                    <Content width="25%">
                      <FinancialTableOthersStatus isActive={item.is_active} status={item.status} />
                    </Content>
                    <Content width="15%">{item.subscription?.name}</Content>
                    <Content center width="15%">
                      {formatCurrencyBRL(item.amount)}
                    </Content>
                    <Content width="15%">
                      <Tooltip
                        colorScheme="black"
                        placement="top"
                        label="Reenviar contrato"
                        fontSize="md"
                      >
                        <IconButton
                          bg="transparent"
                          isDisabled={
                            item.status === 'waiting_payment' || item.status === 'refunded'
                          }
                          onClick={() => handleSendContract(item.id)}
                          icon={<VscGoToFile size={22} />}
                          _active={{
                            background: 'gray.200',
                          }}
                          _hover={{
                            background: 'gray.100',
                          }}
                        />
                      </Tooltip>
                      <Tooltip
                        colorScheme="black"
                        placement="top"
                        label="Copiar link de pagamento"
                        fontSize="md"
                      >
                        <IconButton
                          bg="transparent"
                          isDisabled={!item.payment_token}
                          onClick={() => handleOnCopyLink(item)}
                          icon={<VscFiles size={22} />}
                          _active={{
                            background: 'gray.200',
                          }}
                          _hover={{
                            background: 'gray.100',
                          }}
                        />
                      </Tooltip>
                      <Tooltip
                        colorScheme="black"
                        placement="top"
                        label="Ver detalhes"
                        fontSize="md"
                      >
                        <IconButton
                          bg="transparent"
                          isDisabled={item.status === 'refunded'}
                          onClick={() => handleOnClickItemDetails(item)}
                          icon={<FiEdit size={20} />}
                          _active={{
                            background: 'gray.200',
                          }}
                          _hover={{
                            background: 'gray.100',
                          }}
                        />
                      </Tooltip>
                    </Content>
                  </TableRow>
                  <Row>
                    <Hr />
                  </Row>
                </Fragment>
              ) : null,
            )
          ) : (
            <Row padding="15px 0 10px 0">Nenhum contrato no momento.</Row>
          )}
          {structure && (
            <Row alignCenter justifyEnd>
              <Row alignCenter justifyEnd height={px2vw(40)} margin="10px">
                <TablePerPage response={handlePerPage} length={rows.length} />
                <Row alignCenter justifyCenter width="10%">
                  {structure[currentPage].start} - {structure[currentPage].end} de {rows.length}
                </Row>
                <PagButton
                  onClick={() => handleChangePage('prev')}
                  disabled={currentPage === firstPage}
                  title="Página anterior"
                >
                  <PrevIcon size={px2vw(12)} disabled={currentPage === firstPage} />
                </PagButton>
                <PagButton
                  onClick={() => handleChangePage('next')}
                  title="Próxima página"
                  disabled={currentPage === lastPage}
                >
                  <NextIcon size={px2vw(12)} disabled={currentPage === lastPage} />
                </PagButton>
              </Row>
            </Row>
          )}
        </Col>
      )}

      <ResendContractModalConfirm
        itemId={itemId}
        isOpen={resendContractIsOpen}
        onClose={resendContractOnClose}
      />

      <FinancialModal
        isOpen={isOpen}
        onClose={onClose}
        onClickResendContract={handleOnClickResendContract}
      />
    </>
  );
}
