import { Box } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { MdClear } from 'react-icons/md';

import { Text } from '../../styles/common';
import { Col, Row } from '../../styles/grid';
import { CloseButton, Container, Modal, ModalButton } from './styles';

export default function ConfirmModal({ visible, onHandleVisible, onHandleSubmit }) {
  const [modal, setModal] = useState(visible);

  useEffect(() => {
    setModal(visible);
  }, [visible]);

  const handleSubmit = () => {
    onHandleSubmit();
    onHandleVisible();
  };

  return (
    <Container visible={modal}>
      <Modal width="360px" modelEmail>
        <CloseButton onClick={onHandleVisible}>
          <Box as={MdClear} w="35px" h="35px" />
        </CloseButton>
        <Col alignCenter justifyCenter>
          <br />
          <br />
          <Text textAlign="center" padding="0 20px" size="16px">
            Você tem certeza que deseja excluir o lead?
          </Text>
          <br />
          <Row>
            <Col padding="20px 10px 0 0" alignEnd justifyCenter>
              <ModalButton outlineTheme onClick={onHandleVisible}>
                Cancelar
              </ModalButton>
            </Col>
            <Col padding="20px 0 0 10px" alignStart justifyCenter>
              <ModalButton onClick={handleSubmit}>Ok</ModalButton>
            </Col>
          </Row>
        </Col>
      </Modal>
    </Container>
  );
}
