export const branco = '#FFF';
export const preto = '#000';
export const cinzaEscuro3 = '#4F4F4F';
export const cinzaEscuro2 = '#383539';
export const cinzaEscuro = '#696968';
export const cinzaMedio2 = '#9B999B';
export const cinzaMedio = '#DBDBDB';
export const cinzaClaro3 = '#F6F7FB';
export const cinzaClaro2 = '#EFEFEF';
export const cinzaClaro = '#EDEDED';
export const vermelho = '#E50148';
export const vermelhoClaro = '#FF6C6C';
export const verde3 = '#2ffe5f';
export const verde2 = '#4CAF50';
export const verde = '#009762';
export const azul2 = '#0071CE';
export const azul = '#007BC3';
export const roxoEscuro = '#501C67';
export const roxo = '#652581';
export const amarelo2 = '#F6A113';
export const amarelo = '#F9AE00';
export const amareloClaro = '#f2bc3d';
export const amareloClaro2 = '#FBD271';
export const verdeKuppi = '#2BC48A';

export const verdeKuppi1 = '#2FFE60';
export const verdeKuppi2 = '#2CFE86';
export const verdeKuppi3 = '#2CFE89';
export const verdeKuppi4 = '#90FDB7';
