import { Box } from '@chakra-ui/react';
import { Form } from '@rocketseat/unform';
import React, { useEffect, useState } from 'react';
import { MdClear } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { editBankAccount } from '../../../stores/modules/user/actions';
import { CloseButton, InputCustom, Label, SelectCustom } from '../../../styles/common';
import { Col, Row } from '../../../styles/grid';
import { accountTypes, banks } from '../../../utils/commonObjects';
import { cpfCnpjMask } from '../../../utils/masks';
import { onlyNumbers } from '../../../utils/onlyNumbers';
import { ColumnContent, Container, Modal, ModalButton, ModelTitle } from '../styles';

export default function ProfileModalThree({ visible, onChange, user }) {
  const [modal, setModal] = useState(visible);
  const [userState, setUserState] = useState(user);
  const dispatch = useDispatch();
  const [cpfCnpj, setcpfCnpj] = useState(cpfCnpjMask(userState?.userInfo?.cpf_cnpj));

  const [agency, setAgency] = useState(onlyNumbers(userState?.userAccountBank?.agency));

  const [account, setAccount] = useState(onlyNumbers(userState?.userAccountBank?.account));

  useEffect(() => {
    setUserState(user);
  }, [user]);

  useEffect(() => {
    setModal(visible);
  }, [visible]);

  const handleVisible = () => onChange();

  const handleSubmit = ({
    bank_id,
    agency,
    agency_digit,
    account,
    account_digit,
    account_type,
    account_holder_name,
  }) => {
    if (
      bank_id === '' ||
      agency === '' ||
      account === '' ||
      account_digit === '' ||
      account_type === '' ||
      account_holder_name === ''
    ) {
      toast.error('Preencha todos os campos obrigatórios', {
        autoClose: 6000,
      });
    } else if (account_holder_name.length > 30) {
      toast.error(
        'O nome do titular deve ter no máximo 30 caracteres. Abrevie o nome caso seja necessário',
        {
          autoClose: 6000,
        },
      );
    } else {
      dispatch(
        editBankAccount(
          bank_id,
          agency,
          agency_digit,
          account,
          account_digit,
          account_type,
          account_holder_name,
        ),
      );
      handleVisible();
    }
  };

  return (
    <Container visible={modal}>
      <Modal width="600px">
        <CloseButton onClick={handleVisible}>
          <Box as={MdClear} w="35px" h="35px" />
        </CloseButton>
        <ModelTitle>Alterar conta bancária</ModelTitle> <br />
        <Form
          initialData={{
            bank_id: userState?.userAccountBank?.bank_id,
            agency_digit: userState?.userAccountBank?.agency_digit,
            account_digit: userState?.userAccountBank?.account_digit,
            account_type: userState?.userAccountBank?.account_type,
            cpf_cnpj: cpfCnpjMask(userState?.userInfo?.cpf_cnpj),
            account_holder_name: userState?.userAccountBank?.account_holder_name,
          }}
          onSubmit={handleSubmit}
        >
          <Col padding="10px 0">
            <Label>Selecione seu banco</Label>
            <SelectCustom name="bank_id" required options={banks} />
          </Col>

          <Row padding="10px 0">
            <Row left>
              <Col left>
                <Label>Número da Agência</Label>
                <InputCustom
                  name="agency"
                  required
                  value={agency}
                  maxLength="4"
                  onChange={(e) => setAgency(onlyNumbers(e.target.value))}
                  type="text"
                />
              </Col>

              <Col width="30%">
                <Label>Dígito</Label>
                <InputCustom name="agency_digit" maxLength="1" type="text" />
              </Col>
            </Row>

            <Row right>
              <Col left>
                <Label>Número da Conta</Label>
                <InputCustom
                  name="account"
                  value={account}
                  maxLength="20"
                  onChange={(e) => setAccount(onlyNumbers(e.target.value))}
                  type="text"
                />
              </Col>

              <Col width="30%">
                <Label>Dígito</Label>
                <InputCustom name="account_digit" maxLength="1" type="text" required />
              </Col>
            </Row>
          </Row>

          <Row padding="10px 0">
            <Col left>
              <Label>Tipo de Conta</Label>
              <SelectCustom name="account_type" required options={accountTypes} />
            </Col>

            <Col right>
              <Row>
                <Col>
                  <Label>CPF/CNPJ do Titular da Conta</Label>
                  <InputCustom
                    name="cpf_cnpj"
                    type="text"
                    required
                    disabled
                    value={cpfCnpj}
                    onChange={(e) => setcpfCnpj(cpfCnpjMask(e.target.value))}
                    maxLength="18"
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row padding="10px 0">
            <Col>
              <Label>Nome Completo do Titular da Conta (30 caracteres no máximo)</Label>
              <InputCustom name="account_holder_name" type="text" maxLength="30" required />
            </Col>
          </Row>

          <Row padding="30px 0 0 0">
            <ColumnContent left button>
              <ModalButton outlineTheme onClick={handleVisible} type="button">
                Cancelar
              </ModalButton>
            </ColumnContent>

            <ColumnContent right button>
              <ModalButton type="submit">Confirmar</ModalButton>
            </ColumnContent>
          </Row>
        </Form>
      </Modal>
    </Container>
  );
}
