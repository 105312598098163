import styled from '@emotion/styled';
import { Input, Select } from '@rocketseat/unform';

import { branco, cinzaClaro2, cinzaEscuro, cinzaEscuro2, verdeKuppi } from '../../styles/colors';

export const Container = styled.div`
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  background: rgb(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
  height: 100%;
  z-index: 10;
`;

export const Label = styled.div`
  margin-top: 22px;
  font-weight: bold;
  margin-bottom: 5px;
  text-align: left;
  font-size: 15px;
  font-family: AvenirNextLTPro;
  font-weight: 700;
`;

export const Modal = styled.div`
  position: relative;
  padding: 30px;
  background: ${branco};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;

  /* width: 500px; */
  width: 750px;
  width: ${(props) => props.width};
  /* height: 630px; */

  form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 100%;
  }
`;

export const ModelTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: ${cinzaEscuro2};
  font-family: AvenirNextLTPro;
  font-weight: 700;
`;

export const Error = styled.div`
  margin-top: 5px;
  width: 100%;
  height: 21px;
  font-size: 14px;
  color: red;
  text-align: left;
`;

export const RowContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;

  ${(props) =>
    props.lastRow &&
    `
    margin-top: 50px;
  `};
`;

export const ColumnContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;

  ${(props) => props.width && `width: ${props.width}`};
  ${(props) => props.maxWidth && `max-width: ${props.maxWidth}`};

  ${(props) =>
    props.right &&
    `
    margin-left: 10px;
  `};

  ${(props) =>
    props.right &&
    props.button &&
    `
    align-items: flex-start;
  `};

  ${(props) =>
    props.center &&
    `
    margin-right: 10px;
    margin-left: 10px;
  `};

  ${(props) =>
    props.center &&
    props.button &&
    `
    align-items: center;
  `};

  ${(props) =>
    props.left &&
    `
    margin-right: 10px;
  `};

  ${(props) =>
    props.left &&
    props.button &&
    `
    align-items: flex-end;
  `};
`;

export const ModalButton = styled.button`
  border: 2px solid ${(props) => (props.outlineTheme ? cinzaEscuro2 : verdeKuppi)};
  font-size: 15px;
  padding: 10px;
  font-weight: bold;
  color: ${(props) => (props.outlineTheme ? cinzaEscuro2 : branco)};
  border-radius: 10px;
  width: 80%;
  font-family: AvenirNextLTPro;
  font-weight: 700;

  background: ${(props) => (props.outlineTheme ? 'transparent' : verdeKuppi)};

  :focus {
    background: ${(props) => (props.outlineTheme ? 'transparent' : verdeKuppi)};
  }

  :hover {
    color: ${(props) => (props.outlineTheme ? cinzaEscuro2 : cinzaClaro2)};
  }
`;

export const CloseButton = styled.button`
  border: none;
  font-size: 20px;
  background: transparent;
  position: absolute;
  top: 20px;
  right: 30px;

  :focus {
    background: transparent;
  }

  i {
    font-size: 34px;
  }
`;

export const InputCustom = styled(Input)`
  border: 1px solid ${(props) => (props.onError ? 'red' : cinzaEscuro)} !important;
  padding: 7px 10px !important;
  height: auto !important;
  margin: 0 !important;
  border-radius: 5px !important;

  box-sizing: inherit !important;
  font-size: 14px !important;
  width: 100%;

  ::placeholder {
    font-size: 12px;
  }

  :focus {
    border: 1px solid ${(props) => (props.onError ? 'red' : cinzaEscuro)} !important;
    box-shadow: none !important;
  }
`;

export const SelectCustom = styled(Select)`
  border: 1px solid ${(props) => (props.onError ? 'red' : cinzaEscuro)} !important;
  padding: 5px 10px !important;
  height: auto !important;
  margin: 0 !important;
  border-radius: 5px !important;
  display: inherit;
  background: ${branco} !important;

  box-sizing: inherit !important;
  font-size: 14px !important;

  ::placeholder {
    font-size: 12px;
  }

  :focus {
    border: 1px solid ${(props) => (props.onError ? 'red' : cinzaEscuro)} !important;
    box-shadow: none !important;
    border-radius: 5px !important;
  }
`;

export const LoadingBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Space = styled.div`
  width: 100%;
`;
