import { toast } from 'react-toastify';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { apiKuppido } from '../../../services/api';
import { structureBilling } from '../dashboard/actions';
import {
  endAction,
  getPayablesData as getPayablesDataAction,
  indexOthersContracts as indexOthersContractsAction,
  setActiveContracts,
  setOthersContracts,
  setPayablesData,
  setRecipientData,
  setSubscriptions as setSubscriptionsAction,
} from './actions';

const getState = (state) => state;

export function* getPayablesData({ payload }) {
  const state = yield select(getState);
  const { master } = state.auth;
  const { payables, dashboard } = payload;

  function* getPayable() {
    yield* payables;
  }

  const sequence = getPayable();

  let hasIterable = true;
  const results = {};

  while (hasIterable) {
    const value = yield sequence.next().value;

    if (value) {
      const currentId = value.id;

      // Exclude manuals payments
      if (
        value.processor_transaction_id &&
        !value.processor_transaction_id.includes('PAGAMENTO_')
      ) {
        let data = {
          transaction_id: value.processor_transaction_id,
          recipient_id: master
            ? value.advertiserInfo.advertiserSplit.kuppido_master_recipient_id
            : value.advertiserInfo.advertiserSplit.kuppido_recipient_id,
          count: 12,
        };
        let response = yield call(apiKuppido.post, '/pagar-me/payables', data);
        results[currentId] = [...response.data].reverse();
      } else {
        results[currentId] = [];
      }
    } else {
      hasIterable = false;
    }
  }
  yield put(setPayablesData(results));

  if (dashboard) {
    yield put(structureBilling(results));
  }
}

export function* indexActiveContracts({ payload }) {
  try {
    const state = yield select(getState);
    const userInfoId = state.user.userInfo.id;
    const { master } = state.auth;

    const { dashboard } = payload;

    const response = yield call(
      apiKuppido.get,
      `/${master ? 'master/' : ''}user-info/${userInfoId}/contracts/active`,
    );

    yield put(setActiveContracts(response.data.reverse()));
    if (response.data.length > 0) {
      // skip get payables
      //yield put(getPayablesDataAction(response.data, dashboard));
    } else {
      yield put(structureBilling({ payables: [] }));
    }
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(endAction());
      toast.error('Não foi possível atualizar seus contratos.');
    }
  }
}

export function* indexOthersContracts() {
  try {
    const state = yield select(getState);
    const userInfoId = state.user.userInfo.id;
    const { master } = state.auth;

    const response = yield call(
      apiKuppido.get,
      `/${master ? 'master/' : ''}user-info/${userInfoId}/contracts/others`,
    );
    yield put(setOthersContracts(response.data.reverse()));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(endAction());
      toast.error('Não foi possível atualizar seus contratos.');
    }
  }
}

export function* getRecipientData({ payload }) {
  const { recipient } = payload;

  const response = yield call(apiKuppido.post, `/pagar-me/recipients`, {
    recipient_id: recipient,
  });

  yield put(setRecipientData(response.data));
}

export function* fetchSubscriptions() {
  try {
    const response = yield call(apiKuppido.get, `/subscriptions`);

    yield put(setSubscriptionsAction(response.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(setSubscriptionsAction([]));
    }
  }
}

export function* saveContract({ payload }) {
  try {
    const contract = payload?.contract;
    const resendContract = contract?.resendContract;

    const response = yield call(apiKuppido.put, `/contract/${contract.id}`, contract);
    if (response.status === 200) {
      yield put(indexOthersContractsAction());

      if (resendContract) {
        yield call(apiKuppido.get, `/contract/${contract.id}/resend`);
        toast.success('O contrato foi alterado e reenviado com sucesso.');
        return;
      }

      toast.success('O contrato foi alterado com sucesso.');
    }
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      toast.error('Não foi possível salvar este contrato.');
      yield put(endAction());
    }
  }
}

export default all([
  takeLatest('@financial/GET_PAYABLE_DATA', getPayablesData),
  takeLatest('@financial/FETCH_SUBSCRIPTIONS', fetchSubscriptions),
  takeLatest('@financial/GET_RECIPIENT_DATA', getRecipientData),
  takeLatest('@financial/SAVE_CONTRACT', saveContract),
  takeLatest('@financial/INDEX_ACTIVE_CONTRACTS', indexActiveContracts),
  takeLatest('@financial/INDEX_OTHERS_CONTRACTS', indexOthersContracts),
]);
