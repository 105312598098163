import styled from '@emotion/styled';
import React from 'react';
import { Link } from 'react-router-dom';

import { branco, verdeKuppi } from '../../styles/colors';
import py2vw from '../../utils/px2vw';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
`;

export const TextBox = styled.div`
  margin-left: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const Title = styled.div`
  font-size: ${py2vw(24)};
  font-weight: bold;
  font-family: AvenirNextLTPro;
  font-weight: 700;
`;

export const SwitchTableButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: ${py2vw(16)};
  font-family: AvenirNextLTPro;
  font-weight: 700;

  .selected {
    margin-top: 5px;
    width: 100%;
    height: 4px;
    background: transparent;
    border-radius: 10px;
  }

  ${(props) =>
    props.selected &&
    `
    .selected {
      background: ${verdeKuppi};
    }
  `}
`;

/** *************************************** */

export const TextField = styled.div`
  font-size: 15px;
`;

export const AddButton = styled.button`
  font-family: AvenirNextLTPro;
  font-weight: 700;
  background: ${verdeKuppi} !important;
  color: ${branco} !important;
  border-radius: 10px;
  border: none;
  padding: 10px 30px;
  transition: all 0.2s ease-in-out;

  :active {
    filter: brightness(95%);
  }
`;

/** ****************** */

const StyledLink = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  text-decoration: none !important;
`;

export const CustonLink = ({ ...props }) => <StyledLink {...props} />;
