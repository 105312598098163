import { Box, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

import { statusConfig } from './status';

interface FinancialTableOthersStatusProps {
  status: 'not_paid' | 'inactive' | 'waiting_payment' | 'authorized' | 'paid' | 'refunded';
  isActive: boolean;
}

const FinancialTableOthersStatus: React.FC<FinancialTableOthersStatusProps> = ({
  status,
  isActive,
}) => {
  const [statusContract, setStatusContract] = useState(status);

  useEffect(() => {
    if (!isActive && status === 'paid') {
      setStatusContract('inactive');
    } else {
      setStatusContract(status);
    }
  }, [status, isActive]);

  return (
    <Box d="flex" justifyContent="flex-start" alignItems="center" w="100%">
      <Box bg={statusConfig[status].color} w="1rem" h="1rem" borderRadius="0.5rem" mr={2} />
      <Text>{statusConfig[statusContract].text}</Text>
    </Box>
  );
};

export default FinancialTableOthersStatus;
