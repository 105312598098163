import React from 'react';
import { Redirect, Switch } from 'react-router-dom';

import ClientManagement from '../pages/ClientManagement';
import ClientsDetail from '../pages/ClientsDetail';
import Dashboard from '../pages/Dashboard';
import FinancialManagement from '../pages/FinancialManagement';
import KpAcademy from '../pages/KpAcademy';
import LeadManagement from '../pages/LeadManagement';
import LeadsDetail from '../pages/LeadsDetail';
import Login from '../pages/Login';
import Logout from '../pages/Logout';
import ProfileAndAccount from '../pages/ProfileAndAccount';
import Route from './Route';
import {
  activeClientDetail,
  clientManagement,
  dashboard,
  financialManagement,
  inactiveClientDetail,
  kpAcademy,
  leadDetail,
  leadManagement,
  login,
  logout,
  profileAndAccount,
} from './routeMap';

export default function Routes() {
  return (
    <Switch>
      {/* <Route option="dashboard" path={dashboard} component={Dashboard} isPrivate /> */}

      <Route option="profile" path={profileAndAccount} component={ProfileAndAccount} isPrivate />

      <Route exact option="leeds" path={leadManagement} component={LeadManagement} isPrivate />

      <Route
        exact
        option="clients"
        path={clientManagement}
        component={ClientManagement}
        isPrivate
      />

      <Route
        option="finances"
        path={financialManagement}
        component={FinancialManagement}
        isPrivate
      />

      <Route option="academy" path={kpAcademy} component={KpAcademy} isPrivate />

      <Route option="leeds" path={leadDetail} component={LeadsDetail} isPrivate />

      <Route option="clients" path={activeClientDetail} component={ClientsDetail} isPrivate />

      <Route option="clients" path={inactiveClientDetail} component={ClientsDetail} isPrivate />

      <Route path={login} component={Login} noLayout />

      <Route path={logout} component={Logout} noLayout />

      <Route path="/" component={() => <Redirect to={login} />} />
    </Switch>
  );
}
