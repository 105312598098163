import styled from '@emotion/styled';

import px2vw from '../../utils/px2vw';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
`;

export const Title = styled.div`
  font-size: ${px2vw(24)};
  font-weight: bold;
  font-family: AvenirNextLTPro;
  font-weight: 700;
`;

export const ImageBox = styled.div`
  position: relative;
  border-radius: ${px2vw(40)};
  max-width: ${px2vw(60)};
  max-height: ${px2vw(60)};
  width: ${px2vw(60)};
  height: ${px2vw(60)};
`;
export const Image = styled.img`
  position: absolute;
  border-radius: ${px2vw(40)};
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const BoxMetrics = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 20px;
  width: ${px2vw(150)};
  height: ${px2vw(90)};

  ${(props) => props.margin && `margin: ${props.margin};`}
  ${(props) => props.amarelo && `border: 1px solid #f9ae00;`}
  ${(props) => props.verde && `border: 1px solid #53bc9b;`}
`;

export const StyledLabels = styled.div`
  .bar tspan {
    fill: #fff;
  }

  .line tspan {
    fill: #53bc9b;
  }

  .bar path[j='6'] {
    fill: #f9ae00;
  }

  .line .apexcharts-menu-icon svg {
    fill: #38353980;
  }

  .bar .apexcharts-menu-icon svg {
    fill: #fff;
  }

  .bar .apexcharts-menu-icon:hover svg {
    fill: #ffffff80;
  }

  .line .apexcharts-legend span {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
