import styled from '@emotion/styled';

const Default = styled('div')<any>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;

  /* ****** OVERFLOW ****** */

  ${(props) => props.overflowY && `overflow-x: hidden; overflow-y: scroll;`}
  ${(props) => props.overflowX && `overflow-y: hidden; overflow-x: scroll;`}
  ${(props) => props.overflow && `overflow: scroll;`}

  /* ****** BORDER RADIUS ****** */

  ${(props) => props.borderRadius && `border-radius: ${props.borderRadius};`}

  /* ****** BACKGROUND ****** */

  ${(props) => props.background && `background: ${props.background};`}

    /* ****** BOLD ****** */

    ${(props) => props.bold && `font-family: AvenirNextLTPro; font-weight: 700; `}

  /* ****** BOLD ON HOVER ****** */

  ${(props) => props.boldOnHover && `:hover { font-family: AvenirNextLTPro; font-weight: 700; }`}

  /* ****** UNDERLINE ****** */

  ${(props) => props.underline && `text-decoration: underline;`}

  /* ****** OVERLINE ****** */

  ${(props) => props.overline && `text-decoration: overline;`}

  /* ****** TRASITION ****** */

  ${(props) => props.transition && `transition: all 0.2s ease-in-out;`}

  /* ****** NOWWRAP ****** */

  ${(props) => props.noWrap && `flex-wrap: nowrap;`}

  /* ****** RIGHT ****** */

  ${(props) => props.alignStart && `align-items: flex-start;`}
  ${(props) => props.justifyStart && `justify-content: flex-start;`}

  /* ****** CENTER ****** */

  ${(props) => props.alignCenter && `align-items: center;`}
  ${(props) => props.justifyCenter && `justify-content: center;`}

  /* ****** LEFT ****** */

  ${(props) => props.alignEnd && `align-items: flex-end;`}
  ${(props) => props.justifyEnd && `justify-content: flex-end;`}

  /* ****** Width / Height ****** */
  width: ${(props) => props.width};
  height: ${(props) => props.height};

  /* ****** MAX Width / MAX Height ****** */
  max-width: ${(props) => props.maxWidth};
  max-height: ${(props) => props.maxHeight};

  /* ****** MIN Width / MIN Height ****** */
  min-width: ${(props) => props.minWidth};
  min-height: ${(props) => props.minHeight};

  /* ****** Padding ****** */
  padding: ${(props) => props.padding};

  /* ****** Margin ****** */
  margin: ${(props) => props.margin};

  /* ****** Flex ****** */
  ${(props) => props.flexOn && `flex: 1;`}

  /* ****** POSITION ****** */
  ${(props) => props.relative && `position: relative;`}
  ${(props) => props.absolute && `position: absolute;`}
  ${(props) => props.fixed && `position: fixed;`}

    /* ****** SPACE ****** */
  ${(props) => props.spaceAround && `justify-content: space-around;`}
  ${(props) => props.spaceBetween && `justify-content: space-between;`}
  ${(props) => props.spaceEvenly && `justify-content: space-evenly;`}

  /* ****** CURSOR ****** */
  ${(props) => props.pointer && `cursor: pointer;`}

  /* ****** RIGHT MARGIN ****** */
  ${(props) => props.right && `margin-left: 10px;`}

  /* ****** CENTER MARGIN****** */
  ${(props) => props.center && `margin-right: 10px; margin-left: 10px;`}

  /* ****** LEFT MARGIN****** */
  ${(props) => props.left && `margin-right: 10px;`}

  .ml-auto {
    margin-left: auto;
  }
  .mr-auto {
    margin-right: auto;
  }
  .mt-auto {
    margin-top: auto;
  }
  .mb-auto {
    margin-bottom: auto;
  }
`;

export const Row = styled(Default)`
  flex-direction: row;
`;

export const Col = styled(Default)`
  flex-direction: column;
`;
