import styled from '@emotion/styled';

import { cinzaEscuro2, cinzaMedio, verdeKuppi } from '../../styles/colors';

export const SvgBox = styled.svg`
  ${(props) => props.sorted && `transform: rotate(180deg);`};
  transition: all 0.2s ease-in-out;
  margin-left: 5px;
  width: 18px;
  height: 18px;

  path {
    fill: ${verdeKuppi};
    fill: ${(props) => props.color && props.color};
  }
`;

export const SvgBox2 = styled.svg`
  width: 24px;
  height: 24px;
  path {
    fill: ${cinzaEscuro2};
    fill: ${(props) => props.disabled && `${cinzaMedio}`};
  }
`;

export const Arrow = styled.path`
  transform: scale(0.8);
`;

export const Arrow2 = styled.path`
  transform: scale(0.8);
`;

export const SendContract = styled.path``;

export const SendContract2 = styled.path``;
