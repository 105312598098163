import { IconButton, Spinner, Text, Tooltip } from '@chakra-ui/react';
import { push } from 'connected-react-router';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { FiEdit } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';

import { Clients } from '~/models/Clients';
import { activeClientDetail } from '~/routes/routeMap';
import { RootState } from '~/stores';
import { verdeKuppi } from '~/styles/colors';
import { formatCurrencyBRL } from '~/utils/currency';

import { indexActiveClients } from '../../stores/modules/clients/actions';
import { Col, Row } from '../../styles/grid';
import px2vw from '../../utils/px2vw';
import ClientContractWarning from '../ClientContractWarning';
import ClientOpenedTheStore from '../ClientOpenedTheStore';
import TablePerPage from '../TablePerPage';
import { Arrow, Arrow2, SvgBox } from './icons';
import {
  Content,
  Header,
  HeaderButton,
  Hr,
  Image,
  ImageBox,
  NextIcon,
  PagButton,
  PrevIcon,
  TableRow,
} from './styles';
import { makePages, sort } from './tableOptions';

export default function ClientManagementActives() {
  const globalState = useSelector((state: RootState) => state);
  const { active_clients: activeClients } = globalState?.clients;
  const { loading } = globalState?.clients;

  const [rows, setRows] = useState<Clients[]>([]);
  const [rowsCurrentPage, setRowsCurrentPage] = useState<any>();
  const [structure, setStructure] = useState(null);
  const [currentPage, setCurrentPage] = useState('1');
  const [lastPage, setLastPage] = useState<any>();
  const [firstPage] = useState('1');
  const [sortedCol, setSortedCol] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(indexActiveClients());
  }, [dispatch]);

  useEffect(() => {
    if (structure) {
      setLastPage(Object.keys(structure)[Object.keys(structure).length - 1].toString());
    }
  }, [structure]);

  useMemo(() => {
    setStructure(makePages(rows.length, rowsCurrentPage));
  }, [rows, rowsCurrentPage]);

  useEffect(() => {
    setRows(activeClients);
  }, [activeClients]);

  useEffect(() => {
    if (rows.length < 5) {
      setRowsCurrentPage(rows.length);
    }
    if (rowsCurrentPage > 5) {
    } else {
      setRowsCurrentPage(5);
    }
  }, [rows, rowsCurrentPage]);

  const handleSort = useCallback(
    (col) => {
      if (sortedCol !== col) {
        setSortedCol(col);
        setRows(sort(rows, col, 'asc'));
      } else {
        setSortedCol('');
        setRows(sort(rows, col, 'desc'));
      }
    },
    [rows, sortedCol],
  );

  function handlePerPage(size: any) {
    setRowsCurrentPage(size);
  }

  function handleChangePage(direction: 'next' | 'prev') {
    if (direction === 'next') {
      const curr = +currentPage + 1;
      setCurrentPage(curr.toString());
    } else if (direction === 'prev') {
      const curr = +currentPage - 1;
      setCurrentPage(curr.toString());
    }
  }

  const pageContent = (
    <Col padding="0 15px" alignCenter JustifyCenter>
      <Row>
        <Header width="100%" onClick={() => handleSort('name')}>
          <HeaderButton row={true} height="42px">
            <Text>Estabelecimento</Text>
            <SvgBox sorted={sortedCol === 'name'}>
              <Arrow d="M6.63,11.61,12,7.29l5.37,4.48a1,1,0,0,0,1.27-1.54l-6-5a1,1,0,0,0-1.27,0l-6,4.83a1,1,0,1,0,1.26,1.55Z" />
              <Arrow2 d="M12.64,12.23a1,1,0,0,0-1.27,0l-6,4.83a1,1,0,1,0,1.26,1.56L12,14.29l5.37,4.48a1,1,0,0,0,1.27-1.54Z" />
            </SvgBox>
          </HeaderButton>
        </Header>

        <Header width="55%" onClick={() => handleSort('total_orders')}>
          <HeaderButton row={true} justifyContent="center">
            <Text>
              Total de <br /> pedidos
            </Text>
            <SvgBox sorted={sortedCol === 'total_orders'}>
              <Arrow d="M6.63,11.61,12,7.29l5.37,4.48a1,1,0,0,0,1.27-1.54l-6-5a1,1,0,0,0-1.27,0l-6,4.83a1,1,0,1,0,1.26,1.55Z" />
              <Arrow2 d="M12.64,12.23a1,1,0,0,0-1.27,0l-6,4.83a1,1,0,1,0,1.26,1.56L12,14.29l5.37,4.48a1,1,0,0,0,1.27-1.54Z" />
            </SvgBox>
          </HeaderButton>
        </Header>

        <Header width="55%" onClick={() => handleSort('newClients')}>
          <HeaderButton row={true} justifyContent="center">
            <Text>
              Clientes <br /> Novos
            </Text>
            <SvgBox sorted={sortedCol === 'newClients'}>
              <Arrow d="M6.63,11.61,12,7.29l5.37,4.48a1,1,0,0,0,1.27-1.54l-6-5a1,1,0,0,0-1.27,0l-6,4.83a1,1,0,1,0,1.26,1.55Z" />
              <Arrow2 d="M12.64,12.23a1,1,0,0,0-1.27,0l-6,4.83a1,1,0,1,0,1.26,1.56L12,14.29l5.37,4.48a1,1,0,0,0,1.27-1.54Z" />
            </SvgBox>
          </HeaderButton>
        </Header>

        <Header width="55%" onClick={() => handleSort('recurringClients')}>
          <HeaderButton row={true} justifyContent="center">
            <Text>
              Clientes <br /> Recorrentes
            </Text>
            <SvgBox sorted={sortedCol === 'recurringClients'}>
              <Arrow d="M6.63,11.61,12,7.29l5.37,4.48a1,1,0,0,0,1.27-1.54l-6-5a1,1,0,0,0-1.27,0l-6,4.83a1,1,0,1,0,1.26,1.55Z" />
              <Arrow2 d="M12.64,12.23a1,1,0,0,0-1.27,0l-6,4.83a1,1,0,1,0,1.26,1.56L12,14.29l5.37,4.48a1,1,0,0,0,1.27-1.54Z" />
            </SvgBox>
          </HeaderButton>
        </Header>

        <Header width="55%" onClick={() => handleSort('billing')}>
          <HeaderButton row={true} justifyContent="center">
            <Text>
              Faturamento <br /> Total
            </Text>
            <SvgBox sorted={sortedCol === 'billing'}>
              <Arrow d="M6.63,11.61,12,7.29l5.37,4.48a1,1,0,0,0,1.27-1.54l-6-5a1,1,0,0,0-1.27,0l-6,4.83a1,1,0,1,0,1.26,1.55Z" />
              <Arrow2 d="M12.64,12.23a1,1,0,0,0-1.27,0l-6,4.83a1,1,0,1,0,1.26,1.56L12,14.29l5.37,4.48a1,1,0,0,0,1.27-1.54Z" />
            </SvgBox>
          </HeaderButton>
        </Header>

        <Header width="55%" onClick={() => handleSort('next_transfer')}>
          <HeaderButton row={true} justifyContent="center">
            <Text>
              Inaugurou <br /> a loja?
            </Text>
            <SvgBox sorted={sortedCol === 'next_transfer'}>
              <Arrow d="M6.63,11.61,12,7.29l5.37,4.48a1,1,0,0,0,1.27-1.54l-6-5a1,1,0,0,0-1.27,0l-6,4.83a1,1,0,1,0,1.26,1.55Z" />
              <Arrow2 d="M12.64,12.23a1,1,0,0,0-1.27,0l-6,4.83a1,1,0,1,0,1.26,1.56L12,14.29l5.37,4.48a1,1,0,0,0,1.27-1.54Z" />
            </SvgBox>
          </HeaderButton>
        </Header>
        <Header width="20%" padding="25px 5px" />
        <Header width="10%" padding="25px 5px" />
      </Row>
      <Row>
        <Hr />
      </Row>
      {structure ? (
        rows.map((item, index) =>
          structure[currentPage].start <= index + 1 && structure[currentPage].end >= index + 1 ? (
            <Fragment key={item.id}>
              <TableRow alignCenter justifyCenter>
                <Content width="75%" title={item?.name}>
                  <ImageBox>
                    <Image
                      src={
                        item?.logo_url
                          ? `${item?.logo_url}?nocache=${new Date().getTime()}`
                          : '/assets/images/profile/profile@3x.png'
                      }
                    />
                  </ImageBox>
                  <Text whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" maxW="18ch">
                    {item?.name}
                  </Text>
                </Content>
                <Content title={item?.total_orders_count} justifyContent="center" width="40%">
                  {item?.total_orders_count}
                </Content>
                <Content title={item?.new_clients_count} justifyContent="center" width="40%">
                  {item?.new_clients_count}
                </Content>
                <Content title={item?.recurrent_clients_count} justifyContent="center" width="40%">
                  {item?.recurrent_clients_count}
                </Content>
                <Content
                  title={formatCurrencyBRL(item?.revenue ? item?.revenue : 0)}
                  justifyContent="center"
                  width="40%"
                >
                  {formatCurrencyBRL(item?.revenue ? item?.revenue : 0)}
                </Content>
                <Content justifyContent="center" width="40%">
                  <ClientOpenedTheStore client={item} />
                </Content>
                <Content width="20%">
                  <ClientContractWarning client={item} />
                </Content>
                <Content width="10%">
                  <Tooltip colorScheme="black" placement="top" label="Ver detalhes" fontSize="md">
                    <IconButton
                      aria-label="Ver detalhes"
                      bg="transparent"
                      onClick={() =>
                        dispatch(push(activeClientDetail, { detail: item, type: 'active' }))
                      }
                      icon={<FiEdit size={20} />}
                      _active={{
                        background: 'gray.200',
                      }}
                      _hover={{
                        background: 'gray.100',
                      }}
                    />
                  </Tooltip>
                </Content>
              </TableRow>
              <Row>
                <Hr />
              </Row>
            </Fragment>
          ) : null,
        )
      ) : (
        <Row padding="15px 0 10px 0">Nenhum cliente no momento.</Row>
      )}
      {structure && (
        <Row alignCenter justifyEnd>
          <Row alignCenter justifyEnd height={px2vw(40)} margin="10px">
            <TablePerPage response={handlePerPage} length={rows.length} />
            <Row alignCenter justifyCenter width="10%">
              {structure[currentPage].start} - {structure[currentPage].end} de {rows.length}
            </Row>
            <PagButton
              onClick={() => handleChangePage('prev')}
              disabled={currentPage === firstPage}
              title="Página anterior"
            >
              <PrevIcon size={px2vw(12)} disabled={currentPage === firstPage} />
            </PagButton>
            <PagButton
              onClick={() => handleChangePage('next')}
              title="Próxima página"
              disabled={currentPage === lastPage}
            >
              <NextIcon size={px2vw(12)} disabled={currentPage === lastPage} />
            </PagButton>
          </Row>
        </Row>
      )}
    </Col>
  );

  const loader = (
    <Col height="20vh" alignCenter justifyCenter>
      <Spinner w="50px" h="50px" color={verdeKuppi} />
    </Col>
  );

  return loading ? loader : pageContent;
}
