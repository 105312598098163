import * as yup from 'yup';

import cpfCnpjValidator from '~/utils/CpfCnpjValidator';

export default yup.object().shape({
  advertiser_name: yup.string().required('Por favor informe o nome do estabelecimento.'),
  cnpj: yup
    .string()
    .required('Por favor informe o CPF/CNPJ do estabelecimento.')
    .test('validade_cpf_cnpj', 'O CPF/CNPJ do estabelecimento é inválido', cpfCnpjValidator),
  email: yup
    .string()
    .email('O email informado é inválido.')
    .required('Por favor informe o email do estabelecimento.'),
  representative_name: yup
    .string()
    .required('Por favor informe o nome do representante do estabelecimento.'),
  phone_whatsapp_representative: yup
    .string()
    .required('Por favor informe o whatsapp do representante do estabelecimento.'),
  phone_1: yup.string().required('Por favor informe a telefone do anunciante.'),
  phone_2: yup.string(),
  phone_whatsapp: yup.string(),
  advertiser_category_id: yup.string().required('Por favor informe o segmento do anunciante.'),
});
