import produce from 'immer';

import { Clients } from '~/models/Clients';

export interface ClientsReducerType {
  active_clients: Clients[];
  inactive_clients: Clients[];
  active_client_detail?: Clients;
  inactive_client_detail?: Clients;
  loading: boolean;
}

const INITIAL_STATE: ClientsReducerType = {
  active_clients: [],
  inactive_clients: [],
  active_client_detail: null,
  inactive_client_detail: null,
  loading: false,
};

export default function clients(state = INITIAL_STATE, action: { payload: any; type: string }) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@clients/INDEX_ACTIVE_CLIENTS':
        draft.loading = true;
        break;

      case '@clients/INDEX_INACTIVE_CLIENTS':
        draft.loading = true;
        break;

      case '@clients/SET_ACTIVE_CLIENTS':
        draft.loading = false;
        draft.active_clients = action.payload.clients;
        break;

      case '@clients/SET_INACTIVE_CLIENTS':
        draft.loading = false;
        draft.inactive_clients = action.payload.clients;
        break;

      case '@clients/SET_ACTIVE_CLIENT_DETAIL':
        draft.loading = false;
        draft.active_client_detail = action.payload.clients;
        break;

      case '@clients/SET_INACTIVE_CLIENT_DETAIL':
        draft.loading = false;
        draft.inactive_client_detail = action.payload.clients;
        break;

      case '@clients/END_ACTION':
        draft.loading = false;
        break;
      case '@auth/LOG_OUT':
        return INITIAL_STATE;
      default:
    }
  });
}
