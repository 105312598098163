import { Box, SwitchProps as ChakraSwitchProps, Text } from '@chakra-ui/react';
import React from 'react';

import { CustomSwitch } from './styles';

export interface SwitchProps extends ChakraSwitchProps {
  text?: string;
}

const Switch: React.FC<SwitchProps> = ({ text, h = '28px', ...rest }) => {
  return (
    <Box d="flex" alignItems="center" justifyContent="flex-start">
      <CustomSwitch h={h} {...rest} />
      {text && (
        <Text fontSize="1rem" fontWeight="bold" ml="10px">
          {text}
        </Text>
      )}
    </Box>
  );
};

export default Switch;
