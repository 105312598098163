import { toast } from 'react-toastify';

import { apiGlobal } from '~/services/api';
import { onlyNumbers } from '~/utils/onlyNumbers';

export interface ResponseQueryZipcode {
  zipcode?: string;
  street?: string;
  neighborhood?: string;
  city?: string;
  state?: string;
}

export default async function queryZipcode(zipcode: string): Promise<ResponseQueryZipcode> {
  try {
    const formatedZipCode = onlyNumbers(zipcode);

    const response = await apiGlobal.post('/query-zipcode', {
      zipcode: formatedZipCode,
    });

    const address = response?.data;
    return address;
  } catch (err) {
    toast.error('Endereço não encontrado, verifique o CEP.');
  }
}
