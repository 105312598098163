import { Box } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { MdClear } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';

import { cleanLeadSituation, endAction } from '../../../stores/modules/lead/actions';
import { Text, Text2 } from '../../../styles/common';
import { Col, Row } from '../../../styles/grid';
import TableSituation from '../../TableSituation';
import { CloseButton, Container, Modal, ModalButton } from './styles';

export default function ProfileModalOne({ visible, onChange, situationProps }) {
  const [modal, setModal] = useState(visible);
  const [situation, setSituation] = useState(useSelector((state) => state.lead.leads_situation));
  const dispatch = useDispatch();

  useEffect(() => {
    setModal(visible);
  }, [visible]);

  useEffect(() => {
    if (!situation) {
      setSituation(situationProps);
    }
  }, [situationProps, situation]);

  const handleVisible = () => {
    onChange();
    dispatch(endAction());
    dispatch(cleanLeadSituation());
  };

  return (
    <Container visible={modal}>
      <Modal modelEmail>
        <CloseButton onClick={handleVisible}>
          <Box as={MdClear} w="35px" h="35px" />
        </CloseButton>
        <Row padding="20px" />

        <Col alignCenter justifyCenter>
          <Text regular size="18px">
            {situation === 'perdido' && 'Este estabelecimento foi'}
            {situation === 'disponivel' && 'Este estabelecimento está'}
            {situation === 'foi_cliente' && 'Este estabelecimento'}
            {situation === 'rejeitou_outro_kuppido' && 'Este estabelecimento'}
            {situation === 'outro_kuppido_em_contato' && 'Este estabelecimento já tem'}
            {situation === 'ja_e_cliente' && 'Este estabelecimento'}
          </Text>
          <Row padding="20px" alignCenter justifyCenter>
            {situation && <TableSituation big situation={situation} width="auto" />}
          </Row>
          <Text2 textAlign="center" regular size="18px">
            {situation === 'disponivel' &&
              'E será incluído ao seu funil de vendas para prospecção.'}
            {situation === 'foi_cliente' &&
              'E será incluído ao seu funil de vendas para prospecção.'}
            {situation === 'rejeitou_outro_kuppido' &&
              'Mas será incluído ao seu funil de vendas para prospecção.'}
            {situation === 'perdido' && (
              <>
                <span className="bold">Removemos</span> do seu funil de vendas, mas estará na sua
                lista de leads em caso de consultas posteriores.
              </>
            )}
            {situation === 'outro_kuppido_em_contato' && (
              <>
                <span className="bold">Não Incluiremos</span> no seu funil de vendas, mas estará na
                sua lista de leads. Em caso de negativa na negociação você será informado.
              </>
            )}
            {situation === 'ja_e_cliente' && (
              <>
                <span className="bold">Não Incluiremos</span> no seu funil de vendas mas estará na
                sua lista de leads.
              </>
            )}
          </Text2>
        </Col>
        <Row margin="40px 0 0 0" alignCenter justifyCenter>
          <Col alignCenter justifyCenter>
            <ModalButton onClick={handleVisible} width="35%">
              Ok
            </ModalButton>
          </Col>
        </Row>
      </Modal>
    </Container>
  );
}
