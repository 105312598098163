export enum URLType {
  GLOBAL_API = 'global_api',
  KUPPIDO_API = 'kuppido_api',
  ANUNCIANTE = 'anunciante',
  KUPPIDO = 'kuppido',
}

type Environments = 'production' | 'staging' | 'development' | 'qa' | 'local';

export function getURL(urlType: URLType) {
  const reactAppEnv = process.env.REACT_APP_ENV_ALL_API as Environments;
  const reactAppEnvGlobal = process.env.REACT_APP_ENV_GLOBAL_API as Environments;
  const reactAppEnvKuppido = process.env.REACT_APP_ENV_KUPPIDO_API as Environments;
  const reactAppEnvAnunciante =
    (process.env.REACT_APP_ENV_GLOBAL_API as Environments) ||
    (process.env.REACT_APP_ENV_KUPPIDO_API as Environments);

  const baseAPIsUrlsKuppido = {
    production: 'https://api.kuppi.com.br/kuppido',
    staging: 'https://stg-api.kuppi.com.br/kuppido',
    development: 'https://dev-api.kuppi.com.br/kuppido',
    qa: 'https://qa-api.kuppi.com.br/kuppido',
    local: 'http://0.0.0.0:3333/kuppido',
  };

  const baseAPIsUrlsGlobal = {
    production: 'https://api.kuppi.com.br/global',
    staging: 'https://stg-api.kuppi.com.br/global',
    development: 'https://dev-api.kuppi.com.br/global',
    qa: 'https://qa-api.kuppi.com.br/global',
    local: 'http://0.0.0.0:3334/global',
  };

  const baseUrlsKuppido = {
    production: 'https://kuppido.kuppi.com.br/',
    staging: 'https://stg-kuppido.kuppi.com.br/',
    development: 'https://dev-kuppido.kuppi.com.br/',
    qa: 'https://qa-kuppido.kuppi.com.br/',
    local: 'http://0.0.0.0:3335/',
  };

  const baseUrlsAnunciante = {
    production: 'https://portal.kuppi.com.br/',
    staging: 'https://stg-portal.kuppi.com.br/',
    development: 'https://dev-portal.kuppi.com.br/',
    qa: 'https://qa-portal.kuppi.com.br/',
    local: 'http://0.0.0.0:3336/',
  };

  switch (urlType) {
    case URLType.GLOBAL_API:
      return baseAPIsUrlsGlobal[reactAppEnv || reactAppEnvGlobal];
    case URLType.KUPPIDO_API:
      return baseAPIsUrlsKuppido[reactAppEnv || reactAppEnvKuppido];
    case URLType.KUPPIDO:
      return baseUrlsKuppido[reactAppEnv || reactAppEnvKuppido];
    case URLType.ANUNCIANTE:
      return baseUrlsAnunciante[reactAppEnv || reactAppEnvAnunciante];
    default:
      return 'invalid url type';
  }
}
