import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

import { cinzaEscuro2, verdeKuppi } from '../../../styles/colors';
import px2vw from '../../../utils/px2vw';

export const Hr = styled.hr`
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #00000010;
  width: 100%;
`;

export const Container = styled.div`
  height: 100%;
  width: ${px2vw(265)};
  padding-top: ${px2vw(50)};
  padding-left: ${px2vw(60)};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;

export const ContentLogo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: ${px2vw(20)};
  width: 100%;
`;

export const LogoImageBox = styled.div`
  position: relative;
  width: 100%;
  height: 100vw;
  max-width: ${px2vw(180)};
  max-height: ${px2vw(57)};
  margin-left: ${px2vw(-20)};

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;

export const LogoImage = styled.img`
  position: absolute;
  width: 90%;
  height: 90%;
  object-fit: cover;
`;

export const OptionBox = styled(Link)`
  width: 100%;
  height: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: ${px2vw(4)} 0;
  text-decoration: none !important;
`;

export const OptionBoxLink = styled.a`
  width: 100%;
  height: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: ${px2vw(4)} 0;
  text-decoration: none !important;

  border: none;
`;

export const LeaveButton = styled.button`
  width: 100%;
  height: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
`;

export const Text = styled.div`
  margin-left: ${px2vw(15)};
  color: ${(props) => (props.optionActive ? verdeKuppi : cinzaEscuro2)};
  font-size: 15px;
`;

export const Title = styled.div`
  margin-bottom: ${px2vw(50)};
`;

export const SelectBar = styled.div`
  background: ${(props) => (props.optionActive ? verdeKuppi : 'transparent')};
  width: 5px;
  height: 100%;
  border-radius: 5px;
  margin-left: auto;
`;
