import React from 'react';

import { Row } from '../../styles/grid';
import { situationConfig } from './situations';
import { Ball, Text } from './styles';

export default function FinancialTableStatus({ big, situation, noColorText, ...rest }) {
  return (
    <Row alignCenter justifyStart {...rest}>
      <Ball situation={situation} big={big} />
      <Text situation={noColorText ? false : situation} big={big}>
        {situationConfig[situation].text}
      </Text>
    </Row>
  );
}
