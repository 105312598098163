import React, { useState } from 'react';

import ClientManagementActives from '../../components/ClientManagementActives';
import ClientManagementInactives from '../../components/ClientManagementInactives';
// import NiceSelect from '../../components/NiceSelect';
import { Row } from '../../styles/grid';
import {
  Container,
  ContentDefault,
  Field,
  SwitchTableButton,
  Title,
  // SearchInput,
  // SearchBox,
} from './styles';

// import { SvgBox, Search } from './icons';

export default function ClientManagement() {
  const [table, setTable] = useState('actives');
  const toggleTable = (newTable) => setTable(newTable);

  return (
    <Container>
      <Row alignStart justifyStart>
        <Title>Gestão de Clientes</Title>

        {/* <SearchBox className="ml-auto">
          <SvgBox>
            <Search />
          </SvgBox>
          <SearchInput
            name="search"
            placeholder="Busque por um cliente"
            // onFocus={() => toggleTable('actives')}
          />
        </SearchBox> */}
      </Row>
      <Row padding="20px" />
      <ContentDefault flexStart groupTop>
        <Field>
          <SwitchTableButton onClick={() => toggleTable('actives')} selected={table === 'actives'}>
            Clientes Ativos
            <div className="selected" />
          </SwitchTableButton>
        </Field>
        <Field>
          <SwitchTableButton
            onClick={() => toggleTable('inactives')}
            selected={table === 'inactives'}
          >
            Clientes Inativos
            <div className="selected" />
          </SwitchTableButton>
        </Field>
        {/* <Field mlAuto>
          <NiceSelect />
        </Field> */}
      </ContentDefault>
      <ContentDefault groupBottom>
        {table === 'actives' && <ClientManagementActives />}
        {table === 'inactives' && <ClientManagementInactives />}
      </ContentDefault>
    </Container>
  );
}
