export function setIsKuppiCommercialTeam(status) {
  return {
    type: '@auth/SET_IS_KUPPI_COMMERCIAL_TEAM',
    payload: { status },
  };
}

export function setIsKuppido10(status) {
  return {
    type: '@auth/SET_IS_KUPPIDO_1.0',
    payload: { status },
  };
}

export function setIsKuppido20(status) {
  return {
    type: '@auth/SET_IS_KUPPIDO_2.0',
    payload: { status },
  };
}

export function setIsKuppido30(status) {
  return {
    type: '@auth/SET_IS_KUPPIDO_3.0',
    payload: { status },
  };
}

export function setIsKuppidoX(status) {
  return {
    type: '@auth/SET_IS_KUPPIDO_X',
    payload: { status },
  };
}

export function loginRequest(email, password) {
  return {
    type: '@auth/LOGIN_REQUEST',
    payload: { email, password },
  };
}

export function loginSuccess(token, user) {
  return {
    type: '@auth/LOGIN_SUCCESS',
    payload: { token, user },
  };
}

export function loginMasterSuccess(token, user) {
  return {
    type: '@auth/LOGIN_MASTER_SUCCESS',
    payload: { token, user },
  };
}

export function loginFailure() {
  return {
    type: '@auth/LOGIN_FAILURE',
  };
}

export function logout(invalidSession = false) {
  return {
    type: '@auth/LOG_OUT_REQUEST',
    payload: { invalidSession },
  };
}

export function logoutSuccessFailure() {
  return {
    type: '@auth/LOG_OUT',
  };
}

export function recoverEmail(name, cpf_cnpj) {
  return {
    type: '@auth/RECOVER_EMAIL',
    payload: { name, cpf_cnpj },
  };
}

export function postRecoverEmail(email) {
  return {
    type: '@auth/POST_RECOVER_EMAIL',
    payload: { email },
  };
}

export function postRecoverEmailFailure() {
  return {
    type: '@auth/POST_RECOVER_EMAIL_FAILURE',
  };
}

export function recoverPassword(email) {
  return {
    type: '@auth/RECOVER_PASSWORD',
    payload: { email },
  };
}

export function endAction() {
  return {
    type: '@auth/END_ACTION',
  };
}
