import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import SendContract from '~/models/SendContract';
import { RootState } from '~/stores';

import { leadManagement } from '../../../routes/routeMap';
import { apiGlobal, apiKuppido } from '../../../services/api';
import {
  allLeads,
  currentLead,
  endAction,
  indexLeads as indexLeadsAction,
  notFoundLead,
  notFoundLeads,
  // indexLeadsNoLoading as indexLeadsNoLoadingAction,
  sendContractStatus,
  setLeadsByStatus,
  showLead as showLeadAction,
  situationLeads,
} from './actions';
import { logout } from '../auth/actions';

const getState = (state: RootState) => state;

export function* sendContract({ payload }: { payload: SendContract }) {
  try {
    const state = yield select(getState);
    const { master, isKuppiCommercialTeam, isKuppidoX } = state.auth;
    const userInfo = state.user.userInfo;
    const recipient_id = state.user?.userAccountBank?.recipient_id;
    const kuppidoMasterChild = state.user.userInfo?.kuppidoMasterChild;

    const dataSplit = {
      master,
      custom_split: isKuppidoX ? 'kuppido_x' : null,
      is_corporation: userInfo?.is_corporation,
      kuppido_info_id: master ? null : userInfo?.id,
      kuppido_recipient_id: master ? null : recipient_id,
      kuppido_master_info_id: master ? userInfo?.id : kuppidoMasterChild?.kuppido_master_info_id,
      kuppido_master_recipient_id: master
        ? recipient_id
        : kuppidoMasterChild?.kuppidoMasterInfo?.user?.kuppidoBankAccount?.recipient_id,
    };

    // Verify if the kuppido does not has account and block the send contract, informing to register the account
    if (!recipient_id && !isKuppiCommercialTeam) {
      toast.error(
        'Não foi possível enviar o contrato devido a você não ter cadastrado a conta corrente onde irá receber os repasses. Vá até o menu "Perfil e Conta" e faça o cadastro.',
      );
      // The kuppido has account, go to send contract or is a Kuppido 2.0
    } else {
      const dataAddress = {
        zipcode: payload.zipcode,
        street: payload.street,
        number: payload.number,
        neighborhood: payload.neighborhood,
        complement: payload.complement,
        city: payload.city,
        state: payload.state,
        phone_1: payload.phone_1,
        phone_2: payload?.phone_2,
        phone_whatsapp: payload?.phone_whatsapp,
        latitude: '',
        longitude: '',
      };

      const address = `${dataAddress.street}, ${dataAddress.number} ${dataAddress.neighborhood} ${dataAddress.city} ${dataAddress.state} ${dataAddress.zipcode}`;

      const { data: addressResponseData } = yield call(apiGlobal.post, `/get-address`, {
        address,
      });
      dataAddress.latitude = addressResponseData.latitude;
      dataAddress.longitude = addressResponseData.longitude;

      const data = {
        split: dataSplit,
        address: dataAddress,
        lead_id: payload.lead_id,
        advertiser_name: payload.advertiser_name,
        cnpj: payload.cnpj,
        email: payload.email,
        representative_name: payload.representative_name,
        advertiser_category_id: payload.advertiser_category_id,
        phone_whatsapp_representative: payload.phone_whatsapp_representative,
        subscription_id: payload.subscription_id === '99' ? '2' : payload.subscription_id,
        amount: payload.amount,
        free_plan: payload.free_plan,
        free_plan_days_period: payload.free_plan_days_period,
        instagram: payload.instagram !== '' ? payload.instagram : null,
        instagram_followers_qty: payload.instagram !== '' ? payload.instagram_followers_qty : null,
        region_type: payload.region_type,
      };

      if (isKuppiCommercialTeam) {
        yield call(apiKuppido.post, `/commercial-team/contract/send`, data);
      } else {
        yield call(apiKuppido.post, `/contract/send`, data);
      }

      yield put(sendContractStatus(true));
      yield put(indexLeadsAction(false));
    }
  } catch (err: any) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      console.debug('sendContract.err', err);
      yield put(sendContractStatus(false));
    }
  }
}

export function* showLead({ payload }: any) {
  try {
    const state = yield select(getState);
    const { master } = state.auth;
    const userInfoId = state.user.userInfo.id;
    const { slug } = payload;

    if (master) {
      const response = yield call(apiKuppido.get, `/master/user-info/${userInfoId}/lead/${slug}`);
      const lead = response.data;

      yield put(currentLead(lead));
    } else {
      const response = yield call(apiKuppido.get, `/user-info/${userInfoId}/lead/${slug}`);
      const lead = response.data;

      yield put(currentLead(lead));
    }
  } catch (err: any) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(notFoundLead());
    }
  }
}

export function* destroyLead({ payload }: any) {
  try {
    const state = yield select(getState);
    const { master } = state.auth;
    const { id } = state.user.userInfo;
    const { slug, redirect } = payload;

    if (master) {
      yield call(apiKuppido.delete, `/master/user-info/${id}/lead/${slug}`);
    } else {
      yield call(apiKuppido.delete, `/user-info/${id}/lead/${slug}`);
    }

    if (redirect) {
      yield put(push(leadManagement));
    }
  } catch (err: any) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(notFoundLead());
    }
  }
}

export function* updateLead({ payload }: any) {
  try {
    const state = yield select(getState);
    const { master } = state.auth;
    const userInfoId = state.user.userInfo.id;
    const { slug } = state.lead.lead;
    const {
      name,
      funnel_status,
      advertiser_category_id,
      email,
      phone_1,
      phone_2,
      cpf_cnpj,
    } = payload;

    const data = {
      name,
      funnel_status,
      advertiser_category_id,
      email,
      phone_1,
      phone_2,
      cpf_cnpj,
    };

    if (master) {
      const response = yield call(
        apiKuppido.put,
        `/master/user-info/${userInfoId}/lead/${slug}`,
        data,
      );
      const lead = response.data;

      yield put(currentLead(lead));
    } else {
      const response = yield call(apiKuppido.put, `/user-info/${userInfoId}/lead/${slug}`, data);
      const lead = response.data;

      yield put(currentLead(lead));
    }

    toast.success(`As informações foram atualizadas com sucesso.`);
    yield put(endAction());
  } catch (err: any) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      toast.error(
        'Não foi possível atualizar o lead, por favor, recarregue a página e tente novamente.',
      );
      yield put(endAction());
    }
  }
}

export function* updateLeadAddress({ payload }: any) {
  try {
    const reduxState = yield select(getState);
    const { slug } = reduxState.lead.lead;
    const { zipcode, state, city, street, neighborhood, number, complement } = payload;

    const data = {
      zipcode,
      state,
      city,
      street,
      neighborhood,
      number,
      complement,
    };

    const response = yield call(apiKuppido.put, `/lead/${slug}/address`, data);
    const lead = response.data;
    yield put(currentLead(lead));
    toast.success(`As informações foram atualizadas com sucesso.`);
  } catch (err: any) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      toast.error(
        'Não foi possível atualizar o lead, por favor, recarregue a página e tente novamente.',
      );
      yield put(endAction());
    }
  }
}

export function* indexLeads() {
  try {
    const state = yield select(getState);
    const { master } = state.auth;
    const userInfoId = state.user.userInfo.id;

    if (master) {
      const response = yield call(apiKuppido.get, `/master/user-info/${userInfoId}/leads`);
      const leads = response.data;
      yield put(allLeads(leads));
    } else {
      const response = yield call(apiKuppido.get, `/user-info/${userInfoId}/leads`);
      const leads = response.data;
      yield put(allLeads(leads));
    }
  } catch (err: any) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(notFoundLeads());
    }
  }
}

export function* indexLeadsNoLoading() {
  try {
    const state = yield select(getState);
    const { master } = state.auth;
    const userInfoId = state.user.userInfo.id;

    if (master) {
      const response = yield call(apiKuppido.get, `/master/user-info/${userInfoId}/leads`);
      const leads = response.data;
      yield put(allLeads(leads));
    } else {
      const response = yield call(apiKuppido.get, `/user-info/${userInfoId}/leads`);
      const leads = response.data;
      yield put(allLeads(leads));
    }
    yield put(setLeadsByStatus());
  } catch (err: any) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(notFoundLeads());
    }
  }
}

export function* indexLeads2() {
  try {
    const state = yield select(getState);
    const { master } = state.auth;
    const userInfoId = state.user.userInfo.id;

    if (master) {
      const response = yield call(apiKuppido.get, `/master/user-info/${userInfoId}/leads`);
      const leads = response.data;
      yield put(allLeads(leads));
    } else {
      const response = yield call(apiKuppido.get, `/user-info/${userInfoId}/leads`);
      const leads = response.data;
      yield put(allLeads(leads));
    }
    yield put(setLeadsByStatus());
    yield put(endAction());
  } catch (err: any) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(notFoundLeads());
    }
  }
}

export function* storeLeads({ payload }: any) {
  try {
    const state = yield select(getState);
    const { master } = state.auth;
    const userInfoId = state.user.userInfo.id;
    const {
      advertiser_category_id,
      name,
      cpf_cnpj,
      email,
      phone_1,
      phone_2,
      funnel_status,
    } = payload;

    const data = {
      advertiser_category_id,
      name,
      cpf_cnpj,
      email,
      phone_1,
      phone_2,
      funnel_status,
    };

    if (master) {
      const response = yield call(apiKuppido.post, `/master/user-info/${userInfoId}/leads`, data);
      const { situation } = response.data;

      yield put(situationLeads(situation));
    } else {
      const response = yield call(apiKuppido.post, `/user-info/${userInfoId}/leads`, data);
      const { situation } = response.data;
      yield put(situationLeads(situation));
    }

    yield put(indexLeadsAction());
  } catch (err: any) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(endAction());
      toast.error(
        'Não foi possível adicionar o lead, pois o mesmo já existe em sua lista de leads.',
      );
    }
  }
}

export function* loseLead({ payload }: any) {
  try {
    const { slug, reason_for_loss, loss_description } = payload;
    const data = { reason_for_loss, loss_description };

    const response = yield call(apiKuppido.put, `/lead/${slug}/lose`, data);
    const lead = response.data;

    yield put(currentLead(lead));
    yield put(endAction());
  } catch (err: any) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(endAction());
    }
  }
}

export function* leadsByStatus({ payload }: any) {
  try {
    const { leads } = payload;
    const status = {
      cliente_potencial: 0,
      contactado: 0,
      reuniao_marcada: 0,
      proposta_enviada: 0,
      em_negociacao: 0,
      enviar_contrato: 0,
    };

    leads.forEach((lead: any) => {
      if (lead.funnel_status === 'cliente_potencial') {
        status.cliente_potencial += 1;
      }
      if (lead.funnel_status === 'contactado') {
        status.contactado += 1;
      }
      if (lead.funnel_status === 'reuniao_marcada') {
        status.reuniao_marcada += 1;
      }
      if (lead.funnel_status === 'proposta_enviada') {
        status.proposta_enviada += 1;
      }
      if (lead.funnel_status === 'em_negociacao') {
        status.em_negociacao += 1;
      }
      if (lead.funnel_status === 'enviar_contrato') {
        status.enviar_contrato += 1;
      }
    });

    yield put(setLeadsByStatus(status));
  } catch (err: any) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(notFoundLead());
    }
  }
}

export function* updateFunnelStatus({ payload }: any) {
  try {
    const state: RootState = yield select(getState);
    const { master } = state.auth;
    const userInfoId = state.user.userInfo.id;
    const { slug, funnel_status } = payload;

    if (master) {
      yield call(apiKuppido.put, `/master/user-info/${userInfoId}/lead/${slug}`, {
        funnel_status,
      });
    } else {
      yield call(apiKuppido.put, `/user-info/${userInfoId}/lead/${slug}`, {
        funnel_status,
      });
    }
  } catch (err: any) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      toast.error(
        'Não foi possível atualizar o lead, por favor, recarregue a página e tente novamente.',
      );
      yield put(endAction());
    }
  }
}

export function* storeNotes({ payload }: any) {
  try {
    const state = yield select(getState);
    const { slug } = state.lead.lead;
    const { lead_id, name, description } = payload;

    const data = { name, description };

    yield call(apiKuppido.post, `/lead/${lead_id}/note`, data);

    yield put(showLeadAction(slug));
    toast.success('Nova observação adicionada com sucesso!');
  } catch (err: any) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(endAction());
      toast.error('Não foi possível adicionar uma nova observação a esse lead.');
    }
  }
}

export function* storeActivities({ payload }: any) {
  try {
    const state = yield select(getState);
    const { slug } = state.lead.lead;
    const { lead_id, name, description, type, activity_at } = payload;

    const data = { name, description, type, activity_at };

    yield call(apiKuppido.post, `/lead/${lead_id}/activity`, data);

    yield put(showLeadAction(slug));
    toast.success('Nova atividade adicionada com sucesso!');
  } catch (err: any) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(endAction());
      toast.error('Não foi possível adicionar uma nova atividade a esse lead.');
    }
  }
}

export function* updateActivities({ payload }: any) {
  try {
    const state = yield select(getState);
    const { slug } = state.lead.lead;
    const { activity_id, lead_id, name, description, type, is_concluded, activity_at } = payload;

    const data =
      is_concluded === undefined
        ? { name, description, type, activity_at }
        : { name, description, type, is_concluded, activity_at };

    yield call(apiKuppido.put, `/lead/${lead_id}/activity/${activity_id}`, data);

    yield put(showLeadAction(slug));
    toast.success('Atividade atualizada com sucesso!');
  } catch (err: any) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(endAction());
      toast.error(
        'Não foi possível atualizar as informações da atividade, verifique os dados e tente novamente.',
      );
    }
  }
}

export function* updateNotes({ payload }: any) {
  try {
    const state = yield select(getState);
    const { slug } = state.lead.lead;
    const { note_id, lead_id, name, description } = payload;

    const data = { name, description };

    yield call(apiKuppido.put, `/lead/${lead_id}/note/${note_id}`, data);

    yield put(showLeadAction(slug));
    toast.success('Observação atualizada com sucesso!');
  } catch (err: any) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(endAction());
      toast.error(
        'Não foi possível atualizar as informações da observação, verifique os dados e tente novamente.',
      );
    }
  }
}

export function* destroyActivities({ payload }: any) {
  try {
    const state = yield select(getState);
    const { slug } = state.lead.lead;
    const { activity_id, lead_id } = payload;

    yield call(apiKuppido.delete, `/lead/${lead_id}/activity/${activity_id}`);

    yield put(showLeadAction(slug));
    toast.success('Atividade deletada com sucesso!');
  } catch (err: any) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(endAction());
      toast.error('Não foi possível adicionar uma nova atividade a esse lead.');
    }
  }
}

export function* destroyNotes({ payload }: any) {
  try {
    const state = yield select(getState);
    const { slug } = state.lead.lead;
    const { note_id, lead_id } = payload;

    yield call(apiKuppido.delete, `/lead/${lead_id}/note/${note_id}`);

    yield put(showLeadAction(slug));
    toast.success('Observação deletada com sucesso!');
  } catch (err: any) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(endAction());
      toast.error('Não foi possível adicionar uma nova observação a esse lead.');
    }
  }
}

export function* dontShowReasonForLoss({ payload }: any) {
  try {
    const state = yield select(getState);
    const { master } = state.auth;
    const userInfoId = state.user.userInfo.id;
    const { lead_slug } = payload;

    const data = { is_show_reason_for_loss: false };

    if (master) {
      yield call(apiKuppido.put, `/master/user-info/${userInfoId}/lead/${lead_slug}`, data);
    } else {
      yield call(apiKuppido.put, `/user-info/${userInfoId}/lead/${lead_slug}`, data);
    }
  } catch (err: any) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      toast.error(
        'Não foi possível atualizar o lead, por favor, recarregue a página e tente novamente.',
      );
      yield put(endAction());
    }
  }
}

export default all([
  takeLatest('@lead/SEND_CONTRACT' as any, sendContract),
  takeLatest('@lead/DONT_SHOW_REASON_FOR_LOSS', dontShowReasonForLoss),
  takeLatest('@lead/STORE_NOTE', storeNotes),
  takeLatest('@lead/UPDATE_NOTE', updateNotes),
  takeLatest('@lead/DESTROY_NOTE', destroyNotes),
  takeLatest('@lead/STORE_ACTIVITY', storeActivities),
  takeLatest('@lead/UPDATE_ACTIVITY', updateActivities),
  takeLatest('@lead/DESTROY_ACTIVITY', destroyActivities),
  takeLatest('@lead/UPDATE_FUNNEL_STATUS', updateFunnelStatus),
  takeLatest('@lead/LEADS_BY_STATUS', leadsByStatus),
  takeLatest('@lead/LOSE_LEAD', loseLead),
  takeLatest('@lead/DESTROY_LEAD', destroyLead),
  takeLatest('@lead/INDEX_LEADS', indexLeads),
  takeLatest('@lead/INDEX_LEADS2', indexLeads2),
  takeLatest('@lead/INDEX_LEADS_NO_LOADING', indexLeadsNoLoading),
  takeLatest('@lead/STORE_LEAD', storeLeads),
  takeLatest('@lead/SHOW_LEAD', showLead),
  takeLatest('@lead/UPDATE_LEAD', updateLead),
  takeLatest('@lead/UPDATE_LEAD_ADDRESS', updateLeadAddress),
]);
