import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { Row } from '../../styles/grid';
import MillisecConversion from '../../utils/MillisecConversion';
import { statusConfig } from './status';
import { Ball, Text } from './styles';

export default function FinancialTableStatus({ item }) {
  const [
    contract,
    // setContract
  ] = useState(item);
  const [status, setStatus] = useState('ativo');

  useEffect(() => {
    const now = new Date().getTime();
    const finish = new Date(moment(contract.finish_validity_at, 'DD/MM/YYYY', true)).getTime();

    if (MillisecConversion(finish, 'days', 0) - MillisecConversion(now, 'days', 0) <= 30) {
      setStatus('encerrando');
    } else {
      setStatus('ativo');
    }
  }, [item, contract]);

  return (
    <Row alignCenter justifyStart>
      <Ball status={status} />
      <Text
      // status={status}
      >
        {statusConfig[status].text}
      </Text>
    </Row>
  );
}
