import styled from '@emotion/styled';

import { cinzaEscuro2, verdeKuppi } from '../../../styles/colors';

export const SvgBox = styled.svg`
  height: 30px;
  width: 30px;
  border: 2px solid ${(props) => (props.optionActive ? verdeKuppi : cinzaEscuro2)};
  border-radius: 4px;
  padding: 1px;

  path,
  rect {
    fill: ${(props) => (props.optionActive ? verdeKuppi : cinzaEscuro2)};
  }
`;

export const SvgBox2 = styled.svg`
  height: 30px;
  width: 30px;
  border: 2px solid ${(props) => (props.optionActive ? verdeKuppi : cinzaEscuro2)};
  border-radius: 4px;
  padding: 2px 3px;

  path,
  rect {
    fill: ${(props) => (props.optionActive ? verdeKuppi : cinzaEscuro2)};
  }
`;

export const SvgBox3 = styled.svg`
  height: 24px;
  width: 24px;
  /* padding: 2px 3px; */
`;
/** ************************************************* */

export const Leave = styled.path``;

export const Leave2 = styled.path``;

/** ************************************************* */

export const Dashboard = styled.path``;

/** ************************************************* */

export const Profile1 = styled.path``;

export const Profile2 = styled.path``;

/** ************************************************* */

export const Funel1 = styled.path``;

export const Funel2 = styled.path``;

export const Funel3 = styled.path``;

export const Funel4 = styled.path``;

export const Funel5 = styled.path``;

/** ************************************************* */

export const Clients1 = styled.path``;

export const Clients2 = styled.path``;

export const Clients3 = styled.path``;

/** ************************************************* */

export const Materials1 = styled.rect`
  width: 16px;
  height: 2px;
  transform: translate(4 18);
`;

export const Materials2 = styled.rect`
  width: 4px;
  height: 2px;
  transform: translate(4 20) rotate(-90);
`;

export const Materials3 = styled.rect`
  width: 4px;
  height: 2px;
  transform: translate(18 20) rotate(-90);
`;

export const Materials4 = styled.path``;

export const Materials5 = styled.path``;

/** ************************************************* */

export const Academy = styled.path``;

export const MemberGetMember = styled.path``;
