import styled from '@emotion/styled';

import { branco, cinzaEscuro, cinzaEscuro2 } from '../../../styles/colors';

// export const FileInputCustom = styled(FileInput)`
export const FileInputCustom = styled.input`
  opacity: 0;
  border: 1px solid ${(props) => (props.onError ? 'red' : cinzaEscuro)} !important;
  padding: 7px 10px !important;
  height: auto !important;
  margin: 0 !important;
  border-radius: 5px !important;
  display: flex;
  background: ${branco} !important;
  z-index: 2;
  cursor: pointer;

  box-sizing: inherit !important;
  font-size: 14px !important;
  width: 100%;
  height: 100px !important;

  :focus {
    border: 1px solid ${(props) => (props.onError ? 'red' : cinzaEscuro)} !important;
    box-shadow: none !important;
    border-radius: 5px !important;
  }
`;

export const FileInputCustomBox = styled.div`
  cursor: pointer;
  z-index: 1;
  position: absolute;
  top: 50px;
  border: 1px dashed ${(props) => (props.onError ? 'red' : cinzaEscuro)} !important;
  border-radius: 5px;
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-family: AvenirNextLTPro;
  font-weight: 700;

  i {
    padding: 5px;
    margin-bottom: 3px;
  }
`;

export const FileInputLabel = styled.label`
  color: ${cinzaEscuro2};
  cursor: pointer;
  font-weight: bold;
  text-align: left;
  font-size: 16px;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: AvenirNextLTPro;
  font-weight: 700;
`;
