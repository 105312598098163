import * as yup from 'yup';

import { onlyNumbers } from '~/utils/onlyNumbers';

export default function step4Validation(currMinPlanAmount: string, currMaxPlanAmount: string) {
  return yup.object().shape({
    subscription_id: yup
      .string()
      .required('Por favor informe o plano de assinatura do anunciante.'),
    amount: yup
      .string()
      .required('Por favor informe um valor para o plano do anunciante.')
      .test(
        'valid-amount',
        `O valor deve ser entre ${Number(currMinPlanAmount)} e ${Number(currMaxPlanAmount)}.`,
        (value: string) => {
          const parsedValue = parseInt(onlyNumbers(value));
          return (
            parsedValue <= parseInt(currMaxPlanAmount) && parsedValue >= parseInt(currMinPlanAmount)
          );
        },
      ),
  });
}
