export function indexLogLeads() {
  return {
    type: '@dashboard/INDEX_LOG_LEADS',
  };
}

export function setLogLeads(currentMonth, lastMonth, monthBeforeTheLast) {
  return {
    type: '@dashboard/SET_LOG_LEADS',
    payload: { currentMonth, lastMonth, monthBeforeTheLast },
  };
}

export function structureBilling(payables) {
  return {
    type: '@dashboard/STRUCTURE_BILLING',
    payload: { payables },
  };
}

export function setStructureBilling(barMonths, barMonthsData) {
  return {
    type: '@dashboard/SET_STRUCTURE_BILLING',
    payload: { barMonths, barMonthsData },
  };
}

export function getConversionRate() {
  return {
    type: '@dashboard/GET_CONVERSION_RATE',
  };
}

export function setConversionRate(rate) {
  return {
    type: '@dashboard/SET_CONVERSION_RATE',
    payload: { rate },
  };
}
