import styled from '@emotion/styled';

import { verdeKuppi } from '../../styles/colors';

export const SvgBox = styled.svg<any>`
  ${(props) => props.sorted && `transform: rotate(180deg);`};
  transition: all 0.2s ease-in-out;
  margin-left: 5px;
  width: 18px;
  height: 18px;

  path {
    fill: ${verdeKuppi};
    fill: ${(props) => props.color && props.color};
  }
`;

export const Arrow = styled.path`
  transform: scale(0.8);
`;

export const Arrow2 = styled.path`
  transform: scale(0.8);
`;
