export const login = '/acesso-portal';

export const logout = '/portal/logout';

export const dashboard = '/portal/dashboard';

export const profileAndAccount = '/portal/perfil-e-conta';

export const leadManagement = '/portal/gestao-de-leads';

export const leadDetail = `${leadManagement}/lead/:slug`;

export const leadDetailForLink = `${leadManagement}/lead`;

export const clientManagement = '/portal/gestao-de-clientes';

export const activeClientDetail = `${clientManagement}/ativo/detalhes-cliente`;

export const inactiveClientDetail = `${clientManagement}/inativo/detalhes-cliente`;

export const financialManagement = '/portal/gestao-financeira';

export const supportMaterials = '/portal/materiais-de-suporte';

export const kpAcademy = '/portal/kp-academy';

export const memberGetMember = '/portal/member-get-member';

export const memberGetMemberInfo = `${memberGetMember}/informacoes`;

export const memberGetMemberStatistics = `${memberGetMember}/estatisticas`;
