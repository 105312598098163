import {
  Box,
  FormControl,
  FormControlProps,
  FormLabel,
  InputGroup,
  InputProps,
  InputRightElement,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { DeepMap, FieldError } from 'react-hook-form';
import { RiErrorWarningFill } from 'react-icons/ri';

import Input from '../Input';

interface CustomInputWithValidationProps {
  register?: any;
  errors?: DeepMap<Record<string, any>, FieldError>;
  textLabel: string;
  name: string;
  formProps?: FormControlProps;
}

export type InputWithValidationProps = InputProps & CustomInputWithValidationProps;

const InputWithValidation: React.FC<InputWithValidationProps> = ({
  register,
  errors,
  name,
  textLabel,
  placeholder,
  formProps,
  ...rest
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <FormControl w="100%" d="flex" flexDir="column" isInvalid={!!errors[name]} {...formProps}>
      <FormLabel fontSize="1rem" fontWeight="bold">
        {textLabel}
      </FormLabel>
      <InputGroup>
        <Input
          placeholder={placeholder}
          name={name}
          error={errors[name]}
          register={register}
          onBlur={onClose}
          onFocus={onOpen}
          {...rest}
        />
        {!!errors[name] && (
          <InputRightElement h="37px" onFocus={onOpen} onBlur={onClose}>
            <Tooltip
              label={errors[name]?.message}
              isOpen={isOpen}
              shouldWrapChildren
              hasArrow
              placement="bottom"
              bg="red.400"
            >
              <Box p="3px" w="25px" h="25px" color="red.400" as={RiErrorWarningFill} />
            </Tooltip>
          </InputRightElement>
        )}
      </InputGroup>
    </FormControl>
  );
};

export default InputWithValidation;
