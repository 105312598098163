import styled from '@emotion/styled';

import { verdeKuppi } from '../../styles/colors';

export const SvgBox = styled.svg`
  width: 80px;
  height: 80px;
`;

export const Verified = styled.path`
  fill: ${verdeKuppi};
`;
