import { Box } from '@chakra-ui/react';
import React, { useState } from 'react';
import { GrFormClose } from 'react-icons/gr';
import { MdMoreVert } from 'react-icons/md';

import { ButtonEdit, ChangeButton, Container, ModalEdit } from './styles';

export default function TableEditButton({ editClick, text, width }) {
  const [visible, setVisible] = useState(false);

  const handleVisible = () => {
    setVisible(!visible);
  };

  const handleEdit = () => {
    setVisible(!visible);
    editClick();
  };
  return (
    <Container>
      <ModalEdit visible={visible} width={width}>
        <ButtonEdit onClick={handleEdit}>{text}</ButtonEdit>
      </ModalEdit>
      <ChangeButton onClick={handleVisible}>
        {!visible ? (
          <Box as={MdMoreVert} w="22px" h="22px" />
        ) : (
          <Box
            alignItems="center"
            justifyContent="center"
            w="22px"
            h="22px"
            border="2px solid #000"
            borderRadius="50%"
          >
            <Box as={GrFormClose} w="100%" h="100%" />
          </Box>
        )}
      </ChangeButton>
    </Container>
  );
}
