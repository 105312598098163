import styled from '@emotion/styled';

import { branco, cinzaEscuro, cinzaMedio, verdeKuppi } from '../../styles/colors';
import { Button } from '../../styles/common';
import py2vw from '../../utils/px2vw';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
`;

export const TextBox = styled.div`
  margin-left: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const Title = styled.div`
  font-size: ${py2vw(24)};
  font-weight: bold;
  font-family: AvenirNextLTPro;
  font-weight: 700;
`;

export const ContentPrimary = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 70px;
  width: 100%;
  height: 100%;
`;

export const ProfileImageBox = styled.div`
  position: relative;
  max-width: 65px;
  max-height: 65px;
  width: 65px;
  height: 65px;
`;
export const ProfileImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const SwitchTableButton = styled(Button)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: ${py2vw(18)};
  font-family: AvenirNextLTPro;
  font-weight: 700;

  .selected {
    margin-top: 5px;
    width: 100%;
    height: 4px;
    background: transparent;
    border-radius: 10px;
  }

  ${(props) =>
    props.selected &&
    `
    .selected {
      background: ${verdeKuppi};
    }
  `}
`;

/** *************************************** */

export const ContentDefault = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  background: ${branco};
  width: 100%;
  padding: 10px;
  border-radius: 20px;
  margin-bottom: 30px;

  ${(props) => props.flexStart && `justify-content: flex-start;`}

  ${(props) =>
    props.groupTop &&
    `
    border-radius: 0px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    margin-bottom: 2px;
  `}

  ${(props) =>
    props.groupBottom &&
    `
    border-radius: 0px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    margin-bottom: 30px;
  `}

  .MuiPaper-elevation1 {
    box-shadow: none;
  }
  .MuiTableCell-root {
  }
  button.MuiButtonBase-root {
    :focus {
      background: transparent;
    }
  }

  .MuiTableRow-root.Mui-selected,
  .MuiTableRow-root.Mui-selected:hover {
    background-color: transparent;
  }

  .MuiTableRow-head {
    th {
      font-size: ${py2vw(11)};
      font-family: AvenirNextLTPro;
      font-weight: 700;
    }
  }
`;

export const Field = styled.div`
  padding: 15px 20px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  ${(props) => props.mlAuto && `margin-left: auto`}
  ${(props) => props.mrAuto && `border-right: auto`}
`;

export const TextField = styled.div`
  font-size: 15px;
`;

export const AddButton = styled.button`
  font-family: AvenirNextLTPro;
  font-weight: 700;
  background: ${verdeKuppi} !important;
  color: ${branco} !important;
  border-radius: 10px;
  border: none;
  padding: 10px 30px;
  transition: all 0.2s ease-in-out;

  :active {
    filter: brightness(95%);
  }
`;

export const SearchInput = styled.input`
  width: 250px !important;
  height: 38px !important;
  margin: 0 !important;
  padding-left: 10px !important;
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;

  ::placeholder {
    color: ${cinzaEscuro};
  }
`;

export const SearchBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  padding: 0 15px;
  background: ${branco};
  border: 1px solid ${cinzaMedio} !important;
  border-radius: 10px !important;
`;
