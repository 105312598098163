import { routerMiddleware, RouterState } from 'connected-react-router';
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';

import history from '~/services/history';
import { ClientsReducerType } from '~/stores/modules/clients/reducer';
import { LeadReducerType } from '~/stores/modules/lead/reducer';

import createStore from './createStore';
import rootReducer from './modules/rootReducer';
import rootSaga from './modules/rootSaga';
import persistReducers from './persistReducers';

const sagaMonitor =
  process.env.NODE_ENV === 'development' ? console.tron.createSagaMonitor() : null;

const sagaMiddleware = createSagaMiddleware({ sagaMonitor });

const middlewares = [routerMiddleware(history), sagaMiddleware];

export const store = createStore(persistReducers(rootReducer(history)), middlewares);
export const persistor = persistStore(store);

export type RootState = {
  router: RouterState<object>;
  auth: any;
  user: any;
  lead: LeadReducerType;
  utils: any;
  dashboard: any;
  financial: any;
  clients: ClientsReducerType;
  mgm: any;
};

sagaMiddleware.run(rootSaga);
