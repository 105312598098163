import styled from '@emotion/styled';

export const SvgBox = styled.svg`
  height: 24px;
  width: 24px;
  cursor: pointer;

  ${(props) => props.disabled && `cursor: not-allowed;`}

  path {
    ${(props) =>
      props.checked
        ? `
      fill: #26ab81;
    `
        : `
      fill: #f9ae00;
    `}

    ${(props) => props.disabled && `fill: #9B999B;`}
  }
`;
