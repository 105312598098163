import styled from '@emotion/styled';

import py2vw from '../../utils/px2vw';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  #funnelMain .card,
  #funnelMain .dropzone {
    transition: 200ms;
  }

  #funnelMain .highlight {
    background: #d5ebe3;
  }

  #funnelMain .over {
    background: #90fdb790;
  }

  #funnelMain .is-dragging {
    opacity: 0.5;
  }
`;

export const FunnelHeader = styled.div`
  width: 100%;
  padding: 15px;
  border-radius: 15px 15px 0px 0px;
  background: #dbdbdb;
  margin-bottom: 1px;
  min-height: 100px;
`;

export const FunnelBody = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  min-height: 420px;
  min-height: ${(props) => props.height && props.height}px;
  width: 100%;
  padding: 15px 10px;
  background: #efefef;
  border-radius: 0px 0px 15px 15px;
`;

export const Title = styled.div`
  font-family: AvenirNextLTPro;
  font-weight: 700;
  font-size: ${py2vw(13)};
`;

export const Count = styled.div`
  font-size: ${py2vw(13)};
`;
