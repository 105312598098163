import React, { useEffect, useMemo, useState } from 'react';
import { FiAlertTriangle } from 'react-icons/fi';

import MillisecConversion from '../../utils/MillisecConversion';
import { InfoTag, TagText } from './styles';

export default function FunelItemTag({ lead }) {
  const [leadItem, setLeadItem] = useState(lead);
  const [noActiviTy, setNoActiviTy] = useState(false);
  const [forTheDay, setForTheDay] = useState(false);
  const [forTheFuture, setForTheFuture] = useState(false);
  const [late, setLate] = useState(false);
  const [currentlyTag, setCurrentlyTag] = useState('');

  useEffect(() => {
    setLeadItem(lead);
  }, [lead]);

  // tags => noActiviTy // forTheDay // forTheFuture // late

  useMemo(() => {
    try {
      const date = new Date();
      const less3Hours = 1000 * 60 * 60 * -1 * 3;

      const now = new Date(date.getTime() + less3Hours);
      const activities = leadItem.kuppidoLeadActivity;
      if (activities.length <= 0) {
        setNoActiviTy(true);
      } else {
        activities.forEach((item) => {
          const activityDate = new Date(item.activity_at);
          if (item.is_concluded) {
          } else if (now > activityDate) {
            setLate(true);
          } else if (now < activityDate) {
            const diference = MillisecConversion(activityDate - now, 'days', 0);
            if (diference < 1) {
              setForTheDay(true);
            } else {
              setForTheFuture(true);
            }
          }
        });
      }
    } catch (err) {}
  }, [leadItem]);

  useEffect(() => {
    if (noActiviTy) {
      setCurrentlyTag('noActiviTy');
    } else if (late) {
      setCurrentlyTag('late');
    } else if (forTheDay) {
      setCurrentlyTag('forTheDay');
    } else if (forTheFuture) {
      setCurrentlyTag('forTheFuture');
    } else {
      setCurrentlyTag('noActiviTy');
    }
  }, [noActiviTy, forTheDay, forTheFuture, late]);

  return (
    <InfoTag tag={currentlyTag}>
      {currentlyTag === 'noActiviTy' && (
        <>
          <FiAlertTriangle size="1.1em" />{' '}
          <TagText tag={currentlyTag} regular>
            Sem Atividades
          </TagText>
        </>
      )}
      {currentlyTag === 'forTheDay' && 'Atividade pro dia'}
      {currentlyTag === 'late' && 'Atividade Atrasada'}
      {currentlyTag === 'forTheFuture' && 'Atividade Futura'}
    </InfoTag>
  );
}
