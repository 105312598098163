import { Box } from '@chakra-ui/react';
import { Form } from '@rocketseat/unform';
import React, { useEffect, useState } from 'react';
import { MdClear } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { editAddress } from '../../../stores/modules/user/actions';
import { statesOptions } from '../../../utils/commonObjects';
import { cepMask, phoneMask } from '../../../utils/masks';
import { onlyNumbers } from '../../../utils/onlyNumbers';
import {
  CloseButton,
  ColumnContent,
  Container,
  InputCustom,
  Label,
  Modal,
  ModalButton,
  ModelTitle,
  RowContent,
  SelectCustom,
} from '../styles';

export default function ProfileModalTwo({ visible, onChange, user }) {
  const [modal, setModal] = useState(visible);
  const [userState, setUserState] = useState(user);
  const dispatch = useDispatch();

  const [cepField, setcepField] = useState(cepMask(userState?.address?.zipcode));
  const [phone1, setphone1] = useState(phoneMask(userState?.address?.phone_1));
  const [phone2, setphone2] = useState(phoneMask(userState?.address?.phone_2));
  const [phoneWhatsapp, setphoneWhatsapp] = useState(phoneMask(userState?.address?.phone_whatsapp));

  useEffect(() => {
    setUserState(user);
  }, [user]);

  useEffect(() => {
    setModal(visible);
  }, [visible]);

  const handleVisible = () => onChange();

  const handleSubmit = ({
    street,
    number,
    complement,
    zipcode,
    neighborhood,
    city,
    state,
    phone_1,
    phone_2,
    phone_whatsapp,
  }) => {
    if (
      street === '' ||
      number === '' ||
      zipcode === '' ||
      neighborhood === '' ||
      city === '' ||
      state === '' ||
      phone_whatsapp === ''
    ) {
      toast.error('Preencha todos os campos obrigatórios', {
        autoClose: 6000,
      });
    } else if (zipcode.length < 10) {
      toast.error('CEP inválido', {
        autoClose: 6000,
      });
    } else {
      dispatch(
        editAddress(
          street,
          number,
          complement,
          onlyNumbers(zipcode),
          neighborhood,
          city,
          state,
          onlyNumbers(phone_1),
          onlyNumbers(phone_2),
          onlyNumbers(phone_whatsapp),
        ),
      );
      handleVisible();
    }
  };

  return (
    <Container visible={modal}>
      <Modal width="750px">
        <CloseButton onClick={handleVisible}>
          <Box as={MdClear} w="35px" h="35px" />
        </CloseButton>
        <ModelTitle>Alterar dados</ModelTitle> <br />
        <Form
          initialData={{
            street: userState?.address?.street,
            number: userState?.address?.number,
            complement: userState?.address?.complement,
            neighborhood: userState?.address?.neighborhood,
            city: userState?.address?.city,
            state: userState?.address?.state,
          }}
          onSubmit={handleSubmit}
        >
          <RowContent>
            <ColumnContent left>
              <Label>Endereço</Label>
              <InputCustom
                name="street"
                required
                type="text"
                maxLength="255"
                placeholder="Endereço"
              />
            </ColumnContent>

            <ColumnContent right>
              <RowContent>
                <ColumnContent left>
                  <Label>Número</Label>
                  <InputCustom
                    name="number"
                    required
                    type="text"
                    maxLength="10"
                    placeholder="Número"
                  />
                </ColumnContent>

                <ColumnContent center>
                  <Label>Complemento</Label>
                  <InputCustom
                    name="complement"
                    type="text"
                    maxLength="100"
                    placeholder="Complemento"
                  />
                </ColumnContent>

                <ColumnContent right>
                  <Label>Cep</Label>
                  <InputCustom
                    name="zipcode"
                    required
                    type="text"
                    placeholder="Cep"
                    maxLength="10"
                    value={cepField}
                    onChange={(e) => setcepField(cepMask(e.target.value))}
                  />
                </ColumnContent>
              </RowContent>
            </ColumnContent>
          </RowContent>

          <RowContent>
            <ColumnContent left>
              <Label>Bairro</Label>
              <InputCustom
                name="neighborhood"
                type="text"
                required
                maxLength="100"
                placeholder="Bairro"
              />
            </ColumnContent>

            <ColumnContent center>
              <Label>Cidade</Label>
              <InputCustom name="city" required type="text" maxLength="100" placeholder="Cidade" />
            </ColumnContent>

            <ColumnContent right>
              <Label>Estado</Label>
              <SelectCustom name="state" required options={statesOptions} />
            </ColumnContent>
          </RowContent>

          <RowContent>
            <ColumnContent left>
              <Label>Telefone 1</Label>
              <InputCustom
                name="phone_1"
                type="text"
                maxLength="13"
                value={phone1}
                onChange={(e) => setphone1(phoneMask(e.target.value))}
                placeholder="Telefone 1"
              />
            </ColumnContent>

            <ColumnContent center>
              <Label>Telefone 2</Label>
              <InputCustom
                name="phone_2"
                type="text"
                maxLength="13"
                value={phone2}
                onChange={(e) => setphone2(phoneMask(e.target.value))}
                placeholder="Telefone 2"
              />
            </ColumnContent>

            <ColumnContent right>
              <Label>WhatsApp</Label>
              <InputCustom
                name="phone_whatsapp"
                type="text"
                value={phoneWhatsapp}
                maxLength="13"
                required
                onChange={(e) => setphoneWhatsapp(phoneMask(e.target.value))}
                placeholder="WhatsApp"
              />
            </ColumnContent>
          </RowContent>

          <RowContent lastRow>
            <ColumnContent left button>
              <ModalButton outlineTheme onClick={handleVisible} type="button">
                Cancelar
              </ModalButton>
            </ColumnContent>

            <ColumnContent right button>
              <ModalButton type="submit">Confirmar</ModalButton>
            </ColumnContent>
          </RowContent>
        </Form>
      </Modal>
    </Container>
  );
}
