import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';

import { ContentDefault, Text } from '../../styles/common';
import { Col, Row } from '../../styles/grid';
import LeadDetailEditButton from '../LeadDetailEditButton';
import { Tag } from './styles';

export default function ContentNote({ item, disabled }) {
  const [note, setItem] = useState(item);

  useEffect(() => {
    setItem(item);
  }, [item]);

  return (
    <ContentDefault>
      <Col>
        <Row>
          <Row margin="0 5px 0 0" width="auto">
            <Tag>Observação</Tag>
          </Row>
          <Row margin="0 0 0 5px" width="auto">
            <Tag dateTag>Adicionada {format(new Date(note.created_at), 'dd/MM/yyyy')}</Tag>
          </Row>
          <Row width="auto" className="ml-auto">
            {!disabled && <LeadDetailEditButton item={note} type="notes" />}
          </Row>
        </Row>
        <Row alignStart justifyStart margin="15px 0 0 0">
          <Text>{note.name}</Text>
        </Row>
        <Col margin="20px 0 0 0">
          <Text regular>Descrição da atividade: </Text>
          <Text margin="0 0 10px 0">{note.description}</Text>
        </Col>
      </Col>
    </ContentDefault>
  );
}
