import { Box, Spinner } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { MdChevronRight } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';

import { verdeKuppi } from '~/styles/colors';

import ButtonEditModal from '../../components/ButtonEditModal';
import ProfileModalAvatar from '../../components/ProfileModal/Avatar';
import ProfileModalOne from '../../components/ProfileModal/One';
import ProfileModalOneMaster from '../../components/ProfileModal/OneMaster';
import ProfileModalThree from '../../components/ProfileModal/Three';
import ProfileModalTwo from '../../components/ProfileModal/Two';
import { getAllprofile } from '../../stores/modules/user/actions';
import { Col } from '../../styles/grid';
import { accountTypesObject } from '../../utils/commonObjects';
import { cepMask, cpfCnpjMask, phoneMask } from '../../utils/masks';
import {
  AvatarButton,
  Container,
  ContentDefault,
  ContentPrimary,
  EditProfileImage,
  Field,
  LabelField,
  ProfileImage,
  ProfileImageBox,
  TextBox,
  TextField,
  Title,
} from './styles';

export default function ProfileAndAccount() {
  const user = useSelector((state) => state.user);
  const loading = useSelector((state) => state.user?.loading);
  const master = useSelector((state) => state.auth?.master);
  const isKuppiCommercialTeam = useSelector((state) => state.auth?.isKuppiCommercialTeam);
  const [userState, setUserState] = useState(user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllprofile());
  }, [dispatch]);

  useEffect(() => {
    setUserState(user);
  }, [user]);

  const [visibleOne, setVisibleOne] = useState(false);
  const toggleOne = () => setVisibleOne(!visibleOne);

  const [visibleTwo, setVisibleTwo] = useState(false);
  const toggleTwo = () => setVisibleTwo(!visibleTwo);

  const [visibleThree, setVisibleThree] = useState(false);
  const toggleThree = () => setVisibleThree(!visibleThree);

  const [visibleAvatar, setVisibleAvatar] = useState(false);
  const toggleAvatar = () => setVisibleAvatar(!visibleAvatar);

  const contentPage = (
    <Container>
      <ContentPrimary>
        <ProfileImageBox>
          <ProfileImage
            src={
              user.avatar
                ? `${user.avatar.url}?nocache=${new Date().getTime()}`
                : '/assets/images/profile/profile@3x.png'
            }
          />
        </ProfileImageBox>
        <TextBox>
          <Title>Perfil e Conta</Title>
          <EditProfileImage onClick={() => toggleAvatar()}>
            <AvatarButton>Editar foto</AvatarButton>
            <Box as={MdChevronRight} cursor="pointer" w="20px" h="20px" />
          </EditProfileImage>
        </TextBox>
      </ContentPrimary>
      <ContentDefault>
        <Field>
          <LabelField>Nome</LabelField>
          <TextField>{userState?.userInfo && userState?.userInfo?.name}</TextField>
        </Field>
        <Field>
          <LabelField>Login</LabelField>
          <TextField>{userState?.user && userState?.user?.email}</TextField>
        </Field>
        <Field>
          <LabelField>CNPJ/CPF</LabelField>
          <TextField>{userState?.userInfo && cpfCnpjMask(userState?.userInfo?.cpf_cnpj)}</TextField>
        </Field>
        <Field>
          <LabelField>Senha</LabelField>
          <TextField>**********</TextField>
        </Field>
        <ButtonEditModal text="Editar" editClick={() => toggleOne()} />
      </ContentDefault>
      {!isKuppiCommercialTeam && (
        <>
          <ContentDefault groupTop>
            <Field>
              <LabelField>Endereço</LabelField>
              <TextField>
                {userState?.address && userState?.address?.street}
                {userState?.address?.number && `, ${userState?.address?.number}`}
              </TextField>
            </Field>
            <Field>
              <LabelField>Bairro</LabelField>
              <TextField>{userState?.address && userState?.address?.neighborhood}</TextField>
            </Field>
            <Field>
              <LabelField>Cidade</LabelField>
              <TextField>{userState?.address && userState?.address?.city}</TextField>
            </Field>
            <Field>
              <LabelField>Estado</LabelField>
              <TextField>{userState?.address && userState?.address?.state}</TextField>
            </Field>
            <Field>
              <LabelField>CEP</LabelField>
              <TextField>{userState?.address && cepMask(userState?.address?.zipcode)}</TextField>
            </Field>
            <ButtonEditModal text="Editar" editClick={toggleTwo} />
          </ContentDefault>
          <ContentDefault groupBottom>
            <Field>
              <LabelField>Telefone 1</LabelField>
              <TextField>{userState?.address && phoneMask(userState?.address?.phone_1)}</TextField>
            </Field>
            <Field>
              <LabelField>Telefone 2</LabelField>
              <TextField>
                {userState?.address?.phone_2 ? phoneMask(userState?.address?.phone_2) : '-'}
              </TextField>
            </Field>
            <Field>
              <LabelField>Whatsapp</LabelField>
              <TextField>
                {userState?.address?.phone_whatsapp
                  ? phoneMask(userState?.address?.phone_whatsapp)
                  : '-'}
              </TextField>
            </Field>
            <Field>
              <LabelField />
              <TextField />
            </Field>
            <Field>
              <LabelField />
              <TextField />
            </Field>
          </ContentDefault>
          <ContentDefault>
            <Field>
              <LabelField>Banco</LabelField>
              <TextField>
                {user?.userAccountBank?.bank && user?.userAccountBank?.bank?.code} -{' '}
                {user?.userAccountBank?.bank && user?.userAccountBank?.bank?.name}
              </TextField>
            </Field>
            <Field>
              <LabelField>Agência</LabelField>
              <TextField>
                {user?.userAccountBank && user?.userAccountBank?.agency}
                {user?.userAccountBank &&
                  user?.userAccountBank?.agency_digit &&
                  `-${user?.userAccountBank && user?.userAccountBank?.agency_digit}`}
              </TextField>
            </Field>
            <Field>
              <LabelField>Conta</LabelField>
              <TextField>
                {user?.userAccountBank && user?.userAccountBank?.account}
                {user?.userAccountBank &&
                  user?.userAccountBank?.account_digit &&
                  `-${user?.userAccountBank && user?.userAccountBank?.account_digit}`}
              </TextField>
            </Field>
            <Field>
              <LabelField>Tipo de Conta</LabelField>
              <TextField>
                {user?.userAccountBank && accountTypesObject[user?.userAccountBank?.account_type]}
              </TextField>
            </Field>
            <ButtonEditModal text="Editar" editClick={toggleThree} />
          </ContentDefault>
          <ProfileModalTwo user={user} visible={visibleTwo} onChange={toggleTwo} />
          <ProfileModalThree user={user} visible={visibleThree} onChange={toggleThree} />
        </>
      )}
      {master ? (
        <ProfileModalOneMaster user={user} visible={visibleOne} onChange={toggleOne} />
      ) : (
        <ProfileModalOne user={user} visible={visibleOne} onChange={toggleOne} />
      )}
      <ProfileModalAvatar user={user} visible={visibleAvatar} onChange={toggleAvatar} />
    </Container>
  );

  const loader = (
    <Col height="85vh" alignCenter justifyCenter>
      <Spinner w="50px" h="50px" color={verdeKuppi} />{' '}
    </Col>
  );

  return loading ? loader : contentPage;
}
