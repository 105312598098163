import styled from '@emotion/styled';

import { roxo } from '../../../styles/colors';

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  min-height: 100vh;

  /* background: ${roxo}; */
`;
