import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '~/components/Button';

import { updateLeadAddress } from '../../stores/modules/lead/actions';
import { getAddress } from '../../stores/modules/utils/actions';
import { CustomForm, Error, InputCustom, Label, SelectCustom } from '../../styles/common';
import { Col, Row } from '../../styles/grid';
import { statesOptions } from '../../utils/commonObjects';
import { cepMask } from '../../utils/masks';
import { onlyNumbers } from '../../utils/onlyNumbers';

export default function Address() {
  const dispatch = useDispatch();
  const lead = useSelector((state) => state.lead.lead);
  const loading = useSelector((state) => state.lead.address_loading);
  const receivedAddress = useSelector((state) => state.utils.received_address);
  const status = lead.funnel_status;

  const [zipCodeField, setZipCodeField] = useState(lead.zipcode);
  const [stateField, setStateField] = useState(lead.state);
  const [cityField, setCityField] = useState(lead.city);
  const [streetField, setStreetField] = useState(lead.street);
  const [neighborhoodField, setNeighborhoodField] = useState(lead.neighborhood);
  const [numberField, setNumberField] = useState(lead.number);
  const [complementField, setComplementField] = useState(lead.complement);

  useEffect(() => {
    if (zipCodeField) {
      if (zipCodeField.length === 10) {
        dispatch(getAddress(onlyNumbers(zipCodeField)));
      }
    }
  }, [zipCodeField, dispatch]);

  useEffect(() => {
    if (receivedAddress) {
      setStateField(receivedAddress.state);
      setCityField(receivedAddress.city);
      setStreetField(receivedAddress.street);
      setNeighborhoodField(receivedAddress.neighborhood);
      setNumberField('');
      setComplementField('');
    }
  }, [receivedAddress]);

  function handleSubmit({ zipcode, state, city, street, neighborhood, number, complement }) {
    dispatch(
      updateLeadAddress(
        onlyNumbers(zipcode),
        state,
        city,
        street,
        neighborhood,
        number,
        complement,
      ),
    );
  }

  return (
    <CustomForm onSubmit={handleSubmit}>
      <Col justifyStart alignStart>
        <Label>Cep</Label>
        <InputCustom
          required
          disabled={status === null}
          value={cepMask(zipCodeField)}
          onChange={(event) => setZipCodeField(cepMask(event.target.value))}
          type="text"
          name="zipcode"
        />
        <Error />
      </Col>
      <Col justifyStart alignStart>
        <Label>Estado</Label>
        <SelectCustom
          required
          disabled={status === null}
          options={statesOptions}
          value={stateField}
          onChange={(event) => setStateField(event.target.value)}
          name="state"
        />
        <Error />
      </Col>
      <Col justifyStart alignStart>
        <Label>Cidade</Label>
        <InputCustom
          required
          disabled={status === null}
          value={cityField}
          onChange={(event) => setCityField(event.target.value)}
          type="text"
          name="city"
        />
        <Error />
      </Col>
      <Col justifyStart alignStart>
        <Label>Rua</Label>
        <InputCustom
          required
          disabled={status === null}
          value={streetField}
          onChange={(event) => setStreetField(event.target.value)}
          type="text"
          name="street"
        />
        <Error />
      </Col>
      <Col justifyStart alignStart>
        <Label>Bairro</Label>
        <InputCustom
          required
          disabled={status === null}
          value={neighborhoodField}
          onChange={(event) => setNeighborhoodField(event.target.value)}
          type="text"
          name="neighborhood"
        />
        <Error />
      </Col>
      <Col justifyStart alignStart>
        <Label>Número</Label>
        <InputCustom
          required
          disabled={status === null}
          value={numberField}
          onChange={(event) => setNumberField(event.target.value)}
          type="text"
          name="number"
        />
        <Error />
      </Col>
      <Col justifyStart alignStart>
        <Label>Complemento</Label>
        <InputCustom
          type="text"
          disabled={status === null}
          value={complementField}
          onChange={(event) => setComplementField(event.target.value)}
          name="complement"
        />
        <Error />
      </Col>
      <Row>
        <Button
          isLoading={loading || status === null}
          padding="0"
          w="130px"
          height="40px"
          type="submit"
        >
          Salvar
        </Button>
      </Row>
      <Row padding="5px" />
    </CustomForm>
  );
}
