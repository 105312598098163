import { Input as ChakraInput, InputProps as ChakraInputProps } from '@chakra-ui/react';
import React from 'react';

interface CustomInputProps {
  register?: any;
  error?: any;
}

export type InputProps = ChakraInputProps & CustomInputProps;

const Input: React.FC<InputProps> = ({
  h = '37px',
  w = '100%',
  error,
  _focus = {
    borderColor: '#2BC48A',
    boxShadow: '0 0 0 1px #2BC48A',
  },
  register,
  ...rest
}) => {
  return <ChakraInput h={h} w={w} _focus={!error ? _focus : {}} ref={register} {...rest} />;
};

export default Input;
