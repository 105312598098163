import { Clients } from '~/models/Clients';

export function indexActiveClients() {
  return {
    type: '@clients/INDEX_ACTIVE_CLIENTS',
  };
}

export function indexInactiveClients() {
  return {
    type: '@clients/INDEX_INACTIVE_CLIENTS',
  };
}

export function setActiveClients(clients: Clients[]) {
  return {
    type: '@clients/SET_ACTIVE_CLIENTS',
    payload: { clients },
  };
}

export function setInactiveClients(clients: Clients[]) {
  return {
    type: '@clients/SET_INACTIVE_CLIENTS',
    payload: { clients },
  };
}

export function setActiveClientDetail(clients: Clients) {
  return {
    type: '@clients/SET_ACTIVE_CLIENT_DETAIL',
    payload: { clients },
  };
}

export function setInactiveClientDetail(clients: Clients) {
  return {
    type: '@clients/SET_INACTIVE_CLIENT_DETAIL',
    payload: { clients },
  };
}

export function endAction() {
  return {
    type: '@clients/END_ACTION',
  };
}
