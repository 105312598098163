import { apiGlobal } from '~/services/api';

interface validateUniqueProps {
  email?: string;
  phone?: string;
  facebook_user_id?: string;
  google_user_id?: string;
  advertiser_cnpj?: string;
}

export default async function validateUnique({
  email,
  phone,
  facebook_user_id,
  google_user_id,
  advertiser_cnpj,
}: validateUniqueProps) {
  try {
    const response = await apiGlobal.post('/validate/unique', {
      email,
      phone,
      facebook_user_id,
      google_user_id,
      advertiser_cnpj,
    });

    const status = response?.status;

    return status === 200;
  } catch (err) {
    return false;
  }
}
