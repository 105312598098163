import axios from 'axios';

const reactAppEnv = process.env.REACT_APP_ENV_ALL_API;
const reactAppEnvGlobal = process.env.REACT_APP_ENV_GLOBAL_API;
const reactAppEnvKuppido = process.env.REACT_APP_ENV_KUPPIDO_API;

const baseUrlsKuppido: { [key: string]: string } = {
  production: 'https://api.kuppi.com.br/kuppido',
  staging: 'https://stg-api.kuppi.com.br/kuppido',
  development: 'https://dev-api.kuppi.com.br/kuppido',
  qa: 'https://qa-api.kuppi.com.br/kuppido',
  local: 'http://localhost:3333/kuppido',
};

const baseUrlsGlobal: { [key: string]: string } = {
  production: 'https://api.kuppi.com.br/global',
  staging: 'https://stg-api.kuppi.com.br/global',
  development: 'https://dev-api.kuppi.com.br/global',
  qa: 'https://qa-api.kuppi.com.br/global',
  local: 'http://0.0.0.0:3334/global',
};

const baseUrlGlobal = baseUrlsGlobal[reactAppEnv || reactAppEnvGlobal];
const baseUrlKuppido = baseUrlsKuppido[reactAppEnv || reactAppEnvKuppido];

export const apiGlobal = axios.create({
  baseURL: baseUrlGlobal,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const apiKuppido = axios.create({
  baseURL: baseUrlKuppido,
  headers: {
    'Content-Type': 'application/json',
  },
});
