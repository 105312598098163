import styled from '@emotion/styled';

import { statusConfig } from './status';

export const Ball = styled.div`
  height: 13px;
  width: 13px;
  border-radius: 10px;
  background: ${(props) => props.status && statusConfig[props.status].color};
`;

export const Text = styled.div`
  margin-left: 5px;

  font-size: 14px;
  color: ${(props) => props.status && statusConfig[props.status].color};
`;
