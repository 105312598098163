import 'moment/locale/pt-br';
import 'react-toastify/dist/ReactToastify.css';

import { ConnectedRouter } from 'connected-react-router';
import React from 'react';

import AppContainer from './contexts';
import Routes from './routes';
import history from './services/history';

function App() {
  return (
    <AppContainer>
      <ConnectedRouter history={history}>
        <Routes />
      </ConnectedRouter>
    </AppContainer>
  );
}

export default App;
