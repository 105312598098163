export function setUserAvatar(avatar) {
  return {
    type: '@user/SET_USER_AVATAR',
    payload: { avatar },
  };
}

export function setIsDoneOnboarding() {
  return {
    type: '@user/SET_IS_DONE_ONBOARDING',
  };
}

export function getAllprofile() {
  return {
    type: '@user/GET_ALL_PROFILE',
  };
}

export function editPassword(password) {
  return {
    type: '@user/EDIT_PASSWORD',
    payload: { password },
  };
}

export function editProfile(name, cpf_cnpj, is_corporation) {
  return {
    type: '@user/EDIT_PROFILE',
    payload: { name, cpf_cnpj, is_corporation },
  };
}

export function editProfileOnboarding(name, cpf_cnpj, is_corporation) {
  return {
    type: '@user/EDIT_PROFILE_ONBOARDING',
    payload: { name, cpf_cnpj, is_corporation },
  };
}

export function editAddress(
  street,
  number,
  complement,
  zipcode,
  neighborhood,
  city,
  state,
  phone_1,
  phone_2,
  phone_whatsapp,
  atOnboarding = false,
) {
  return {
    type: '@user/EDIT_ADDRESS',
    payload: {
      street,
      number,
      complement,
      zipcode,
      neighborhood,
      city,
      state,
      phone_1,
      phone_2,
      phone_whatsapp,
      atOnboarding,
    },
  };
}

export function createBankAccount(
  bank_id,
  agency,
  agency_digit,
  account,
  account_digit,
  account_type,
  account_holder_name,
) {
  return {
    type: '@user/CREATE_BANK_ACCOUNT',
    payload: {
      bank_id,
      agency,
      agency_digit,
      account,
      account_digit,
      account_type,
      account_holder_name,
    },
  };
}

export function editBankAccount(
  bank_id,
  agency,
  agency_digit,
  account,
  account_digit,
  account_type,
  account_holder_name,
) {
  return {
    type: '@user/EDIT_BANK_ACCOUNT',
    payload: {
      bank_id,
      agency,
      agency_digit,
      account,
      account_digit,
      account_type,
      account_holder_name,
    },
  };
}

export function setUserInfo(userInfo) {
  return {
    type: '@user/SET_USER_INFO',
    payload: { userInfo },
  };
}

export function setUser(user) {
  return {
    type: '@user/SET_USER',
    payload: { user },
  };
}

export function setUserInfoMaster(userInfo) {
  return {
    type: '@user/SET_USER_INFO_MASTER',
    payload: { userInfo },
  };
}

export function setAddress(address) {
  return {
    type: '@user/SET_ADDRESS',
    payload: { address },
  };
}

export function setAccountBank(accountBank) {
  return {
    type: '@user/SET_ACCOUNT_BANK',
    payload: { accountBank },
  };
}

export function endAction() {
  return {
    type: '@user/END_ACTION',
  };
}

export function getAdvertiserCategoriesRequest() {
  return {
    type: '@user/GET_ADVERTISER_CATEGORIES_REQUEST',
  };
}

export function getAdvertiserCategoriesSuccess(categories) {
  return {
    type: '@user/GET_ADVERTISER_CATEGORIES_SUCCESS',
    payload: { categories },
  };
}

export function getAdvertiserCategoriesFailure() {
  return {
    type: '@user/GET_ADVERTISER_CATEGORIES_FAILURE',
  };
}

// TODO: Remove this function when all kuppido types are using the new
// way of term acceptance
export function setTermsAccepted() {
  return {
    type: '@user/SET_TERMS_ACCEPTED',
  };
}

export function fetchIpAddress() {
  return {
    type: '@user/FETCH_IP_ADDRESS',
  };
}

export function setIpAddress(ipAddress) {
  return {
    type: '@user/SET_IP_ADDRESS',
    payload: { ipAddress },
  };
}

export function getTermsAndPolicyRequest(termPolicyCode) {
  return {
    type: '@user/GET_TERMS_AND_POLICY_REQUEST',
    payload: { termPolicyCode },
  };
}

export function getTermsAndPolicySuccess(termPolicyContent) {
  return {
    type: '@user/GET_TERMS_AND_POLICY_SUCCESS',
    payload: { termPolicyContent },
  };
}

export function getTermsAndPolicyFailure() {
  return {
    type: '@user/GET_TERMS_AND_POLICY_FAILURE',
  };
}

export function acceptTermsAndPolicyRequest(termsId) {
  return {
    type: '@user/ACCEPT_TERMS_AND_POLICY_REQUEST',
    payload: {
      termsId,
    },
  };
}

export function setIsTermsTermsAndPolicyAccepted(isTermsAccepted) {
  return {
    type: '@user/SET_IS_TERMS_AND_POLICY_ACCEPTED',
    payload: { isTermsAccepted },
  };
}

export function endActionOnboarding(success) {
  return {
    type: '@user/END_ACTION_ONBOARDING',
    payload: { success },
  };
}
