import { Box } from '@chakra-ui/react';
import { Form } from '@rocketseat/unform';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { MdClear } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';

import { updateActivity } from '../../../stores/modules/lead/actions';
import { SelectCustom, TextareaCustom } from '../../../styles/common';
import { activityTypes } from '../../../utils/commonObjects';
import { dateTimeMask } from '../../../utils/masks';
import {
  CloseButton,
  ColumnContent,
  Container,
  InputCustom,
  Label,
  Modal,
  ModalButton,
  ModelTitle,
  RowContent,
} from '../styles';

export default function ProfileModalOne({ visible, onChange, item }) {
  const [modal, setModal] = useState(visible);
  const [dateField, setDateField] = useState(
    moment(item.activity_at).utcOffset('-00:00').format('DDMMYYYYHHmm'),
  );
  const lead_id = useSelector((state) => state.lead.lead.id);
  const dispatch = useDispatch();

  useEffect(() => {
    setModal(visible);
  }, [visible]);

  const handleVisible = () => onChange();

  const handleSubmit = ({ name, description, type, activity_at }) => {
    const activity_id = item.id;
    const activityAt = moment(activity_at, 'DD/MM/YYYY HH:mm', true).format('YYYY-MM-DD HH:mm');

    dispatch(updateActivity(activity_id, lead_id, name, description, type, activityAt));
  };

  return (
    <Container visible={modal}>
      <Modal modelEmail>
        <CloseButton onClick={handleVisible}>
          <Box as={MdClear} w="35px" h="35px" />
        </CloseButton>
        <ModelTitle>Alterar dados</ModelTitle> <br />
        <Form
          initialData={{
            name: item && item.name,
            description: item && item.description,
            type: item && item.type,
            activity_at: item && dateTimeMask(dateField),
          }}
          onSubmit={handleSubmit}
        >
          <ColumnContent>
            <Label>Titulo da atividade</Label>
            <InputCustom name="name" type="text" placeholder="Titulo da atividade" />
          </ColumnContent>
          <RowContent>
            <ColumnContent left>
              <Label>Tipo de Atividade</Label>
              <SelectCustom name="type" placeholder="Tipo da atividade" options={activityTypes} />
            </ColumnContent>

            <ColumnContent right>
              <Label>Data e Hora</Label>
              <InputCustom
                name="activity_at"
                type="text"
                maxLength="16"
                value={dateTimeMask(dateField)}
                onChange={(e) => setDateField(dateTimeMask(e.target.value))}
              />
            </ColumnContent>
          </RowContent>

          <RowContent>
            <ColumnContent>
              <Label>Informações adicionais</Label>
              <TextareaCustom
                name="description"
                multiline="true"
                placeholder="Informações adicionais"
              />
            </ColumnContent>
          </RowContent>

          <RowContent lastRow>
            <ColumnContent left button>
              <ModalButton outlineTheme onClick={handleVisible} type="button">
                Cancelar
              </ModalButton>
            </ColumnContent>

            <ColumnContent right button>
              <ModalButton type="submit">Confirmar</ModalButton>
            </ColumnContent>
          </RowContent>
        </Form>
      </Modal>
    </Container>
  );
}
