import { Switch } from '@chakra-ui/react';
import styled from '@emotion/styled';

export const CustomSwitch = styled(Switch)`
  :focus span {
    box-shadow: 0 0 0 3px #2bc48a60;
  }

  :focus span span {
    box-shadow: 0 0 0 3px #2bc48a60;
  }

  span:focus {
    box-shadow: 0 0 0 3px #2bc48a60;
  }

  span:focus[data-focus] {
    box-shadow: 0 0 0 3px #2bc48a60;
  }

  span[data-focus] {
    box-shadow: 0 0 0 3px #2bc48a60;
  }

  span span:focus {
    box-shadow: 0 0 0 3px #2bc48a60;
  }
`;
