import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';

import {
  acceptTermsAndPolicyRequest,
  fetchIpAddress,
  getTermsAndPolicyRequest,
} from '../../stores/modules/user/actions';
import { cinzaClaro3, preto } from '../../styles/colors';
import { LoadingBox, SubmitButton, Text } from '../../styles/common';
import { Col, Row } from '../../styles/grid';
import { Container, Modal, ModelTitle, TermsContent } from './styles';

export default function TermAndPolicies({ visible }) {
  const [modal, setModal] = useState(visible);
  const [termsContent, setTermsContent] = useState(null);
  const user = useSelector((state) => state.user);
  const { isKuppidoX } = useSelector((state) => state.auth);
  const { loadingGetTermsAndPolicy, successGetTermsAndPolicy, loadingAcceptTermsOfUse } = user;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchIpAddress());
    dispatch(getTermsAndPolicyRequest(isKuppidoX ? 'TUKX' : 'TUK'));
  }, [dispatch]);

  useEffect(() => {
    if (successGetTermsAndPolicy) {
      setTermsContent(user.terms.term_policy_content);
    }
  }, [successGetTermsAndPolicy]);

  const handleOnConfirm = () => {
    if (user.terms) {
      dispatch(acceptTermsAndPolicyRequest(user.terms.id));
    }
  };

  useEffect(() => {
    setModal(visible);
  }, [visible]);

  const contentTerms = (
    <Container visible={modal}>
      <Modal width="600px" height="570px">
        <Col padding="20px 30px" height="auto">
          <ModelTitle>Termos de uso</ModelTitle>
          <Text size="14px" regular>
            Para acessar a Plataforma Kuppido, é necessário aceitar nossos termos e condições de
            uso.
            <br />
          </Text>
        </Col>
        <Col
          padding="20px 30px"
          height="350px"
          overflowY
          background={cinzaClaro3}
          alignStart
          justifyStart
        >
          {!termsContent ? (
            <Spinner
              className="m-auto"
              style={{
                width: '1.8rem',
                height: '1.8rem',
                color: preto,
              }}
            />
          ) : (
            <TermsContent>{termsContent}</TermsContent>
          )}
        </Col>
        <Row className="mt-auto" padding="30px 30px" height="auto" alignCenter justifyStart>
          <Row width="auto" height="auto" className="ml-auto">
            <SubmitButton
              onClick={() => {
                if (!loadingAcceptTermsOfUse || !loadingGetTermsAndPolicy) {
                  handleOnConfirm();
                }
              }}
              disabled={loadingAcceptTermsOfUse || loadingGetTermsAndPolicy}
            >
              {loadingAcceptTermsOfUse ? (
                <LoadingBox>
                  <Spinner
                    style={{
                      width: '1.8rem',
                      height: '1.8rem',
                      color: preto,
                    }}
                  />{' '}
                </LoadingBox>
              ) : (
                'Aceitar termos'
              )}
            </SubmitButton>
          </Row>
        </Row>
      </Modal>
    </Container>
  );

  return contentTerms;
}
