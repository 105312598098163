import { Form } from '@rocketseat/unform';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  editProfileOnboarding,
  setIsDoneOnboarding,
  setTermsAccepted,
} from '../../stores/modules/user/actions';
import { cleanAddress } from '../../stores/modules/utils/actions';
import { cinzaClaro3 } from '../../styles/colors';
import { InputCustom, Label, SubmitButton, Text } from '../../styles/common';
import { Col, Row } from '../../styles/grid';
import { cnpjMask, cpfMask } from '../../utils/masks';
import { onlyNumbers } from '../../utils/onlyNumbers';
import CustomCheckBox from '../CustomCheckBox';
import { Ball, Container, Modal, ModelTitle } from './styles';

export default function TermAndPoliciesInitialCommercialTeam({ visible }) {
  const user = useSelector((state) => state.user);
  const [userState, setUserState] = useState(user);
  const [modal, setModal] = useState(visible);
  const dispatch = useDispatch();

  const [Content, setContent] = useState('terms');
  const toggleContent = (newContent) => setContent(newContent);

  /** ********* User Info ********** */
  const [cpfCnpj, setcpfCnpj] = useState();
  const [isCorporationField, setIsCorporationField] = useState();
  const [isIndividualField, setIsIndividualField] = useState();

  useEffect(() => {
    if (user?.userInfo?.is_corporation) {
      setcpfCnpj(cnpjMask(user?.userInfo?.cpf_cnpj));
    } else {
      setcpfCnpj(cpfMask(user?.userInfo?.cpf_cnpj));
    }
  }, [user?.userInfo]);

  const toggleToCorporation = () => {
    setIsCorporationField(true);
    setIsIndividualField(false);
    setcpfCnpj('');
  };

  const toggleToIndividual = () => {
    setIsCorporationField(false);
    setIsIndividualField(true);
    setcpfCnpj('');
  };

  useEffect(() => {
    setUserState(user);
    if (user?.userInfo?.is_corporation === true) {
      setIsCorporationField(true);
      setIsIndividualField(false);
    } else {
      setIsCorporationField(false);
      setIsIndividualField(true);
    }
  }, [user]);

  useEffect(() => {
    setModal(visible);
  }, [visible]);

  useEffect(() => {
    setUserState(user);
  }, [user]);

  const handleInfoSubmit = ({ name, cpf_cnpj, is_corporation }) => {
    if (name || cpf_cnpj || is_corporation) {
      dispatch(editProfileOnboarding(name, onlyNumbers(cpf_cnpj), is_corporation));
      dispatch(setIsDoneOnboarding());
      dispatch(setTermsAccepted());
      dispatch(cleanAddress());
    }
  };

  const contentTerms = (
    <Container visible={modal}>
      <Modal width="600px" height="570px">
        <Col padding="20px 30px" height="auto">
          <ModelTitle>Termos de uso</ModelTitle>
          <Text size="14px" regular>
            Para acessar A Plataforma Kuppido, é necessário aceitar nossos termos e condições de
            uso.
            <br />
          </Text>
        </Col>
        <Col
          padding="20px 30px"
          height="350px"
          overflowY
          background={cinzaClaro3}
          alignStart
          justifyStart
        >
          <p>TERMOS DE USO</p>

          <p>Prezado (a)</p>

          <br />

          <p>
            Estes Termos de Uso disciplinar&atilde;o a rela&ccedil;&atilde;o entre a Plataforma do
            Kuppido e Voc&ecirc;s.
          </p>

          <p>
            Para utilizar os servi&ccedil;os da Plataforma, Voc&ecirc; dever&aacute; concordar e
            aceitar os Termos de Uso
          </p>

          <p>e a Pol&iacute;tica de Privacidade.</p>

          <br />

          <p>- CONDI&Ccedil;&Otilde;ES GERAIS -</p>

          <p>
            1.1. &Acirc;mbito. Os presentes Termos e Condi&ccedil;&otilde;es Gerais de Uso
            (denominado somente
          </p>

          <p>
            &ldquo;Termos de Uso&rdquo; ou &ldquo;Termos&rdquo;) vinculam todas as atividades
            desenvolvidas e servi&ccedil;os
          </p>

          <p>
            oferecidos por N&oacute;s (doravante assim denominada ou somente
            &ldquo;Plataforma&rdquo;), que engloba
          </p>

          <p>
            o website e seus subdom&iacute;nios, aplicativos, programas e demais extens&otilde;es. A
            Plataforma &eacute;
          </p>

          <p>de propriedade da KUPIDO TECNOLOGIA LTDA., sociedade inscrita no CNPJ sob o n&ordm;</p>

          <p>
            37.246.660/0001-67, com sede na Rua Tapaj&oacute;s, n&ordm; 281, S&atilde;o Francisco,
            Niter&oacute;i/RJ CEP:
          </p>

          <p>24.360-200, e est&aacute; sob sua responsabilidade e gest&atilde;o.</p>

          <br />

          <p>
            1.2. Atua&ccedil;&atilde;o. N&oacute;s somos uma solu&ccedil;&atilde;o digital que
            permite que Voc&ecirc;s realizem o controle
          </p>

          <p>
            de suas vendas e cadastrem seus potenciais leads. Al&eacute;m disso, por meio da
            Plataforma, &eacute;
          </p>

          <p>
            poss&iacute;vel gerenciar seus ganhos. Para isso, permitimos a
            visualiza&ccedil;&atilde;o dos receb&iacute;veis futuros
          </p>

          <p>e possibilitamos a antecipa&ccedil;&atilde;o dos mesmos.</p>

          <br />

          <p>
            1.3. Sujeitos. &ldquo;Voc&ecirc;s&rdquo; ou &ldquo;Kuppidos&rdquo; s&atilde;o Pessoas
            F&iacute;sicas ou Jur&iacute;dicas respons&aacute;veis por
          </p>

          <p>comercializar a Plataforma Kuppi, recebendo, para isso uma comiss&atilde;o sobre as</p>

          <p>mensalidades de cada venda realizada durante todo o per&iacute;odo em que o cliente</p>

          <p>
            permanecer ativo, inclusive em casos de renova&ccedil;&otilde;es de pacotes de
            mensalidades.
          </p>

          <br />

          <p>
            1.3.1. A comiss&atilde;o para Pessoas F&iacute;sicas ser&aacute; de 40% (quarenta por
            cento) sobre o
          </p>

          <p>valor de todas as mensalidades decorrentes de uma venda, enquanto as Pessoas</p>

          <p>Jur&iacute;dicas receber&atilde;o 60% (sessenta por cento) do valor.</p>

          <br />

          <p>
            1.3.2. Com o expresso consentimento da KUPPI, Voc&ecirc; poder&aacute; ceder e
            transferir
          </p>

          <p>
            sem &ocirc;nus, os direitos e obriga&ccedil;&otilde;es decorrentes do presente Contrato
            a uma
          </p>

          <p>
            sociedade empres&aacute;ria , fazendo jus ao recebimento da Comiss&atilde;o disposta no
          </p>

          <p>item 1.3.1.</p>

          <br />

          <p>1.4. Ades&atilde;o e Aceite. Pelo presente instrumento, Voc&ecirc;s concordam que:</p>

          <p>
            (i) Dever&atilde;o aceitar nossos Termos de Uso e Pol&iacute;tica de Privacidade, para
            fins de
          </p>

          <p>cadastro, controle de acesso e execu&ccedil;&atilde;o de melhorias na Plataforma;</p>

          <p>
            (ii) Clicando na caixa de aceita&ccedil;&atilde;o dos Termos devem aderir e concordar em
            se
          </p>

          <p>submeter ao disposto nos presentes Termos;</p>

          <br />

          <p>
            1.5. Viola&ccedil;&atilde;o das Condi&ccedil;&otilde;es. Na eventualidade de Voc&ecirc;
            descumprir qualquer das
          </p>

          <p>
            condi&ccedil;&otilde;es aqui previstas, N&oacute;s nos reservamos o direito de encerrar
            suas respectivas
          </p>

          <p>
            contas e suspender ou anular seu acesso &agrave; Plataforma, sem necessidade de aviso
            pr&eacute;vio.
          </p>

          <br />

          <p>
            1.6. Modifica&ccedil;&otilde;es. N&oacute;s nos reservamos o direito de, a qualquer
            momento, modificar os
          </p>

          <p>Termos de Uso, sempre com o envio de avisos para sua ci&ecirc;ncia, sem qualquer</p>

          <p>responsabiliza&ccedil;&atilde;o da KUPIDO TECNOLOGIA LTDA. devido a:</p>

          <br />

          <p>
            1.6.1. Quaisquer modifica&ccedil;&otilde;es efetuadas por N&oacute;s na plataforma, que
            entrar&atilde;o em
          </p>

          <p>vigor imediatamente ap&oacute;s sua publica&ccedil;&atilde;o online.</p>

          <br />

          <p>- MODO DE UTILIZA&Ccedil;&Atilde;O -</p>

          <p>
            2.1. Din&acirc;mica. Para acessar a Plataforma do Kuppido, Voc&ecirc;s dever&atilde;o
            adquirir o pacote
          </p>

          <p>
            de acesso ao Curso-Neg&oacute;cio atrav&eacute;s de compra efetuada via cart&atilde;o de
            cr&eacute;dito ou boleto
          </p>

          <p>
            banc&aacute;rio. Ap&oacute;s confirma&ccedil;&atilde;o do pagamento, Voc&ecirc;s
            receber&atilde;o por email, o acesso ao curso
          </p>

          <p>
            em que ser&aacute; explicado didaticamente a din&acirc;mica de venda da Plataforma
            Kuppi, al&eacute;m de
          </p>

          <p>
            conte&uacute;dos sobre vendas, e tamb&eacute;m o link para acessar a Plataforma do
            Kuppido para
          </p>

          <p>
            utiliza&ccedil;&atilde;o de suas funcionalidades e come&ccedil;ar a realizar suas
            vendas.
          </p>

          <br />

          <p>2.1.1. Em todo primeiro contrato comercializado com um Anunciante, at&eacute; 20%</p>

          <p>
            do valor total do pacote contratado poder&aacute; ser revertido em cr&eacute;ditos para
          </p>

          <p>
            impulsionamento publicit&aacute;rio do mesmo atrav&eacute;s da funcionalidade Kuppi
            Boost,
          </p>

          <p>visando potencializar seus resultados iniciais. Para isso, Voc&ecirc; concorda que</p>

          <p>
            metade deste percentual (10%) ser&aacute; deduzido da comiss&atilde;o a que Voc&ecirc;
            teria
          </p>

          <p>direito ao realizar a venda, enquanto a outra metade (10%) ser&aacute; deduzido dos</p>

          <p>valores recebidos por N&oacute;s.</p>

          <br />

          <p>
            2.1.2. A comiss&atilde;o ser&aacute; sempre devida sobre o valor bruto das mensalidades
          </p>

          <p>pagas pelos Anunciantes, quando houver o efetivo pagamento por parte das</p>

          <p>mesmas.</p>

          <br />

          <p>
            2.1.3. N&oacute;s pagaremos &agrave; Voc&ecirc;s o saldo de comiss&otilde;es apurado no
            &uacute;ltimo dia do
          </p>

          <p>m&ecirc;s vencido, referentes aos recebimentos efetivados pelos Anunciantes,</p>

          <p>considerando o prazo de 2 (dois) dias para pagamentos realizados via boleto</p>

          <p>
            banc&aacute;rio e 30 (trinta) dias para pagamentos realizados via cart&atilde;o de
            cr&eacute;dito, n&atilde;o
          </p>

          <p>
            sendo devido qualquer valor em fun&ccedil;&atilde;o de eventual inadimpl&ecirc;ncia do
          </p>

          <p>Anunciante.</p>

          <br />

          <p>2.1.4. O recebimento dos pagamentos dos Anunciantes ocorre por</p>

          <p>plataforma de pagamento terceirizada integrada &agrave; Plataforma da Kuppi, que</p>

          <p>possui suas pr&oacute;prias regras e taxas cobradas de forma independente. A Kuppi</p>

          <p>
            assumir&aacute; todas as taxas da opera&ccedil;&atilde;o, com exce&ccedil;&atilde;o das
            taxas de saque ou de
          </p>

          <p>
            antecipa&ccedil;&atilde;o de recebimentos. Essas dever&atilde;o ser consultadas por
            Voc&ecirc;s no
          </p>

          <p>momento da efetiva transa&ccedil;&atilde;o.</p>

          <br />

          <p>2.1.5 A Kuppi n&atilde;o se responsabiliza por prazos estipulados por plataformas</p>

          <p>de terceiros, que poder&atilde;o ser alterados a exclusivo crit&eacute;rio de sua</p>

          <p>
            propriet&aacute;ria. Eventuais questionamentos ou d&uacute;vidas de Voc&ecirc;s
            dever&atilde;o ser
          </p>

          <p>
            enviados &agrave; Kuppi, que realizar&aacute; a intermedia&ccedil;&atilde;o junto
            &agrave; plataforma com a
          </p>

          <p>qual realiza integra&ccedil;&otilde;es.</p>

          <br />

          <p>
            2.1.6. As comiss&otilde;es n&atilde;o ser&atilde;o devidas em casos de pedidos
            cancelados ou
          </p>

          <p>recusados por N&oacute;s, quando ocorrer Chargeback e quando o cancelamento ou</p>

          <p>
            recusa houver sido manifestado no prazo de at&eacute; 10 (dez) dias &uacute;teis a
            partir da
          </p>

          <p>
            apresenta&ccedil;&atilde;o da proposta comercial aprovada e assinada pelo Anunciante.
          </p>

          <br />

          <p>
            2.1.6.1. Para todos os fins, considera-se &ldquo;Chargeback&rdquo; a
            solicita&ccedil;&atilde;o, pelo
          </p>

          <p>
            comprador, do cancelamento da compra &agrave; operadora do cart&atilde;o de
            cr&eacute;dito.
          </p>

          <p>Nesse caso, a plataforma respons&aacute;vel por processar os pagamentos retira o</p>

          <p>valor da Nossa conta.</p>

          <br />

          <p>
            2.1.7. Caso ocorra Chargeback provocado pelo Anunciante, N&oacute;s nos reservamos o
          </p>

          <p>
            direito de realizar o devido desconto do valor recebido por Voc&ecirc;s na
            comiss&atilde;o
          </p>

          <p>subsequente a esta ocorr&ecirc;ncia.</p>

          <br />

          <p>
            2.2. Incorre&ccedil;&atilde;o de Informa&ccedil;&otilde;es Cadastrais. Voc&ecirc;, para
            utilizar os Servi&ccedil;os da
          </p>

          <p>
            Plataforma, dever&aacute; prestar as informa&ccedil;&otilde;es exigidas no momento do
            cadastro, conforme
          </p>

          <p>disposto na Pol&iacute;tica de Privacidade.</p>

          <br />

          <p>
            2.2.1. N&oacute;s nos reservamos o direito de impedir, a Nosso crit&eacute;rio, novos
            cadastros,
          </p>

          <p>
            ou cancelar os j&aacute; efetuados, em caso de ser detectada anomalia que considere, em
          </p>

          <p>
            Nossa an&aacute;lise, revestida de erro grave ou demonstre tentativa deliberada de
            burlar
          </p>

          <p>as regras aqui descritas, obrigat&oacute;rias para todos Voc&ecirc;s.</p>

          <br />

          <p>
            2.3. Uso de Cadastro por Terceiros. Os cadastros na plataforma s&atilde;o individuais e
          </p>

          <p>
            intransfer&iacute;veis, n&atilde;o sendo a KUPIDO TECNOLOGIA LTDA. respons&aacute;vel
            por quaisquer
          </p>

          <p>
            preju&iacute;zos ou danos oriundos do uso de &ldquo;login&rdquo; e senha por terceiro,
            com ou sem o
          </p>

          <p>consentimento do usu&aacute;rio.</p>

          <br />

          <p>
            2.3.1. Voc&ecirc;s dever&atilde;o: (i) Ser cuidadosos com os dados de sua
            identifica&ccedil;&atilde;o
          </p>

          <p>individual sempre que acessarem a Internet; (ii) Tomar outras medidas</p>

          <p>
            necess&aacute;rias para se protegerem de danos, inclusive fraudes online e of -line;
            (iii)
          </p>

          <p>
            Ser diligentes no uso do conte&uacute;do disponibilizado na Nossa Plataforma, sabendo
          </p>

          <p>
            de suas responsabilidades; e (iv) Cumprir rigorosamente todas as
            determina&ccedil;&otilde;es
          </p>

          <p>destes Termos de Uso.</p>

          <br />

          <p>
            2.4. Obriga&ccedil;&otilde;es de Voc&ecirc;s. &Eacute; de obriga&ccedil;&atilde;o de
            Voc&ecirc;s:
          </p>

          <p>
            a) N&atilde;o utilizar a Nossa Plataforma para qualquer prop&oacute;sito que seja
            il&iacute;cito ou
          </p>

          <p>proibido pelos termos de uso e/ou pelas normas aplic&aacute;veis;</p>

          <p>
            b) Autorizar que N&oacute;s possamos enviar comunica&ccedil;&otilde;es por e-mail,
            telefone ou
          </p>

          <p>mensagens instant&acirc;neas, inclusive de car&aacute;ter publicit&aacute;rio;</p>

          <p>c) Apresentar comportamento honesto e de boa f&eacute;;</p>

          <p>
            d) Informar corretamente e com precis&atilde;o todos os seus dados, para fins
            cadastrais.
          </p>

          <p>
            e) Seguir orienta&ccedil;&otilde;es e eventual manual da marca da KUPPI para realizar
          </p>

          <p>
            qualquer divulga&ccedil;&atilde;o da marca ou nome da KUPPI, inclusive em cart&otilde;es
            de
          </p>

          <p>visita, e-mails, dom&iacute;nios, m&iacute;dias sociais ou quaisquer outra forma de</p>

          <p>divulga&ccedil;&atilde;o que fa&ccedil;a refer&ecirc;ncia &agrave; KUPPI.</p>

          <p>
            f) Manter-se ativo no cadastro de novos anunciantes . Caso Voc&ecirc; n&atilde;o realize
          </p>

          <p>novos cadastros de anunciante na Plataforma no prazo total de 120 (cento e</p>

          <p>
            vinte) dias, a KUPPI poder&aacute;, a seu exclusivo crit&eacute;rio, retirar o acesso
            &agrave;
          </p>

          <p>
            Plataforma e &agrave;s comiss&otilde;es dos anunciantes que cadastrou previamente, de
          </p>

          <p>forma definitiva.</p>

          <br />

          <p>2.5. Obriga&ccedil;&otilde;es da KUPPI. &Eacute; Nossa obriga&ccedil;&atilde;o:</p>

          <p>
            a) Disponibilizar os servi&ccedil;os da Plataforma de forma segura e est&aacute;vel;
          </p>

          <p>
            b) Manter, segundo nosso julgamento e possibilidades de mercado, a
            atualiza&ccedil;&atilde;o
          </p>

          <p>
            constante da plataforma visando seu aperfei&ccedil;oamento e adapta&ccedil;&atilde;o
            &agrave;s novas
          </p>

          <p>tecnologias dispon&iacute;veis;</p>

          <p>
            c) Proteger a confidencialidade de todas as informa&ccedil;&otilde;es de Voc&ecirc;s;
          </p>

          <p>
            d) A KUPPI se obriga a fornecer ao KUPPIDO os documentos necess&aacute;rios &agrave;
          </p>

          <p>promo&ccedil;&atilde;o de vendas dos seus produtos e/ou servi&ccedil;os.</p>

          <p>
            2.6. Idoneidade. Voc&ecirc;s declaram, ao consentir com este documento para utilizar a
          </p>

          <p>
            Plataforma, que det&eacute;m idoneidade &iacute;ntegra, ficando ciente, desde j&aacute;,
            que as informa&ccedil;&otilde;es
          </p>

          <p>
            cadastrais falsas ou inver&iacute;dicas registradas ou fornecidas durante o processo
            eletr&ocirc;nico de
          </p>

          <p>contrata&ccedil;&atilde;o de servi&ccedil;os podem constituir crime.</p>

          <br />

          <p>- ACESSO E RESTRI&Ccedil;&Otilde;ES DE FUNCIONALIDADE -</p>

          <p>
            3.1. Restri&ccedil;&otilde;es. N&atilde;o &eacute; permitido acessar Nossas &aacute;reas
            de programa&ccedil;&atilde;o, seu banco de
          </p>

          <p>
            dados ou qualquer outro conjunto de informa&ccedil;&otilde;es que fa&ccedil;a parte da
            atividade de
          </p>

          <p>
            webmastering, realizar ou permitir que se realize qualquer tipo de engenharia reversa,
          </p>

          <p>
            tradu&ccedil;&atilde;o, decompila&ccedil;&atilde;o, c&oacute;pia,
            modifica&ccedil;&atilde;o, reprodu&ccedil;&atilde;o, loca&ccedil;&atilde;o,
            subloca&ccedil;&atilde;o,
          </p>

          <p>
            sublicenciamento, publica&ccedil;&atilde;o, divulga&ccedil;&atilde;o,
            transmiss&atilde;o, empr&eacute;stimo, distribui&ccedil;&atilde;o ou, de
          </p>

          <p>
            qualquer outra forma, a disposi&ccedil;&atilde;o de ferramentas de consulta deste
            website e de suas
          </p>

          <p>
            funcionalidades para terceiros sem a nossa pr&eacute;via e expressa
            autoriza&ccedil;&atilde;o, ficando sujeito
          </p>

          <p>
            quem o fizer a penalidades da legisla&ccedil;&atilde;o brasileira, sem preju&iacute;zo
            da obriga&ccedil;&atilde;o de reparar
          </p>

          <p>
            os danos que causarem. Esta restri&ccedil;&atilde;o inclui qualquer tentativa de
            incorporar quaisquer
          </p>

          <p>
            informa&ccedil;&otilde;es da plataforma em qualquer outro diret&oacute;rio, produto ou
            servi&ccedil;o.
          </p>

          <br />

          <p>- REMUNERA&Ccedil;&Atilde;O E CANCELAMENTO&ndash;</p>

          <p>
            4.1. Remunera&ccedil;&atilde;o. Para utilizar as funcionalidades oferecidas pela
            Plataforma, Voc&ecirc;
          </p>

          <p>
            dever&aacute; contratar apenas o pacote de acesso ao Curso-Neg&oacute;cio da Kuppi, no
            valor
          </p>

          <p>
            estabelecido pela KUPIDO TECNOLOGIA LTDA.. Ap&oacute;s isso n&atilde;o haver&aacute;
            cobran&ccedil;as
          </p>

          <p>recorrentes.</p>

          <br />

          <p>
            4.2. Exerc&iacute;cio do Direito de Arrependimento. Voc&ecirc; poder&aacute; desistir da
            contrata&ccedil;&atilde;o em
          </p>

          <p>
            at&eacute; 7 (sete) dias &uacute;teis a contar da data em que a compra foi realizada,
            recebendo os valores
          </p>

          <p>pagos de volta em sua integralidade.</p>

          <br />

          <p>
            4.3. Cancelamento por Voc&ecirc;. Caso Voc&ecirc; cancele seu cadastro na Plataforma,
            todos os
          </p>

          <p>
            seus registros ser&atilde;o exclu&iacute;dos, ressalvadas as hip&oacute;teses em que
            haja a necessidade de
          </p>

          <p>
            reten&ccedil;&atilde;o dos registros para cumprimento de obriga&ccedil;&atilde;o legal
            ou regulat&oacute;ria.
          </p>

          <br />

          <p>
            4.3.1. Ao cancelar seu cadastro na Plataforma, os receb&iacute;veis futuros a que
            Voc&ecirc;
          </p>

          <p>
            teria direito, referente &agrave;s comiss&otilde;es decorrentes do ingresso de
            Anunciantes na
          </p>

          <p>
            Kuppi por seu interm&eacute;dio, ser&atilde;o destinados, em sua totalidade, &agrave;
            KUPIDO
          </p>

          <p>TECNOLOGIA LTDA..</p>

          <br />

          <p>- EXCLUS&Atilde;O DE GARANTIAS -</p>

          <p>
            5.1. Disponibilidade. A Plataforma est&aacute; dispon&iacute;vel para uso de Voc&ecirc;s
            e &eacute; oferecida &quot;no
          </p>

          <p>
            estado em que se encontra&quot; e &quot;conforme dispon&iacute;vel&quot;. N&oacute;s
            n&atilde;o garantimos: (i) a
          </p>

          <p>
            comerciabilidade da Plataforma;, (ii) que as fun&ccedil;&otilde;es contidas na
            Plataforma atender&atilde;o &agrave;s
          </p>

          <p>
            necessidades dos Anunciantes; (iii) retorno financeiro &agrave; Voc&ecirc;s; (iv) que os
            servi&ccedil;os ser&atilde;o
          </p>

          <p>
            fornecidos sem interrup&ccedil;&otilde;es ou erros. Voc&ecirc; aceita que o risco
            decorrente da utiliza&ccedil;&atilde;o da
          </p>

          <p>Plataforma &eacute; da sua exclusiva responsabilidade..</p>

          <br />

          <p>- LIMITA&Ccedil;&Atilde;O DE RESPONSABILIDADE -</p>

          <p>
            6.1. N&oacute;s responderemos exclusiva e limitadamente pelos servi&ccedil;os por si
            prestados
          </p>

          <br />

          <p>
            6.1.1. N&oacute;s reiteramos que n&atilde;o constitu&iacute;mos com nenhum de
            Voc&ecirc;s: (i) sociedade,
          </p>

          <p>
            associa&ccedil;&atilde;o ou representa&ccedil;&atilde;o; (ii) presta&ccedil;&atilde;o de
            qualquer servi&ccedil;o diverso do objeto
          </p>

          <p>deste instrumento particular, e/ou (iii) rela&ccedil;&atilde;o empregat&iacute;cia.</p>

          <br />

          <p>
            6.2. Responsabilidade. N&oacute;s n&atilde;o seremos respons&aacute;veis por quaisquer
            perdas ou danos
          </p>

          <p>
            sofridos, resultantes de: (i) qualquer informa&ccedil;&atilde;o errada ou incompleta que
            seja fornecida
          </p>

          <p>
            por Voc&ecirc;; (ii) qualquer fraude, declara&ccedil;&atilde;o fraudulenta ou
            viola&ccedil;&atilde;o do dever, ou viola&ccedil;&atilde;o de
          </p>

          <p>
            qualquer uma das condi&ccedil;&otilde;es por Voc&ecirc;; (iii) falhas nas
            conex&otilde;es; (iv) problemas no
          </p>

          <p>
            processamento de dados por culpa de terceiros; (v) produtos ou servi&ccedil;os de
            terceiros,
          </p>

          <p>mesmo que adquiridos atrav&eacute;s da Plataforma.</p>

          <br />

          <p>
            6.3. Viola&ccedil;&atilde;o de Direito de Terceiros. N&oacute;s n&atilde;o nos
            responsabilizamos perante Voc&ecirc;s em
          </p>

          <p>rela&ccedil;&atilde;o a qualquer viola&ccedil;&atilde;o de direito de terceiros.</p>

          <br />

          <p>
            6.4. Danos. Em nenhum caso, N&oacute;s seremos respons&aacute;veis por danos indiretos,
            incidentais,
          </p>

          <p>
            especiais, exemplares, punitivos ou consequentes, incluindo, danos pessoais ou &agrave;
          </p>

          <p>propriedade, relacionados com, ou de outra forma resultantes, de qualquer uso da</p>

          <p>
            plataforma que Voc&ecirc; possa sofrer devido &agrave;s a&ccedil;&otilde;es realizadas
            ou n&atilde;o realizadas atrav&eacute;s
          </p>

          <p>desta, ainda que decorrentes da conduta de terceiros.</p>

          <br />

          <p>
            6.5. Indeniza&ccedil;&atilde;o. Voc&ecirc;s indenizar&atilde;o a KUPIDO TECNOLOGIA
            LTDA., diretores,
          </p>

          <p>
            administradores, colaboradores, representantes e empregados por preju&iacute;zos
            reclamados
          </p>

          <p>
            por terceiros decorrentes de suas atividades na Plataforma, e pelo descumprimento dos
          </p>

          <p>
            termos de uso e condi&ccedil;&otilde;es e demais pol&iacute;ticas da mesma, e ainda pela
            viola&ccedil;&atilde;o de qualquer
          </p>

          <p>lei ou direitos de terceiros, incluindo honor&aacute;rios advocat&iacute;cios.</p>

          <br />

          <p>- INATIVA&Ccedil;&Atilde;O DO CADASTRO, D&Uacute;VIDAS E SUGEST&Otilde;ES -</p>

          <p>
            7.1. Inativa&ccedil;&atilde;o Do Cadastro, D&uacute;vidas e Sugest&otilde;es. Em caso de
            inativa&ccedil;&atilde;o da conta,
          </p>

          <p>
            d&uacute;vida ou sugest&otilde;es sobre nossos Termos de Uso ou qualquer outra
            informa&ccedil;&atilde;o, Voc&ecirc;
          </p>

          <p>
            poder&aacute; entrar em contato conosco atrav&eacute;s do suporte via chat oferecido na
            Plataforma.
          </p>

          <br />

          <p>
            7.2. Veda&ccedil;&atilde;o e inativa&ccedil;&atilde;o de cadastro. Como forma de manter
            a confian&ccedil;a e respeito
          </p>

          <p>
            nesta Plataforma, ser&atilde;o vedados atos ilegais ou incompat&iacute;veis com a
            proposta de zelar
          </p>

          <p>
            pela presta&ccedil;&atilde;o dos servi&ccedil;os de qualidade. Assim, poder&aacute;
            resultar em suspens&atilde;o ou
          </p>

          <p>
            inativa&ccedil;&atilde;o do seu cadastro se Voc&ecirc; incorrer em qualquer das
            hip&oacute;teses elencadas abaixo:
          </p>

          <p>
            (i) Promo&ccedil;&atilde;o da viol&ecirc;ncia e/ou discrimina&ccedil;&atilde;o, baseada
            em quest&otilde;es de ra&ccedil;a, sexo,
          </p>

          <p>
            religi&atilde;o, nacionalidade, orienta&ccedil;&atilde;o sexual ou de qualquer outro
            tipo;
          </p>

          <p>
            (ii) Viola&ccedil;&atilde;o de quais leis, principalmente no que tange aos direitos
            autorais,
          </p>

          <p>
            propriedade intelectual, tais como c&oacute;pias n&atilde;o autorizadas,
            utiliza&ccedil;&atilde;o de
          </p>

          <p>
            imagens, sons, movimentos ou textos sem autoriza&ccedil;&atilde;o do autor, sejam elas
          </p>

          <p>marcas, r&eacute;plicas e/ou falsifica&ccedil;&otilde;es;</p>

          <p>
            (iii) Viola&ccedil;&atilde;o do disposto na Pol&iacute;tica de Privacidade da
            Plataforma.
          </p>

          <br />

          <p>- ENGENHARIA DA PLATAFORMA -</p>

          <p>
            8.1. Aperfei&ccedil;oamento. Voc&ecirc;s concordam que todo e qualquer sistema,
            plataforma ou
          </p>

          <p>
            servi&ccedil;o, independentemente de seu produtor ou caracter&iacute;sticas, &eacute;
            uma esp&eacute;cie de produto
          </p>

          <p>que est&aacute; sempre em atualiza&ccedil;&atilde;o e aperfei&ccedil;oamento.</p>

          <br />

          <p>
            8.2. Erros de Funcionamento. Eventuais erros no funcionamento da Plataforma ser&atilde;o
          </p>

          <p>
            corrigidos o mais breve poss&iacute;vel, durante o per&iacute;odo que for
            necess&aacute;rio para manuten&ccedil;&atilde;o.
          </p>

          <p>
            N&oacute;s n&atilde;o pretendemos que o funcionamento do servidor da Plataforma, bem
            como de seu
          </p>

          <p>
            sistema, do seu banco de dados, software e de seu website, sejam livres de erros, falhas
            ou
          </p>

          <p>de interrup&ccedil;&otilde;es.</p>

          <br />

          <p>- PROPRIEDADE INTELECTUAL -</p>

          <p>
            9.1. Utiliza&ccedil;&atilde;o da Propriedade Intelectual. O uso comercial da
            express&atilde;o &quot;Kuppi&quot;, como
          </p>

          <p>
            marca, nome empresarial ou nome de dom&iacute;nio, e o logotipo s&atilde;o de
            propriedade exclusiva
          </p>

          <p>
            dos Nossos propriet&aacute;rios, conforme disposto no respectivo ato constitutivo da
          </p>

          <p>
            organiza&ccedil;&atilde;o. Da mesma forma, direitos autorais e outros direitos
            pass&iacute;veis de prote&ccedil;&atilde;o por
          </p>

          <p>
            propriedade industrial como estrutura, os conte&uacute;dos das telas relativas &agrave;
            Plataforma,
          </p>

          <p>assim como os programas relacionados a mesma, software, website, bancos de dados,</p>

          <p>
            redes e arquivos. Todos os direitos est&atilde;o protegidos, no Brasil e
            internacionalmente por
          </p>

          <p>
            leis e tratados internacionais de direito autoral, marcas, patentes, modelos e desenhos
          </p>

          <p>
            industriais. Aos Nossos propriet&aacute;rios reservam-se todos os direitos relativos a
            autorias,
          </p>

          <p>marcas, patentes, modelos e desenhos industriais, quer sejam de sua propriedade ou</p>

          <p>licenciados em seu favor, a si.</p>

          <br />

          <p>
            9.1.1. Ao finalizar o Curso-Neg&oacute;cio disponibilizado pela Kuppi, Voc&ecirc;s
            adquirem o
          </p>

          <p>
            direito de comercializar a Plataforma Kuppi para Anunciantes. N&atilde;o &eacute;
            permitido,
          </p>

          <p>
            por&eacute;m, reproduzir, duplicar, copiar, ou explorar de forma diversa o
            conte&uacute;do da
          </p>

          <p>
            Plataforma sem o pr&eacute;vio consentimento escrito dos Nossos propriet&aacute;rios.
          </p>

          <p>
            Particularmente, &eacute; expressamente proibida a utiliza&ccedil;&atilde;o de
            minera&ccedil;&atilde;o de dados,
          </p>

          <p>
            rob&ocirc;s, ou outras ferramentas de coleta e extra&ccedil;&atilde;o de dados para
            extrair, de forma
          </p>

          <p>isolada ou recorrente, qualquer parte substancial da Plataforma visando sua</p>

          <p>reutiliza&ccedil;&atilde;o.</p>

          <br />

          <p>
            9.2. Rela&ccedil;&otilde;es com Terceiros. A Plataforma poder&aacute; fornecer links de
            acesso a outros
          </p>

          <p>
            websites, o que n&atilde;o significa que esses sites sejam de propriedade ou operados
            por N&oacute;s.
          </p>

          <p>
            Como n&atilde;o possu&iacute;mos controle sobre esses sites, N&oacute;s n&atilde;o
            seremos respons&aacute;veis pelos
          </p>

          <p>
            conte&uacute;dos, pr&aacute;ticas e servi&ccedil;os ofertados nos mesmos. A
            presen&ccedil;a de links para outros sites
          </p>

          <p>
            n&atilde;o implica rela&ccedil;&atilde;o de sociedade, de supervis&atilde;o, de
            cumplicidade ou solidariedade da
          </p>

          <p>KUPIDO TECNOLOGIA LTDA. para com esses sites e seus conte&uacute;dos.</p>

          <br />

          <p>- CONDI&Ccedil;&Otilde;ES FINAIS &ndash;</p>

          <p>
            10.1. Suspens&atilde;o e Cancelamento do Acesso. Na eventualidade de um descumprimento
          </p>

          <p>
            de qualquer disposi&ccedil;&atilde;o dos Termos de Uso, Voc&ecirc; reconhece e aceita
            que N&oacute;s podemos,
          </p>

          <p>
            sem aviso pr&eacute;vio, interromper ou suspender, tempor&aacute;ria ou permanentemente,
            parte ou a
          </p>

          <p>totalidade, do acesso &agrave; plataforma.</p>

          <br />

          <p>
            10.2. Advert&ecirc;ncias e suspens&atilde;o. Sem preju&iacute;zo de outras medidas
            cab&iacute;veis, N&oacute;s
          </p>

          <p>
            poderemos advertir, suspender, tempor&aacute;ria ou definitivamente, seu acesso, a
            qualquer
          </p>

          <p>tempo, se:</p>

          <p>
            a) n&atilde;o cumprir com qualquer dispositivo dos termos de uso ou da pol&iacute;tica
            de
          </p>

          <p>privacidade;</p>

          <p>
            b) descumprir com quaisquer de suas obriga&ccedil;&otilde;es ou proibi&ccedil;&otilde;es
            assumidas ao
          </p>

          <p>utilizar os servi&ccedil;os da plataforma;</p>

          <p>
            c) utilizar, sem autoriza&ccedil;&atilde;o, Nosso nome e/ou marca, em parte ou na sua
          </p>

          <p>totalidade, e violar direitos de propriedade intelectual da KUPIDO</p>

          <p>TECNOLOGIA LTDA.;</p>

          <p>
            d) n&atilde;o puder ser verificada a identidade ou se qualquer informa&ccedil;&atilde;o
            fornecida
          </p>

          <p>por este estiver incorreta;</p>

          <br />

          <p>
            10.3. Tratamento dos Dados Pessoais. Para que a Plataforma tenha qualidade e para que
          </p>

          <p>
            Voc&ecirc;s possam obter resultados de forma r&aacute;pida e segura, &eacute;
            necess&aacute;rio o fornecimento de
          </p>

          <p>dados pessoais, nos termos dispostos na Nossa Pol&iacute;tica de Privacidade.</p>

          <br />

          <p>
            10.4. Nulidades. Se qualquer cl&aacute;usula deste instrumento for considerada ilegal,
            inv&aacute;lida
          </p>

          <p>
            ou inaplic&aacute;vel, no seu todo ou em parte, ao abrigo de qualquer lei, essa
            cl&aacute;usula ou parte
          </p>

          <p>
            do mesmo ser&aacute; nesta exata medida entendida como n&atilde;o fazendo parte dos
            Termos de Uso,
          </p>

          <p>
            sendo que a legalidade, validade e aplicabilidade das demais cl&aacute;usulas n&atilde;o
            ser&atilde;o afetadas.
          </p>

          <br />

          <p>- LEI APLIC&Aacute;VEL E JURISDI&Ccedil;&Atilde;O &ndash;</p>

          <p>
            11.1. Os presentes Termos de Uso s&atilde;o regidos pelas leis brasileiras, tendo como
            definido o
          </p>

          <p>
            foro da Comarca de Niter&oacute;i/RJ como competente para dirimir eventuais
            controv&eacute;rsias
          </p>

          <p>
            oriundas destes, em preju&iacute;zo de qualquer outro, por mais privilegiado que seja ou
            venha a
          </p>

          <p>
            ser, devendo-se dar prioridade, sempre, &agrave;s tentativas de resolu&ccedil;&atilde;o
            por concilia&ccedil;&atilde;o ou
          </p>

          <p>media&ccedil;&atilde;o.</p>

          <p>Criado em 28 de julho de 2020.</p>
        </Col>
        <Row className="mt-auto" padding="30px 30px" height="auto" alignCenter justifyStart>
          <Ball active />
          <Ball />
          <Row width="auto" height="auto" className="ml-auto">
            <SubmitButton onClick={() => toggleContent('userInfo')}>Aceitar termos</SubmitButton>
          </Row>
        </Row>
      </Modal>
    </Container>
  );

  const contentUserInfo = (
    <Container visible={modal}>
      <Modal width="600px" height="570px">
        <Col padding="20px 30px 10px 30px" height="auto">
          <ModelTitle>Confirmação de dados</ModelTitle>
          <Text size="14px" regular>
            Precisamos confirmar alguns de seus dados, preencha com muita atenção, para não conter
            nenhum erro que impossibilite você de receber as comissões.
          </Text>
        </Col>
        <Row padding="0 30px 30px 30px">
          <Form
            initialData={{
              name: userState.userInfo.name,
            }}
            onSubmit={handleInfoSubmit}
          >
            <Col height="auto" padding="15px 0">
              <Row alignCenter justifyStart>
                <Col margin="0 10px 0 0" width="auto">
                  <CustomCheckBox
                    label="Pessoa Física"
                    checked={isIndividualField}
                    onCheck={toggleToIndividual}
                    disabled={userState.userInfo.document_changed_at}
                  />
                </Col>
                <Col width="auto">
                  <CustomCheckBox
                    label="Pessoa Jurídica"
                    checked={isCorporationField}
                    onCheck={toggleToCorporation}
                    disabled={userState.userInfo.document_changed_at}
                  />
                </Col>
                {/* INPUT HIDDEN */}

                <InputCustom name="is_corporation" value={isCorporationField} hidden required />

                {/* INPUT HIDDEN */}
              </Row>
            </Col>

            <Col height="auto" padding="15px 0">
              <Label>Nome Completo</Label>
              <InputCustom name="name" type="text" required placeholder="Nome completo" />
            </Col>

            <Col height="auto" padding="15px 0">
              {isCorporationField ? (
                <>
                  <Label>CNPJ</Label>
                  <InputCustom
                    name="cpf_cnpj"
                    type="text"
                    required
                    placeholder="CNPJ"
                    maxLength="18"
                    value={cpfCnpj}
                    onChange={(e) => setcpfCnpj(cnpjMask(e.target.value))}
                  />
                </>
              ) : (
                <>
                  <Label>CPF</Label>
                  <InputCustom
                    name="cpf_cnpj"
                    type="text"
                    required
                    placeholder="CPF"
                    maxLength="14"
                    value={cpfCnpj}
                    onChange={(e) => setcpfCnpj(cpfMask(e.target.value))}
                  />
                </>
              )}
            </Col>

            <Row className="mt-auto" height="auto" alignCenter justifyStart>
              <Ball onClick={() => toggleContent('terms')} active />
              <Ball active />
              <Row width="auto" height="auto" className="ml-auto" alignCenter justifyCenter>
                <SubmitButton type="submit">Confirmar</SubmitButton>
              </Row>
            </Row>
          </Form>
        </Row>
      </Modal>
    </Container>
  );

  if (Content === 'terms') {
    return contentTerms;
  }
  if (Content === 'userInfo') {
    return contentUserInfo;
  }
}
