export default function MillisecConversion(millisec, type, decimalPlaces) {
  if (type === 'seconds') {
    return (millisec / 1000).toFixed(
      decimalPlaces !== undefined || decimalPlaces !== null ? decimalPlaces : 1,
    );
  }
  if (type === 'minutes') {
    return (millisec / (1000 * 60)).toFixed(
      decimalPlaces !== undefined || decimalPlaces !== null ? decimalPlaces : 1,
    );
  }
  if (type === 'hours') {
    return (millisec / (1000 * 60 * 60)).toFixed(
      decimalPlaces !== undefined || decimalPlaces !== null ? decimalPlaces : 1,
    );
  }
  if (type === 'days') {
    return (millisec / (1000 * 60 * 60 * 24)).toFixed(
      decimalPlaces !== undefined || decimalPlaces !== null ? decimalPlaces : 1,
    );
  }
  if (type === 'weeksCurrentYear') {
    const currentYear = new Date(millisec).getFullYear();
    const firstDayCurrentYear = new Date(`${currentYear}-01-01`); // .getUTCDay()
    const diferent = millisec - firstDayCurrentYear.getTime();
    const diferentInDays = (diferent / (1000 * 60 * 60 * 24)).toFixed(0);
    const currentWeek = diferentInDays / 7;

    return Math.trunc(currentWeek);
  }
}
