import styled from '@emotion/styled';
import { Input } from '@rocketseat/unform';

import { branco, cinzaEscuro2, verdeKuppi } from '../../styles/colors';

export const Container = styled.div`
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  background: rgb(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
  height: 100%;
`;

export const Label = styled.div`
  margin-top: 30px;
  font-weight: bold;
  margin-bottom: 5px;
  width: 100%;
  text-align: left;
  font-size: 14px;
`;

export const Modal = styled.div`
  position: relative;
  padding: 40px;
  background: ${branco};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;

  ${(props) =>
    props.modalStart &&
    `
    width: 510px;
    height: 350px;
  `};

  ${(props) =>
    props.modalPassword &&
    `
    width: 500px;
    height: 400px;
  `};

  ${(props) =>
    props.modelEmail &&
    `
    width: 500px;
    height: 500px;
  `};

  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const ModelTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: ${cinzaEscuro2};
`;

export const Error = styled.div`
  margin-top: 5px;
  width: 100%;
  font-size: 14px;
  color: red;
  text-align: left;
`;

export const ModelText = styled.div`
  font-size: 21px;
  color: ${cinzaEscuro2};
  text-align: center;

  span {
    font-weight: bold;
  }
`;

export const ModelBoxTop = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ModelBoxBottom = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  height: 40%;
  width: 100%;
`;

export const ModalButton = styled.button`
  border: 2px solid ${(props) => (props.noBg ? cinzaEscuro2 : verdeKuppi)};
  font-size: 15px;
  padding: 10px ${(props) => (props.noBg ? '20px' : props.login ? '60px' : '11px')};

  ${(props) => (props.noBg ? `color: ${cinzaEscuro2};` : `color: ${branco};`)}
  font-weight: bold;

  border-radius: 10px;
  width: 49%;
  margin: 0 5px;
  background: ${(props) => (props.noBg ? 'transparent' : verdeKuppi)};

  :focus {
    background: ${(props) => (props.noBg ? 'transparent' : verdeKuppi)};
  }

  ${(props) =>
    props.single &&
    `
   width: 100%;
  `};

  ${(props) =>
    props.topbox &&
    `
    margin-top: 40px;
    width: 60%;

  `};
`;

export const CloseButton = styled.button`
  border: none;
  font-size: 20px;
  background: transparent;
  position: absolute;
  top: 20px;
  right: 30px;

  :focus {
    background: transparent;
  }

  i {
    font-size: 34px;
  }
`;

export const InputCustom = styled(Input)`
  border: 1px solid ${(props) => (props.error ? 'red' : '#dbdbdb')} !important;
  padding: 7px 10px !important;
  height: auto !important;
  margin: 0 !important;
  border-radius: 5px !important;
  width: 100%;
  box-sizing: inherit !important;
  font-size: 14px !important;

  ::placeholder {
    font-size: 12px;
  }

  :focus {
    border: 1px solid ${(props) => (props.error ? 'red' : '#dbdbdb')} !important;
    box-shadow: none !important;
    border-right: none !important;
  }
`;

export const LoadingBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
