import styled from '@emotion/styled';

import { branco, cinzaClaro2 } from '../../styles/colors';

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const ChangeButton = styled.button`
  border: none !important;
  box-shadow: none !important;
  background: transparent !important;
  z-index: 2;
  height: 30px;
  width: 30px;
  border-radius: 20px;

  ${(props) => props.visible && `background: ${branco} !important;`}
`;

export const ModalEdit = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  top: 15px;
  right: 15px;
  background: ${branco};
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  width: ${(props) => props.width && props.width};

  ${(props) => (props.visible ? 'display: inherit;' : 'display: none')};
`;
export const ButtonEdit = styled.button`
  width: 100%;
  padding: 8px 15px;
  border: none !important;
  box-shadow: none !important;
  background: transparent !important;
  ${(props) => props.padding && `padding: ${props.padding};`}

  :focus {
    background: transparent !important;
  }

  :hover {
    background: ${cinzaClaro2} !important;
  }
`;
