import { Box } from '@chakra-ui/react';
import { Form } from '@rocketseat/unform';
import React, { useEffect, useState } from 'react';
import { MdClear, MdExpandLess, MdExpandMore } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { editPassword, editProfile } from '../../../stores/modules/user/actions';
import { InputCustom, Text } from '../../../styles/common';
import { Col, Row } from '../../../styles/grid';
import CpfCnpjValidator from '../../../utils/CpfCnpjValidator';
import { cnpjMask, cpfMask } from '../../../utils/masks';
import { onlyNumbers } from '../../../utils/onlyNumbers';
import CustomCheckBox from '../../CustomCheckBox';
import ChangeConfirm from '../ChangeConfirm';
import {
  CloseButton,
  ColumnContent,
  Container,
  Label,
  Modal,
  ModalButton,
  ModelTitle,
  RowContent,
} from '../styles';

export default function ProfileModalOne({ visible, onChange, user }) {
  const [modal, setModal] = useState(visible);
  const [visiblePassword, setvisiblePassword] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [visibleAlert, setvisibleAlert] = useState(false);
  const [userState, setUserState] = useState(user);
  const [cpfCnpj, setcpfCnpj] = useState();
  const [isCorporationField, setIsCorporationField] = useState();
  const [isIndividualField, setIsIndividualField] = useState();

  const toggleToCorporation = () => {
    setIsCorporationField(true);
    setIsIndividualField(false);
    setcpfCnpj('');
  };

  const toggleToIndividual = () => {
    setIsCorporationField(false);
    setIsIndividualField(true);
    setcpfCnpj('');
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (userState?.userInfo?.is_corporation) {
      setcpfCnpj(cnpjMask(userState?.userInfo?.cpf_cnpj));
    } else {
      setcpfCnpj(cpfMask(userState?.userInfo?.cpf_cnpj));
    }
  }, [userState?.userInfo]);

  useEffect(() => {
    setUserState(user);
    if (userState?.userInfo?.is_corporation) {
      setIsCorporationField(true);
      setIsIndividualField(false);
    } else {
      setIsCorporationField(false);
      setIsIndividualField(true);
    }
  }, [user, userState?.userInfo?.is_corporation]);

  useEffect(() => {
    setModal(visible);
  }, [visible]);

  useEffect(() => {
    if (onlyNumbers(cpfCnpj) !== onlyNumbers(userState?.userInfo?.cpf_cnpj)) {
      setvisibleAlert(true);
    } else {
      setvisibleAlert(false);
    }
  }, [cpfCnpj, userState?.userInfo?.cpf_cnpj]);

  const [changeConfirm, setChangeConfirm] = useState(false);
  const toggleChangeConfirm = () => setChangeConfirm(!changeConfirm);

  const handleVisible = () => onChange();

  const handleSubmit = ({ name, cpf_cnpj, is_corporation, password, password_confirmation }) => {
    if (visiblePassword) {
      if (password === '' || password_confirmation === '') {
        toast.error("Os campos de 'Nova senha' e 'Confirmar nova senha' não podem ser vazios", {
          autoClose: 6000,
        });
        return;
      }
      if (password !== password_confirmation) {
        toast.error("Os campos de 'Nova senha' e 'Confirmar nova senha' precisam ser iguais", {
          autoClose: 6000,
        });
        return;
      }
      if (password.length < 6) {
        toast.error('A nova senha precisa ter pelo menos seis dígitos.', {
          autoClose: 6000,
        });
        return;
      }
      if (!CpfCnpjValidator(cpf_cnpj)) {
        toast.error('CPF/CNPJ Inválido.', {
          autoClose: 6000,
        });
        return;
      }
      dispatch(editPassword(password));
      setvisiblePassword(false);
      setPassword('');
      setPasswordConfirm('');
      dispatch(editProfile(name, onlyNumbers(cpf_cnpj), is_corporation));
    } else {
      if (!CpfCnpjValidator(cpf_cnpj)) {
        toast.error('CPF/CNPJ Inválido.', {
          autoClose: 6000,
        });
        return;
      }
      dispatch(editProfile(name, onlyNumbers(cpf_cnpj), is_corporation));
    }
    onChange();
  };

  return (
    <>
      <Container visible={modal}>
        <Modal width="600px">
          <CloseButton onClick={handleVisible}>
            <Box as={MdClear} w="35px" h="35px" />
          </CloseButton>
          <ModelTitle>Alterar dados</ModelTitle> <br />
          <Form
            initialData={{
              name: userState?.userInfo?.name,
              email: userState?.user.email,
            }}
            onSubmit={handleSubmit}
          >
            <Row alignCenter justifyStart>
              <Col margin="0 10px 0 0" width="auto">
                <CustomCheckBox
                  label="Pessoa Física"
                  checked={isIndividualField}
                  onCheck={toggleToIndividual}
                  disabled={userState?.userInfo?.document_changed_at}
                />
              </Col>
              <Col width="auto">
                <CustomCheckBox
                  label="Pessoa Jurídica"
                  checked={isCorporationField}
                  onCheck={toggleToCorporation}
                  disabled={userState?.userInfo?.document_changed_at}
                />
              </Col>
              {/* INPUT HIDDEN */}

              <InputCustom name="is_corporation" value={isCorporationField} hidden required />

              {/* INPUT HIDDEN */}
            </Row>
            <ColumnContent>
              <Label>Nome Completo</Label>
              <InputCustom name="name" type="text" required placeholder="Nome completo" />
            </ColumnContent>
            <RowContent>
              <ColumnContent left>
                {isCorporationField ? (
                  <>
                    <Label>CNPJ</Label>
                    <InputCustom
                      name="cpf_cnpj"
                      type="text"
                      required
                      placeholder="CNPJ"
                      maxLength="18"
                      value={cpfCnpj}
                      onChange={(e) => setcpfCnpj(cnpjMask(e.target.value))}
                      disabled={userState?.userInfo?.document_changed_at}
                    />
                  </>
                ) : (
                  <>
                    <Label>CPF</Label>
                    <InputCustom
                      name="cpf_cnpj"
                      type="text"
                      required
                      placeholder="CPF"
                      maxLength="14"
                      value={cpfCnpj}
                      onChange={(e) => setcpfCnpj(cpfMask(e.target.value))}
                      disabled={userState?.userInfo?.document_changed_at}
                    />
                  </>
                )}
              </ColumnContent>

              <ColumnContent right>
                <Label>E-mail</Label>
                <InputCustom name="email" disabled type="email" placeholder="E-mail" />
              </ColumnContent>
            </RowContent>

            <RowContent>
              <Row alignCenter justifyEnd padding="10px 0">
                <Row onClick={() => setvisiblePassword(!visiblePassword)} width="auto" pointer>
                  {!visiblePassword ? (
                    <>
                      <Text size="14px">Alterar senha </Text>
                      <Box as={MdExpandMore} w="22px" h="22px" />
                    </>
                  ) : (
                    <>
                      <Text size="14px"> Mostrar menos </Text>
                      <Box as={MdExpandLess} w="22px" h="22px" />
                    </>
                  )}
                </Row>
              </Row>
            </RowContent>

            {visiblePassword && (
              <RowContent>
                <ColumnContent left>
                  <Label>Nova Senha</Label>
                  <InputCustom
                    name="password"
                    type="password"
                    required
                    placeholder="Nova Senha"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </ColumnContent>

                <ColumnContent right>
                  <Label>Confirmar nova senha</Label>
                  <InputCustom
                    name="password_confirmation"
                    type="password"
                    required
                    placeholder="Confirmar nova senha"
                    value={passwordConfirm}
                    onChange={(e) => setPasswordConfirm(e.target.value)}
                  />
                </ColumnContent>
              </RowContent>
            )}

            <RowContent lastRow>
              <ColumnContent left button>
                <ModalButton outlineTheme onClick={handleVisible} type="button">
                  Cancelar
                </ModalButton>
              </ColumnContent>

              <ColumnContent right button>
                {visibleAlert && !userState?.userInfo?.document_changed_at ? (
                  <ModalButton onClick={toggleChangeConfirm} type="button">
                    Confirmar
                  </ModalButton>
                ) : (
                  <ModalButton type="submit">Confirmar</ModalButton>
                )}
              </ColumnContent>
            </RowContent>
            <ChangeConfirm visible={changeConfirm} onHandleVisible={toggleChangeConfirm} />
          </Form>
        </Modal>
      </Container>
    </>
  );
}
