import React from 'react';
import { useDispatch } from 'react-redux';

import {
  clientManagement,
  dashboard,
  financialManagement,
  leadManagement,
  profileAndAccount,
  // supportMaterials,
  // kpAcademy,
  // memberGetMemberInfo,
  // memberGetMemberStatistics,
} from '~/routes/routeMap';
import { logout } from '~/stores/modules/auth/actions';

import {
  // Materials1,
  // Materials2,
  // Materials3,
  // Materials4,
  // Materials5,
  Academy,
  Clients1,
  Clients2,
  Clients3,
  Dashboard,
  Funel1,
  Funel2,
  Funel3,
  Funel4,
  Funel5,
  Leave,
  Leave2,
  Profile1,
  Profile2,
  SvgBox,
  SvgBox2,
  SvgBox3,
  // MemberGetMember,
} from './icons';
import {
  Container,
  Content,
  ContentLogo,
  Hr,
  LeaveButton,
  LogoImage,
  LogoImageBox,
  OptionBox,
  OptionBoxLink,
  SelectBar,
  Text,
} from './styles';

export default function AdminSideBar({ option }) {
  const dispatch = useDispatch();
  // const master = useSelector((state) => state.auth.master);

  function handleLogout() {
    dispatch(logout());
  }

  return (
    <Container>
      <Content>
        <ContentLogo>
          <LogoImageBox>
            <LogoImage src="/assets/images/logo_kuppi/logo_kuppi@2x.png" />
          </LogoImageBox>
        </ContentLogo>
        {/* <OptionBox to={dashboard}>
          <SvgBox optionActive={option === 'dashboard'}>
            <Dashboard d="M21,7a.78.78,0,0,0,0-.21.64.64,0,0,0-.05-.17,1.1,1.1,0,0,0-.09-.14.75.75,0,0,0-.14-.17l-.12-.07a.69.69,0,0,0-.19-.1h-.2A.7.7,0,0,0,20,6H15a1,1,0,0,0,0,2h2.83l-4,4.71L9.51,10.14a1,1,0,0,0-1.28.22l-5,6a1,1,0,0,0,1.54,1.28L9.22,12.3l4.27,2.56a1,1,0,0,0,1.27-.21L19,9.7V12a1,1,0,0,0,2,0Z" />
          </SvgBox>
          <Text optionActive={option === 'dashboard'} className="text">
            Dashboard{' '}
          </Text>
          <SelectBar optionActive={option === 'dashboard'} />
        </OptionBox> */}
        <OptionBox to={leadManagement}>
          <SvgBox2 optionActive={option === 'leeds'}>
            <Funel1
              transform="translate(-175.05 -444.203)"
              d="M178.4,454.194c.068.075.126.142.184.2.788.761,1.584,1.515,2.36,2.288.457.453.929.891,1.38,1.351.318.325.651.637.986.946a.706.706,0,0,1,.239.557v5.023a.577.577,0,0,1-.475.6.594.594,0,0,1-.489-.106.537.537,0,0,1-.214-.432v-4.844a.247.247,0,0,0-.072-.2c-.309-.293-.616-.589-.923-.885-.586-.566-1.176-1.128-1.756-1.7-.838-.827-1.687-1.646-2.533-2.466-.512-.5-1.024-1-1.539-1.492a1.53,1.53,0,0,1-.437-.65.984.984,0,0,1,.2-.931,2.566,2.566,0,0,1,.883-.684,7.47,7.47,0,0,1,1.8-.653.586.586,0,0,1,.673.47.566.566,0,0,1-.492.642,9.293,9.293,0,0,0-1.421.45,1.54,1.54,0,0,0-.385.218c-.149.12-.14.183-.019.324a2.075,2.075,0,0,0,1.01.521,12.518,12.518,0,0,0,1.783.436c.371.069.748.12,1.123.171.533.077,1.068.111,1.6.173.279.031.563.039.844.054.464.025.933.007,1.4.035.432.025.863,0,1.294-.007.6-.019,1.2-.032,1.794-.079.357-.029.716-.046,1.07-.093.473-.063.947-.12,1.416-.2a16.434,16.434,0,0,0,2.072-.482,3.745,3.745,0,0,0,.722-.285,1.685,1.685,0,0,0,.379-.343c.042-.043.018-.083-.009-.12a.8.8,0,0,0-.329-.241,7.589,7.589,0,0,0-1.371-.482.9.9,0,0,1-.379-.147.547.547,0,0,1-.154-.679.562.562,0,0,1,.7-.282,6.467,6.467,0,0,1,1.72.648,2.454,2.454,0,0,1,.805.669.976.976,0,0,1,.045,1.169,1.483,1.483,0,0,1-.246.311q-.781.751-1.556,1.512-.909.889-1.818,1.782L188.4,458.11c-.49.482-.976.963-1.467,1.445a.308.308,0,0,0-.1.241v3.749a.587.587,0,0,1-.187.453.6.6,0,0,1-.631.107.523.523,0,0,1-.36-.492v-4.2a.5.5,0,0,1,.167-.361l.732-.722,2.088-2.047q.9-.885,1.807-1.774c.1-.1.194-.2.3-.318A35.093,35.093,0,0,1,178.4,454.194Z"
            />
            <Funel2
              transform="translate(-203.016 -401.24)"
              d="M218,406.276a5.154,5.154,0,0,1-.851,3.027.6.6,0,0,1-.708.258.567.567,0,0,1-.29-.843,3.611,3.611,0,0,0,.529-1.043,4.092,4.092,0,0,0,.193-1.457,3.737,3.737,0,0,0-.352-1.392,4.553,4.553,0,0,0-2.741-2.319,4.331,4.331,0,0,0-1.835-.136,4.434,4.434,0,0,0-2.505,1.233,3.91,3.91,0,0,0-1.036,1.606,3.6,3.6,0,0,0-.216,1.246,3.915,3.915,0,0,0,.411,1.7c.1.2.207.384.326.568a.539.539,0,0,1-.252.83.559.559,0,0,1-.687-.17,4.654,4.654,0,0,1-.675-1.365,5.141,5.141,0,0,1-.191-2.128,5.264,5.264,0,0,1,.492-1.675,5.073,5.073,0,0,1,.765-1.141,5.141,5.141,0,0,1,1.859-1.338,5.575,5.575,0,0,1,1.358-.385,5.348,5.348,0,0,1,.916-.084,6.02,6.02,0,0,1,2.468.531,5.143,5.143,0,0,1,2.263,2,4.792,4.792,0,0,1,.541,1.239A4.932,4.932,0,0,1,218,406.276Z"
            />
            <Funel3
              transform="translate(-225.06 -411.816)"
              d="M234.02,420.234c0-.2,0-.2-.2-.255a3.724,3.724,0,0,1-1.242-.618.558.558,0,0,1-.258-.353.536.536,0,0,1,.082-.423.59.59,0,0,1,.89-.106,2.678,2.678,0,0,0,.669.383,2.191,2.191,0,0,0,.957.12.7.7,0,0,0,.541-.433.524.524,0,0,0-.334-.728c-.333-.149-.694-.228-1.036-.352a2.836,2.836,0,0,1-1.236-.763,1.237,1.237,0,0,1-.331-1.009,1.982,1.982,0,0,1,1.347-1.6c.114-.046.164-.1.154-.216a.505.505,0,0,1,.366-.555.6.6,0,0,1,.647.148.526.526,0,0,1,.141.408.2.2,0,0,0,.163.221,2.082,2.082,0,0,1,1.045.756.51.51,0,0,1,.1.395.526.526,0,0,1-.224.345.586.586,0,0,1-.427.118.573.573,0,0,1-.385-.212,1.58,1.58,0,0,0-.268-.261.619.619,0,0,0-.389-.137h-.476a.638.638,0,0,0-.6.5c-.061.37.02.507.379.661.3.129.611.21.918.311a2.87,2.87,0,0,1,1.243.708,1.319,1.319,0,0,1,.252,1.653A2.062,2.062,0,0,1,235.3,420c-.086.03-.126.065-.111.159a.626.626,0,0,1-.461.632.639.639,0,0,1-.678-.333A.5.5,0,0,1,234.02,420.234Z"
            />
            <Funel4
              transform="translate(-248.314 -553.48)"
              d="M259.525,575.455a.574.574,0,0,1-.607-.549.609.609,0,0,1,1.038-.377.536.536,0,0,1,.149.423.513.513,0,0,1-.179.368A.564.564,0,0,1,259.525,575.455Z"
            />
            <Funel5
              transform="translate(-233.19 -561.164)"
              d="M242.214,583.089a.551.551,0,0,1,.582.555.534.534,0,0,1-.169.391.587.587,0,0,1-.411.159.605.605,0,0,1-.425-.157.551.551,0,0,1-.181-.4.526.526,0,0,1,.175-.4A.58.58,0,0,1,242.214,583.089Z"
            />
          </SvgBox2>
          <Text optionActive={option === 'leeds'} className="text">
            Gestão de Leads{' '}
          </Text>
          <SelectBar optionActive={option === 'leeds'} />
        </OptionBox>
        <OptionBox to={clientManagement}>
          <SvgBox optionActive={option === 'clients'}>
            <Clients1 d="M9,11A4,4,0,1,0,5,7,4,4,0,0,0,9,11ZM9,5A2,2,0,1,1,7,7,2,2,0,0,1,9,5Z" />
            <Clients2 d="M17,13a3,3,0,1,0-3-3A3,3,0,0,0,17,13Zm0-4a1,1,0,1,1-1,1A1,1,0,0,1,17,9Z" />
            <Clients3 d="M17,14a5,5,0,0,0-3.06,1.05A7,7,0,0,0,2,20a1,1,0,1,0,2,0,5,5,0,0,1,10,0,1,1,0,0,0,2,0,6.9,6.9,0,0,0-.86-3.35A3,3,0,0,1,20,19a1,1,0,1,0,2,0A5,5,0,0,0,17,14Z" />
          </SvgBox>
          <Text optionActive={option === 'clients'} className="text">
            Gestão de Clientes{' '}
          </Text>
          <SelectBar optionActive={option === 'clients'} />
        </OptionBox>
        <OptionBox to={financialManagement}>
          <SvgBox optionActive={option === 'finances'}>
            <Dashboard d="M 21 7 a 0.78 0.78 0 0 0 0 -0.21 a 0.64 0.64 0 0 0 -0.05 -0.17 a 1.1 1.1 0 0 0 -0.09 -0.14 a 0.75 0.75 0 0 0 -0.14 -0.17 l -0.12 -0.07 a 0.69 0.69 0 0 0 -0.19 -0.1 h -0.2 A 0.7 0.7 0 0 0 20 6 H 15 a 1 1 0 0 0 0 2 h 2.83 l -4 4.71 L 9.51 10.14 a 1 1 0 0 0 -1.28 0.22 l -5 6 a 1 1 0 0 0 1.54 1.28 L 9.22 12.3 l 4.27 2.56 a 1 1 0 0 0 1.27 -0.21 L 19 9.7 V 12 a 1 1 0 0 0 2 0 Z" />
          </SvgBox>
          <Text optionActive={option === 'finances'} className="text">
            Gestão de Contratos{' '}
          </Text>
          <SelectBar optionActive={option === 'finances'} />
        </OptionBox>
        {/* <OptionBox to={supportMaterials}>
          <SvgBox optionActive={option === 'materials'}>
            <Materials1 rx='1' />
            <Materials2 rx='1' />
            <Materials3 rx='1' />
            <Materials4 d='M12,15a1,1,0,0,1-.58-.18L7.42,12a1,1,0,1,1,1.16-1.63L12,12.76l3.4-2.56a1,1,0,1,1,1.2,1.6l-4,3a1,1,0,0,1-.6.2Z'/>
            <Materials5 d='M12,13a1,1,0,0,1-1-1V4a1,1,0,0,1,2,0v8A1,1,0,0,1,12,13Z'/>
          </SvgBox>
          <Text optionActive={option === 'materials'} className="text">
            Materiais de Suporte{' '}
          </Text>
          <SelectBar optionActive={option === 'materials'} />
        </OptionBox>
        <Hr />
            <OptionBoxLink href="https://kuppiacademy.club.hotmart.com/lesson/M7q02g8mex/7-motivos-para-se-tornar-um-kuppido">
            <OptionBoxLink href="https://www.youtube.com/playlist?list=PLtg86YmbzDSYo51ohBPLX1VeO0w2MSLUo">
        <OptionBoxLink href="https://kuppiuniversity.myedools.com" target="_blank">
          <SvgBox optionActive={option === 'academy'}>
            <Academy d="M20.62,4.22a1,1,0,0,0-.84-.2L12,5.77,4.22,4A1,1,0,0,0,3,5V17.2a1,1,0,0,0,.78,1l8,1.8h.44l8-1.8a1,1,0,0,0,.78-1V5A1,1,0,0,0,20.62,4.22ZM5,6.25,11,7.6V17.75L5,16.4ZM19,16.4l-6,1.35V7.6l6-1.35Z" />
          </SvgBox>
          <Text optionActive={option === 'academy'} className="text">
            KP Academy{' '}
          </Text>
          <SelectBar optionActive={option === 'academy'} />
        </OptionBoxLink>
        {master && (
          <OptionBox
            // to={true ? memberGetMemberInfo : memberGetMemberStatistics}
            to={memberGetMemberStatistics}
          >
            <SvgBox optionActive={option === 'mgm'}>
              <MemberGetMember d='M19,20.75l-2.31-9A5.94,5.94,0,0,0,18,8,6,6,0,1,0,6,8a5.94,5.94,0,0,0,1.34,3.77L5,20.75a1,1,0,0,0,1.48,1.11l5.33-3.13,5.68,3.14A.91.91,0,0,0,18,22a1,1,0,0,0,1-1.25ZM12,4A4,4,0,1,1,8,8a4,4,0,0,1,4-4Zm.31,12.71a1,1,0,0,0-1,0l-3.75,2.2L9,13.21a5.94,5.94,0,0,0,5.92,0L16.45,19Z' />
            </SvgBox>
            <Text optionActive={option === 'mgm'} className="text">
              Member get Member{' '}
            </Text>
            <SelectBar optionActive={option === 'mgm'} />
          </OptionBox>
        )} */}
        <Hr />
        <OptionBox to={profileAndAccount}>
          <SvgBox optionActive={option === 'profile'}>
            <Profile1 d="M12,11A4,4,0,1,0,8,7,4,4,0,0,0,12,11Zm0-6a2,2,0,1,1-2,2A2,2,0,0,1,12,5Z" />
            <Profile2 d="M12,13a7,7,0,0,0-7,7,1,1,0,0,0,2,0,5,5,0,0,1,10,0,1,1,0,0,0,2,0,7,7,0,0,0-7-7Z" />
          </SvgBox>
          <Text optionActive={option === 'profile'} className="text">
            Perfil e Conta{' '}
          </Text>
          <SelectBar optionActive={option === 'profile'} />
        </OptionBox>
        <Hr />
        <LeaveButton onClick={handleLogout}>
          <SvgBox3>
            <Leave d="M7,6A1,1,0,0,0,7,4H5A1,1,0,0,0,4,5V19a1,1,0,0,0,1,1H7a1,1,0,0,0,0-2H6V6Z" />
            <Leave2 d="M20.82,11.42,18,7.42a1,1,0,1,0-1.63,1.16L18.09,11H10a1,1,0,0,0,0,2h8l-1.8,2.4a1,1,0,0,0,1.6,1.2l3-4a1,1,0,0,0,.02-1.18Z" />
          </SvgBox3>
          <Text className="text">Sair </Text>
        </LeaveButton>
      </Content>
    </Container>
  );
}
