import { Spinner, Text, useDisclosure } from '@chakra-ui/react';
import { IconButton, Tooltip } from '@chakra-ui/react';
import { format } from 'date-fns';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { FiEdit } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';

import { useAdvertiserSubscription } from '~/contexts/AdvertiserSubscriptionContext';
import { verdeKuppi } from '~/styles/colors';
import { formatDateForDatefield } from '~/utils/formatDate';

import { indexActiveContracts } from '../../stores/modules/financial/actions';
import { Col, Row } from '../../styles/grid';
import MillisecConversion from '../../utils/MillisecConversion';
import px2vw from '../../utils/px2vw';
import FinancialModal from '../FinancialModal';
import FinancialTableActivesStatus from '../FinancialTableActivesStatus';
import TablePerPage from '../TablePerPage';
import { Arrow, Arrow2, SvgBox } from './icons';
import {
  Content,
  Header,
  HeaderButton,
  Hr,
  NextIcon,
  PagButton,
  PrevIcon,
  TableRow,
} from './styles';
import { makePages, sort } from './tableOptions';

export default function LeadTable() {
  const loading = useSelector((state) => state.financial.loading);
  const payables = useSelector((state) => state.financial.active_contracts_payables);
  const activeContracts = useSelector((state) => state.financial.active_contracts);
  const [rows, setRows] = useState([]);
  const [rowsCurrentPage, setRowsCurrentPage] = useState();
  const [structure, setStructure] = useState(null);
  const [currentPage, setCurrentPage] = useState('1');
  const [lastPage, setLastPage] = useState();
  const [firstPage] = useState('1');
  const [sortedCol, setSortedCol] = useState('');
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [, dispatchAdvertiserSubscription] = useAdvertiserSubscription();

  useEffect(() => {
    dispatch(indexActiveContracts());
  }, [dispatch]);

  useEffect(() => {
    if (structure) {
      setLastPage(Object.keys(structure)[Object.keys(structure).length - 1].toString());
    }
  }, [structure]);

  useMemo(() => {
    setStructure(makePages(rows.length, rowsCurrentPage));
  }, [rows, rowsCurrentPage]);

  useEffect(() => {
    setRows(activeContracts);
  }, [activeContracts]);

  useEffect(() => {
    if (rows.length < 5) {
      setRowsCurrentPage(rows.length);
    }
    if (rowsCurrentPage > 5) {
    } else {
      setRowsCurrentPage(5);
    }
  }, [rows, rowsCurrentPage]);

  const handleSort = useCallback(
    (col) => {
      if (sortedCol !== col) {
        setSortedCol(col);
        if (col === 'advertiserInfo.name') {
          setRows(sort(rows, col, 'asc', true));
        } else {
          setRows(sort(rows, col, 'asc'));
        }
      } else {
        setSortedCol('');
        if (col === 'advertiserInfo.name') {
          setRows(sort(rows, col, 'desc', true));
        } else {
          setRows(sort(rows, col, 'desc'));
        }
      }
    },
    [rows, sortedCol],
  );

  function handlePerPage(size) {
    setRowsCurrentPage(size);
  }

  function handleNextPayable(payablesArray) {
    if (payablesArray) {
      const now = MillisecConversion(new Date().getTime(), 'days', 0);
      let nextPaymentDate = null;
      let currentDiference = null;
      if (payablesArray.length > 0) {
        payablesArray.forEach((item) => {
          const paymentDate = MillisecConversion(new Date(item.payment_date).getTime(), 'days', 0);
          const diferente = paymentDate - now;
          if (!nextPaymentDate) {
            currentDiference = diferente;
            nextPaymentDate = item.payment_date;
          } else if (diferente < currentDiference) {
            currentDiference = diferente;
            nextPaymentDate = item.payment_date;
          }
        });

        return format(new Date(nextPaymentDate), 'dd/MM/yyyy');
      }
      return '-';
    }
    return '-';
  }

  function handleChangePage(direction) {
    if (direction === 'next') {
      const curr = +currentPage + 1;
      setCurrentPage(curr.toString());
    } else if (direction === 'prev') {
      const curr = +currentPage - 1;
      setCurrentPage(curr.toString());
    }
  }

  const handleOnClickItemDetails = (contract) => {
    dispatchAdvertiserSubscription({
      type: 'setAdvertiserSubscription',
      payload: contract,
    });
    onOpen();
  };

  return (
    <>
      {loading && (
        <Col height="20vh" alignCenter justifyCenter>
          <Spinner w="50px" h="50px" color={verdeKuppi} />{' '}
        </Col>
      )}
      {!loading && (
        <Col padding="0 15px" alignCenter JustifyCenter>
          <Row>
            <Header width="20%" onClick={() => handleSort('advertiserInfo.name')}>
              <HeaderButton>
                Estabelecimento
                <SvgBox sorted={sortedCol === 'advertiserInfo.name'}>
                  <Arrow d="M6.63,11.61,12,7.29l5.37,4.48a1,1,0,0,0,1.27-1.54l-6-5a1,1,0,0,0-1.27,0l-6,4.83a1,1,0,1,0,1.26,1.55Z" />
                  <Arrow2 d="M12.64,12.23a1,1,0,0,0-1.27,0l-6,4.83a1,1,0,1,0,1.26,1.56L12,14.29l5.37,4.48a1,1,0,0,0,1.27-1.54Z" />
                </SvgBox>
              </HeaderButton>
            </Header>

            <Header width="20%" onClick={() => handleSort('status')}>
              <HeaderButton>
                Status do Contrato
                <SvgBox sorted={sortedCol === 'status'}>
                  <Arrow d="M6.63,11.61,12,7.29l5.37,4.48a1,1,0,0,0,1.27-1.54l-6-5a1,1,0,0,0-1.27,0l-6,4.83a1,1,0,1,0,1.26,1.55Z" />
                  <Arrow2 d="M12.64,12.23a1,1,0,0,0-1.27,0l-6,4.83a1,1,0,1,0,1.26,1.56L12,14.29l5.37,4.48a1,1,0,0,0,1.27-1.54Z" />
                </SvgBox>
              </HeaderButton>
            </Header>

            <Header width="20%" onClick={() => handleSort('start_validity_at')}>
              <HeaderButton>
                Vigência
                <SvgBox sorted={sortedCol === 'start_validity_at'}>
                  <Arrow d="M6.63,11.61,12,7.29l5.37,4.48a1,1,0,0,0,1.27-1.54l-6-5a1,1,0,0,0-1.27,0l-6,4.83a1,1,0,1,0,1.26,1.55Z" />
                  <Arrow2 d="M12.64,12.23a1,1,0,0,0-1.27,0l-6,4.83a1,1,0,1,0,1.26,1.56L12,14.29l5.37,4.48a1,1,0,0,0,1.27-1.54Z" />
                </SvgBox>
              </HeaderButton>
            </Header>

            <Header width="20%" onClick={() => handleSort('payment_type')}>
              <HeaderButton>
                Forma de Pagamento
                <SvgBox sorted={sortedCol === 'payment_type'}>
                  <Arrow d="M6.63,11.61,12,7.29l5.37,4.48a1,1,0,0,0,1.27-1.54l-6-5a1,1,0,0,0-1.27,0l-6,4.83a1,1,0,1,0,1.26,1.55Z" />
                  <Arrow2 d="M12.64,12.23a1,1,0,0,0-1.27,0l-6,4.83a1,1,0,1,0,1.26,1.56L12,14.29l5.37,4.48a1,1,0,0,0,1.27-1.54Z" />
                </SvgBox>
              </HeaderButton>
            </Header>

            <Header width="15%" onClick={() => handleSort('next_transfer')}>
              <HeaderButton>
                Próximo Repasse
                <SvgBox sorted={sortedCol === 'next_transfer'}>
                  <Arrow d="M6.63,11.61,12,7.29l5.37,4.48a1,1,0,0,0,1.27-1.54l-6-5a1,1,0,0,0-1.27,0l-6,4.83a1,1,0,1,0,1.26,1.55Z" />
                  <Arrow2 d="M12.64,12.23a1,1,0,0,0-1.27,0l-6,4.83a1,1,0,1,0,1.26,1.56L12,14.29l5.37,4.48a1,1,0,0,0,1.27-1.54Z" />
                </SvgBox>
              </HeaderButton>
            </Header>

            <Header width="5%" padding="25px 5px" />
          </Row>
          <Row>
            <Hr />
          </Row>
          {structure ? (
            rows.map((item, index) =>
              structure[currentPage].start <= index + 1 &&
              structure[currentPage].end >= index + 1 ? (
                <Fragment key={item.id}>
                  <TableRow alignCenter justifyCenter>
                    <Content width="20%" title={item.advertiserInfo.name}>
                      <Text
                        whiteSpace="nowrap"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        maxW="22ch"
                      >
                        {item.advertiserInfo.name}
                      </Text>
                    </Content>
                    <Content width="20%">
                      <FinancialTableActivesStatus item={item} />
                    </Content>
                    <Content width="20%">
                      {formatDateForDatefield(item.start_validity_at)} à{' '}
                      {formatDateForDatefield(item.finish_validity_at)}
                    </Content>
                    <Content width="20%">
                      {item.payment_type &&
                        item.payment_type === 'credit_card' &&
                        'Cartão de crédito'}
                      {item.payment_type && item.payment_type === 'boleto' && 'Boleto'}
                      {item.payment_type && item.payment_type === 'pix' && 'PIX'}
                      {item.payment_type && item.payment_type === 'free' && 'Plano Grátis'}
                    </Content>
                    <Content width="15%">{handleNextPayable(payables[item.id])}</Content>
                    <Content width="5%">
                      <Tooltip
                        colorScheme="black"
                        placement="top"
                        label="Ver detalhes"
                        fontSize="md"
                      >
                        <IconButton
                          bg="transparent"
                          onClick={() => handleOnClickItemDetails(item)}
                          icon={<FiEdit size={20} />}
                          _active={{
                            background: 'gray.200',
                          }}
                          _hover={{
                            background: 'gray.100',
                          }}
                        />
                      </Tooltip>
                    </Content>
                  </TableRow>
                  <Row>
                    <Hr />
                  </Row>
                </Fragment>
              ) : null,
            )
          ) : (
            <Row padding="15px 0 10px 0">Nenhum contrato no momento.</Row>
          )}
          {structure && (
            <Row alignCenter justifyEnd>
              <Row alignCenter justifyEnd height={px2vw(40)} margin="10px">
                <TablePerPage response={handlePerPage} length={rows.length} />
                <Row alignCenter justifyCenter width="10%">
                  {structure[currentPage].start} - {structure[currentPage].end} de {rows.length}
                </Row>
                <PagButton
                  onClick={() => handleChangePage('prev')}
                  disabled={currentPage === firstPage}
                  title="Página anterior"
                >
                  <PrevIcon size={px2vw(12)} disabled={currentPage === firstPage} />
                </PagButton>
                <PagButton
                  onClick={() => handleChangePage('next')}
                  title="Próxima página"
                  disabled={currentPage === lastPage}
                >
                  <NextIcon size={px2vw(12)} disabled={currentPage === lastPage} />
                </PagButton>
              </Row>
            </Row>
          )}
        </Col>
      )}
      <FinancialModal isOpen={isOpen} onClose={onClose} />
    </>
  );
}
