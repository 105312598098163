import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';

import { formatDateForDatefield, formatTimeForDatefield } from '~/utils/formatDate';

import { ContentDefault, Text } from '../../styles/common';
import { Col, Row } from '../../styles/grid';
import { activityTypesObject } from '../../utils/commonObjects';
import ActivityCheckBox from '../ActivityCheckBox';
import LeadDetailEditButton from '../LeadDetailEditButton';
import { Tag } from './styles';

export default function ContentActivity({ item, disabled }) {
  const [leadItem, setLeadItem] = useState(item);

  useEffect(() => {
    setLeadItem(item);
  }, [item]);

  return (
    <ContentDefault>
      <Col>
        <Row>
          <Row margin="0 5px 0 0" width="auto">
            <Tag>Atividade</Tag>
          </Row>
          <Row margin="0 0 0 5px" width="auto">
            <Tag dateTag>Adicionada {format(new Date(leadItem.created_at), 'dd/MM/yyyy')}</Tag>
          </Row>
          <Row width="auto" className="ml-auto">
            {!disabled && <LeadDetailEditButton item={item} type="activity" />}
          </Row>
        </Row>
        <Row alignStart justifyStart margin="15px 0 0 0">
          <Text>{leadItem.name}</Text>
        </Row>
        <Row alignStart justifyStart margin="15px 0 0 0">
          <Text regular>Tipo de Atividade: </Text>
          <Text margin="0 0 0 5px"> {activityTypesObject[leadItem.type]}</Text>
        </Row>
        <Row alignStart justifyStart margin="15px 0 20px 0">
          <Text regular>Data: </Text>
          <Text margin="0 20px 0 5px">{formatDateForDatefield(leadItem.activity_at)}</Text>
          <Text regular>Hora: </Text>
          <Text margin="0 0 0 5px">{formatTimeForDatefield(leadItem.activity_at)}</Text>
        </Row>
        <Col margin="0 0 20px 0">
          <Text regular>Descrição da atividade: </Text>
          <Text margin="0 20px 0 0">{leadItem.description}</Text>
        </Col>
        <Row alignStart justifyStart>
          <ActivityCheckBox disabled={disabled} item={item} />
        </Row>
      </Col>
    </ContentDefault>
  );
}
