import produce from 'immer';

const INITIAL_STATE = {
  user: null,
  userInfo: null,
  avatar: null,
  address: null,
  userAccountBank: null,
  loading: false,
  advertiserCategories: [],
  ipAddress: null,
  terms: null,
  loadingGetTermsAndPolicy: false,
  successGetTermsAndPolicy: false,
  loadingAcceptTermsOfUse: false,
  loadingOnboardingStep: false,
  successOnboardingStep: false,
};

export default function user(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@auth/LOGIN_SUCCESS':
        draft.user = action.payload.user;
        draft.userInfo = action.payload.user.kuppidoInfo;
        draft.address = action.payload.user.address[0];
        draft.userAccountBank = action.payload.user.kuppidoBankAccount;
        draft.avatar = action.payload.user.avatar;
        break;
      case '@auth/LOGIN_MASTER_SUCCESS':
        draft.user = action.payload.user;
        draft.userInfo = action.payload.user.kuppidoMasterInfo;
        draft.address = action.payload.user.address[0];
        draft.userAccountBank = action.payload.user.kuppidoBankAccount;
        draft.avatar = action.payload.user.avatar;
        break;
      case '@user/SET_USER':
        draft.user = action.payload.user;
        break;
      case '@user/SET_USER_INFO':
        draft.userInfo = action.payload.userInfo;
        draft.loading = false;
        break;
      case '@user/SET_USER_AVATAR':
        draft.avatar = action.payload.avatar;
        draft.loading = false;
        break;
      case '@user/SET_ADDRESS':
        draft.address = action.payload.address;
        draft.loading = false;
        break;
      case '@user/SET_ACCOUNT_BANK':
        draft.userAccountBank = action.payload.accountBank;
        draft.loading = false;
        break;
      case '@user/SET_USER_INFO_MASTER':
        draft.userInfo = action.payload.userInfo;
        break;
      case '@user/GET_ALL_PROFILE':
        draft.loading = true;
        break;
      case '@auth/LOG_OUT':
        return INITIAL_STATE;
      case '@user/EDIT_PROFILE':
        draft.loading = true;
        break;
      case '@user/EDIT_ADDRESS':
        draft.loading = true;
        draft.loadingOnboardingStep = true;
        draft.successOnboardingStep = false;
        break;
      case '@user/CREATE_BANK_ACCOUNT':
        draft.loading = true;
        draft.loadingOnboardingStep = true;
        draft.successOnboardingStep = false;
        break;
      case '@user/EDIT_BANK_ACCOUNT':
        draft.loading = true;
        draft.loadingOnboardingStep = true;
        draft.successOnboardingStep = false;
        break;
      case '@user/END_ACTION':
        draft.loading = false;
        break;
      case '@user/GET_ADVERTISER_CATEGORIES_SUCCESS': {
        draft.advertiserCategories = action.payload.categories;
        break;
      }
      case '@user/GET_ADVERTISER_CATEGORIES_FAILURE': {
        draft.advertiserCategories = [];
        break;
      }
      case '@user/FETCH_IP_ADDRESS': {
        draft.loading = true;
        break;
      }
      case '@user/SET_IP_ADDRESS': {
        draft.loading = false;
        draft.ipAddress = action.payload.ipAddress;
        break;
      }
      case '@user/GET_TERMS_AND_POLICY_REQUEST': {
        draft.loadingGetTermsAndPolicy = true;
        break;
      }
      case '@user/GET_TERMS_AND_POLICY_SUCCESS': {
        draft.successGetTermsAndPolicy = true;
        draft.terms = action.payload.termPolicyContent;
        draft.loadingGetTermsAndPolicy = false;
        break;
      }
      case '@user/GET_TERMS_AND_POLICY_FAILURE': {
        draft.successGetTermsAndPolicy = false;
        draft.terms = null;
        draft.loadingGetTermsAndPolicy = false;
        break;
      }
      case '@user/ACCEPT_TERMS_AND_POLICY_REQUEST': {
        draft.loadingAcceptTermsOfUse = true;
        break;
      }
      case '@user/SET_IS_TERMS_AND_POLICY_ACCEPTED': {
        draft.loadingAcceptTermsOfUse = false;
        draft.userInfo.is_terms_accepted = action.payload.isTermsAccepted;
        break;
      }
      case '@user/EDIT_PROFILE_ONBOARDING': {
        draft.loadingOnboardingStep = true;
        draft.successOnboardingStep = false;
        break;
      }
      case '@user/END_ACTION_ONBOARDING': {
        draft.loadingOnboardingStep = false;
        draft.successOnboardingStep = action.payload.success;
        break;
      }
      default:
    }
  });
}
