import styled from '@emotion/styled';

import { branco, cinzaClaro2, cinzaEscuro2, verdeKuppi } from '../../../styles/colors';

export const Container = styled.div`
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  background: rgb(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
  height: 100%;
  z-index: 10;
`;

export const Modal = styled.div`
  position: relative;
  padding: 30px;
  background: ${branco};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;

  width: 450px;
  ${(props) => props.width && `width: ${props.width};`}

  form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 100%;
  }
`;

export const ModalButton = styled.button`
  border: 2px solid ${(props) => (props.outlineTheme ? cinzaEscuro2 : verdeKuppi)};
  font-size: 15px;
  padding: 10px;
  font-weight: bold;
  color: ${(props) => (props.outlineTheme ? cinzaEscuro2 : branco)};
  border-radius: 10px;
  width: 80%;
  font-family: AvenirNextLTPro;
  font-weight: 700;

  ${(props) => props.width && `width: ${props.width};`};

  background: ${(props) => (props.outlineTheme ? 'transparent' : verdeKuppi)};

  :focus {
    background: ${(props) => (props.outlineTheme ? 'transparent' : verdeKuppi)};
  }

  :hover {
    color: ${(props) => (props.outlineTheme ? cinzaEscuro2 : cinzaClaro2)};
  }
`;

export const CloseButton = styled.button`
  border: none;
  font-size: 20px;
  background: transparent;
  position: absolute;
  top: 20px;
  right: 30px;

  :focus {
    background: transparent;
  }

  i {
    font-size: 34px;
  }
`;
