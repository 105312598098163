import {
  AlertDialog as ChakraAlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  IconButton,
  ModalContentProps,
} from '@chakra-ui/react';
import React from 'react';
import { MdClear } from 'react-icons/md';

import Button from '../Button';

interface AlertDialogProps extends ModalContentProps {
  isOpen: boolean;
  onClose: () => void;
  headerText?: React.ReactNode;
  bodyText: React.ReactNode;
  onClickYes: () => void;
  onClickNo?: () => void;
  loadingButtonYes?: boolean;
  loadingButtonNo?: boolean;
  closeOnOverlayClick?: boolean;
}

const AlertDialog: React.FC<AlertDialogProps> = ({
  isOpen,
  onClose,
  headerText,
  bodyText,
  onClickYes,
  onClickNo,
  loadingButtonYes,
  loadingButtonNo,
  closeOnOverlayClick,
  ...rest
}) => {
  const cancelRef = React.useRef();

  return (
    <ChakraAlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isOpen={isOpen}
      closeOnOverlayClick={closeOnOverlayClick}
      isCentered
    >
      <AlertDialogOverlay />

      <AlertDialogContent padding="0.5rem" borderRadius="1.5rem" {...rest}>
        <AlertDialogHeader>{headerText}</AlertDialogHeader>
        <AlertDialogCloseButton
          isDisabled={loadingButtonYes || loadingButtonNo}
          as={IconButton}
          size="lg"
          icon={<MdClear size={30} />}
          bg="transparent"
          _focus={{
            outline: 0,
            boxShadow: 'none',
          }}
          _hover={{
            outline: 0,
            background: 'gray.100',
          }}
        />
        <AlertDialogBody>{bodyText}</AlertDialogBody>
        <AlertDialogFooter>
          <Button
            isLoading={loadingButtonNo}
            isDisabled={loadingButtonYes}
            variant="outline"
            register={cancelRef}
            onClick={onClickNo ? onClickNo : onClose}
          >
            Não
          </Button>
          <Button
            isDisabled={loadingButtonNo}
            isLoading={loadingButtonYes}
            ml={3}
            onClick={onClickYes ? onClickYes : onClose}
          >
            Sim
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </ChakraAlertDialog>
  );
};

export default AlertDialog;
