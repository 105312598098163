import moment from 'moment';
import { toast } from 'react-toastify';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { apiKuppido } from '../../../services/api';
import { setConversionRate, setLogLeads, setStructureBilling } from './actions';
import { logout } from '../auth/actions';

const getState = (state) => state;

function leadSeparation(leads) {
  const currentMonthDate = new Date();
  currentMonthDate.setDate(1);

  const lastMonthDate = new Date();
  lastMonthDate.setDate(1);
  lastMonthDate.setMonth(currentMonthDate.getMonth() - 1);

  const monthBeforeTheLastDate = new Date();
  monthBeforeTheLastDate.setDate(1);
  monthBeforeTheLastDate.setMonth(currentMonthDate.getMonth() - 2);

  const status = {
    currentMonth: {
      month: moment(currentMonthDate).format('MMMM'),
      leads_cliente_potencial: 0,
      leads_reuniao_marcada: 0,
      leads_ganhos: 0,
    },
    lastMonth: {
      month: moment(lastMonthDate).format('MMMM'),
      leads_cliente_potencial: 0,
      leads_reuniao_marcada: 0,
      leads_ganhos: 0,
    },
    monthBeforeTheLast: {
      month: moment(monthBeforeTheLastDate).format('MMMM'),
      leads_cliente_potencial: 0,
      leads_reuniao_marcada: 0,
      leads_ganhos: 0,
    },
  };

  leads.forEach((item) => {
    const createAtMonth = new Date(item.created_at).getMonth() + 1;
    const currentMonth = currentMonthDate.getMonth() + 1;
    const lastMonth = lastMonthDate.getMonth() + 1;
    const monthBeforeTheLast = monthBeforeTheLastDate.getMonth() + 1;

    if (createAtMonth === currentMonth) {
      if (
        item.funnel_status === 'cliente_potencial'
        // || item.funnel_status === 'contactado'
      ) {
        status.currentMonth.leads_cliente_potencial += 1;
      } else if (item.funnel_status === 'reuniao_marcada') {
        status.currentMonth.leads_reuniao_marcada += 1;
      } else if (item.funnel_status === 'ganho') {
        status.currentMonth.leads_ganhos += 1;
      }
    } else if (createAtMonth === lastMonth) {
      if (item.funnel_status === 'cliente_potencial' || item.funnel_status === 'contactado') {
        status.lastMonth.leads_cliente_potencial += 1;
      } else if (item.funnel_status === 'reuniao_marcada') {
        status.lastMonth.leads_reuniao_marcada += 1;
      } else if (item.funnel_status === 'ganho') {
        status.lastMonth.leads_ganhos += 1;
      }
    } else if (createAtMonth === monthBeforeTheLast) {
      if (item.funnel_status === 'cliente_potencial' || item.funnel_status === 'contactado') {
        status.monthBeforeTheLast.leads_cliente_potencial += 1;
      } else if (item.funnel_status === 'reuniao_marcada') {
        status.monthBeforeTheLast.leads_reuniao_marcada += 1;
      } else if (item.funnel_status === 'ganho') {
        status.monthBeforeTheLast.leads_ganhos += 1;
      }
    }
  });

  return status;
}

export function* indexLogLeads() {
  try {
    const state = yield select(getState);
    const userInfoId = state.user.userInfo.id;

    const response = yield call(apiKuppido.get, `/user-info/${userInfoId}/leads/log`);
    const logLeadsStatus = leadSeparation(response.data);
    const { currentMonth } = logLeadsStatus;
    const { lastMonth } = logLeadsStatus;
    const { monthBeforeTheLast } = logLeadsStatus;

    yield put(setLogLeads(currentMonth, lastMonth, monthBeforeTheLast));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      toast.error('Não foi possível carregar as métricas de leads. Tente novamente.');
    }
  }
}

export function* getConversionRate() {
  try {
    const state = yield select(getState);
    const userInfoId = state.user.userInfo.id;
    const { master } = state.auth;

    const response = yield call(
      apiKuppido.get,
      `/${master ? 'master/' : ''}user-info/${userInfoId}/conversion-rate`,
    );
    yield put(setConversionRate(response.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    }
  }
}

export function* structureBilling({ payload }) {
  const { payables } = payload;

  const monthsString = [];

  // Get last 7 months name
  for (var i = 6; i >= 0; i--) {
    const currMonthData = new Date();
    currMonthData.setDate(1);
    currMonthData.setMonth(currMonthData.getMonth() - i);

    monthsString.push(moment(currMonthData).format('MMMM'));
  }

  const currentMonthDate = new Date();
  currentMonthDate.setDate(1);
  const currentMonthBar = currentMonthDate.getMonth() + 1;

  let currentMonthBar6 = 0;
  let currentMonthBar5 = 0;
  let currentMonthBar4 = 0;
  let currentMonthBar3 = 0;
  let currentMonthBar2 = 0;
  let currentMonthBar1 = 0;
  let currentMonthBar0 = 0;

  const payablesArr = Object.keys(payables).map((key) => payables[key]);

  if (payablesArr) {
    payablesArr.forEach((item) => {
      if (item) {
        item.forEach((pay) => {
          if (pay.status === 'paid') {
            const payData = new Date(pay.payment_date).getMonth() + 1;
            if (payData === currentMonthBar) {
              currentMonthBar0 += pay.amount / 100;
            } else if (payData === currentMonthBar - 1) {
              currentMonthBar1 += pay.amount / 100;
            } else if (payData === currentMonthBar - 2) {
              currentMonthBar2 += pay.amount / 100;
            } else if (payData === currentMonthBar - 3) {
              currentMonthBar3 += pay.amount / 100;
            } else if (payData === currentMonthBar - 4) {
              currentMonthBar4 += pay.amount / 100;
            } else if (payData === currentMonthBar - 5) {
              currentMonthBar5 += pay.amount / 100;
            } else if (payData === currentMonthBar - 6) {
              currentMonthBar6 += pay.amount / 100;
            }
          }
        });
      }
    });
  }

  yield put(
    setStructureBilling(monthsString, [
      currentMonthBar6,
      currentMonthBar5,
      currentMonthBar4,
      currentMonthBar3,
      currentMonthBar2,
      currentMonthBar1,
      currentMonthBar0,
    ]),
  );
}

export default all([
  takeLatest('@dashboard/INDEX_LOG_LEADS', indexLogLeads),
  takeLatest('@dashboard/GET_CONVERSION_RATE', getConversionRate),
  takeLatest('@dashboard/STRUCTURE_BILLING', structureBilling),
]);
