import {
  IconButton,
  Modal as ChakraModal,
  ModalBody,
  ModalBodyProps as ChakraModalBodyProps,
  ModalCloseButton,
  ModalContent,
  ModalContentProps,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import React from 'react';
import { MdClear } from 'react-icons/md';

export interface ModalProps extends ChakraModalBodyProps {
  isOpen: boolean;
  onClose: () => void;
  headerText?: React.ReactNode;
  headerAddon?: React.ReactNode;
  modalContentProps?: ModalContentProps;
  isCentered?: boolean;
  closeOnOverlayClick?: boolean;
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  headerText,
  headerAddon,
  children,
  isCentered,
  closeOnOverlayClick,
  modalContentProps = {
    minW: '40rem',
    maxW: '50rem',
  },
  ...rest
}) => {
  return (
    <ChakraModal
      closeOnOverlayClick={closeOnOverlayClick}
      isOpen={isOpen}
      onClose={onClose}
      isCentered={isCentered}
    >
      <ModalOverlay />
      <ModalContent padding="2rem" paddingTop="1rem" borderRadius="1.5rem" {...modalContentProps}>
        {headerText && (
          <ModalHeader p={0} my={4} fontSize="24px" fontWeight="700">
            {headerText}
            {headerAddon}
          </ModalHeader>
        )}
        <ModalCloseButton
          as={IconButton}
          size="lg"
          icon={<MdClear size={30} />}
          bg="transparent"
          _focus={{
            outline: 0,
            boxShadow: 'none',
          }}
          _hover={{
            outline: 0,
            background: 'gray.100',
          }}
        />
        <ModalBody p={0} {...rest}>
          {children}
        </ModalBody>
      </ModalContent>
    </ChakraModal>
  );
};

export default Modal;
