import { Box, useDisclosure } from '@chakra-ui/react';
import React, { useState } from 'react';
import { MdClear } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '~/stores';
import { lossStatusObject } from '~/utils/commonObjects';

// import PostSendContract from '../../components/FunnelModal/PostSendContract';
import SendContractModal from '../../components/SendContractModal';
import { dontShowReasonForLoss } from '../../stores/modules/lead/actions';
import { amareloClaro2, vermelhoClaro } from '../../styles/colors';
import { CloseButton2, ContentDefault, SubmitButton, Text } from '../../styles/common';
import { Col, Row } from '../../styles/grid';
// import { lossStatusObject } from '../../utils/commonObjects';
import { Doc1, Doc2, Doc3, SvgBox } from './icons';

function Alerts({ slug, lose, another }: any) {
  const [loseVisible, setLoseVisible] = useState(true);
  const globalState = useSelector((state: RootState) => state);
  const { lead } = globalState?.lead;
  // const postSend = globalState?.lead?.success_send_contract;

  const dispatch = useDispatch();

  function handleDontShowMore() {
    setLoseVisible(false);
    dispatch(dontShowReasonForLoss(slug));
  }
  const { isOpen, onOpen, onClose } = useDisclosure();
  const contentSendContract = (
    <>
      <ContentDefault padding="15px" bgColor={amareloClaro2}>
        <Row alignCenter spaceBetween>
          <Col alignStart justifyCenter width="70%">
            <Text size="14px" regular>
              Você chegou até a última etapa do funil e está prestes a enviar o contrato para o
              estabelecimento.
            </Text>
            <Text size="14px">
              Para definir e enviar os termos de contrato clique no botão ao lado.
            </Text>
          </Col>
          <Col alignCenter justifyCenter width="auto">
            <SubmitButton onClick={onOpen} padding="5px 15px">
              <Row alignCenter justifyCenter>
                <Row width="auto" margin="0 5px 0 0">
                  <SvgBox scale="0.9">
                    <Doc1 d="M15,16H9a1,1,0,0,0,0,2h6a1,1,0,0,0,0-2Z" />
                    <Doc2 d="M9,14h3a1,1,0,0,0,0-2H9a1,1,0,0,0,0,2Z" />
                    <Doc3 d="M19.74,8.33l-5.44-6A1,1,0,0,0,13.56,2h-7A2.53,2.53,0,0,0,4,4.5v15A2.53,2.53,0,0,0,6.56,22H17.44A2.53,2.53,0,0,0,20,19.5V9a1,1,0,0,0-.26-.67ZM14,5l2.74,3h-2A.79.79,0,0,1,14,7.15Zm3.44,15H6.56A.53.53,0,0,1,6,19.5V4.5A.53.53,0,0,1,6.56,4H12V7.15A2.79,2.79,0,0,0,14.71,10H18v9.5a.53.53,0,0,1-.56.5Z" />
                  </SvgBox>
                </Row>
                <Row>Enviar Contrato</Row>
              </Row>
            </SubmitButton>
          </Col>
        </Row>
      </ContentDefault>
      <SendContractModal onClose={onClose} isOpen={isOpen} advertiserData={lead} />
    </>
  );

  const contentLoseLead = (
    <>
      {loseVisible && (
        <ContentDefault padding="15px" bgColor={vermelhoClaro}>
          <Col>
            <Row relative alignStart justifyCenter>
              <Col alignStart justifyStart>
                <Text size="15px">
                  {another
                    ? 'Esse estabelecimento foi perdido por outro Kuppido'
                    : 'Esse estabelecimento foi perdido'}
                </Text>
                <Row padding="8px" />
                <Text size="14px">Motivo da perda</Text>
                <Text size="14px" regular>
                  {(lossStatusObject as any)[lead?.reason_for_loss]}
                </Text>
                <Row padding="5px" />
                <Text size="14px">Observação</Text>
                <Text size="14px" regular>
                  {lead.loss_description ? lead.loss_description : 'Sem Observações'}
                </Text>
              </Col>
              <Col alignStart justifyStart width="auto" padding="0">
                <CloseButton2 onClick={() => setLoseVisible(false)}>
                  <Box as={MdClear} w="35px" h="35px" />
                </CloseButton2>
              </Col>
            </Row>
            <Row underline pointer alignCenter justifyEnd onClick={handleDontShowMore}>
              Não mostrar mais
            </Row>
          </Col>
        </ContentDefault>
      )}
    </>
  );

  return lose ? contentLoseLead : contentSendContract;
}

export default Alerts;
