import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import clients from './clients/sagas';
import dashboard from './dashboard/sagas';
import financial from './financial/sagas';
import lead from './lead/sagas';
import mgm from './mgm/sagas';
import user from './user/sagas';
import utils from './utils/sagas';

export default function* rootSaga() {
  return yield all([auth, user, lead, utils, dashboard, financial, clients, mgm]);
}
