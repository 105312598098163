import { Box, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import Button from '~/components/Button';
import InputWithValidation from '~/components/Form/InputWithValidation';
import SelectWithValidation from '~/components/Form/SelectWithValidation';
import useYupValidationResolver from '~/hooks/useYupValidationResolver';
import step3Schema from '~/models/schemas/sendContract/step3Schema';
import {
  advertiserMetricsInstagramFollowersOptions,
  advertiserMetricsRegionTypeOptions,
} from '~/utils/commonObjects';

import StepsModel from '../StepsModel';

interface ContractFields {
  region_type?: string;
  instagram?: string;
  instagram_followers_qty?: string;
}

interface Step3Props extends StepsModel {}

const Step3: React.FC<Step3Props> = ({
  handleChangeContractData,
  contractData,
  onClose,
  isOpen,
}) => {
  const { register, handleSubmit, setValue, errors } = useForm<ContractFields>({
    resolver: useYupValidationResolver(step3Schema),
    reValidateMode: 'onChange',
  });

  const [instagramValue, setInstagramValue] = useState(
    contractData?.instagram ? contractData?.instagram : '',
  );
  const [instagramFollowersValue, setInstagramFollowersValue] = useState(
    contractData?.instagram_followers_qty ? contractData?.instagram_followers_qty : 'Selecione',
  );

  useEffect(() => {
    setValue('region_type', contractData?.region_type);
    setValue('instagram', contractData?.instagram);
    setValue('instagram_followers_qty', contractData?.instagram_followers_qty);
  }, [contractData, isOpen, setValue]);

  // Clear instagram followers when the instagram was not provided
  useEffect(() => {
    if (instagramValue === '') {
      setInstagramFollowersValue('Selecione');
    }
  }, [instagramValue]);

  const onSubmit = (data: ContractFields) => {
    handleChangeContractData(data);
  };

  return (
    <>
      <Text fontSize="18px" fontWeight="700" mb="1.5rem">
        Métricas do estabelecimento
      </Text>

      <Box
        as="form"
        d="flex"
        flexDirection="column"
        h="100%"
        w="100%"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box h="100%" d="flex" flexDir="column">
          <Box d="flex" w="99.2%" flexDir="row">
            <SelectWithValidation
              textLabel="Como se configura a região em que seu cliente está presente?"
              name="region_type"
              errors={errors}
              register={register()}
              placeholder="Selecione"
              formProps={{ ml: '5px' }}
            >
              {advertiserMetricsRegionTypeOptions.map(
                (regionTypes: { id: string; title: string }) => (
                  <option key={regionTypes.id} value={regionTypes.id}>
                    {regionTypes.title}
                  </option>
                ),
              )}
            </SelectWithValidation>
          </Box>

          <Box d="flex" w="100%" mt="10px" flexDir="row">
            <InputWithValidation
              textLabel="Qual o instagram do estabelecimento?"
              name="instagram"
              errors={errors}
              register={register()}
              placeholder="@instagram"
              formProps={{ mx: '5px' }}
              onChange={(e) => {
                setInstagramValue(e.target.value);
              }}
            />

            <SelectWithValidation
              textLabel="Qual a faixa de seguidores ele tem?"
              name="instagram_followers_qty"
              errors={errors}
              register={register()}
              placeholder="Selecione"
              formProps={{ ml: '5px' }}
              isDisabled={instagramValue === ''}
              value={instagramFollowersValue}
              onChange={(e) => {
                setInstagramFollowersValue(e.target.value);
              }}
            >
              {advertiserMetricsInstagramFollowersOptions.map(
                (instagramFollowers: { id: string; title: string }) => (
                  <option key={instagramFollowers.id} value={instagramFollowers.id}>
                    {instagramFollowers.title}
                  </option>
                ),
              )}
            </SelectWithValidation>
          </Box>
        </Box>

        <Box mt="103px" pt="2.5rem" d="flex" justifyContent="center">
          <Button w="237px" type="button" variant="outline" mr="1rem" onClick={onClose}>
            Cancelar
          </Button>
          <Button w="237px" type="submit">
            Confirmar envio
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default Step3;
