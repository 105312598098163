export function getAddress(zipcode) {
  return {
    type: '@utils/GET_ADDRESS',
    payload: { zipcode },
  };
}

export function setAddress(address) {
  return {
    type: '@utils/SET_ADDRESS',
    payload: { address },
  };
}

export function endAction() {
  return {
    type: '@utils/END_ACTION',
  };
}

export function cleanAddress() {
  return {
    type: '@utils/CLEAN_ACTION',
  };
}
