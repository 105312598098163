import { Box, Text, useDisclosure } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { MdChevronLeft } from 'react-icons/md';
import { RiCheckboxCircleFill, RiCloseCircleFill } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import AlertDialog from '~/components/AlertDialog';
import Alerts from '~/components/Alerts';
import Modal, { ModalProps } from '~/components/Modal';
import AdvertiserSendContract from '~/models/AdvertiserSendContract';
import SendContract from '~/models/SendContract';
import { apiKuppido } from '~/services/api';
import { RootState } from '~/stores';
import { sendContract, sendContractStatus } from '~/stores/modules/lead/actions';
import { onlyNumbers } from '~/utils/onlyNumbers';

import Step1 from './Steps/Step1';
import Step2 from './Steps/Step2';
import Step3 from './Steps/Step3';
import Step4 from './Steps/Step4';
import StepsMarking from './StepsMarking';

interface SendContractProps extends ModalProps {
  advertiserData: AdvertiserSendContract;
}

enum SendContractStep {
  FIRST = '1',
  SECOND = '2',
  THIRD = '3',
  FOURTH = '4',
}

const SendContractModal: React.FC<SendContractProps> = ({ advertiserData, isOpen, onClose }) => {
  const [currentStep, setCurrentStep] = useState<SendContractStep>(SendContractStep.FIRST);
  const dispatch = useDispatch();
  const generalState = useSelector((state: RootState) => state);
  const { loading_send_contract: loading, send_contract_status } = generalState.lead;
  const [contractData, setContractData] = useState<SendContract>(null);
  const [subscriptionsOptions, setSubscriptionsOptions] = useState([]);

  const goFront = () => {
    setCurrentStep((prevState) => {
      if (prevState === SendContractStep.FIRST) {
        return SendContractStep.SECOND;
      } else if (prevState === SendContractStep.SECOND) {
        return SendContractStep.THIRD;
      } else if (prevState === SendContractStep.THIRD) {
        return SendContractStep.FOURTH;
      } else {
        return prevState;
      }
    });
  };

  const goBack = () => {
    setCurrentStep((prevState) => {
      if (prevState === SendContractStep.SECOND) {
        return SendContractStep.FIRST;
      } else if (prevState === SendContractStep.THIRD) {
        return SendContractStep.SECOND;
      } else if (prevState === SendContractStep.FOURTH) {
        return SendContractStep.THIRD;
      } else {
        onClose();
        return SendContractStep.FIRST;
      }
    });
  };

  const getSubscriptionOptions = async () => {
    try {
      const response = await apiKuppido.get('/subscriptions');

      let currentSubscriptions = response.data;

      return currentSubscriptions;
    } catch (err) {
      toast.error('Não foi possível carregar os planos');
      return false;
    }
  };

  useEffect(() => {
    async function getCurrentSubscriptions() {
      const currentSubscriptions = await getSubscriptionOptions();
      setSubscriptionsOptions(currentSubscriptions);
    }
    getCurrentSubscriptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setContractData({
      lead_id: advertiserData?.id,
      advertiser_name: advertiserData?.name,
      advertiser_category_id: advertiserData?.advertiser_category_id,
      cnpj: advertiserData?.cpf_cnpj,
      email: advertiserData?.email,
      zipcode: advertiserData?.zipcode,
      city: advertiserData?.city,
      street: advertiserData?.street,
      state: advertiserData?.state,
      neighborhood: advertiserData?.neighborhood,
      number: advertiserData?.number,
      complement: advertiserData?.complement,
      phone_1: advertiserData?.phone_1,
      phone_2: advertiserData?.phone_2,
    });
  }, [advertiserData]);

  const { isOpen: AlertIsOpen, onClose: AlertOnClose, onOpen: AlertOnOpen } = useDisclosure();

  useEffect(() => {
    dispatch(sendContractStatus(null));
  }, [dispatch]);

  const handleClose = () => {
    onClose();
    AlertOnClose();
    dispatch(sendContractStatus(null));
    setCurrentStep(SendContractStep.FIRST);
  };

  const onChangeContractData = (data: SendContract) => {
    setContractData((prevState) => {
      return { ...prevState, ...data };
    });
    goFront();

    if (currentStep === SendContractStep.FOURTH) {
      AlertOnOpen();
    }
  };

  const onSendContract = () => {
    dispatch(
      sendContract({
        ...contractData,
        zipcode: onlyNumbers(contractData.zipcode),
        cnpj: onlyNumbers(contractData.cnpj),
        complement: contractData.complement ? contractData.complement : null,
        phone_1: contractData.phone_1 ? `55${onlyNumbers(contractData.phone_1)}` : null,
        phone_2: contractData.phone_2 ? `55${onlyNumbers(contractData.phone_2)}` : null,
        phone_whatsapp: contractData.phone_whatsapp
          ? `55${onlyNumbers(contractData.phone_whatsapp)}`
          : null,
        phone_whatsapp_representative: contractData.phone_whatsapp_representative
          ? `55${onlyNumbers(contractData.phone_whatsapp_representative)}`
          : null,
      }),
    );
    AlertOnOpen();
  };

  return send_contract_status === null ? (
    <>
      <Modal
        h="515px"
        minH="515px"
        isOpen={isOpen}
        onClose={handleClose}
        d="flex"
        flexDir="column"
        closeOnOverlayClick={loading ? false : true}
        headerText={
          <Box d="flex" flexDir="column">
            Enviar contrato
            <Text
              fontSize="md"
              fontWeight="normal"
              d="flex"
              alignItems="center"
              cursor="pointer"
              onClick={goBack}
            >
              <Box as={MdChevronLeft} w="20px" h="20px" />
              voltar
            </Text>
          </Box>
        }
      >
        <StepsMarking step={Number(currentStep)} />

        {currentStep === SendContractStep.FIRST && (
          <Step1
            contractData={contractData}
            onClose={handleClose}
            isOpen={isOpen}
            handleChangeContractData={onChangeContractData}
          />
        )}

        {currentStep === SendContractStep.SECOND && (
          <Step2
            contractData={contractData}
            onClose={handleClose}
            isOpen={isOpen}
            handleChangeContractData={onChangeContractData}
          />
        )}

        {currentStep === SendContractStep.THIRD && (
          <Step3
            contractData={contractData}
            onClose={handleClose}
            isOpen={isOpen}
            handleChangeContractData={onChangeContractData}
          />
        )}

        {currentStep === SendContractStep.FOURTH && (
          <Step4
            subscriptionsOptions={subscriptionsOptions}
            contractData={contractData}
            onClose={handleClose}
            isOpen={isOpen}
            handleChangeContractData={onChangeContractData}
          />
        )}
      </Modal>

      <AlertDialog
        w="620px"
        maxW="620px"
        onClickYes={onSendContract}
        loadingButtonYes={loading}
        closeOnOverlayClick={loading ? false : true}
        isOpen={AlertIsOpen}
        onClose={AlertOnClose}
        headerText="Confirmar envio do contrato"
        bodyText={
          <>
            <Alerts
              mb="1.5rem"
              danger={false}
              text={
                <Text as="span" fontSize="15px">
                  Todos os dados a preenchidos anteriormente devem ser do estabelecimento, eles
                  serão utilizados no contrato, não podendo ser editados posteriormente.
                </Text>
              }
            />
            <Text fontWeight="bold" fontSize="md" textAlign="center" w="100%">
              Tem certeza que deseja enviar o contrato?
            </Text>
          </>
        }
      />
    </>
  ) : (
    <Modal
      modalContentProps={{
        h: '350px',
        w: '450px',
      }}
      isOpen={isOpen}
      onClose={handleClose}
      d="flex"
      flexDir="column"
      isCentered
    >
      {send_contract_status === true ? (
        <Box
          w="100%"
          h="100%"
          d="flex"
          flexDir="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box as={RiCheckboxCircleFill} w="5rem" h="5rem" color="green.500" />

          <Text textAlign="center" mt="2rem" fontSize="lg" fontWeight="bold">
            Contrato gerado com sucesso! <br />
            Verifique seu e-mail.
          </Text>
        </Box>
      ) : (
        <Box
          w="100%"
          h="100%"
          d="flex"
          flexDir="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box as={RiCloseCircleFill} w="5rem" h="5rem" color="red.400" />

          <Text textAlign="center" mt="2rem" fontSize="lg" fontWeight="bold">
            Falha ao enviar o contrato, <br />
            verifique os dados.
          </Text>
        </Box>
      )}
    </Modal>
  );
};

export default SendContractModal;
