import { Form } from '@rocketseat/unform';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import {
  editAddress,
  createBankAccount,
  editProfileOnboarding,
  setIsDoneOnboarding,
  endActionOnboarding,
  editBankAccount,
} from '../../stores/modules/user/actions';
import { cleanAddress, getAddress } from '../../stores/modules/utils/actions';
import { preto } from '../../styles/colors';
import {
  InputCustom,
  Label,
  LoadingBox,
  SelectCustom,
  SubmitButton,
  Text,
} from '../../styles/common';
import { Col, Row } from '../../styles/grid';
import { accountTypes, banks, statesOptions } from '../../utils/commonObjects';
import { cepMask, cnpjMask, cpfMask, phoneMask } from '../../utils/masks';
import { onlyNumbers } from '../../utils/onlyNumbers';
import CustomCheckBox from '../CustomCheckBox';
import { Ball, Container, Modal, ModelTitle } from './styles';
import CpfCnpjValidator from '~/utils/CpfCnpjValidator';
import { Spinner } from 'reactstrap';

export default function OnboardingModal({ visible }) {
  const user = useSelector((state) => state.user);
  const master = useSelector((state) => state.auth?.master);
  const [userState, setUserState] = useState(user);
  const [modal, setModal] = useState(visible);
  const { loadingOnboardingStep, successOnboardingStep } = user;

  const dispatch = useDispatch();

  /** ********* Bank Account ********** */
  const [agency, setAgency] = useState(onlyNumbers(user?.userAccountBank?.agency));
  const [account, setAccount] = useState(onlyNumbers(user?.userAccountBank?.account));
  const [phone1, setphone1] = useState(phoneMask(user?.user?.address[0]?.phone_1));
  const [phone2, setphone2] = useState(phoneMask(user?.user?.address[0]?.phone_2));
  const [phoneWhatsapp, setphoneWhatsapp] = useState(
    phoneMask(user?.user?.address[0]?.phone_whatsapp),
  );

  const [Content, setContent] = useState('userInfo');
  const toggleContent = (newContent) => setContent(newContent);

  /** ********* Address ********** */
  const receivedAddress = useSelector((state) => state.utils?.received_address);
  const [zipCodeField, setZipCodeField] = useState(user?.address?.zipcode);
  const [stateField, setStateField] = useState(user?.address?.state);
  const [cityField, setCityField] = useState(user?.address?.city);
  const [streetField, setStreetField] = useState(user?.address?.street);
  const [neighborhoodField, setNeighborhoodField] = useState(user?.address?.neighborhood);
  const [numberField, setNumberField] = useState(user?.address?.number);
  const [complementField, setComplementField] = useState(user?.address?.complement);

  useEffect(() => {
    if (zipCodeField) {
      if (zipCodeField.length === 10) {
        dispatch(getAddress(onlyNumbers(zipCodeField)));
      }
    }
  }, [zipCodeField, dispatch]);

  useEffect(() => {
    if (receivedAddress) {
      setStateField(receivedAddress.state);
      setCityField(receivedAddress.city);
      setStreetField(receivedAddress.street);
      setNeighborhoodField(receivedAddress.neighborhood);
      setNumberField('');
      setComplementField('');
    }
  }, [receivedAddress]);

  /** ********* User Info ********** */
  const [cpfCnpj, setcpfCnpj] = useState();
  const [isCorporationField, setIsCorporationField] = useState();
  const [isIndividualField, setIsIndividualField] = useState();

  useEffect(() => {
    if (user?.userInfo?.is_corporation) {
      setcpfCnpj(cnpjMask(user?.userInfo?.cpf_cnpj));
    } else {
      setcpfCnpj(cpfMask(user?.userInfo?.cpf_cnpj));
    }
  }, [user.userInfo]);

  const toggleToCorporation = () => {
    setIsCorporationField(true);
    setIsIndividualField(false);
    setcpfCnpj('');
  };

  const toggleToIndividual = () => {
    setIsCorporationField(false);
    setIsIndividualField(true);
    setcpfCnpj('');
  };

  useEffect(() => {
    setUserState(user);
    if (user?.userInfo?.is_corporation === true) {
      setIsCorporationField(true);
      setIsIndividualField(false);
    } else {
      setIsCorporationField(false);
      setIsIndividualField(true);
    }
  }, [user]);

  /** ****************************** */

  useEffect(() => {
    setModal(visible);
  }, [visible]);

  useEffect(() => {
    setUserState(user);
  }, [user]);

  const handleSubmitBank = ({
    bank_id,
    agency,
    agency_digit,
    account,
    account_digit,
    account_type,
    account_holder_name,
  }) => {
    if (
      bank_id === '' ||
      agency === '' ||
      account === '' ||
      account_digit === '' ||
      account_type === '' ||
      account_holder_name === ''
    ) {
      toast.error('Preencha todos os campos obrigatórios', {
        autoClose: 6000,
      });
    } else if (account_holder_name.length > 30) {
      toast.error(
        'O nome do titular deve ter no máximo 30 caracteres. Abrevie o nome caso seja necessário',
        {
          autoClose: 6000,
        },
      );
    } else {
      if (userState?.userAccountBank?.bank_id) {
        dispatch(
          editBankAccount(
            bank_id,
            agency,
            agency_digit,
            account,
            account_digit,
            account_type,
            account_holder_name,
          ),
        );
      } else {
        dispatch(
          createBankAccount(
            bank_id,
            agency,
            agency_digit,
            account,
            account_digit,
            account_type,
            account_holder_name,
          ),
        );
      }
    }
  };

  const handleSubmitAddress = ({
    street,
    number,
    complement,
    zipcode,
    neighborhood,
    city,
    state,
    phone_1,
    phone_2,
    phone_whatsapp,
  }) => {
    if (
      street === '' ||
      number === '' ||
      zipcode === '' ||
      neighborhood === '' ||
      city === '' ||
      state === '' ||
      phone_whatsapp === ''
    ) {
      toast.error('Preencha todos os campos obrigatórios', {
        autoClose: 6000,
      });
    } else if (zipcode?.length < 10) {
      toast.error('CEP inválido', {
        autoClose: 6000,
      });
    } else {
      dispatch(
        editAddress(
          street,
          number,
          complement,
          onlyNumbers(zipcode),
          neighborhood,
          city,
          state,
          onlyNumbers(phone_1),
          phone_2 && onlyNumbers(phone_2),
          phone_whatsapp && onlyNumbers(phone_whatsapp),
          true,
        ),
      );
    }
  };

  const handleInfoSubmit = ({ name, cpf_cnpj, is_corporation }) => {
    const isCorporation = is_corporation === 'true';

    if (!CpfCnpjValidator(cpf_cnpj)) {
      toast.error('CPF/CNPJ Inválido', {
        autoClose: 6000,
      });
      return;
    } else if (
      (isCorporation && onlyNumbers(cpf_cnpj).length < 14) ||
      (!isCorporation && onlyNumbers(cpf_cnpj).length < 11)
    ) {
      toast.error('CPF/CNPJ Inválido', {
        autoClose: 6000,
      });
      return;
    }

    if (name || cpf_cnpj || is_corporation) {
      dispatch(editProfileOnboarding(name, onlyNumbers(cpf_cnpj), is_corporation));
    }
  };

  useEffect(() => {
    if (successOnboardingStep) {
      switch (Content) {
        case 'userInfo':
          toggleContent('bank');
          break;
        case 'bank':
          toggleContent('address');
          break;
        case 'address':
          dispatch(setIsDoneOnboarding());
          dispatch(cleanAddress());
        default:
          phone_1;
          break;
      }
      dispatch(endActionOnboarding(false));
    }
  }, [successOnboardingStep]);

  const contentUserInfo = (
    <Container visible={modal}>
      <Modal width="600px" height="570px">
        <Col padding="20px 30px 10px 30px" height="auto">
          <ModelTitle>Confirmação de dados</ModelTitle>
          <Text size="14px" regular>
            Precisamos confirmar alguns de seus dados, preencha com muita atenção, para não conter
            nenhum erro que impossibilite você de receber as comissões.
          </Text>
        </Col>
        <Row padding="0 30px 30px 30px">
          <Form
            initialData={{
              name: userState?.userInfo?.name,
            }}
            onSubmit={handleInfoSubmit}
          >
            <Col height="auto" padding="15px 0">
              <Row alignCenter justifyStart>
                <Col margin="0 10px 0 0" width="auto">
                  <CustomCheckBox
                    label="Pessoa Física"
                    checked={isIndividualField}
                    onCheck={toggleToIndividual}
                    disabled={userState?.userInfo?.document_changed_at}
                  />
                </Col>
                <Col width="auto">
                  <CustomCheckBox
                    label="Pessoa Jurídica"
                    checked={isCorporationField}
                    onCheck={toggleToCorporation}
                    disabled={userState?.userInfo?.document_changed_at}
                  />
                </Col>
                {/* INPUT HIDDEN */}

                <InputCustom name="is_corporation" value={isCorporationField} hidden required />

                {/* INPUT HIDDEN */}
              </Row>
            </Col>

            <Col height="auto" padding="15px 0">
              <Label>Nome Completo</Label>
              <InputCustom name="name" type="text" required placeholder="Nome completo" />
            </Col>

            <Col height="auto" padding="15px 0">
              {isCorporationField ? (
                <>
                  <Label>CNPJ</Label>
                  <InputCustom
                    name="cpf_cnpj"
                    type="text"
                    required
                    placeholder="CNPJ"
                    maxLength="18"
                    value={cpfCnpj}
                    onChange={(e) => setcpfCnpj(cnpjMask(e.target.value))}
                  />
                </>
              ) : (
                <>
                  <Label>CPF</Label>
                  <InputCustom
                    name="cpf_cnpj"
                    type="text"
                    required
                    placeholder="CPF"
                    maxLength="14"
                    value={cpfCnpj}
                    onChange={(e) => setcpfCnpj(cpfMask(e.target.value))}
                  />
                </>
              )}
            </Col>

            <Row className="mt-auto" height="auto" alignCenter justifyStart>
              <Ball active />
              <Ball />
              <Ball />
              <Row width="auto" height="auto" className="ml-auto" alignCenter justifyCenter>
                <SubmitButton disabled={loadingOnboardingStep} type="submit">
                  {loadingOnboardingStep ? (
                    <LoadingBox>
                      <Spinner
                        style={{
                          width: '1.8rem',
                          height: '1.8rem',
                          color: preto,
                        }}
                      />{' '}
                    </LoadingBox>
                  ) : (
                    'Confirmar'
                  )}
                </SubmitButton>
              </Row>
            </Row>
          </Form>
        </Row>
      </Modal>
    </Container>
  );

  const contentBank = (
    <Container visible={modal}>
      <Modal width="600px" height="570px">
        <Col padding="20px 30px 10px 30px" height="auto">
          <ModelTitle>Cadastrar conta bancária</ModelTitle>
          <Text size="14px" regular>
            No segundo passo, você precisa cadastrar sua conta bancária para receber o pagamento das
            suas comissões.
          </Text>
        </Col>
        <Row padding="0 30px 30px 30px">
          <Form
            initialData={{
              bank_id: userState && userState?.userAccountBank?.bank_id,
              agency_digit: userState && userState?.userAccountBank?.agency_digit,
              account_digit: userState && userState?.userAccountBank?.account_digit,
              account_type: userState && userState?.userAccountBank?.account_type,
              account_holder_name: userState && userState?.userAccountBank?.account_holder_name,
            }}
            onSubmit={handleSubmitBank}
          >
            <Col>
              <Label>Selecione seu banco</Label>
              <SelectCustom name="bank_id" required options={banks} />
            </Col>

            <Row>
              <Row left>
                <Col left>
                  <Label>Número da Agência</Label>
                  <InputCustom
                    name="agency"
                    required
                    value={agency}
                    onChange={(e) => setAgency(onlyNumbers(e.target.value))}
                    type="text"
                    maxLength="4"
                  />
                </Col>

                <Col width="30%">
                  <Label>Dígito</Label>
                  <InputCustom name="agency_digit" maxLength="1" type="text" />
                </Col>
              </Row>

              <Row right>
                <Col left>
                  <Label>Número da Conta</Label>
                  <InputCustom
                    name="account"
                    value={account}
                    onChange={(e) => setAccount(onlyNumbers(e.target.value))}
                    type="text"
                    maxLength="20"
                  />
                </Col>

                <Col width="30%">
                  <Label>Dígito</Label>
                  <InputCustom name="account_digit" maxLength="1" type="text" required />
                </Col>
              </Row>
            </Row>

            <Row>
              <Col left>
                <Label>Tipo de Conta</Label>
                <SelectCustom name="account_type" required options={accountTypes} />
              </Col>

              <Col right>
                <Row>
                  <Col>
                    <Label>CPF/CNPJ do Titular da Conta</Label>
                    <InputCustom
                      name="cpf_cnpj"
                      type="text"
                      required
                      disabled
                      value={cpfCnpj}
                      onChange={(e) => setcpfCnpj(cnpjMask(e.target.value))}
                      maxLength="18"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col>
                <Label>Nome Completo do Titular da Conta (30 caracteres no máximo)</Label>
                <InputCustom name="account_holder_name" type="text" maxLength="30" required />
              </Col>
            </Row>

            <Row className="mt-auto" height="auto" alignCenter justifyStart>
              <Ball
                onClick={
                  master ? () => toggleContent('userMasterInfo') : () => toggleContent('userInfo')
                }
                active
              />
              <Ball active />
              <Ball />
              <Row width="auto" height="auto" className="ml-auto" alignCenter justifyCenter>
                <SubmitButton disabled={loadingOnboardingStep} type="submit">
                  {loadingOnboardingStep ? (
                    <LoadingBox>
                      <Spinner
                        style={{
                          width: '1.8rem',
                          height: '1.8rem',
                          color: preto,
                        }}
                      />{' '}
                    </LoadingBox>
                  ) : (
                    'Cadastrar'
                  )}
                </SubmitButton>
              </Row>
            </Row>
          </Form>
        </Row>
      </Modal>
    </Container>
  );

  const contentAddress = (
    <Container visible={modal}>
      <Modal width="600px" height="570px">
        <Col padding="20px 30px 10px 30px" height="auto">
          <ModelTitle>Cadastrar endereço</ModelTitle>
          <Text size="14px" regular>
            No terceiro passo, você precisa cadastrar seu endereço e telefones para contato.
          </Text>
          <br />
        </Col>
        <Row padding="0 30px 30px 30px">
          <Form onSubmit={handleSubmitAddress}>
            <Row height="auto" padding="20px 0">
              <Row width="30%" left>
                <Col>
                  <Label>Cep</Label>
                  <InputCustom
                    name="zipcode"
                    required
                    type="text"
                    placeholder="Cep"
                    maxLength="10"
                    value={cepMask(zipCodeField)}
                    onChange={(event) => setZipCodeField(cepMask(event.target.value))}
                  />
                </Col>
              </Row>
              <Row right>
                <Col left>
                  <Label>Endereço</Label>
                  <InputCustom
                    name="street"
                    required
                    value={streetField}
                    onChange={(event) => setStreetField(event.target.value)}
                    type="text"
                    maxLength="255"
                    placeholder="Endereço"
                  />
                </Col>
                <Col width="30%" right>
                  <Label>Número</Label>
                  <InputCustom
                    name="number"
                    required
                    type="text"
                    maxLength="10"
                    value={numberField}
                    onChange={(event) => setNumberField(event.target.value)}
                    placeholder="Número"
                  />
                </Col>
              </Row>
            </Row>

            <Row height="auto" padding="20px 0">
              <Col left>
                <Label>Complemento</Label>
                <InputCustom
                  name="complement"
                  type="text"
                  maxLength="100"
                  value={complementField}
                  onChange={(event) => setComplementField(event.target.value)}
                  placeholder="Complemento"
                />
              </Col>

              <Col center>
                <Label>Bairro</Label>
                <InputCustom
                  name="neighborhood"
                  type="text"
                  required
                  maxLength="100"
                  value={neighborhoodField}
                  onChange={(event) => setNeighborhoodField(event.target.value)}
                  placeholder="Bairro"
                />
              </Col>

              <Col center>
                <Label>Cidade</Label>
                <InputCustom
                  name="city"
                  required
                  type="text"
                  maxLength="100"
                  value={cityField}
                  onChange={(event) => setCityField(event.target.value)}
                  placeholder="Cidade"
                />
              </Col>

              <Col right>
                <Label>Estado</Label>
                <SelectCustom
                  placeholder="Estado"
                  name="state"
                  value={stateField}
                  onChange={(event) => setStateField(event.target.value)}
                  required
                  options={statesOptions}
                />
              </Col>
            </Row>

            <Row height="auto" padding="20px 0">
              <Col left>
                <Label>Telefone 1</Label>
                <InputCustom
                  name="phone_1"
                  type="text"
                  maxLength="13"
                  value={phone1}
                  onChange={(e) => setphone1(phoneMask(e.target.value))}
                  placeholder="Telefone 1"
                />
              </Col>

              <Col center>
                <Label>Telefone 2</Label>
                <InputCustom
                  name="phone_2"
                  type="text"
                  maxLength="13"
                  value={phone2}
                  onChange={(e) => setphone2(phoneMask(e.target.value))}
                  placeholder="Telefone 2"
                />
              </Col>

              <Col right>
                <Label>WhatsApp</Label>
                <InputCustom
                  name="phone_whatsapp"
                  type="text"
                  value={phoneWhatsapp}
                  maxLength="13"
                  required
                  onChange={(e) => setphoneWhatsapp(phoneMask(e.target.value))}
                  placeholder="WhatsApp"
                />
              </Col>
            </Row>

            <Row className="mt-auto" height="auto" alignCenter justifyStart>
              <Ball onClick={() => toggleContent('userInfo')} active />
              <Ball onClick={() => toggleContent('bank')} active />
              <Ball active />
              <Row width="auto" height="auto" className="ml-auto" alignCenter justifyCenter>
                <SubmitButton disabled={loadingOnboardingStep} type="submit">
                  {loadingOnboardingStep ? (
                    <LoadingBox>
                      <Spinner
                        style={{
                          width: '1.8rem',
                          height: '1.8rem',
                          color: preto,
                        }}
                      />{' '}
                    </LoadingBox>
                  ) : (
                    'Cadastrar'
                  )}
                </SubmitButton>
              </Row>
            </Row>
          </Form>
        </Row>
      </Modal>
    </Container>
  );

  if (Content === 'userInfo') {
    return contentUserInfo;
  }
  if (Content === 'bank') {
    return contentBank;
  }
  if (Content === 'address') {
    return contentAddress;
  }
}
