import * as yup from 'yup';

import { onlyNumbers } from '~/utils/onlyNumbers';

export default function updateContractValidation(
  currMinPlanAmount: string,
  currMaxPlanAmount: string,
) {
  return yup.object().shape({
    name: yup
      .string()
      .required('Por favor informe o nome do estabelecimento.')
      .max(100, 'O nome não deve ser maior que 100 caracteres.'),
    email: yup
      .string()
      .email('O email informado é inválido.')
      .required('Por favor informe o email do anunciante.')
      .max(255, 'O email não deve ser maior que 100 caracteres.'),
    phone: yup
      .string()
      .trim()
      .transform((value) => (value === '' ? undefined : value))
      .min(14, 'O telefone informado é inválido.')
      .max(15, 'O telefone informado é inválido.'),
    whatsapp: yup
      .string()
      .trim()
      .transform((value) => (value === '' ? undefined : value))
      .min(14, 'O telefone informado é inválido.')
      .max(15, 'O telefone informado é inválido.'),
    amount: yup
      .string()
      .test(
        'valid-amount',
        `O valor deve ser entre ${Number(currMinPlanAmount)} e ${Number(currMaxPlanAmount)}.`,
        (value: string) => {
          const parsedValue = parseInt(onlyNumbers(value));
          return (
            parsedValue <= parseInt(currMaxPlanAmount) && parsedValue >= parseInt(currMinPlanAmount)
          );
        },
      ),
    subscription_id: yup.number().required('Selecione um plano para este contrato.'),
  });
}
