import styled from '@emotion/styled';

import py2vw from '../../utils/px2vw';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
`;

export const Title = styled.div`
  font-size: ${py2vw(24)};
  font-weight: bold;
  font-family: AvenirNextLTPro;
  font-weight: 700;
`;

export const Field = styled.div`
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const ImageBox = styled.div`
  position: relative;
  max-width: 90px;
  max-height: 90px;
  width: 90px;
  height: 90px;
`;
export const Image = styled.img`
  position: absolute;
  border-radius: 45px;
  width: 100%;
  height: 100%;
`;
