import '~/configs/ReactotronConfig';

import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { persistor, store } from '~/stores';

import ThemeContainer from './theme/ThemeContainer';

const AppContainer: React.FC = ({ children }) => {
  return (
    <ThemeContainer>
      <Provider store={store}>
        <PersistGate persistor={persistor}>{children}</PersistGate>
      </Provider>
    </ThemeContainer>
  );
};

export default AppContainer;
