import styled from '@emotion/styled';

import { branco, cinzaEscuro2, cinzaMedio, verdeKuppi } from '../../styles/colors';

export const Container = styled.div`
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  background: rgb(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
  height: 100%;
  z-index: 99;
`;

export const Label = styled.div`
  margin-top: 22px;
  font-weight: bold;
  margin-bottom: 5px;
  text-align: left;
  font-size: 15px;
  font-family: AvenirNextLTPro;
  font-weight: 700;
`;

export const Modal = styled.div`
  position: relative;
  /* padding: 30px; */
  background: ${branco};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;

  width: 700px;
  ${(props) => props.width && `width: ${props.width} !important;`}
  ${(props) => props.height && `height: ${props.height} !important;`}
  /* height: 630px; */

  form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 100%;
  }
`;

export const ModelTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: ${cinzaEscuro2};
  font-family: AvenirNextLTPro;
  font-weight: 700;
`;

export const Ball = styled.div`
  cursor: pointer;
  height: 16px;
  width: 16px;
  border-radius: 10px;
  margin: 0 5px;
  background: ${(props) => (props.active ? verdeKuppi : cinzaMedio)};
`;
