import styled from '@emotion/styled';

import { cinzaEscuro2, cinzaMedio2, verdeKuppi } from '../../styles/colors';

export const SvgBox = styled.svg`
  height: 24px;
  width: 24px;
  cursor: pointer;
  fill: ${cinzaEscuro2};

  ${(props) => props.disabled && `cursor: default;`}
  ${(props) => props.disabled && `fill: ${cinzaMedio2};`}

  path {
    fill: ${(props) => (props.checked ? verdeKuppi : cinzaEscuro2)};
    ${(props) => props.disabled && `fill: ${cinzaMedio2};`};
  }
`;

export const CkeckBox = styled.path``;

export const CkeckBox2 = styled.path``;
