import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Spinner } from '@chakra-ui/react';
import { Form } from '@rocketseat/unform';
import { toast } from 'react-toastify';

import { branco, cinzaEscuro } from '~/styles/colors';

import LoginModal from '../../components/LoginModal';
import { endAction, loginRequest } from '../../stores/modules/auth/actions';
import {
  ButtonIcon,
  Container,
  Content,
  ContentLogo,
  ForgotBox,
  ForgotButton,
  GroupBox,
  InputCustom,
  Label,
  LeftImage,
  LeftImageBox,
  LoadingBox,
  LogoImage,
  LogoImageBox,
  SubmitButton,
} from './styles';

export default function Login() {
  const urlParams = new URLSearchParams(useLocation().search);
  const error = urlParams.get('erro');
  const [type, setType] = useState('password');
  const [icon, setIcon] = useState('visibility');
  const [modal, setModal] = useState(false);
  const loading = useSelector((state) => state.auth.loading);
  const dispatch = useDispatch();

  const toggle = () => setModal(!modal);

  useEffect(() => {
    if (error === 'sessao-invalida') {
      toast.error('Sua sessão está inválida. Faça login novamente', {
        autoClose: false,
      });
    }
  }, [error]);

  useEffect(() => {
    dispatch(endAction());
  }, [dispatch]);

  const handleShowPassword = useCallback(() => {
    if (icon === 'visibility') {
      setIcon('visibility_off');
      setType('text');
    }

    if (icon === 'visibility_off') {
      setIcon('visibility');
      setType('password');
    }
  }, [icon]);

  const handleSubmit = ({ email, password }) => {
    dispatch(loginRequest(email, password));
  };

  return (
    <Container>
      <Content padding="0">
        <LeftImageBox>
          <LeftImage src="/assets/images/login/login@2x.png" />
        </LeftImageBox>
      </Content>
      <Content>
        <ContentLogo>
          <LogoImageBox>
            <LogoImage src="/assets/images/logo_kuppi/logo_kuppi@2x.png" />
          </LogoImageBox>
        </ContentLogo>
        <Form onSubmit={handleSubmit}>
          <Label>E-mail</Label>
          <InputCustom name="email" type="email" placeholder="nome@email.com.br" required />
          <Label>Senha</Label>
          <GroupBox>
            <InputCustom
              group="true"
              name="password"
              type={type}
              required
              placeholder="Insira sua senha"
            />
            <ButtonIcon onClick={handleShowPassword} type="button">
              <Box
                as={icon === 'visibility' ? MdVisibility : MdVisibilityOff}
                color={cinzaEscuro}
                d="flex"
                alignItems="center"
                justifyContent="center"
                fontSize="16px"
              ></Box>
            </ButtonIcon>
          </GroupBox>
          <ForgotBox>
            <ForgotButton type="button" onClick={toggle}>
              Esqueci meu email ou senha
            </ForgotButton>
          </ForgotBox>
          <SubmitButton disabled={loading} type="submit">
            {loading ? (
              <LoadingBox>
                <Spinner w="16px" h="16px" color={branco} />
              </LoadingBox>
            ) : (
              'Entrar'
            )}
          </SubmitButton>
        </Form>
      </Content>
      <LoginModal visible={modal} onChange={toggle} />
    </Container>
  );
}
