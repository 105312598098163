import { List, ListProps } from '@chakra-ui/react';
import styled from '@emotion/styled';
import React from 'react';

const ListWithScrollbar = styled(List)`
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
    background-color: #f2f2f2;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: #f2f2f2;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #9b999b;
  }
`;

const ScrollableList: React.FC<ListProps> = ({ children, ...rest }) => {
  return <ListWithScrollbar {...rest}>{children}</ListWithScrollbar>;
};

export default ScrollableList;
