import { toast } from 'react-toastify';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { apiKuppido } from '../../../services/api';
import { notFound, setAdvertisers, setIndications } from './actions';
import { logout } from '../auth/actions';

const getState = (state) => state;

export function* indexIndications() {
  try {
    const state = yield select(getState);
    const userInfoId = state.user.userInfo.id;

    const response = yield call(apiKuppido.get, `/master/user-info/${userInfoId}/mgm-indications`);
    yield put(setIndications(response.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(notFound());
      toast.error('Não foi possível atualizar os dados.');
    }
  }
}

export function* indexAdvertisers() {
  try {
    const state = yield select(getState);
    const userInfoId = state.user.userInfo.id;

    const response = yield call(apiKuppido.get, `/master/user-info/${userInfoId}/mgm-advertisers`);
    yield put(setAdvertisers(response.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(notFound());
      toast.error('Não foi possível atualizar os dados.');
    }
  }
}

export default all([
  takeLatest('@mgm/INDEX_INDICATIONS', indexIndications),
  takeLatest('@mgm/INDEX_ADVERTISERS', indexAdvertisers),
]);
