import { onlyNumbers } from './onlyNumbers';

export function amountMask(value, decimals = true) {
  if (value !== null && value !== undefined) {
    const amount = onlyNumbers(value);

    // 999,99
    if (decimals) {
      return amount.replace(/(\d{1,3})(\d{2})/, 'R$ $1,$2');
    } else {
      return amount.replace(/(\d{1,3})/, 'R$ $1');
    }
  }
  return '';
}

export function dateMask(value) {
  if (value !== null && value !== undefined) {
    const date = onlyNumbers(value);

    // DD/MM/YYYY
    return date
      .replace(/(\d{2})(\d)/, '$1/$2')
      .replace(/(\d{2})(\d)/, '$1/$2')
      .replace(/(:\d{2})\d+?$/, '$1');
  }
  return '';
}

export function dateTimeMask(value) {
  if (value !== null && value !== undefined) {
    const dateTime = onlyNumbers(value);

    // DD/MM/YYYY HH:MM
    return dateTime
      .replace(/(\d{2})(\d)/, '$1/$2')
      .replace(/(\d{2})(\d)/, '$1/$2')
      .replace(/(\d{4})(\d{2})(\d)/, '$1 $2:$3')
      .replace(/(:\d{2})\d+?$/, '$1');
  }
  return '';
}

export function timeMask(value) {
  if (value !== null && value !== undefined) {
    const time = onlyNumbers(value);
    // HH:MM
    return time.replace(/(\d{2})(\d)/, '$1:$2').replace(/(-\d{2})\d+?$/, '$1');
  }
  return '';
}

export function cnpjMask(value) {
  if (value !== null && value !== undefined) {
    const cnpj = onlyNumbers(value);

    // 00.000.000/0000-00
    return cnpj
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1/$2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  }
  return '';
}

export function cpfMask(value) {
  if (value !== null && value !== undefined) {
    const cpf = onlyNumbers(value);

    // 000.000.000-00
    return cpf
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  }
  return '';
}

export function cpfCnpjMask(value) {
  if (value !== null && value !== undefined) {
    const cpfCnpj = onlyNumbers(value);

    if (cpfCnpj.length === 11) {
      // 000.000.000-00
      return cpfCnpj
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1');
    }
    // 00.000.000/0000-00
    return cpfCnpj
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1/$2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  }
  return '';
}

export function cepMask(value) {
  if (value !== null && value !== undefined) {
    const cep = onlyNumbers(value);

    // 00.000-000
    return cep
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1-$2')
      .replace(/(-\d{3})\d+?$/, '$1');
  }
  return '';
}

export function phoneMask(value) {
  if (value !== null && value !== undefined) {
    const phone = onlyNumbers(value);

    // 00 0000-0000
    if (phone.length <= 10) {
      return phone
        .replace(/(\d{2})(\d)/, '$1 $2')
        .replace(/(\d{4})(\d)/, '$1-$2')
        .replace(/(-\d{4})\d+?$/, '$1');
    }
    return phone
      .replace(/(\d{2})(\d)/, '$1 $2')
      .replace(/(\d{5})(\d)/, '$1-$2')
      .replace(/(-\d{4})\d+?$/, '$1');
  }
  return '';
}

export function cellPhoneMask(value) {
  if (value !== null && value !== undefined) {
    let phone = onlyNumbers(value);

    if (phone.length >= 12) {
      phone = phone.substring(2, phone.length);
    }

    // (00) 00000-0000
    if (phone.length <= 10) {
      return phone
        .replace(/(\d{2})(\d)/, '($1) $2')
        .replace(/(\d{4})(\d)/, '$1-$2')
        .replace(/(-\d{4})\d+?$/, '$1');
    }
    return phone
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{5})(\d)/, '$1-$2')
      .replace(/(-\d{4})\d+?$/, '$1');
  }
  return '';
}

export function cardValidityMask(value) {
  if (value !== null && value !== undefined) {
    const cardValidity = onlyNumbers(value);

    // 00/00
    return cardValidity.replace(/(\d{2})(\d)/, '$1/$2').replace(/(\/\d{2})\d+?$/, '$1');
  }
  return '';
}
