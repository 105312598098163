import produce from 'immer';

const INITIAL_STATE = {
  currentMonth: {
    month: '',
    leads_cliente_potencial: 0,
    leads_reuniao_marcada: 0,
    leads_ganhos: 0,
  },
  lastMonth: {
    month: '',
    leads_cliente_potencial: 0,
    leads_reuniao_marcada: 0,
    leads_ganhos: 0,
  },
  monthBeforeTheLast: {
    month: '',
    leads_cliente_potencial: 0,
    leads_reuniao_marcada: 0,
    leads_ganhos: 0,
  },
  bar_months: [],
  bar_months_data: [],
  conversion_rate: 0,
  leadsChartLoading: false,
  billingChartLoading: false,
};

export default function dashboard(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@dashboard/INDEX_LOG_LEADS':
        draft.leadsChartLoading = true;
        break;
      case '@financial/INDEX_ACTIVE_CONTRACTS':
        draft.billingChartLoading = true;
        break;
      case '@dashboard/SET_LOG_LEADS':
        draft.currentMonth = action.payload.currentMonth;
        draft.lastMonth = action.payload.lastMonth;
        draft.monthBeforeTheLast = action.payload.monthBeforeTheLast;
        draft.leadsChartLoading = false;
        break;
      case '@dashboard/SET_STRUCTURE_BILLING':
        draft.bar_months = action.payload.barMonths;
        draft.bar_months_data = action.payload.barMonthsData;
        draft.billingChartLoading = false;
        break;
      case '@dashboard/SET_CONVERSION_RATE':
        draft.conversion_rate = action.payload.rate;
        break;
      case '@auth/LOG_OUT':
        return INITIAL_STATE;
      default:
    }
  });
}
