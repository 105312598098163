import { Box, BoxProps, Divider, List, Text, TextProps, Tooltip } from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { RiCheckboxCircleLine, RiCloseCircleLine } from 'react-icons/ri';

import { Clients } from '~/models/Clients';

import ListItemTooltip from './ListItemTooltip';

const weekdays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

interface ClientOpenedTheStoreProps {
  client: Clients;
  iconProps?: BoxProps;
  textProps?: TextProps;
}

const ClientOpenedTheStore: React.FC<ClientOpenedTheStoreProps> = ({
  client,
  iconProps,
  textProps,
}) => {
  const [hasPhotos, setHasPhotos] = useState(false);
  const [hasProducts, setHasProducts] = useState(false);
  const [hasHoraries, setHasHoraries] = useState(false);
  const [hasPaymentMethods, setHasPaymentMethods] = useState(false);
  const [hasDeliveryParams, setHasDeliveryParams] = useState(false);

  const handleHoraries = useCallback(() => {
    const haveHoraries = weekdays.some((weekday) => {
      return client?.availabilities && (client?.availabilities as any)[weekday].is_active;
    });

    return haveHoraries;
  }, [client?.availabilities]);

  const handleDeliveryParams = useCallback(() => {
    if (client?.delivery_range_area > 0) {
      return true;
    }
    if (
      client?.delivery_dynamic_fee > 0 &&
      client?.delivery_dynamic_fee_max_dist &&
      client?.delivery_dynamic_fee_free_dist
    ) {
      return true;
    }

    return false;
  }, [client]);

  useEffect(() => {
    const clientHasHoraries = handleHoraries();
    const clientHasDeliveryParams = handleDeliveryParams();

    if (Number(client?.products_count) > 0) {
      setHasProducts(true);
    }
    if (client?.logo_url) {
      setHasPhotos(true);
    }
    if (clientHasHoraries) {
      setHasHoraries(true);
    }
    if (Number(client?.active_payments_count) > 0) {
      setHasPaymentMethods(true);
    }
    if (clientHasDeliveryParams) {
      setHasDeliveryParams(true);
    }
  }, [client, handleHoraries, handleDeliveryParams]);

  return (
    <Tooltip
      bg="white"
      border="1px solid #ddd"
      placement="top"
      label={
        <List spacing={3} color="black">
          <ListItemTooltip
            mt="10px"
            condition={hasProducts}
            text="Já existe pelo menos 1 item no cardápio?"
          />
          <Divider w="100%" />

          <ListItemTooltip condition={hasHoraries} text="Já existe horário de funcionamento?" />
          <Divider w="100%" />

          <ListItemTooltip condition={hasPhotos} text="Já tem foto de logo?" />
          <Divider w="100%" />

          <ListItemTooltip
            condition={hasPaymentMethods}
            text="Já foram selecionadas formas de pagamento?"
          />
          <Divider w="100%" />

          <ListItemTooltip
            mb="10px"
            condition={hasDeliveryParams}
            text="Já foram definidos os parâmetros de entrega?"
          />
        </List>
      }
    >
      <Box d="flex" alignItems="center" justifyContent="center">
        {hasPhotos && hasProducts && hasHoraries && hasPaymentMethods && hasDeliveryParams ? (
          <>
            <Box
              as={RiCheckboxCircleLine}
              height="25px"
              width="25px"
              color="green.500"
              mr="5px"
              {...iconProps}
            />
            <Text {...textProps}>Sim</Text>
          </>
        ) : (
          <>
            <Box
              as={RiCloseCircleLine}
              height="25px"
              width="25px"
              color="red.500"
              mr="5px"
              {...iconProps}
            />
            <Text {...textProps}>Não</Text>
          </>
        )}
      </Box>
    </Tooltip>
  );
};

export default ClientOpenedTheStore;
