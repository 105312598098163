import { useDisclosure } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { cinzaEscuro2 } from '~/styles/colors';

import { leadDetailForLink } from '../../routes/routeMap';
import { CustonLink } from '../../styles/common';
import SendContractModal from '../SendContractModal';
import ItemTag from './ItemTag';
import { FunnelItem, SubmitButton, Title } from './styles';

export default function Funel({ lead }) {
  const [leadItem, setLeadItem] = useState(lead);
  const recipientId = useSelector((state) => state.user?.userAccountBank?.recipient_id);
  const isKuppiCommercialTeam = useSelector((state) => state.auth?.isKuppiCommercialTeam);

  useEffect(() => {
    setLeadItem(lead);
  }, [lead]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  function handleSendContract() {
    // Verify if the kuppido does not has account and block the send contract, informing to register the account
    if (!recipientId && !isKuppiCommercialTeam) {
      toast.error(
        'Não foi possível enviar o contrato devido a você não ter cadastrado a conta corrente onde irá receber os repasses. Vá até o menu "Perfil e Conta" e faça o cadastro.',
      );
      // The kuppido has account, go to send contract or is a Kuppido 2.0
    } else {
      onOpen();
    }
  }

  return (
    <>
      <FunnelItem className="card" draggable="true" id={leadItem.slug}>
        <Title>
          <CustonLink
            draggable="false"
            color={cinzaEscuro2}
            noStyle
            to={`${leadDetailForLink}/${leadItem.slug}`}
          >
            {leadItem.name}
          </CustonLink>
        </Title>
        {leadItem.funnel_status === 'enviar_contrato' ? (
          <SubmitButton
            onClick={() => {
              handleSendContract();
            }}
          >
            Enviar Contrato
          </SubmitButton>
        ) : (
          <ItemTag lead={leadItem} />
        )}
      </FunnelItem>
      {leadItem.funnel_status === 'enviar_contrato' && (
        <SendContractModal
          draggable="false"
          id="SendContractModal"
          onClose={onClose}
          isOpen={isOpen}
          advertiserData={lead}
        />
      )}
    </>
  );
}
