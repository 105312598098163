import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  fonts: {
    body: 'AvenirNextLTPro, sans-serif',
    heading: 'AvenirNextLTPro, sans-serif',
    mono: 'Menlo, monospace',
  },
  colors: {
    transparent: 'transparent',
    black: '#000',
    white: '#fff',
    gray: {
      50: '#F2F2F2',
      100: '#EFEFEF',
      200: '#DBDBDB',
      300: '#B8B7B8',
      400: '#9B999B',
      500: '#7C797C',
      600: '#353839',
    },
    green: {
      50: '#DEFFE5',
      100: '#AEFFC1',
      200: '#7DFE9B',
      300: '#4AFE75',
      500: '#2BC48A',
      600: '#009D66',
      700: '#007744',
      800: '#005324',
      900: '#003103',
    },
  },
  breakpoints: ['30em', '48em', '62em', '80em'],
  fontSizes: {
    xs: '12px',
    sm: '14px',
    md: '16px',
    lg: '18px',
    xl: '20px',
    '2xl': '24px',
    '3xl': '28px',
    '4xl': '36px',
    '5xl': '48px',
    '6xl': '64px',
  },
  fontWeights: {
    normal: 400,
    medium: 500,
    bold: 700,
  },
  lineHeights: {
    normal: 'normal',
    none: '1',
    shorter: '1.25',
    short: '1.375',
    base: '1.5',
    tall: '1.625',
    taller: '2',
  },
  letterSpacings: {
    tighter: '-0.05em',
    tight: '-0.025em',
    normal: '0',
    wide: '0.025em',
    wider: '0.05em',
    widest: '0.1em',
  },
  space: {
    px: '1px',
    0: '0',
    0.5: '0.125rem',
    1: '0.25rem',
    1.5: '0.375rem',
    2: '0.5rem',
    2.5: '0.625rem',
    3: '0.75rem',
    3.5: '0.875rem',
    4: '1rem',
    5: '1.25rem',
    6: '1.5rem',
    7: '1.75rem',
    8: '2rem',
    9: '2.25rem',
    10: '2.5rem',
    12: '3rem',
    14: '3.5rem',
    16: '4rem',
    20: '5rem',
    24: '6rem',
    28: '7rem',
    32: '8rem',
    36: '9rem',
    40: '10rem',
    44: '11rem',
    48: '12rem',
    52: '13rem',
    56: '14rem',
    60: '15rem',
    64: '16rem',
    72: '18rem',
    80: '20rem',
    96: '24rem',
  },
  components: {
    Button: {
      variants: {
        solid: {
          _focus: {
            outline: 0,
            boxShadow: 'none',
          },
          _hover: {
            outline: 0,
            boxShadow: 'none',
          },
        },
        ghost: {
          _focus: {
            outline: 0,
            boxShadow: 'none',
          },
          _hover: {
            outline: 0,
            boxShadow: 'none',
          },
        },
      },
    },
  },
});

export default theme;
