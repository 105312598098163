import { Box, Spinner } from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { MdSentimentDissatisfied } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';

import { verdeKuppi } from '~/styles/colors';

import Funnel from '../../components/Funnel';
import NewLeadModal from '../../components/FunnelModal/NewLead';
import StatusLeadModal from '../../components/FunnelModal/StatusLead';
import LeadTable from '../../components/LeadTable';
import {
  indexLeads,
  indexLeads2,
  // cleanLeadSituation,
} from '../../stores/modules/lead/actions';
import { getAdvertiserCategoriesRequest, getAllprofile } from '../../stores/modules/user/actions';
import { ContentDefault, SwitchTableButton, Text } from '../../styles/common';
import { Col, Row } from '../../styles/grid';
import {
  AddButton,
  Container,
  Field,
  Title,
  // SearchInput,
  // SearchBox,
} from './styles';
import TermAndPolicies from '~/components/TermAndPolicies';
import OnboardingModal from '~/components/OnboardingModal';
import OnboardingMasterModal from '~/components/OnboardingMasterModal';
import TermAndPoliciesInitialCommercialTeam from '~/components/TermAndPoliciesInitialCommercialTeam';

// import { SvgBox, Search } from './icons';

export default function LeadManagement() {
  const loading = useSelector((state) => state.lead.lead_loading);
  const internalLoading = useSelector((state) => state.lead.lead_internal_loading);
  const leads = useSelector((state) => state.lead.all_leads);
  const serverError = useSelector((state) => state.lead.leads_not_found);
  const situation = useSelector((state) => state.lead.leads_situation);
  const isDoneOnboarding = useSelector((state) => state.user?.userInfo?.is_done_onboarding);
  const { master, isKuppiCommercialTeam } = useSelector((state) => state.auth);
  const isTermsAccepted = useSelector((state) => state.user?.userInfo?.is_terms_accepted);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(indexLeads());
    dispatch(getAllprofile());
    dispatch(getAdvertiserCategoriesRequest());
  }, [dispatch]);

  const [visibleOne, setVisibleOne] = useState(false);
  const toggleOne = useCallback(() => setVisibleOne(!visibleOne), [visibleOne]);

  const [visibleTwo, setVisibleTwo] = useState(false);
  const toggleTwo = useCallback(() => setVisibleTwo(!visibleTwo), [visibleTwo]);

  // const [filter, setFilter] = useState('Sempre');
  // const toggleFilter = (newFilter) => setFilter(newFilter);

  useEffect(() => {
    if (situation) {
      setVisibleTwo(true);
    }
  }, [situation]);

  const [table, setTable] = useState('funnel');
  const toggleTable = (newTable) => {
    dispatch(indexLeads2());
    setTable(newTable);
  };

  const pageContent = (
    <>
      <Container>
        <Row alignStart justifyStart>
          <Title>Gestão de Leads</Title>

          {/* <SearchBox className="ml-auto">
            <SvgBox>
              <Search />
            </SvgBox>
            <SearchInput
              name="search"
              placeholder="Busque por um lead"
              onFocus={() => toggleTable('leads')}
            />
          </SearchBox> */}
        </Row>
        <Row padding="20px" />
        <ContentDefault flexStart groupTop>
          <Field>
            <SwitchTableButton
              onClick={table !== 'funnel' ? () => toggleTable('funnel') : null}
              selected={table === 'funnel'}
            >
              Funil de Vendas
              <div className="selected" />
            </SwitchTableButton>
          </Field>
          <Field>
            <SwitchTableButton
              onClick={table !== 'leads' ? () => toggleTable('leads') : null}
              selected={table === 'leads'}
            >
              Leads
              <div className="selected" />
            </SwitchTableButton>
          </Field>
          <Field mlAuto>
            <AddButton onClick={toggleOne}>Adicionar negócio</AddButton>
          </Field>
        </ContentDefault>
        <ContentDefault groupBottom padding="20px 10px">
          {!internalLoading && table === 'leads' && <LeadTable leads={leads} />}
          {!internalLoading && table === 'funnel' && <Funnel leads={leads} />}
          {internalLoading && (
            <Col height="30vh" alignCenter justifyCenter>
              <Spinner w="50px" h="50px" color={verdeKuppi} />
            </Col>
          )}
        </ContentDefault>
        <NewLeadModal visible={visibleOne} onChange={toggleOne} />
        <StatusLeadModal visible={visibleTwo} onChange={toggleTwo} />
      </Container>
      {/* Show Terms and Policies Modal, if is a Kuppido 1.0, Kuppido 1.0 Master or Kuppido X (Not Kuppido 2.0) */}
      {!isKuppiCommercialTeam && <TermAndPolicies visible={!isTermsAccepted} />}
      {/* Show Onboarding Modal, if is a Kuppido 1.0 or Kuppido X (Not Kuppido 2.0 or Kuppido 1.0 Master)) */}
      {!isKuppiCommercialTeam && !master && (
        <OnboardingModal visible={isTermsAccepted && !isDoneOnboarding} />
      )}
      {/* Show Onboarding Modal, if is a Kuppido 1.0 Master (Not Kuppido 2.0, Kuppido 1.0 or Kuppido X)) */}
      {!isKuppiCommercialTeam && master && (
        <OnboardingMasterModal visible={isTermsAccepted && !isDoneOnboarding} />
      )}
      {/* Show Onboarding Modal, if is a Kuppido 2.0 (Not Kuppido 1.0, Kuppido 1.0 Master or Kuppido X)) */}
      {isKuppiCommercialTeam && (
        <TermAndPoliciesInitialCommercialTeam visible={!isDoneOnboarding} />
      )}
    </>
  );

  const loader = (
    <Col height="85vh" alignCenter justifyCenter>
      <Spinner w="50px" h="50px" color={verdeKuppi} />
    </Col>
  );

  const serverErrorContent = (
    <Col height="85vh" alignCenter justifyCenter>
      <Row alignCenter justifyCenter>
        <Box as={MdSentimentDissatisfied} w="22px" h="22px" />
        <Text margin="0 0 0 5px">
          Não foi possível se conectar com o servidor, por favor, recarregue a página. Caso o erro
          persista, contacte o suporte.
        </Text>
      </Row>
    </Col>
  );

  if (loading) {
    return loader;
  }
  if (serverError) {
    return serverErrorContent;
  }
  return pageContent;
}
