import { Box } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { GrFormClose } from 'react-icons/gr';
import { MdMoreVert } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';

import { destroyActivity, destroyNote } from '../../stores/modules/lead/actions';
import ModalActivity from '../LeadDetailModal/Activity';
import ModalNotes from '../LeadDetailModal/Notes';
import { ButtonEdit, ChangeButton, Container, ModalEdit } from './styles';

export default function Content({ disabled, item, type }) {
  const dispatch = useDispatch();
  const lead_id = useSelector((state) => state.lead.lead.id);
  const [visible, setVisible] = useState(false);
  const [Item, setItem] = useState(item);
  const handleVisible = () => setVisible(!visible);

  useEffect(() => {
    setItem(item);
  }, [item]);

  const [notesModalVisible, setNotesModalVisible] = useState(false);
  const toggleNotes = () => setNotesModalVisible(!notesModalVisible);

  const [activityModalVisible, setActivityModalVisible] = useState(false);
  const toggleActivity = () => setActivityModalVisible(!activityModalVisible);

  const handleEdit = () => {
    if (type === 'notes') {
      toggleNotes();
    } else if (type === 'activity') {
      toggleActivity();
    }
    handleVisible();
  };

  const handleDelete = () => {
    const { id } = Item;

    if (type === 'notes') {
      dispatch(destroyNote(lead_id, id));
    } else if (type === 'activity') {
      dispatch(destroyActivity(lead_id, id));
    }
    handleVisible();
  };

  return (
    <>
      <Container>
        <ModalEdit visible={visible}>
          {!Item.is_concluded && (
            <ButtonEdit onClick={disabled !== null && handleEdit}>Editar</ButtonEdit>
          )}
          <ButtonEdit onClick={disabled !== null && handleDelete}>Excluir</ButtonEdit>
        </ModalEdit>
        <ChangeButton onClick={handleVisible} visible={visible}>
          {!visible ? (
            <Box as={MdMoreVert} w="22px" h="22px" />
          ) : (
            <Box
              alignItems="center"
              justifyContent="center"
              w="22px"
              h="22px"
              border="2px solid #000"
              borderRadius="50%"
            >
              <Box as={GrFormClose} w="100%" h="100%" />
            </Box>
          )}
        </ChangeButton>
      </Container>
      {type === 'notes' && (
        <ModalNotes visible={notesModalVisible} item={Item} onChange={toggleNotes} />
      )}
      {type === 'activity' && (
        <ModalActivity visible={activityModalVisible} item={Item} onChange={toggleActivity} />
      )}
    </>
  );
}
