import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { updateActivity } from '../../stores/modules/lead/actions';
import { Text } from '../../styles/common';
import { Row } from '../../styles/grid';
import { SvgBox } from './icons';

export default function ActivityCheckBox({ item, disabled }) {
  const [checkedComponent, setCheckedComponent] = useState(item.is_concluded);
  const lead_id = useSelector((state) => state.lead.lead.id);

  const dispatch = useDispatch();

  useEffect(() => {
    setCheckedComponent(item.is_concluded);
  }, [item]);

  const toggle = () => {
    setCheckedComponent(!checkedComponent);
    const activity_id = item.id;
    const { name } = item;
    const { description } = item;
    const { type } = item;
    const is_concluded = !checkedComponent;
    const { activity_at } = item;
    dispatch(
      updateActivity(activity_id, lead_id, name, description, type, activity_at, is_concluded),
    );
  };

  return (
    <Row width="auto" justifyCenter alignCenter>
      <SvgBox checked={checkedComponent} disabled={disabled} onClick={!disabled && toggle}>
        <path d="M18,3H6A3,3,0,0,0,3,6V18a3,3,0,0,0,3,3H18a3,3,0,0,0,3-3V6A3,3,0,0,0,18,3Zm1,15a1,1,0,0,1-1,1H6a1,1,0,0,1-1-1V6A1,1,0,0,1,6,5H18a1,1,0,0,1,1,1Z" />
        {checkedComponent && (
          <path d="M14.7,8.39l-3.78,5L9.29,11.28a1,1,0,1,0-1.58,1.23l2.43,3.11a1,1,0,0,0,1.58-.01l4.57-6a1.006,1.006,0,0,0-1.6-1.22Z" />
        )}
      </SvgBox>

      <Text
        margin="0 0 0 5px"
        size="14px"
        regular
        color={checkedComponent ? '#26ab81' : disabled ? '#9B999B' : '#f9ae00'}
      >
        {checkedComponent ? 'Atividade concluída' : 'Concluir Atividade'}
      </Text>
    </Row>
  );
}
