import styled from '@emotion/styled';

import { cinzaClaro3 } from '../../../styles/colors';
import px2vw from '../../../utils/px2vw';

export const Container = styled.div`
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
  min-height: 100vh;
  flex-direction: row;
  flex: 1;
  width: 100%;
  height: 100%;
  background-color: ${cinzaClaro3};
  padding: ${px2vw(36)};
  /* border-top-left-radius: 60px;
  border-bottom-left-radius: 60px; */

  /* ${(props) => props.height && `height:${props.height};`} */
`;

export const Content = styled.div``;
