export const states = {
  AC: 'Acre – AC',
  AL: 'Alagoas – AL',
  AP: 'Amapá – AP',
  AM: 'Amazonas – AM',
  BA: 'Bahia – BA',
  CE: 'Ceará – CE',
  DF: 'Distrito Federal – DF',
  ES: 'Espírito Santo – ES',
  GO: 'Goiás – GO',
  MA: 'Maranhão – MA',
  MT: 'Mato Grosso – MT',
  MS: 'Mato Grosso do Sul – MS',
  MG: 'Minas Gerais – MG',
  PA: 'Pará – PA',
  PB: 'Paraíba – PB',
  PR: 'Paraná – PR',
  PE: 'Pernambuco – PE',
  PI: 'Piauí – PI',
  RR: 'Roraima – RR',
  RO: 'Rondônia – RO',
  RJ: 'Rio de Janeiro – RJ',
  RN: 'Rio Grande do Norte – RN',
  RS: 'Rio Grande do Sul – RS',
  SC: 'Santa Catarina – SC',
  SP: 'São Paulo – SP',
  SE: 'Sergipe – SE',
  TO: 'Tocantins – TO',
};

export const statesOptions = [
  { id: 'AC', title: 'Acre – AC' },
  { id: 'AL', title: 'Alagoas – AL' },
  { id: 'AP', title: 'Amapá – AP' },
  { id: 'AM', title: 'Amazonas – AM' },
  { id: 'BA', title: 'Bahia – BA' },
  { id: 'CE', title: 'Ceará – CE' },
  { id: 'DF', title: 'Distrito Federal – DF' },
  { id: 'ES', title: 'Espírito Santo – ES' },
  { id: 'GO', title: 'Goiás – GO' },
  { id: 'MA', title: 'Maranhão – MA' },
  { id: 'MT', title: 'Mato Grosso – MT' },
  { id: 'MS', title: 'Mato Grosso do Sul – MS' },
  { id: 'MG', title: 'Minas Gerais – MG' },
  { id: 'PA', title: 'Pará – PA' },
  { id: 'PB', title: 'Paraíba – PB' },
  { id: 'PR', title: 'Paraná – PR' },
  { id: 'PE', title: 'Pernambuco – PE' },
  { id: 'PI', title: 'Piauí – PI' },
  { id: 'RR', title: 'Roraima – RR' },
  { id: 'RO', title: 'Rondônia – RO' },
  { id: 'RJ', title: 'Rio de Janeiro – RJ' },
  { id: 'RN', title: 'Rio Grande do Norte – RN' },
  { id: 'RS', title: 'Rio Grande do Sul – RS' },
  { id: 'SC', title: 'Santa Catarina – SC' },
  { id: 'SP', title: 'São Paulo – SP' },
  { id: 'SE', title: 'Sergipe – SE' },
  { id: 'TO', title: 'Tocantins – TO' },
];

export const tableSegments = [
  'Açaiteria',
  'Bebidas',
  'Cafeteria',
  'Comida Árabe',
  'Comida Brasileira',
  'Comida Chinesa',
  'Comida Japonesa',
  'Comida Mexicana',
  'Comida Peruana',
  'Comida Saudável',
  'Congelados',
  'Conveniência',
  'Doceria',
  'Hamburgueria',
  'Lanches',
  'Massas',
  'Mercado',
  'Padaria',
  'Pizzaria',
  'Poke',
  'Sacolé',
  'Sorveteria',
  'Tabacaria',
  'Outros',
];

export const funnelStatus = [
  { id: 'cliente_potencial', title: 'Cliente Potencial' },
  { id: 'contactado', title: 'Contactado' },
  { id: 'reuniao_marcada', title: 'Reunião Marcada' },
  { id: 'proposta_enviada', title: 'Proposta Enviada' },
  { id: 'em_negociacao', title: 'Em Negociação' },
  { id: 'enviar_contrato', title: 'Enviar Contrato' },
];

export const subscriptionsArray = [
  { id: 1, title: 'Adotante Inicial' },
  { id: 2, title: 'Plano Quadrimestral' },
  { id: 3, title: 'Plano Semestral' },
];

export const subscriptionsArray2 = [
  { id: 2, title: 'Plano Quadrimestral' },
  { id: 3, title: 'Plano Semestral' },
];

export const activityTypes = [
  { id: 'mensagem', title: 'Mensagem' },
  { id: 'ligacao', title: 'Ligação' },
  { id: 'email', title: 'E-mail' },
  { id: 'visita', title: 'Visita' },
  { id: 'outra', title: 'Outra' },
  { id: 'solicitacao_de_dados', title: 'Solicitação de dados' },
  { id: '1_contato', title: '1º contato' },
  { id: 'reuniao', title: 'Reunião' },
];

export const lossStatus = [
  { id: 'nao_tinha_capital', title: 'Não tinha capital.' },
  { id: 'nao_tinha_interesse', title: 'Não tinha interesse.' },
  {
    id: 'ja_foi_cliente_e_teve_experiencia_ruim',
    title: 'Já foi cliente e teve uma experiência ruim.',
  },
  { id: 'achou_caro', title: 'Achou caro.' },
  {
    id: 'achou_que_a_kuppi_era_uma_ferramenta_para_fazer_vender_mais_a_curto_prazo',
    title: 'Achou que a Kuppi era uma ferramenta para fazer vender mais a curto prazo.',
  },
  { id: 'ainda_nao_abriu_empresa', title: 'Ainda não abriu a empresa.' },
  { id: 'estabelecimento_fechado', title: 'Estabelecimento fechado.' },
  { id: 'esta_apenas_estudando', title: 'Está apenas estudando.' },
  { id: 'ja_tem_cardapio_digital', title: 'Já tem cardápio digital.' },
  { id: 'nao_houve_contato', title: 'Não houve contato.' },
  { id: 'nao_tem_interesse', title: 'Não tem interesse.' },
  { id: 'nao_tem_computador', title: 'Não tem computador.' },
  { id: 'nao_tem_motoboy_proprio', title: 'Não tem motoboy próprio.' },
  { id: 'tem_menos_de_6_pedidos_por_dia', title: 'Tem menos do que 6 pedidos por dia.' },
  {
    id: 'precisava_de_uma_funcionalidade_que_nao_temos',
    title: 'Precisava de uma funcionalidade que não temos.',
  },
  {
    id: 'nao_e_uma_prioridade_no_momento',
    title: 'Não é uma prioridade no momento.',
  },
];

export const lossStatusObject = {
  nao_tinha_capital: 'Não tinha capital.',
  nao_tinha_interesse: 'Não tinha interesse.',
  ja_foi_cliente_e_teve_experiencia_ruim: 'Já foi cliente e teve uma experiência ruim.',
  achou_caro: 'Achou caro.',
  achou_que_a_kuppi_era_uma_ferramenta_para_fazer_vender_mais_a_curto_prazo:
    'Achou que a Kuppi era uma ferramenta para fazer vender mais a curto prazo.',
  ainda_nao_abriu_empresa: 'Ainda não abriu a empresa.',
  estabelecimento_fechado: 'Estabelecimento fechado.',
  esta_apenas_estudando: 'Está apenas estudando.',
  ja_tem_cardapio_digital: 'Já tem cardápio digital.',
  nao_houve_contato: 'Não houve contato.',
  nao_tem_interesse: 'Não tem interesse.',
  nao_tem_computador: 'Não tem computador.',
  nao_tem_motoboy_proprio: 'Não tem motoboy próprio.',
  tem_menos_de_6_pedidos_por_dia: 'Tem menos do que 6 pedidos por dia.',
  precisava_de_uma_funcionalidade_que_nao_temos: 'Precisava de uma funcionalidade que não temos.',
  nao_e_uma_prioridade_no_momento: 'Não é uma prioridade no momento.',
};

export const activityTypesObject = {
  mensagem: 'Mensagem',
  ligacao: 'Ligação',
  email: 'E-mail',
  visita: 'Visita',
  outra: 'Outra',
  solicitacao_de_dados: 'Solicitação de dados',
  '1_contato': '1º contato',
  reuniao: 'Reunião',
};

export const accountTypesObject = {
  conta_corrente: 'Conta Corrente',
  conta_poupanca: 'Conta Poupanca',
  // conta_corrente_conjunta: 'Conta Corrente Conjunta',
  // conta_poupanca_conjunta: 'Conta Poupanca Conjunta',
};

export const accountTypes = [
  { id: 'conta_corrente', title: 'Conta Corrente' },
  { id: 'conta_poupanca', title: 'Conta Poupanca' },
  // { id: 'conta_corrente_conjunta', title: 'Conta Corrente Conjunta' },
  // { id: 'conta_poupanca_conjunta', title: 'Conta Poupanca Conjunta' },
];

export const banks = [
  { id: '1', title: '001 - Banco do Brasil S.A' },
  { id: '2', title: '003 - Banco da Amazônia S.A' },
  { id: '3', title: '004 - Banco do Nordeste do Brasil S.A' },
  { id: '4', title: '021 - BANESTES S.A. Banco do Estado do Espírito Santo' },
  { id: '5', title: '033 - Banco Santander (Brasil) S.A' },
  { id: '6', title: '037 - Banco do Estado do Pará S.A' },
  { id: '7', title: '041 - Banco do Estado do Rio Grande do Sul S.A' },
  { id: '8', title: '047 - Banco do Estado de Sergipe S.A' },
  { id: '9', title: '070 - BRB - Banco de Brasília S.A' },
  { id: '10', title: '077 - Banco Inter S.A' },
  { id: '11', title: '104 - Caixa Econômica Federal' },
  { id: '12', title: '208 - Banco BTG Pactual S.A' },
  { id: '13', title: '212 - Banco Original S.A' },
  { id: '14', title: '224 - Banco Fibra S.A' },
  { id: '15', title: '237 - Banco Bradesco S.A' },
  { id: '16', title: '246 - Banco ABC Brasil S.A' },
  { id: '17', title: '254 - Paraná Banco S.A' },
  { id: '18', title: '260 - Nu Pagamentos S.A' },
  { id: '19', title: '269 - Banco HSBC S.A' },
  { id: '20', title: '290 - PagSeguro' },
  { id: '21', title: '341 - Itaú Unibanco S.A' },
  { id: '22', title: '336 - Banco C6 S.A' },
  { id: '23', title: '389 - Banco Mercantil do Brasil S.A' },
  { id: '24', title: '422 - Banco Safra S.A' },
  { id: '25', title: '604 - Banco Industrial do Brasil S.A' },
  { id: '26', title: '623 - Banco Pan S.A' },
  { id: '27', title: '633 - Banco Rendimento S.A' },
  { id: '28', title: '653 - Banco Indusval S.A' },
  { id: '29', title: '655 - Banco Votorantim S.A' },
  { id: '30', title: '707 - Banco Daycoval S.A' },
  { id: '31', title: '745 - Banco Citibank S.A' },
  { id: '32', title: '746 - Banco Modal S.A' },
  { id: '33', title: '748 - Banco Cooperativo Sicredi S.A.' },
  { id: '34', title: '756 - Banco Cooperativo do Brasil S.A.' },
  { id: '35', title: '323 - Mercado Pago' },
  { id: '36', title: '301 - Dock IP SA' },
];

export const banksObject = {
  '001': 'Banco do Brasil S.A',
  '003': 'Banco da Amazônia S.A',
  '004': 'Banco do Nordeste do Brasil S.A',
  '021': 'BANESTES S.A. Banco do Estado do Espírito Santo',
  '033': 'Banco Santander (Brasil) S.A',
  '037': 'Banco do Estado do Pará S.A',
  '041': 'Banco do Estado do Rio Grande do Sul S.A',
  '047': 'Banco do Estado de Sergipe S.A',
  '070': 'BRB - Banco de Brasília S.A',
  '077': 'Banco Inter S.A',
  104: 'Caixa Econômica Federal',
  208: 'Banco BTG Pactual S.A',
  212: 'Banco Original S.A',
  224: 'Banco Fibra S.A',
  237: 'Banco Bradesco S.A',
  246: 'Banco ABC Brasil S.A',
  254: 'Paraná Banco S.A',
  260: 'Nu Pagamentos S.A',
  269: 'Banco HSBC S.A',
  290: 'PagSeguro',
  301: 'Dock IP SA',
  323: 'Mercado Pago',
  341: 'Itaú Unibanco S.A',
  336: 'Banco C6 S.A',
  389: 'Banco Mercantil do Brasil S.A',
  422: 'Banco Safra S.A',
  604: 'Banco Industrial do Brasil S.A',
  623: 'Banco Pan S.A',
  633: 'Banco Rendimento S.A',
  653: 'Banco Indusval S.A',
  655: 'Banco Votorantim S.A',
  707: 'Banco Daycoval S.A',
  745: 'Banco Citibank S.A',
  746: 'Banco Modal S.A',
  756: 'Banco Cooperativo do Brasil S.A.',
  748: 'Banco Cooperativo Sicredi S.A.',
};

export const freeDaysPeriodObject = [
  { id: '7', title: '7 dias' },
  //{ id: '15', title: '15 dias' },
];

export const advertiserMetricsRegionTypeOptions = [
  { id: 'interior', title: 'Interior' },
  { id: 'metropole', title: 'Metrópole' },
  { id: 'capital', title: 'Capital' },
];

export const advertiserMetricsInstagramFollowersOptions = [
  { id: '1-500', title: 'De 1 a 500 seguidores' },
  { id: '501-1000', title: 'De 501 a 1000 seguidores' },
  { id: '1001-2000', title: 'De 1001 a 2000 seguidores' },
  { id: '2001-5000', title: 'De 2001 a 5000 seguidores' },
  { id: 'more-5000', title: 'Mais de 5000 seguidores' },
];
