import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import SideBar from '../../../components/Signed/SideBar';
import Skel from '../../../components/Signed/Skel';
import { Wrapper } from './styles';

export default function SignedLayout({ children, option }) {
  const user = useSelector((state) => state.user.user);

  // Octadesk definitions - login data
  useEffect(() => {
    window.addEventListener('onOctaChatReady', function octadeskLogin() {
      window.removeEventListener('onOctaChatReady', octadeskLogin);
      window.octadesk.chat.login({
        user: {
          name: `${user.kuppidoInfo.name} - ${user.kuppidoInfo.id} (Kuppido)`,
          email: `${user.email}`,
        },
      });
    });
  }, []);

  return (
    <Wrapper>
      <SideBar option={option} />
      <Skel height="100%">{children}</Skel>
    </Wrapper>
  );
}

SignedLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
