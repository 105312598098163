import { Button as ChakraButton, ButtonProps as ChakraButtonProps } from '@chakra-ui/react';
import React from 'react';

export interface ButtonProps extends ChakraButtonProps {
  register?: any;
}

const Button: React.FC<ButtonProps> = ({
  children,
  register,
  colorScheme = 'green',
  border = '2px solid',
  borderRadius = '10px',
  fontSize = 'md',
  fontWeight = 'bold',
  color = 'gray.700',
  h = '47px',
  w = '100%',
  _focus = {
    boxShadow: 'none',
  },
  _active = { filter: 'brightness(90%)' },
  ...rest
}) => {
  return (
    <ChakraButton
      ref={register}
      colorScheme={colorScheme}
      border={rest.variant === 'outline' && border}
      borderRadius={borderRadius}
      w={w}
      h={h}
      color={rest.variant === 'outline' ? color : 'white'}
      fontSize={fontSize}
      fontWeight={fontWeight}
      _focus={_focus}
      _active={_active}
      _hover={{}}
      {...rest}
    >
      {children}
    </ChakraButton>
  );
};

export default Button;
