import { Box, Text } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import Button from '~/components/Button';
import InputWithValidation from '~/components/Form/InputWithValidation';
import SelectWithValidation from '~/components/Form/SelectWithValidation';
import useYupValidationResolver from '~/hooks/useYupValidationResolver';
import step1Schema from '~/models/schemas/sendContract/step1Schema';
import validateUnique from '~/services/validateUnique';
import { RootState } from '~/stores';
import { cellPhoneMask, cpfCnpjMask } from '~/utils/masks';
import { onlyNumbers } from '~/utils/onlyNumbers';

import StepsModel from '../StepsModel';

interface ContractFields {
  advertiser_name?: string;
  cnpj?: string;
  email?: string;
  phone_1?: string;
  phone_2?: string;
  phone_whatsapp?: string;
  advertiser_category_id?: string;
  representative_name?: string;
  phone_whatsapp_representative?: string;
}

interface Step1Props extends StepsModel {}

const Step1: React.FC<Step1Props> = ({
  handleChangeContractData,
  contractData,
  onClose,
  isOpen,
}) => {
  const { register, handleSubmit, setValue, errors, setError } = useForm<ContractFields>({
    resolver: useYupValidationResolver(step1Schema),
    reValidateMode: 'onChange',
  });
  const segments = useSelector((state: RootState) => state.user.advertiserCategories);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setValue('advertiser_name', contractData?.advertiser_name);
    setValue('cnpj', cpfCnpjMask(contractData?.cnpj));
    setValue('email', contractData?.email);
    setValue('phone_1', cellPhoneMask(contractData?.phone_1));
    setValue('phone_2', cellPhoneMask(contractData?.phone_2));
    setValue('phone_whatsapp', cellPhoneMask(contractData?.phone_whatsapp));
    setValue('advertiser_category_id', contractData?.advertiser_category_id);
    setValue('representative_name', contractData?.representative_name);
    setValue(
      'phone_whatsapp_representative',
      cellPhoneMask(contractData?.phone_whatsapp_representative),
    );
  }, [contractData, isOpen, setValue]);

  const validatePhone = (phone_number: string, name: any) => {
    if (phone_number.length > 0 && phone_number.length < 14) {
      setError(name, {
        message: 'Número de telefone inválido.',
        type: 'number_is_very_small',
      });
      return false;
    }
    return true;
  };

  const emailInputRef = useRef(null);
  const cnpfInputRef = useRef(null);

  const onSubmit = async (data: ContractFields) => {
    setLoading(true);
    const validatePhone1 = validatePhone(data.phone_1, 'phone_1');
    const validatePhone2 = validatePhone(data.phone_2, 'phone_2');
    const validatePhoneWhatsapp = validatePhone(data.phone_whatsapp, 'phone_whatsapp');
    const validatePhoneWhatsappRepresentative = validatePhone(
      data.phone_whatsapp_representative,
      'phone_whatsapp_representative',
    );

    const validateEmail = await validateUnique({ email: data.email });
    const validateCnpj = await validateUnique({ advertiser_cnpj: onlyNumbers(data.cnpj) });

    if (!validateCnpj) {
      setError('cnpj', {
        message: 'O CPF/CNPJ informado já está em uso.',
        type: 'cnpj_not_unique',
      });
      cnpfInputRef?.current?.focus();
    }

    if (!validateEmail) {
      setError('email', {
        message: 'O E-mail informado já está em uso.',
        type: 'email_not_unique',
      });
      emailInputRef?.current?.focus();
    }

    if (
      validatePhone1 &&
      validateCnpj &&
      validatePhone2 &&
      validatePhoneWhatsapp &&
      validatePhoneWhatsappRepresentative &&
      validateEmail
    ) {
      handleChangeContractData(data);
    }
    setLoading(false);
  };

  return (
    <>
      <Box
        as="form"
        d="flex"
        flexDirection="column"
        h="100%"
        w="100%"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Text fontSize="18px" fontWeight="700" mb="1.5rem">
          Informações e contato do estabelecimento
        </Text>

        <Box h="100%" d="flex" flexDir="column">
          <Box d="flex" w="100%">
            <InputWithValidation
              textLabel="Nome do estabelecimento"
              name="advertiser_name"
              errors={errors}
              register={register()}
              placeholder="Nome do estabelecimento"
              formProps={{ mr: '5px' }}
            />
            <InputWithValidation
              textLabel="CPF/CNPJ"
              name="cnpj"
              errors={errors}
              register={(e: HTMLInputElement) => {
                register(e);
                cnpfInputRef.current = e;
              }}
              placeholder="000.000.000-00 / 00.000.000/0000-00"
              formProps={{ mx: '5px' }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setValue('cnpj', cpfCnpjMask(e.target.value), { shouldValidate: true })
              }
            />
            <InputWithValidation
              textLabel="E-mail"
              name="email"
              errors={errors}
              register={(e: HTMLInputElement) => {
                register(e);
                emailInputRef.current = e;
              }}
              placeholder="E-mail"
              formProps={{ ml: '5px' }}
            />
          </Box>
          <Box d="flex" w="100%" mt="10px">
            <InputWithValidation
              textLabel="Telefone 1"
              name="phone_1"
              placeholder="(00) 00000-0000"
              errors={errors}
              register={register()}
              formProps={{ mr: '5px' }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setValue('phone_1', cellPhoneMask(e.target.value), { shouldValidate: true })
              }
            />

            <InputWithValidation
              textLabel="Telefone 2"
              name="phone_2"
              placeholder="(00) 00000-0000"
              errors={errors}
              register={register()}
              formProps={{ mx: '5px' }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setValue('phone_2', cellPhoneMask(e.target.value), { shouldValidate: true })
              }
            />

            <InputWithValidation
              textLabel="WhatsApp"
              name="phone_whatsapp"
              placeholder="(00) 00000-0000"
              errors={errors}
              register={register()}
              formProps={{ ml: '5px' }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setValue('phone_whatsapp', cellPhoneMask(e.target.value), { shouldValidate: true })
              }
            />
          </Box>

          <Box d="flex" w="100%" mt="10px">
            <SelectWithValidation
              textLabel="Segmento"
              name="advertiser_category_id"
              errors={errors}
              register={register()}
              placeholder="Escolha um Segmento"
              formProps={{ mr: '5px' }}
            >
              {segments.map((state: { id: number; title: string }) => (
                <option key={state.id} value={state.id}>
                  {state.title}
                </option>
              ))}
            </SelectWithValidation>
            <InputWithValidation
              textLabel="Nome do responsável"
              name="representative_name"
              errors={errors}
              register={register()}
              placeholder="Nome do responsável"
              formProps={{ mx: '5px' }}
            />
            <InputWithValidation
              textLabel="WhatsApp do responsável"
              name="phone_whatsapp_representative"
              placeholder="(00) 00000-0000"
              errors={errors}
              register={register()}
              formProps={{ ml: '5px' }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setValue('phone_whatsapp_representative', cellPhoneMask(e.target.value), {
                  shouldValidate: true,
                })
              }
            />
          </Box>
        </Box>

        <Box mt="auto" pt="4rem" d="flex" justifyContent="center">
          <Button w="237px" type="button" variant="outline" mr="1rem" onClick={onClose}>
            Cancelar
          </Button>
          <Button w="237px" type="submit" isLoading={loading}>
            Confirmar envio
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default Step1;
