import { Box, Slider, SliderFilledTrack, SliderThumb, SliderTrack, Text } from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import Button from '~/components/Button';
import SelectWithValidation from '~/components/Form/SelectWithValidation';
import Switch from '~/components/Switch';
import useYupValidationResolver from '~/hooks/useYupValidationResolver';
import step4Validation from '~/models/schemas/sendContract/step4Schema';
import Subscription from '~/models/Subscription';
import { freeDaysPeriodObject } from '~/utils/commonObjects';
import { formatCurrencyBRL } from '~/utils/currency';

import StepsModel from '../StepsModel';

interface ContractFields {
  subscription_id?: string;
  amount?: string;
}

interface Step4Props extends StepsModel {
  subscriptionsOptions: Subscription[];
}

const Step4: React.FC<Step4Props> = ({
  handleChangeContractData,
  contractData,
  isOpen,
  onClose,
  subscriptionsOptions,
}) => {
  const { isKuppiCommercialTeam } = useSelector((state: any) => state.auth);
  const [currDefPlanAmount, setCurrDefPlanAmount] = useState<string>('300');
  const [currMinPlanAmount, setCurrMinPlanAmount] = useState<string>('100');
  const [currMaxPlanAmount, setCurrMaxPlanAmount] = useState<string>('300');
  const [subscription, setSubscription] = useState<string>();
  const [freePlan, setFreePlan] = useState<boolean>(contractData?.free_plan);
  const [freeDaysPeriod, setFreeDaysPeriod] = useState<number>(7);
  const getSelectedSubscription = useMemo((): Subscription => {
    const finded = subscriptionsOptions.find((subs) => {
      return Number(subs.id) === Number(subscription);
    });
    return finded;
  }, [subscriptionsOptions, subscription]);

  const { register, handleSubmit, setValue, errors, watch, control } = useForm<ContractFields>({
    resolver: useYupValidationResolver(step4Validation(currMinPlanAmount, currMaxPlanAmount)),
    reValidateMode: 'onChange',
  });

  const amount = useMemo(() => {
    return watch('amount');
  }, [watch]);

  useEffect(() => {
    setValue('subscription_id', contractData?.subscription_id);
    setValue('amount', contractData?.amount ? contractData?.amount : currDefPlanAmount);
    setFreePlan(contractData?.free_plan ? contractData?.free_plan : false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractData, isOpen, setValue]);

  const toggleSwitch = () => {
    setFreePlan(!freePlan);
  };

  const onSubmit = (data: ContractFields) => {
    handleChangeContractData({
      ...data,
      free_plan: freePlan,
      free_plan_days_period: freeDaysPeriod,
    });
  };

  return (
    <Box
      as="form"
      d="flex"
      flexDirection="column"
      h="100%"
      w="100%"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box d="flex" flexDir="column">
        <SelectWithValidation
          textLabel="Defina o Plano"
          name="subscription_id"
          errors={errors}
          register={register()}
          placeholder="Selecione um plano"
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            setSubscription(e.target.value);

            subscriptionsOptions.forEach((currSub: any) => {
              if (parseInt(currSub.id, 10) === parseInt(e.target.value, 10)) {
                setCurrDefPlanAmount(currSub.amount || '300');
                setValue(
                  'amount',
                  contractData?.amount
                    ? contractData?.amount
                    : currSub.amount
                    ? currSub.amount
                    : '300',
                );
                setCurrMinPlanAmount(currSub.min_amount || '100');
                setCurrMaxPlanAmount(currSub.max_amount || '300');
              }
            });
          }}
        >
          {subscriptionsOptions?.map((s) => {
            if (s.slug === 'plano_inaugural' && !isKuppiCommercialTeam) {
              return;
            }

            return (
              <option key={s.id} value={s.id}>
                {s.name}
              </option>
            );
          })}
        </SelectWithValidation>
        <Box
          d="flex"
          flexDir="column"
          justifyContent="space-between"
          bg="gray.100"
          mt="1rem"
          w="100%"
          h="10rem"
          p="1rem"
        >
          <Box d="flex" justifyContent="space-between">
            <Text fontSize="1.2rem" fontWeight="bold">
              Deslize para definir o valor do plano
            </Text>
            <Text fontSize="1.2rem" fontWeight="regular">
              {`${formatCurrencyBRL(Number(currMinPlanAmount))} - ${formatCurrencyBRL(
                Number(currMaxPlanAmount),
              )}`}
            </Text>
          </Box>

          <Controller
            name="amount"
            control={control}
            defaultValue={250}
            as={
              <Slider
                defaultValue={Number(currDefPlanAmount)}
                step={1}
                min={Number(currMinPlanAmount)}
                max={Number(currMaxPlanAmount)}
                name="amount"
              >
                <SliderTrack bg="gray.200">
                  <SliderFilledTrack bg="green.500" />
                </SliderTrack>
                <SliderThumb
                  boxSize={6}
                  bg="green.500"
                  _focus={{ boxShadow: '0 0 0 3px #2BC48A60' }}
                ></SliderThumb>
              </Slider>
            }
          />

          <Box d="flex" justifyContent="space-between">
            <Text fontSize="1.2rem" fontWeight="bold">
              Valor mensal {formatCurrencyBRL(Number(amount))}{' '}
            </Text>
            {subscription && (
              <Text fontSize="1.2rem" fontWeight="bold">
                Valor total:{' '}
                {formatCurrencyBRL(Number(amount) * Number(getSelectedSubscription?.period))}
              </Text>
            )}
          </Box>
        </Box>

        <Box mt="1rem" mb={'2rem'}>
          <>
            <Box d="flex" flexDir="row">
              <Switch
                text="Conceder período gratuito"
                colorScheme="green"
                size="lg"
                onChange={toggleSwitch}
                isChecked={freePlan}
              />
              {freePlan && (
                <Box w="115px" ml="1rem" mt="-0.3rem">
                  <SelectWithValidation
                    textLabel=""
                    name="free_days_period"
                    errors={errors}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                      setFreeDaysPeriod(Number(e.target.value))
                    }
                  >
                    {freeDaysPeriodObject?.map((period) => (
                      <option key={period.id} value={period.id}>
                        {period.title}
                      </option>
                    ))}
                  </SelectWithValidation>
                </Box>
              )}
            </Box>
            <Text mt="10px">
              * Após completar o período gratuito, será cobrado o valor referente ao plano
              escolhido.
            </Text>
          </>
        </Box>
      </Box>

      <Box mt="-10px" d="flex" justifyContent="center">
        <Button w="237px" variant="outline" mr="1rem" onClick={onClose}>
          Cancelar
        </Button>
        <Button w="237px" type="submit">
          Confirmar envio
        </Button>
      </Box>
    </Box>
  );
};

export default Step4;
