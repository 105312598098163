export function onlyNumbers(value) {
  if (value) {
    return value.replace(/\D/g, '');
  }
  return '';
}

export function onlyNumbersAmount(value) {
  if (value) {
    return value.replace(/R$ /g, '');
  }
  return '';
}

export function formatCurrencyBRL(value, options) {
  const maximumFractionDigits = options.maximumFractionDigits || 2;
  const formatter = new Intl.NumberFormat('pt-BR', {
    currency: 'BRL',
    style: 'currency',
    maximumFractionDigits,
    ...options,
  });
  return formatter.format(value);
}
