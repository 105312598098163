export function indexIndications() {
  return {
    type: '@mgm/INDEX_INDICATIONS',
  };
}

export function indexAdvertisers() {
  return {
    type: '@mgm/INDEX_ADVERTISERS',
  };
}

export function setIndications(indications) {
  return {
    type: '@mgm/SET_INDICATIONS',
    payload: { indications },
  };
}

export function setAdvertisers(advertisers) {
  return {
    type: '@mgm/SET_ADVERTISERS',
    payload: { advertisers },
  };
}

export function endAction() {
  return {
    type: '@mgm/END_ACTION',
  };
}

export function notFound() {
  return {
    type: '@mgm/NOT_FOUND',
  };
}
