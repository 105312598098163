import React, { useEffect, useState } from 'react';

import { cinzaEscuro2, cinzaMedio2 } from '../../styles/colors';
import { Text } from '../../styles/common';
import { Row } from '../../styles/grid';
import { CkeckBox, CkeckBox2, SvgBox } from './icons';

export default function ActivityCheckBox({ label, disabled, checked, onCheck }) {
  const [checkedComponent, setCheckedComponent] = useState(checked);

  useEffect(() => {
    setCheckedComponent(checked);
  }, [checked]);

  const toggle = () => {
    if (!checkedComponent) {
      onCheck();
      setCheckedComponent(true);
    }
  };

  return (
    <Row width="auto" justifyCenter alignCenter>
      <SvgBox checked={checkedComponent} disabled={disabled} onClick={!disabled && toggle}>
        <CkeckBox d="M18,3H6A3,3,0,0,0,3,6V18a3,3,0,0,0,3,3H18a3,3,0,0,0,3-3V6A3,3,0,0,0,18,3Zm1,15a1,1,0,0,1-1,1H6a1,1,0,0,1-1-1V6A1,1,0,0,1,6,5H18a1,1,0,0,1,1,1Z" />
        {checkedComponent && (
          <CkeckBox2 d="M14.7,8.39l-3.78,5L9.29,11.28a1,1,0,1,0-1.58,1.23l2.43,3.11a1,1,0,0,0,1.58-.01l4.57-6a1.006,1.006,0,0,0-1.6-1.22Z" />
        )}
      </SvgBox>
      <Text margin="0 0 0 5px" size="14px" regular color={disabled ? cinzaMedio2 : cinzaEscuro2}>
        {label && label}
      </Text>
    </Row>
  );
}
