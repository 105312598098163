import { Box, Divider } from '@chakra-ui/react';
import { push } from 'connected-react-router';
import { format } from 'date-fns';
import React, { useEffect } from 'react';
import { MdChevronLeft } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import ClientContractWarning from '~/components/ClientContractWarning';
import ClientOpenedTheStore from '~/components/ClientOpenedTheStore';
import { Clients } from '~/models/Clients';
import { cinzaEscuro2 } from '~/styles/colors';
import { formatCurrencyBRL } from '~/utils/currency';

import { clientManagement } from '../../routes/routeMap';
import { BigText2, ContentDefault, CustonLink, LabelField, TextField } from '../../styles/common';
import { Col, Row } from '../../styles/grid';
import { cellPhoneMask, cepMask, cpfCnpjMask } from '../../utils/masks';
import { Container, Field, Image, ImageBox, Title } from './styles';

interface LocationProps {
  detail: Clients;
  type: 'active' | 'inactive';
}

const ClientsDetail: React.FC<any> = () => {
  const { state } = useLocation<LocationProps>();
  const dispatch = useDispatch();
  const detail = state?.detail;
  const type = state?.type;

  useEffect(() => {
    if (!detail) {
      dispatch(push(clientManagement));
    }
  }, [detail, dispatch]);

  return (
    <Container>
      <Row alignCenter justifyStart>
        <Row alignCenter justifyStart width="auto">
          <CustonLink color={cinzaEscuro2} to={clientManagement}>
            <Box as={MdChevronLeft} w="22px" h="22px" /> voltar
          </CustonLink>
        </Row>
      </Row>
      <Row margin="0 0 30px 0" alignCenter justifyStart>
        <Title>Detalhes cliente</Title>
      </Row>
      <Col>
        <ContentDefault padding="0" alignStart>
          <Field>
            <BigText2>{detail?.total_orders_count}</BigText2>
            <LabelField bold>Total de pedidos</LabelField>
          </Field>
          <Divider orientation="vertical" w="1px" h="128px" bg="#F6F7FB" />
          <Field>
            <BigText2>{detail?.new_clients_count}</BigText2>
            <LabelField bold>Clientes novos</LabelField>
          </Field>
          <Divider orientation="vertical" w="1px" h="128px" bg="#F6F7FB" />
          <Field>
            <BigText2>{detail?.recurrent_clients_count}</BigText2>
            <LabelField bold>Clientes Recorrentes</LabelField>
          </Field>
          <Divider orientation="vertical" w="1px" h="128px" bg="#F6F7FB" />
          <Field>
            <BigText2>{formatCurrencyBRL(detail?.revenue)}</BigText2>
            <LabelField bold>Faturamento Total</LabelField>
          </Field>
          <Divider orientation="vertical" w="1px" h="128px" bg="#F6F7FB" />
          <Field>
            <BigText2>
              <ClientOpenedTheStore
                iconProps={{ w: '40px', h: '40px' }}
                // textProps={{ fontSize: '1.5rem' }}
                client={detail}
              />
            </BigText2>
            <LabelField bold>Inaugurou a loja?</LabelField>
          </Field>
        </ContentDefault>

        <ContentDefault>
          <Field>
            <ImageBox>
              <Image
                src={
                  detail?.logo_url
                    ? `${detail?.logo_url}?nocache=${new Date().getTime()}`
                    : '/assets/images/profile/profile@3x.png'
                }
              />
            </ImageBox>
          </Field>

          <Field>
            <LabelField bold>Estabelecimento</LabelField>
            <TextField>{detail?.name}</TextField>
          </Field>
          <Field>
            <LabelField bold>Categoria</LabelField>
            <TextField>{detail?.category}</TextField>
          </Field>
          <Field>
            <LabelField bold>CPF/CNPJ</LabelField>
            <TextField>{cpfCnpjMask(detail?.cnpj)}</TextField>
          </Field>
          <Field>
            <LabelField bold>Vigência de contrato</LabelField>
            <Box d="flex" alignItems="center">
              <Box as={TextField} mr="5px" color={type === 'inactive' && '#E50148'}>
                {detail?.start_validity_at &&
                  format(new Date(detail?.start_validity_at), 'dd/MM/yyyy')}{' '}
                à{' '}
                {detail?.finish_validity_at &&
                  format(new Date(detail?.finish_validity_at), 'dd/MM/yyyy')}
              </Box>
              {type === 'active' && (
                <ClientContractWarning
                  // offTooltip={true}
                  client={detail}
                />
              )}
            </Box>
          </Field>
        </ContentDefault>

        <ContentDefault groupTop>
          <Field>
            <LabelField bold>Telefone 1</LabelField>
            <TextField>{cellPhoneMask(detail?.address?.phone_1)}</TextField>
          </Field>
          <Field>
            <LabelField bold>Telefone 2</LabelField>
            <TextField>{cellPhoneMask(detail?.address?.phone_2) || '-'}</TextField>
          </Field>
          <Field>
            <LabelField bold>Whatsapp</LabelField>
            <TextField>{cellPhoneMask(detail?.address?.phone_whatsapp) || '-'}</TextField>
          </Field>
          <Field>
            <LabelField bold>E-mail</LabelField>
            <TextField>{detail?.email}</TextField>
          </Field>
          <Field>
            <LabelField />
            <TextField />
          </Field>
        </ContentDefault>
        <ContentDefault groupBottom>
          <Field>
            <LabelField bold>Endereço</LabelField>
            <TextField>
              {detail?.address?.street}
              {detail?.address?.number ? `, ${detail?.address?.number}` : `, S/N`}{' '}
              {detail?.address?.complement && `, ${detail?.address?.complement}`}
            </TextField>
          </Field>
          <Field>
            <LabelField bold>Bairro</LabelField>
            <TextField>{detail?.address?.neighborhood}</TextField>
          </Field>
          <Field>
            <LabelField bold>Cidade</LabelField>
            <TextField>{detail?.address?.city}</TextField>
          </Field>
          <Field>
            <LabelField bold>Estado</LabelField>
            <TextField>{detail?.address?.state}</TextField>
          </Field>
          <Field>
            <LabelField bold>CEP</LabelField>
            <TextField>{cepMask(detail?.address?.zipcode)}</TextField>
          </Field>
        </ContentDefault>
      </Col>
    </Container>
  );
};

export default ClientsDetail;
