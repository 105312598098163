import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import Button from '~/components/Button';

import { storeNote } from '../../stores/modules/lead/actions';
import {
  ContentDefault,
  CustomForm,
  InputCustomInvisible,
  TextareaCustomInvisible,
} from '../../styles/common';
import { Row } from '../../styles/grid';

export default function Note({ disabled }) {
  const lead_id = useSelector((state) => state.lead.lead.id);
  const loading = useSelector((state) => state.lead.note_activity_loading);
  const dispatch = useDispatch();

  function handleSubmit({ name, description }, { resetForm }) {
    if (name === '' || description === '') {
      toast.error('Preencha todos os campos para adicionar uma nova observação.');
    } else {
      dispatch(storeNote(lead_id, name, description));
      resetForm();
    }
  }

  return (
    <CustomForm onSubmit={handleSubmit}>
      <ContentDefault margin="1px 0 2px 0" groupMiddle>
        <InputCustomInvisible
          name="name"
          disabled={disabled}
          required
          placeholder="Titulo da observação"
        />
      </ContentDefault>
      <ContentDefault groupBottom>
        <TextareaCustomInvisible
          multiline="true"
          name="description"
          disabled={disabled}
          required
          placeholder="Escreva aqui sua observação"
        />
      </ContentDefault>
      <Row alignCenter justifyEnd>
        <Button isLoading={loading} padding="0" w="130px" height="40px" type="submit">
          Adicionar
        </Button>
      </Row>
    </CustomForm>
  );
}
