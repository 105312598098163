import styled from '@emotion/styled';
import { Input } from '@rocketseat/unform';

import { branco, cinzaClaro2, verdeKuppi } from '../../styles/colors';
import px2vw from '../../utils/px2vw';

export const Container = styled.div`
  background-color: ${cinzaClaro2};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  height: 100vh;
`;

export const Empty = styled.div`
  background-color: ${branco};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 50%;
`;

export const Content = styled.div`
  background-color: ${branco};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 50%;
  height: 100%;
  padding: 100px;
  padding-top: ${px2vw(120)};
  padding: ${(props) => props.padding && props.padding};

  form {
    width: 100%;
  }
`;

export const InputCustom = styled(Input)`
  border: 1px solid #dbdbdb !important;
  padding: 7px 10px !important;
  height: auto !important;
  margin: 0 !important;
  height: 37px;
  box-sizing: inherit !important;
  font-size: 14px !important;

  ::placeholder {
    font-size: 12px;
  }

  :focus {
    border: 1px solid #dbdbdb !important;
    box-shadow: none !important;
  }

  ${(props) =>
    props.group
      ? `
      width: calc(100% - 40px) !important;
      border-top-left-radius: 5px !important;
      border-bottom-left-radius: 5px !important;
      border-right: none !important;
      :focus {
        border-right: none !important;
      }
    `
      : `
      width: 100% !important;
      border-radius: 5px !important;
    `}
`;

export const Label = styled.div`
  margin-top: 20px;
  font-weight: bold;
  margin-bottom: 5px;
`;

export const SubmitButton = styled.button`
  margin-top: 40px;
  width: 100%;
  height: 37px;
  color: ${branco};
  font-weight: bold;
  background: ${verdeKuppi} !important;
  box-shadow: none !important;
  border-radius: 5px;
  font-size: 12px;
  line-height: 12px;

  :hover {
    color: ${cinzaClaro2};
  }
`;

export const ForgotButton = styled.button`
  margin-top: 5px;
  border: none;
  background: none;
  color: ${verdeKuppi};

  :focus {
    background: transparent;
  }
`;

export const ForgotBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  width: 100%;
`;

export const ContentLogo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: ${px2vw(30)};
`;

export const LogoImageBox = styled.div`
  position: relative;
  width: 100%;
  height: 100vw;
  max-width: ${px2vw(262)};
  max-height: ${px2vw(147)};

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;

export const LogoImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const GroupBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 37px;
`;

export const ButtonIcon = styled.button`
  width: 40px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 1px solid #dbdbdb;
  border-left: none;
  height: 37px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;

  :focus {
    background: transparent;
  }
`;

export const LoadingBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const LeftImageBox = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const LeftImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
