import { Box, Text, Tooltip } from '@chakra-ui/react';
import { differenceInDays, format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { RiErrorWarningLine } from 'react-icons/ri';

import { Clients } from '~/models/Clients';

interface ClientContractWarningProps {
  client: Clients;
  offTooltip?: boolean;
}

const ClientContractWarning: React.FC<ClientContractWarningProps> = ({ client, offTooltip }) => {
  const [ending, setEnding] = useState(false);
  const [daysToFinish, setDaysToFinish] = useState(0);

  useEffect(() => {
    const now = new Date();
    const differenceToFinish = differenceInDays(new Date(client?.finish_validity_at), now);

    if (client?.finish_validity_at && differenceToFinish <= 30) {
      setEnding(true);
      setDaysToFinish(differenceToFinish);
    }
  }, [client]);

  return (
    <>
      {ending && (
        <Tooltip
          isDisabled={offTooltip}
          placement="top"
          label={
            <Text as="span" w="100%" textAlign="center">
              <Text as="span">Falta</Text>
              <Text as="span" fontWeight="bold">
                {' '}
                {daysToFinish} {daysToFinish === 1 ? 'dia' : 'dias'}{' '}
              </Text>
              <Text as="span">
                para encerrar <br /> o contrato (
                {format(new Date(client?.finish_validity_at), 'dd/MM/yyyy')})
              </Text>
            </Text>
          }
          hasArrow
          shouldWrapChildren
        >
          <Box as={RiErrorWarningLine} height="25px" width="25px" color="red.500" />
        </Tooltip>
      )}
    </>
  );
};

export default ClientContractWarning;
