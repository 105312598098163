import { toast } from 'react-toastify';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { Clients } from '~/models/Clients';
import { RootState } from '~/stores';

import { apiKuppido } from '../../../services/api';
import { endAction, setActiveClients, setInactiveClients } from './actions';
import { logout } from '../auth/actions';

const getState = (state: RootState) => state;

export function* indexActiveClients() {
  try {
    const state = yield select(getState);
    const userInfoId = state.user.userInfo.id;
    const { master } = state.auth;

    const response = yield call(
      apiKuppido.get,
      `/${master ? 'master/' : ''}user-info/${userInfoId}/clients/active`,
    );

    yield put(setActiveClients(response.data));
  } catch (err: any) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(endAction());
      toast.error('Não foi possível atualizar seus clientes.');
    }
  }
}

export function* indexInactiveClients() {
  try {
    const state = yield select(getState);
    const userInfoId = state.user.userInfo.id;
    const { master } = state.auth;

    const response = yield call(
      apiKuppido.get,
      `/${master ? 'master/' : ''}user-info/${userInfoId}/clients/inactive`,
    );

    yield put(setInactiveClients(response.data));
  } catch (err: any) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(endAction());
      toast.error('Não foi possível atualizar seus clientes.');
    }
  }
}

export default all([
  takeLatest('@clients/INDEX_ACTIVE_CLIENTS', indexActiveClients),
  takeLatest('@clients/INDEX_INACTIVE_CLIENTS', indexInactiveClients),
]);
