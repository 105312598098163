export function indexActiveContracts(dashboard) {
  return {
    type: '@financial/INDEX_ACTIVE_CONTRACTS',
    payload: { dashboard },
  };
}

export function indexOthersContracts() {
  return {
    type: '@financial/INDEX_OTHERS_CONTRACTS',
  };
}

export function setActiveContracts(contracts) {
  return {
    type: '@financial/SET_ACTIVE_CONTRACTS',
    payload: { contracts },
  };
}

export function setOthersContracts(contracts) {
  return {
    type: '@financial/SET_OTHERS_CONTRACTS',
    payload: { contracts },
  };
}

export function endAction() {
  return {
    type: '@financial/END_ACTION',
  };
}

export function getPayablesData(payables, dashboard) {
  return {
    type: '@financial/GET_PAYABLE_DATA',
    payload: { payables, dashboard },
  };
}

export function setPayablesData(payables) {
  return {
    type: '@financial/SET_PAYABLE_DATA',
    payload: { payables },
  };
}

export function getRecipientData(recipient) {
  return {
    type: '@financial/GET_RECIPIENT_DATA',
    payload: { recipient },
  };
}

export function setRecipientData(recipient) {
  return {
    type: '@financial/SET_RECIPIENT_DATA',
    payload: { recipient },
  };
}

export function fetchSubscriptions() {
  return {
    type: '@financial/FETCH_SUBSCRIPTIONS',
  };
}

export function setSubscriptions(subscriptions) {
  return {
    type: '@financial/SET_SUBSCRIPTIONS',
    payload: {
      subscriptions,
    },
  };
}

export function saveContract(contract) {
  return {
    type: '@financial/SAVE_CONTRACT',
    payload: {
      contract,
    },
  };
}
