import React, { createContext, useContext, useReducer } from 'react';

import AdvertiserSubscription from '~/models/AdvertiserSubscription';

type Action =
  | { type: 'setAdvertiserSubscription'; payload: AdvertiserSubscription }
  | { type: 'clear' };
type State = {
  advertiserSubscription?: AdvertiserSubscription;
};
type Dispatch = React.Dispatch<Action>;

const AdvertiserSubscriptionStateContext = createContext<State | undefined>({});
const AdvertiserSubscriptionDispatchContext = createContext<Dispatch | undefined>(undefined);

function advertiserSubscriptionReducer(state: State, action: Action) {
  switch (action.type) {
    case 'setAdvertiserSubscription':
      return {
        advertiserSubscription: action.payload,
      };
    case 'clear':
      return {};
    default:
      return state;
  }
}

function AdvertiserSubscriptionProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(advertiserSubscriptionReducer, {});
  return (
    <AdvertiserSubscriptionStateContext.Provider value={state}>
      <AdvertiserSubscriptionDispatchContext.Provider value={dispatch}>
        {children}
      </AdvertiserSubscriptionDispatchContext.Provider>
    </AdvertiserSubscriptionStateContext.Provider>
  );
}

function useAdvertiserSubscription(): [State, Dispatch] {
  const state = useContext(AdvertiserSubscriptionStateContext);
  const dispatch = useContext(AdvertiserSubscriptionDispatchContext);

  if (!state && !dispatch) {
    throw new Error('useAdvertiserSubscription must be used with AdvertiserSubscriptionProvider');
  }
  return [state, dispatch];
}

export { AdvertiserSubscriptionProvider, useAdvertiserSubscription };
